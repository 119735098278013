import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from 'src/app/core/services/auth/auth.service';
import { IRegistration } from '../../../../registration.model';
import { TimerServiceService } from 'src/app/timer-service.service';
import { SmsAuthCodesService } from 'src/app/core/services/sms-auth-code/sms-auth-code.service';
import { SmsAuthCodeRequest } from 'src/app/core/models/sms-auth-code/sms-auth-code-request.model';
import { SmsAuthCodeCheckResult } from 'src/app/core/models/sms-auth-code/sms-auth-code-check-result.model';
import { AspNetRole } from '../../../../features/superadmin-area/models/asp-net-role.model';
import { AnAlertComponent } from 'src/app/blocks/modals/an-alert/an-alert.component';
import { SimpleModalService } from 'ngx-simple-modal';

declare var $: any;
declare function ucfirst(str: string): string;
declare function openModal(string, closeFn, bodyFn): Promise<boolean>;
declare function closeModal(): void;
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;

@Component({
  selector: 'app-modal-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  @Input() supportEmail: string = '';

  errors = new Map();
  loading: boolean = false;

  newPasswordVisible: boolean = false;
  passwordConfirmationVisible: boolean = false;

  mobileNumber: string | null = null;
  enteredSmsCode: string | null = null;
  smsAuthCodeRequest: SmsAuthCodeRequest = new SmsAuthCodeRequest();
  smsAuthCodeRequestId: string | null = null;
  smsAuthCodeCheckResult: SmsAuthCodeCheckResult = new SmsAuthCodeCheckResult();

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    public ts: TimerServiceService,
    private smsAuthCodeService: SmsAuthCodesService,
    private modalService: SimpleModalService,
  ) { }

  async generateCode(): Promise<string | void> {

    this.enteredSmsCode = "";
    let stdPhone: string = this.mobileNumber ?? '';
    if (stdPhone?.length == 0) {
      return Promise.reject("Невозможно отправить SMS-код - в профиле не указан номер телефона.");
    }
    let numberPattern = /\d+/gi;
    stdPhone = stdPhone.match(numberPattern)?.join('') ?? '';

    if (stdPhone.length < 11) {
      return Promise.reject("В номере телефона должно быть минимум 11 чисел");
    }
    stdPhone = stdPhone.match(numberPattern)?.join('') ?? '';

    this.smsAuthCodeRequest = new SmsAuthCodeRequest();
    return this.smsAuthCodeService.sendCode(stdPhone).then(resp => {
      this.smsAuthCodeRequest = resp;
      this.smsAuthCodeRequestId = this.smsAuthCodeRequest.requestId;
    });


  }

  async checkCode(requestId: string): Promise<void> {
    this.smsAuthCodeCheckResult = await this.smsAuthCodeService.checkAuthCode(requestId ?? '', this.enteredSmsCode ?? '', false);
    ///TODO: Check code
  }

  async ngOnInit(): Promise<void> {

  }
  async register(form: any) {

    let request: IRegistration = {
      Surname: form.surname.value,
      Name: form.name.value,
      MiddleName: form.middleName.value,
      Email: form.email.value,
      PhoneNumber: form.phoneNumber.value,
      Password: form.password.value,
      ConfirmPassword: form.confirmPassword.value,
      AcceptPolicy: form.acceptPolicy.checked ? 'true' : '',
      AcceptTerms: 'true',
      Language: this.cookieService.get('locale').toLocaleLowerCase(),
    };


    this.loading = true;


    let self = this;

    this.errors = await this.authService.verify(request);

    this.loading = false;
    if (this.errors.size==0) {
      await this.generateCode();


      this.ts.smsTmrStart(() => {
        this.generateCode();
      });

      if (self.smsAuthCodeRequest === null)
        return;

      self.smsAuthCodeRequestId = self.smsAuthCodeRequest.requestId;

      if ((self.smsAuthCodeRequestId ?? '').length > 0) {
        openModal("#modal-sms-confirm-reg", (d) => {
          d.reject(false); //Modal was closed by clicking on cross
        }, (d) => {
          $("#modal-sms-confirm-reg .btn.btn--primary.h-50.w-full.mb-20.btOk").click(function () {
            d.resolve(true);
          });
          $("#modal-sms-confirm-reg .btCancel").click(function () {
            self.enteredSmsCode = '';
            d.reject(false);
          });
        }).then(async () => {
          self.checkCode(self.smsAuthCodeRequestId ?? '').then(async () => {
            if (self.smsAuthCodeCheckResult !== null) {
              if (self.smsAuthCodeCheckResult.status) {
                this.errors = await self.authService.register(request);
                if (this.errors.size) {
              //    debugger;
                  await this.modalService.addModal(AnAlertComponent,
                    {
                      title: "", body: 'Возникла проблема при регистрации: ' + this.errors["error"] + '. Проверьте правильность заполнения регистрационной формы и повторите попытку. ' +
                        'Если у вас возникли вопросы - обратитесь<br> в <a href="#" class="tdu font-semibold">техподдержку</a>.'
                    }).toPromise<boolean>();

                  //alert('Возникла проблема при регистрации: ' + this.errors["error"] + '. Проверьте правильность заполнения регистрационной формы и повторите попытку.');
                }

                if (self.authService.nextPoupId !== '') {
                  self.authService.login(request.Email, request.Password).then(
                    () => {
                      self.ts.smsTmrStop();
                      closeModal();
                      $(self.authService.nextPoupId).modal();
                    }, () => {
                      closeModal();
                      self.ts.smsTmrStop();
                    //  alert("Возникла проблема при регистрации. Обратитесь к администратору");
                    }
                  );

                } else {
                  self.ts.smsTmrStop();
                  closeModal();
                  $('#registration-success').modal();
                }
              }
              else {
                await this.modalService.addModal(AnAlertComponent,
                  { title: "", body: self.smsAuthCodeCheckResult.errorText + '<br><br>Если у вас возникли вопросы - обратитесь<br> в <a href="#" class="tdu font-semibold">техподдержку</a>.' }).toPromise<boolean>();

                //alert("Возникла проблема: " + self.smsAuthCodeCheckResult.errorText + ". Обратитесь к администратору.");
              }
            }
            else {
              closeModal();
              self.ts.smsTmrStop();
          //    alert("Возникла проблема при проверке кода из SMS. Обратитесь к администратору.");
            }
          });

        }, () => {
          closeModal();
          self.ts.smsTmrStop();
        });
      }
    }
    else {
   //   alert("Проверьте данные...");
    }
  }

}
