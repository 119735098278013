import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';

import { StaticContentModule } from '../core/components/static-content/static-content.module';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { EditTrComponent } from './components/edit-tr/edit-tr.component';
import { MembersFilterComponent } from './components/members-filter/members-filter.component';
import { MembersListComponent } from './components/members-list/members-list.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PackageComponent } from './components/package/package.component';
import { PackagesSliderComponent } from './components/packages-slider/packages-slider.component';
import { PackagesComponent } from './components/packages/packages.component';
import { PagerComponent } from './components/pager/pager.component';
import { PublicationCardsComponent } from './components/publication-cards/publication-cards.component';
import { PublicationsFilterComponent } from './components/publications-filter/publications-filter.component';
import { PublicationsComponent } from './components/publications/publications.component';
import { RemoveTrComponent } from './components/remove-tr/remove-tr.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SortChevronComponent } from './components/sort-chevron/sort-chevron.component';
import { SortableThComponent } from './components/sortable-th/sortable-th.component';
import { TinymceComponent } from './components/tinymce/tinymce.component';
import { Top5BottomComponent } from './components/top5-bottom/top5-bottom.component';
import { Top5TopComponent } from './components/top5-top/top5-top.component';
import { CurrencyRublePipe } from './pipes/currency-ruble.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CurrencyRubleSymbolPipe } from './pipes/currency-ruble-symbol.pipe';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    EditTrComponent,
    MembersFilterComponent,
    MembersListComponent,
    PackagesSliderComponent,
    PagerComponent,
    PublicationCardsComponent,
    PublicationsComponent,
    RemoveTrComponent,
    SortChevronComponent,
    SortableThComponent,
    TinymceComponent,
    Top5BottomComponent,
    Top5TopComponent,
    PublicationsFilterComponent,
    SidebarComponent,
    PackagesComponent,
    PackageComponent,
    NotificationsComponent,
    CurrencyRublePipe,
    CurrencyRubleSymbolPipe
  ],
  imports: [
    CommonModule,
    EditorModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    StaticContentModule,
    SlickCarouselModule
  ],
  exports: [
    BreadcrumbsComponent,
    MembersFilterComponent,
    MembersListComponent,
    PackagesSliderComponent,
    PagerComponent,
    PublicationCardsComponent,
    PublicationsComponent,
    RemoveTrComponent,
    SortChevronComponent,
    SortableThComponent,
    TinymceComponent,
    Top5BottomComponent,
    Top5TopComponent,
    EditTrComponent,
    PackagesComponent,
    SidebarComponent,
    PackageComponent,
    NotificationsComponent,
    StaticContentModule,
    CurrencyRublePipe,
    CurrencyRubleSymbolPipe,
    SlickCarouselModule
  ]
})
export class SharedModule {}
