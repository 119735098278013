import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { environment } from 'src/environments/environment';
import { Deal } from '../../models/deals/deal.model';
import { NewDeal } from '../../models/deals/new.deal.model';
import { DealSummary } from '../../models/deals/deal-summary.model';
import { ExecuteDealResult } from '../../models/deals/deal.execute.result.model';
import { formatNumber } from '@angular/common';
import { Utils } from 'src/app/core/utils/utils';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { GeneralResponse } from 'src/app/shared/models/GeneralResponse.model';
import { DealTypesEnum } from 'src/app/core/enums/deal-types.enum';
import { DealStatusesEnum } from 'src/app/core/enums/deal-statuses.enum';


@Injectable({
  providedIn: 'root'
})
export class DealsService {

  locale = 'RU';
  currentUserId = '';

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private authService: AuthService,
    private utils: Utils
  ) {
    //PM: temporary use only russian language
    this.locale = 'RU';//this.cookieService.get('locale');
    this.currentUserId = this.authService.userData.id;
  }

  private formatSecondSideName(deal: Deal) {
    deal.secondSideNameFormatted = '';
    if (deal.relatedCustomerId == this.currentUserId) {
      if (this.locale == 'RU') {
        //    if (deal.relatedSellerSurname) deal.secondSideNameFormatted = `${deal.relatedSellerSurname} `;
        //    deal.secondSideNameFormatted += deal.relatedSellerName;
        //    if (deal.relatedSellerMiddleName) deal.secondSideNameFormatted += ` ${deal.relatedSellerMiddleName}`;
        //  } else {
        //    if (deal.relatedSellerSurnameLatin) deal.secondSideNameFormatted = `${deal.relatedSellerSurnameLatin} `;
        //    deal.secondSideNameFormatted += deal.relatedSellerNameLatin;
        //    if (deal.relatedSellerMiddleNameLatin) deal.secondSideNameFormatted += ` ${deal.relatedSellerMiddleNameLatin}`;
        //  }
        //if (deal.relatedPublicationDealTypeName == 1) {
        if (deal.relatedDealTypeCode == 'd_sale') {
          deal.relatedPublicationDealTypeNameFormatted = 'Покупка';
        }
        else {
          deal.relatedPublicationDealTypeNameFormatted = deal.relatedPublicationDealTypeName;
        }


        if (deal.relatedSellerSurname) deal.secondSideNameFormatted = `${deal.relatedSellerSurname} `;
        deal.secondSideNameFormatted += deal.relatedSellerName;
        if (deal.relatedSellerMiddleName) deal.secondSideNameFormatted += ` ${deal.relatedSellerMiddleName}`;
      } else {
        if (deal.relatedSellerSurnameLatin) deal.secondSideNameFormatted = `${deal.relatedSellerSurnameLatin} `;
        deal.secondSideNameFormatted += deal.relatedSellerNameLatin;
        if (deal.relatedSellerMiddleNameLatin) deal.secondSideNameFormatted += ` ${deal.relatedSellerMiddleNameLatin}`;
      }
    } else {
      if (this.locale == 'RU') {
        //if (deal.relatedDealStatusId == 1) {
        deal.relatedDealStatusNameFormatted = deal.relatedDealStatusName;
        //; = "Покупка";
        //}

        if (deal.relatedCustomerSurname) deal.secondSideNameFormatted = `${deal.relatedCustomerSurname} `;
        deal.secondSideNameFormatted += deal.relatedCustomerName;
        if (deal.relatedCustomerMiddleName) deal.secondSideNameFormatted += ` ${deal.relatedCustomerMiddleName}`;
      } else {
        if (deal.relatedCustomerSurnameLatin) deal.secondSideNameFormatted = `${deal.relatedCustomerSurnameLatin} `;
        deal.secondSideNameFormatted += deal.relatedCustomerNameLatin;
        if (deal.relatedCustomerMiddleNameLatin) deal.secondSideNameFormatted += ` ${deal.relatedCustomerMiddleNameLatin}`;
      }
    }
  }


  async getList(dealStatusesCodes: string = '', dealTypesCodes: string = ''): Promise<Deal[]> {
    const ratings = ['A', 'B', 'C'];

    const data = await this.httpClient.get<Deal[]>(
      `${environment.apiUrl}/api/deals/getAll?dealStatusesCodes=${dealStatusesCodes}&dealTypesCodes=${dealTypesCodes}`
    ).toPromise();

    if (data) {
      data.map(deal => {
        deal.amountFormatted = formatNumber(deal.amount, 'ru-RU', '1.2-2');
        this.formatSecondSideName(deal);
        //deal.relatedPublicationDealTypeNameFormatted = this.locale == 'RU' ?
        //  deal.relatedPublicationDealTypeName : deal.relatedPublicationDealTypeNameInEnglish;
        deal.relatedPublicationDealTypeNameFormatted = deal.relatedCustomerId == this.currentUserId ?
          (deal.relatedDealTypeCode == DealTypesEnum.Sell ? 'Покупка' : 'Продажа') : deal.relatedPublicationDealTypeName;
        deal.relatedDealStatusNameFormatted = this.locale == 'RU' ?
          deal.relatedDealStatusName : deal.relatedDealStatusNameInEnglish;

        deal.packageRatingName = ratings[deal.packageRating ?? 2] ?? 'C';
        deal.packageOwnerRatingName = ratings[deal.packageOwnerRating ?? 0] ?? 'A';
        //deal.clubNameFormatted = deal.packageCertsCount == 1 && deal.clubName ?
        ///  this.utils.ucwords(deal.clubName) : 'Mixed'
        deal.canBeApproved = this.isValidForApprove(deal);
        deal.clubNameFormatted = deal.clubName ? this.utils.ucwords(deal.clubName) : '';
        deal.clubPhoto = environment.mainUrl +
          (deal.clubPhoto ? `photos/clubs/${deal.clubPhoto}` : 'assets/img/image-empty.png');
      });
    }

    //console.log(data);
    return data;
  }

  isValidForApprove(deal: Deal): boolean {
    if (deal.relatedCustomerId != this.currentUserId)
      return false;
    else {      
      if (deal.relatedDealTypeCode != DealTypesEnum.Buy) {
        return false;
      }
      else {
        if (deal.relatedDealStatusCode == DealStatusesEnum.InProgress) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  }

  async add(deal: NewDeal): Promise<GeneralResponse<number>> {
    var result = new GeneralResponse<number>();
    try {
      result.Response = await this.httpClient.post<number>(`${environment.apiUrl}/api/deals/add`, JSON.stringify(deal)).toPromise();
    }
    catch (err: any) {
      result.Errors = this.utils.collectErrors(err);
    }

    return result;
  }

  async executeDeal(dealSummary: DealSummary): Promise<GeneralResponse<ExecuteDealResult>> {
    var result = new GeneralResponse<ExecuteDealResult>();

    try {
      result.Response = await this.httpClient.post<ExecuteDealResult>(`${environment.apiUrl}/api/deals/executeDeal`, JSON.stringify(dealSummary)).toPromise();
    } catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
    }

    return result;
  }

  async compliteDeal(dealSummary: DealSummary): Promise<GeneralResponse<ExecuteDealResult>> {
    var result = new GeneralResponse<ExecuteDealResult>();

    try {
      result.Response = await this.httpClient.post<ExecuteDealResult>(`${environment.apiUrl}/api/deals/transferCertificate`, JSON.stringify(dealSummary)).toPromise();
    } catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
    }

    return result;
  }

  async complitePurchaseRequisitionDeal(dealId: number): Promise<GeneralResponse<boolean>> {
    let result = new GeneralResponse<boolean>();

    try {
      result.Response = await this.httpClient.post<boolean>(`${environment.apiUrl}/api/deals/complitePurchaseRequisitionDeal`,
        JSON.stringify(dealId)).toPromise();
    }
    catch (err: any) {
      result.Errors = this.utils.collectErrors(err);
    }

    return result;
  }

  async getDealIdByRelatedPublicationId(relatedPublicationId: number): Promise<GeneralResponse<number>> {
    let result = new GeneralResponse<number>();

    try {
      result.Response = await this.httpClient.get<number>(`${environment.apiUrl}/api/deals/getDealIdByRelatedPublicationId/${relatedPublicationId}`,
        { headers: this.utils.jsonHeaders() }).toPromise();
    }
    catch (err: any) {
      result.Errors = this.utils.collectErrors(err);
    }

    return result;
  }

  async updateDeal(deal: NewDeal): Promise<GeneralResponse<boolean>> {
    let result = new GeneralResponse<boolean>();

    try {
      result.Response = await this.httpClient.post<boolean>(`${environment.apiUrl}/api/deals/updateDeal`,
        JSON.stringify(deal)).toPromise();
    }
    catch (err: any) {
      result.Errors = this.utils.collectErrors(err);
    }

    return result;
  }

  async transferDealFunds(dealId: number): Promise<GeneralResponse<boolean>> {
    let result = new GeneralResponse<boolean>();

    try {
      result.Response = await this.httpClient.post<boolean>(`${environment.apiUrl}/api/deals/transferDealFunds`,
        JSON.stringify(dealId)).toPromise();
    }
    catch (err: any) {
      result.Errors = this.utils.collectErrors(err);
    }

    return result;
  }
}
