import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { UserSessionData } from '../../models/users-sessions-data/users-sessions-data.model';
import { Utils } from 'src/app/core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class UsersSessionsDataService {
  private serverUrl = `${environment.apiUrl}/api/Account`;

  constructor(
    private httpClient: HttpClient,
    private utils: Utils
  ) { }

  async getUserSessionData(): Promise<UserSessionData> {
    try {
      return await this.httpClient.get<UserSessionData>(
        `${this.serverUrl}/GetUserSessionData`,
        { headers: this.utils.jsonHeaders() }
      ).toPromise();
    } catch {
      return Promise.resolve(new UserSessionData());
    }
  }

  async setUserSessionData(sessionData: string): Promise<number> {
    let errors = new Map<string, any>();
    try {
      return await this.httpClient.post<number>(
        `${this.serverUrl}/SetUserSessionData`,
        sessionData || '',
        { headers: this.utils.jsonHeaders() }
      ).toPromise();
    } catch (response: any) {
      debugger;
      errors = this.utils.collectErrors(response);
      //this.errors.entries().next().value[1];
      console.error(errors.entries().next().value[1]);
      return Promise.resolve(0);

    }
  }

  async deleteUserSessionData(userSessionDataId: number): Promise<boolean> {
    try {
      return await this.httpClient.post<boolean>(
        `${this.serverUrl}/DeleteUserSessionData/${userSessionDataId}`,
        { headers: this.utils.jsonHeaders() }
      ).toPromise();
    } catch {
      return Promise.resolve(false);
    }
  }

}
