// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  // AnalyticsApi.proj
  analyticApiUrl: 'https://tst-trade.ru:6210',

  // AuthApi.proj
  authApiUrl: 'https://tst-trade.ru:6110',

  // SiteApi.proj
  apiUrl: 'https://tst-trade.ru:6101',

  // AdminApi.proj
  cmsApiUrl: 'https://tst-trade.ru:6097',

  // WebApi.proj
  webApiUrl: 'https://tst-trade.ru:6105',

  mainUrl: 'https://tst-trade.ru/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
