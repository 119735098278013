<div id="login" class="mw">
  <div class="mw__content">
    <div class="mw__head">
      <div class="mw__title">{{'Sign In' | translate}}</div>
      <span class="mw__line"></span>
    </div>
    <div class="mw__body">
      <form class="form" (ngSubmit)="login()">
        <div class="form__list mb3">

          <div class="error">
            <div class="error__common-message fz14 pb3" *ngIf="errors.get('Error')">
              {{errors.get('Error')[0] | translate}}
            </div>
          </div>

          <div class="form__item" [class.error]="errors.get('Email')" (input)="errors.delete('Email')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">E-mail *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center"
                [attr.data-tooltip]="'Enter your valid e-mail' | translate"></div>
            </div>
            <input type="email" class="form__input" placeholder="E-mail" required [(ngModel)]="email" name="email">
            <div class="error__message" *ngIf="errors.get('Email')">{{errors.get('Email')[0] | translate}}</div>
          </div>

          <div class="form__item mb4" [class.error]="errors.get('Password')" (input)="errors.delete('Password')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Password' | translate}} *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center"
                [attr.data-tooltip]="'Enter your password' | translate"></div>
            </div>
            <div class="form__group">
              <input [type]="passwordVisible ? 'text' : 'password'" class="form__input pl2"
                placeholder="{{'Password' | translate}}" required [(ngModel)]="password" name="password">
              <button type="button" class="form__input-icon" (click)="passwordVisible = !passwordVisible">
                <span class="js-pass-view ma" [ngClass]="passwordVisible ? 'ic-eye' : 'ic-eye-off'"></span>
              </button>
            </div>
            <div class="error__message" *ngIf="errors.get('Password')">{{errors.get('Password')[0] | translate}}</div>
          </div>

        </div>
        <div class="form__item mb2">
          <button [disabled]="loading" type="submit" class="btn w100" [attr.data-tooltip]="'DoSignIn' | translate"
            data-position="bottom center">{{(loading ? 'Loading...' : 'DoSignIn') | translate}}</button>
        </div>
        <div class="form__item mb3">
          <a href="#" type="button" class="btn btn_wt w100" data-modal="registration-warning"
            [attr.data-tooltip]="'DoSignUp' | translate" data-position="bottom center">{{'DoSignUp' | translate}}</a>
        </div>
        <div class="form__item tc">
          <a href="javascript:;" onclick="openModal('#password-recovery')" class="mw__stitle form__link" [attr.data-tooltip]="'Forgot password?' | translate"
            data-position="bottom center">{{'Forgot password?' | translate}}</a>
        </div>
        <div class="form__item mb0 tc">
          <a href="mailto:{{supportEmail}}" class="tdu" [attr.data-tooltip]="'Call support' | translate"
            data-position="bottom center">
            {{'Call support' | translate}}
          </a>
        </div>
      </form>
    </div>
  </div>
</div>