<div id="password-recovery" class="mw">
  <div class="mw__content">
    <div class="mw__head">
      <div class="mw__title">{{'Password recovery' | translate}}</div>
      <span class="mw__line"></span>
      <div class="mw__stitle mt2" *ngIf="linkSent">{{'PasswordRecoveryLinkSent' | translate}}</div>
    </div>
    <div class="mw__body">
      <form class="form" (ngSubmit)="sendLink()">
        <div class="form__list mb3">

          <div class="form__item" [class.error]="errors.get('Email')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Enter e-mail' | translate}}</div>
            </div>
            <input type="email" name="email" [(ngModel)]="email" class="form__input" placeholder="E-mail"
              (ngModelChange)="errors.delete('Email')">
            <div class="error__message" *ngIf="errors.get('Email')">
              {{errors.get('Email')[0] | translate}}
            </div>
          </div>

          <!-- <div class="form__item mb4">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Enter code from picture' | translate}}</div>
            </div>
            <div class="form-two df aic jcsb">
              <input type="password" class="form__input pl2" placeholder="{{'Code from picture' | translate}}">
              <div class="form__recaptcha">
                <img src="/assets/img/recaptcha.jpg" alt="" loading="lazy">
              </div>
            </div>
          </div> -->

        </div>
        <div class="form__item mb2">
          <button [disabled]="loading" type="submit" class="btn w100"
            [attr.data-tooltip]="(loading ? 'Loading...' : (linkSent ? 'Resend' : 'Recover')) | translate"
            data-position="bottom center">
            {{(loading ? 'Loading...' : (linkSent ? 'Resend' : 'Recover')) | translate}}
          </button>
        </div>
        <div class="form__item mb0 tc">
          <a href="mailto:{{supportEmail}}" class="tdu" [attr.data-tooltip]="'Call support' | translate"
            data-position="bottom center">
            {{'Call support' | translate}}
          </a>
        </div>
      </form>
    </div>
  </div>
</div>