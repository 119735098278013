export class UserFinancialTransaction {
  constructor() {}

  financialTransactionId: number = 0;
  financialTransactionTypeCode: string = '';
  financialTransactionApproveDateTime: string = '';
  financialTransactionApprovedAmount: number = 0;
  financialTransactionApprovedAmountFormatted: string = '';
  financialTransactionPlannedAmount: number = 0;
  financialTransactionPlannedAmountFormatted: string = '';
  financialTransactionCreationDateTime: string = '';
  financialTransactionCalculatedCommissionValue: number = 0;
  financialTransactionCalculatedCommissionValueFormatted: string = '';
  financialTransactionCalculatedTaxValue: number = 0;
  financialTransactionCalculatedTaxValueFormatted: string = '';
  financialTransactionTypeName: string = '';

  financialTransactionRelatedUserId: string = '';
  financialTransactionUserEmail: string = '';
  financialTransactionUserFullName: string = '';
  financialTransactionUseDescription: boolean = false;  
}

export enum FinancialTransactionTypesEnum {
  FINANCIAL_TRANSACTION_UNSET = 'Not set', // Не установлен,
  FINANCIAL_TRANSACTION_WITHDRAW = 'Withdraw', // Вывод средств
  FINANCIAL_TRANSACTION_REPLENISH = 'Deposit', // Пополнение счёта
  FINANCIAL_TRANSACTION_BUY = 'Package buy', // Покупка пакета
  FINANCIAL_TRANSACTION_SELL = 'Package sale', // Продажа пакета
  FINANCIAL_TRANSACTION_PENALTY = 'Penalties', // Штрафные санкции
  FINANCIAL_TRANSACTION_CORRECTION = 'Correction', // Корректировка
  FINANCIAL_TRANSACTION_REFUND = 'Refund', // Возврат денежных средств по заявке
  FINANCIAL_TRANSACTION_HOLD = 'Hold' // Резервирование денежных средств
}
