import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import * as dayjs from 'dayjs';
import { UserNotification } from '../../models/user-notification.model';
import { SimpleModalService } from 'ngx-simple-modal';
import { NotificationRequsitionModalComponent } from 'src/app/blocks/modals/notification-requsition-modal/notification-requsition-modal.component';
declare function playAudio(): void;
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  constructor(
    public ns: NotificationService,
    private modalService: SimpleModalService
  ) {}

  ngOnInit(): void {
    playAudio();
    this.ns.messagesArrived.subscribe(() => {
      playAudio();
    });
  }
  hoursAgo(dt: Date) {
    return Math.round(dayjs.duration(dayjs().diff(dayjs(dt))).asHours());
  }

  async notificationClick(mess: UserNotification) {
    switch (mess.type) {
      case 'PurchaseRequisitionRequest': {
        let smsConfirm = await this.modalService
          .addModal(NotificationRequsitionModalComponent, {
            notification: mess,
            data: {}
          })
          .toPromise<boolean>();
        break;
      }
    }
  }
}
