import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Utils } from '../utils/utils';

@Injectable()
export class JsonHeadersInterceptor implements HttpInterceptor {
  constructor(
    private utils: Utils
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiUrl =
      request.url.startsWith(environment.authApiUrl) ||
      request.url.startsWith(environment.apiUrl) ||
      request.url.startsWith(environment.webApiUrl) ||
      request.url.startsWith(environment.cmsApiUrl);
    if (isApiUrl) {
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
        }
      });
    }

    return next.handle(request);
  }
}
