export enum PublicationTypesEnum {
  ///Публикация заявки на покупку
  Buy = 'type_pub_offer_buy',
  ///Публикация пакета на продажу
  Sell = 'type_pub_packet',
  ///Публикация заявки на присоединение
  Add = 'type_pub_offer_add',
  ///Публикация аукциона
  Auction = 'type_pub_auction'
}
