import { Injectable } from '@angular/core';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Account } from '../../core/models/auth/account.model';
import { User } from '../../core/models/auth/user.model';
import { UserNotification } from '../models/user-notification.model';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  user: User | undefined;
  account: Account | undefined;
  ti: Observable<number> | undefined;
  TimerSub: Subscription | null = null;
  currDuration: number = 0;
  userMessages: UserNotification[] = [];
  LastMessageId: number = 0;
  messagesArrived = new Subject<number>();

  constructor(
    private authService: AuthService,
    private accountService: AccountService
  ) {
    this.authService.user.subscribe(async (user) => {
      this.user = user;
      this.account = await this.accountService.getUserAccount().toPromise();
      this.startPolling();
    });
  }
  startPolling() {
    this.LastMessageId = this.GetAllUserMessages();
    this.dealTmrStart(() => {
      this.LastMessageId = this.GetNewUserMMessages(this.LastMessageId);
    });
  }
  dealTmrStart(callback?, duration = 0) {
    if (this.TimerSub != null) {
      this.TimerSub.unsubscribe();
    }
    const cb = callback || (() => {});
    this.ti = interval(10000);
    this.TimerSub = this.ti.subscribe({
      next: (t) => {
        this.currDuration += 1;
        cb();
      }
    });
  }
  dealTmrStop() {
    if (this.TimerSub != null) {
      this.TimerSub.unsubscribe();
    }
    this.currDuration = 0;
  }
  GetAllUserMessages(): number {
    let ret = 0;
    ///TODO: Take all current user unread notifications from server
    let messages: UserNotification[] = [];

    this.userMessages = messages;
    if (this.userMessages.length > 0)
      ret = this.userMessages[this.userMessages.length - 1].id;

    return ret;
  }
  GetNewUserMMessages(startId: number): number {
    let ret = 0;
    let messages: UserNotification[] = [];

    ///TODO: Take all current user unread notifications from server starting from id startId
    if (messages.length > 0) {
      this.messagesArrived.next(messages.length);
      this.userMessages = [...this.userMessages, ...messages];
      ret = this.userMessages[this.userMessages.length - 1].id;
    }
    return ret;
  }
}
