import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BnNgIdleService } from 'bn-ng-idle';
import * as dayjs from 'dayjs';
import 'dayjs/locale/ru';
import * as duration from 'dayjs/plugin/duration';
import * as utc from 'dayjs/plugin/utc';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CookieService } from 'ngx-cookie-service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { defaultSimpleModalOptions, SimpleModalModule } from 'ngx-simple-modal';
import { ACCESS_TOKEN_NAME } from 'src/app/core/services/auth/auth.service';
import { PublicationService } from 'src/app/publication.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { AUTH_API_URL } from './app-injection.tokens';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuyWfComponent } from './blocks/buy-wf/buy-wf.component';
import { CardsListComponent } from './blocks/cards-list/cards-list.component';
import { ActivatePublicationModalComponent } from './blocks/modals/activate-publication-modal/activate-publication-modal.component';
import { AddRequisitionPackagesComponent } from './blocks/modals/add-requisition-packages/add-requisition-packages.component';
import { AgreementModalComponent } from './blocks/modals/agreement-modal/agreement-modal.component';
import { AnAlertComponent } from './blocks/modals/an-alert/an-alert.component';
import { ChangePriceModalComponent } from './blocks/modals/change-price-modal/change-price-modal.component';
import { DocumentModalComponent } from './blocks/modals/document-modal/document-modal.component';
import { JustSelectOperatorModalComponent } from './blocks/modals/just-select-operator-modal/just-select-operator-modal.component';
import { NotificationRequsitionModalComponent } from './blocks/modals/notification-requsition-modal/notification-requsition-modal.component';
import { OffersAcceptModalComponent } from './blocks/modals/offers-accept-modal/offers-accept-modal.component';
import { PriceAgreementModalComponent } from './blocks/modals/price-agreement-modal/price-agreement-modal.component';
import { RequsitionsProfileComponent } from './blocks/modals/requsitions-profile/requsitions-profile.component';
import { SelectOperatorModalComponent } from './blocks/modals/select-operator-modal/select-operator-modal.component';
import { SmsConfirmModalComponent } from './blocks/modals/sms-confirm-modal/sms-confirm-modal.component';
import { PackagesSliderComponent } from './blocks/packages-slider/packages-slider.component';
import { CoreModule } from './core/core.module';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { JsonHeadersInterceptor } from './core/interceptors/json-headers.interceptor';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ModalModule } from './front/modals/modal.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ChangeExpirationDateModalComponent } from './blocks/modals/change-expiration-date-modal/change-expiration-date-modal.component';
import { ChangeTaxModalComponent } from './features/superadmin-area/pages/manual-balance-management/change-tax-modal/change-tax-modal.component';
import { DepositingFundsModalComponent } from './blocks/modals/depositing-funds-modal/depositing-funds-modal.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/localizations/', '.json');
}
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
export function tokenGetter() {
  return localStorage.getItem(ACCESS_TOKEN_NAME);
}

registerLocaleData(localeRu);

dayjs.locale('ru');
dayjs.extend(duration);
dayjs.extend(utc);

@NgModule({
  declarations: [
    AppComponent,
    PackagesSliderComponent,
    CardsListComponent,
    BuyWfComponent,
    RequsitionsProfileComponent,
    OffersAcceptModalComponent,
    SmsConfirmModalComponent,
    AddRequisitionPackagesComponent,
    PriceAgreementModalComponent,
    AgreementModalComponent,
    DocumentModalComponent,
    NotificationRequsitionModalComponent,
    SelectOperatorModalComponent,
    JustSelectOperatorModalComponent,
    ChangePriceModalComponent,
    ChangeTaxModalComponent,
    ChangeExpirationDateModalComponent,
    ActivatePublicationModalComponent,
    AnAlertComponent,
    DepositingFundsModalComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule,
    SharedModule,
    DpDatePickerModule,
    SimpleModalModule.forRoot(
      { container: document.body },
      {
        ...defaultSimpleModalOptions,
        ...{
          closeOnEscape: true,
          closeOnClickOutside: false,
          wrapperDefaultClasses: 'smodal smodal--fade',
          wrapperClass: 'smodal--fade-in',
          animationDuration: 300,
          autoFocus: true
        }
      }
    ),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    SlickCarouselModule
  ],
  exports: [NgxMaskModule, DpDatePickerModule],
  providers: [
    PublicationService,
    CookieService,
    BnNgIdleService,
    { provide: LOCALE_ID, useValue: 'RU' },
    { provide: AUTH_API_URL, useValue: environment.authApiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonHeadersInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
