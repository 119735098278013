import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/core/services/auth/auth.service';

declare function openModal(string): void;

@Component({
  selector: 'app-modal-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  @Input() supportEmail: string = '';

  errors = new Map();
  loading: boolean = false;
  linkSent: boolean = false;
  email: string = '';
  captcha: string = 'abcd';

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
  ) { }

  async ngOnInit(): Promise<void> {
  }

  async sendLink(): Promise<void> {
    this.loading = true;
    this.errors = await this.authService.sendPasswordRecoveryLink(
      this.email,
      this.captcha,
      this.cookieService.get('locale').toLocaleLowerCase()
    );
    this.loading = false;
    if (!this.errors.size) {
      this.email = '';
      this.linkSent = true;
    }
  }
}
