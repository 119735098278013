export class Account {
  userId: string = '';
  name: string = '';
  nameLatin: string = '';
  nameFormatted: string = '';
  avatar: string = '/assets/img/user-empty.png';
  balance: number = 0.00;
  balanceFormatted: string = 'RUB 0,00';
  blockedForOperations: number = 0.00;
  blockedForOperationsFormatted: string = 'RUB 0,00';
  phoneNumber: string = '';
}
