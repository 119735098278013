import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Utils } from 'src/app/core/utils/utils';
import { environment } from 'src/environments/environment';
import { PublicationChangeStateResponse } from '../../models/publication-state/publication-change-state-response.model';

@Injectable({
  providedIn: 'root'
})
export class PublicationStateService {
  private serverUrl = `${environment.webApiUrl}/personal`;

  constructor(private httpClient: HttpClient, private utils: Utils) {}

  blockPublication(publicationId: number): Promise<void> {
    return this.httpClient
      .post<void>(
        `${environment.webApiUrl}/api/Publications/Block/${publicationId}`,
        { headers: this.utils.jsonHeaders() }
      )
      .toPromise();
  }

  async blockPublicationOld(
    publicationId: number
  ): Promise<PublicationChangeStateResponse> {
    try {
      return await this.httpClient
        .post<PublicationChangeStateResponse>(
          `${this.serverUrl}/blockPublication/${publicationId}`,
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();
    } catch {
      return Promise.resolve(new PublicationChangeStateResponse());
    }
  }

  unlockPublication(publicationId: number): Promise<void> {
    return this.httpClient
      .post<void>(
        `${environment.webApiUrl}/api/Publications/Unblock/${publicationId}`,
        {
          headers: this.utils.jsonHeaders()
        }
      )
      .toPromise();
  }

  async unlockPublicationOld(
    publicationId: number
  ): Promise<PublicationChangeStateResponse> {
    try {
      return await this.httpClient
        .post<PublicationChangeStateResponse>(
          `${this.serverUrl}/unlockPublication/${publicationId}`,
          {
            headers: this.utils.jsonHeaders()
          }
        )
        .toPromise();
    } catch {
      return Promise.resolve(new PublicationChangeStateResponse());
    }
  }

  async getPublicationLockState(
    publicationId: number
  ): Promise<PublicationChangeStateResponse> {
    try {
      return await this.httpClient
        .get<PublicationChangeStateResponse>(
          `${this.serverUrl}/getPublicationLockState/${publicationId}`,
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();
    } catch {
      return Promise.resolve(new PublicationChangeStateResponse());
    }
  }
}
