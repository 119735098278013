<div class="smodal-content">
    <div class="modal-header">
      <div data-fancybox-close (click)="Cancel()" class="modal-close"><svg class="icon"><use xlink:href="/assets/img/svg-sprite.svg#times-light"></use></svg></div>
   </div> 
    <div  [innerHTML]="popupHtml"  style="min-height:67vh">

    </div>
    <div #btnSubscribe class="smodal-footer mt2">
        <button type="button" class="btn btn--dark-outline" (click)="Cancel()">Закрыть</button>
      </div>
</div>