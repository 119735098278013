import { EventEmitter, Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { interval, Observable, Subscription } from 'rxjs';
import { Account } from './core/models/auth/account.model';
import { User } from './core/models/auth/user.model';
import { AuthService } from './core/services/auth/auth.service';
import { UsersSessionsDataService } from './core/services/users-sessions-data/users-sessions-data.service';

@Injectable({
  providedIn: 'root'
})
export class TimerServiceService {
  dealTimerSub: Subscription | null = null;
  msg = new EventEmitter<{ msg: String; obj: any }>();
  currDealDuration: number = 0;
  dealTime: string = '10:00';
  ti: Observable<number> | undefined;
  dealDetails: any = {
    price: 0,
    comission: 0,
    total: 0,
    publicationNumber: 0,
    publicationOwnerId: '',
    newPublicationId: 0
  };
  blocked: boolean = false;
  dealId: number = 0;
  smsTimerSub: Subscription | null = null;
  currSmsDuration: number = 0;
  smsTime: string = '4:00';
  ti1: Observable<number> | undefined;
  blockedDeals: Array<number>;
  packagePrice: number = 0;
  availableBalance: number = 0;
  distinctionAmount: number = 0;
  user: User | null = null;
  account: Account | null = null;
  UserData: any = null;
  Step: string = '';
  currentDealId: number = 0;
  subscriptions: Array<{ name: string; timer: Observable<number> }> =
    new Array();
  constructor(
    private uss: UsersSessionsDataService,
    private authService: AuthService
  ) {
    this.blockedDeals = new Array<number>();
  }
  initUserSession(): Promise<any> {
    if (this.authService.isAuthenticated()) {
      return this.uss.getUserSessionData().then(
        (ud) => {
          //debugger;
          let strUserData = ud?.sessionData || '{"deal":{ "id":"-1"}}';
          try {
            this.UserData = JSON.parse(strUserData);
            Promise.resolve(this.UserData);
          } catch {
            //debugger;
            this.UserData = { deal: null };
            Promise.resolve(this.UserData);
          }
        },
        () => {
          this.UserData = { deal: null };
          Promise.resolve(this.UserData);
        }
      );
    } else {
      return Promise.reject('Пользователь не авторизован');
    }
  }
  saveUserSession(stepId: string | null): any {
    //debugger;
    if (this.authService.isAuthenticated()) {
      if (
        !this.UserData ||
        !this.UserData.deal ||
        this.UserData == null ||
        this.UserData.deal == null
      ) {
        this.UserData = { deal: {} };
        this.initUserSession().then(() => {
          this.UserData.deal.currentStepId = stepId;
          this.UserData.deal.id = this.dealId;
          this.UserData.deal.currDealDuration = this.currDealDuration;
          this.UserData.deal.dealTime = this.dealTime;
          this.UserData.deal.currSmsDuration = this.currSmsDuration;
          this.UserData.deal.smsTime = this.smsTime;
          this.UserData.deal.dealDetails = this.dealDetails;
          this.UserData.deal.packagePrice = this.packagePrice;
          this.UserData.deal.availableBalance = this.availableBalance;
          this.UserData.deal.distinctionAmount = this.distinctionAmount;
          this.UserData.deal.user = this.user;
          this.UserData.deal.account = this.account;
          let strUserData = JSON.stringify(this.UserData);
          this.uss.setUserSessionData(strUserData);
        });
      } else {
        this.UserData.deal.currentStepId = stepId;
        this.UserData.deal.id = this.dealId;
        this.UserData.deal.currDealDuration = this.currDealDuration;
        this.UserData.deal.dealTime = this.dealTime;
        this.UserData.deal.currSmsDuration = this.currSmsDuration;
        this.UserData.deal.smsTime = this.smsTime;
        this.UserData.deal.dealDetails = this.dealDetails;
        this.UserData.deal.packagePrice = this.packagePrice;
        this.UserData.deal.availableBalance = this.availableBalance;
        this.UserData.deal.distinctionAmount = this.distinctionAmount;
        this.UserData.deal.user = this.user;
        this.UserData.deal.account = this.account;
        let strUserData = JSON.stringify(this.UserData);
        this.uss.setUserSessionData(strUserData);
      }
    }
  }
  clearSessionDeal(): void {
    if (this.authService.isAuthenticated()) {
      if (!this.UserData) {
        this.initUserSession();
      } else if (this.UserData.deal && this.UserData.deal.id) {
        this.UserData.deal = {};
        let strUserData = JSON.stringify(this.UserData);
        this.uss.setUserSessionData(strUserData);
      }
    }
  }

  applyUserSession(UserData: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      try {
        this.UserData = UserData;
        this.dealId = this.UserData.deal.id;
        this.currDealDuration = this.UserData.deal.currDealDuration;
        this.dealTime = this.UserData.deal.dealTime;
        this.currSmsDuration = this.UserData.deal.currSmsDuration;
        this.smsTime = this.UserData.deal.smsTime;
        this.dealDetails = this.UserData.deal.dealDetails;
        this.packagePrice = this.UserData.deal.packagePrice;
        this.availableBalance = this.UserData.deal.availableBalance;
        this.distinctionAmount = this.UserData.deal.distinctionAmount;
        resolve(UserData);
      } catch {
        reject(UserData);
      }
    });
  }

  dealBlocked(id: number) {
    ///TODO: connect to database;
    let idx = this.blockedDeals.indexOf(id);
    return idx >= 0;
  }

  dealTmrStart(callback?, duration = 0) {
    if (this.dealTimerSub != null) {
      this.dealTimerSub.unsubscribe();
    }
    this.currDealDuration = duration;
    this.dealTime = '10:00';
    const cb = callback || (() => {});
    this.ti = interval(1000);
    this.dealTimerSub = this.ti.subscribe((t) => {
      this.currDealDuration += 1;
      this.dealTime = dayjs
        .utc(
          dayjs
            .duration(600 - this.currDealDuration, 'seconds')
            .asMilliseconds()
        )
        .format('mm:ss');
      if (this.currDealDuration >= 599) {
        cb();
        this.dealTmrStop();
      }
    });
  }
  dealTmrStop() {
    if (this.dealTimerSub != null) {
      this.dealTimerSub.unsubscribe();
    }
    this.currDealDuration = 0;
    this.dealTime = '00:00';
  }

  smsTmrStart(callback?) {
    if (this.smsTimerSub != null) {
      this.smsTimerSub.unsubscribe();
    }
    this.currSmsDuration = 0;
    this.smsTime = '4:00';
    const cb = callback || (() => {});
    this.ti1 = interval(1000);
    this.smsTimerSub = this.ti1.subscribe((t) => {
      this.currSmsDuration += 1;
      this.smsTime = dayjs
        .utc(
          dayjs.duration(240 - this.currSmsDuration, 'seconds').asMilliseconds()
        )
        .format('mm:ss');
      if (this.currSmsDuration >= 239) {
        //this.smsTmrStop();
        this.currSmsDuration = 0;
        this.smsTime = '4:00';
        cb();
      }
    });
  }
  smsTmrStop() {
    if (this.smsTimerSub != null) {
      this.smsTimerSub.unsubscribe();
    }
    this.currSmsDuration = 0;
    this.smsTime = '00:00';
  }
}
