import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/core/utils/utils';
import { environment } from 'src/environments/environment';
import { Profile } from '../../models/profile/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private httpClient: HttpClient,
    private utils: Utils
  ) { }


  async get(): Promise<Profile> {
    let profile = await this.httpClient.get<Profile>(`${environment.apiUrl}/api/profile/get`, { headers: this.utils.jsonHeaders() }).toPromise();

    if (!profile) {
      profile = new Profile();
    }

    profile.avatar = profile.avatar ?
      `${environment.mainUrl}photos/users/${profile.avatar}` :
      '/assets/img/user-empty.png';

    return profile;
  }

  async getByUserId(userId: string): Promise<Profile> {
    let profile = await this.httpClient.get<Profile>(`${environment.apiUrl}/api/profile/GetById/${userId}`, { headers: this.utils.jsonHeaders() }).toPromise();

    if (!profile) {
      profile = new Profile();
    }

    profile.avatar = profile.avatar ?
      `${environment.mainUrl}photos/users/${profile.avatar}` :
      '/assets/img/user-empty.png';

    return profile;
  }


  async update(profile: Profile): Promise<Map<string, any>> {
    let errors = new Map<string, any>();
    try {
      await this.httpClient.post(`${environment.apiUrl}/api/profile/update`, profile).toPromise();
    } catch (response: any) {
      errors = this.utils.collectErrors(response);
    }
    return errors;
  }

  async updateUserProfile(profile: Profile): Promise<Map<string, any>> {
    let errors = new Map<string, any>();
    try {
      await this.httpClient.post(`${environment.apiUrl}/api/profile/UpdateUserProfile`, profile).toPromise();
    } catch (response: any) {
      errors = this.utils.collectErrors(response);
    }
    return errors;
  }

  
}
