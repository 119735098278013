import { formatNumber } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Account } from '../../models/auth/account.model';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {}

  getUserAccount(): Observable<Account> {
    if (!this.authService.isAuthenticated()) {
      return of(new Account());
    }

    return this.httpClient
      .get<Account>(`${environment.apiUrl}/api/account`)
      .pipe(
        map((account) => {
          if (!account) {
            return new Account();
          }

          return {
            ...account,
            avatar: account.avatar
              ? `${environment.mainUrl}photos/users/${account.avatar}`
              : '/assets/img/user-empty.png',
            balanceFormatted: `RUB ${formatNumber(
              account.balance,
              'ru-RU',
              '1.0-2'
            )}`,
            blockedForOperationsFormatted: `RUB ${formatNumber(
              account.blockedForOperations,
              'ru-RU',
              '1.0-2'
            )}`,
            nameFormatted:
              this.cookieService.get('locale') !== 'RU' && account.nameLatin
                ? account.nameLatin
                : account.name
          };
        }),
        catchError((error) => {
          // ToDo toast
          console.log('getUserAccount => error', error);
          return of(new Account());
        })
      );
  }
}
