import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isAuthenticated = this.authService.isAuthenticated();
    const isApiUrl =
      request.url.startsWith(environment.authApiUrl) ||
      request.url.startsWith(environment.apiUrl) ||
      request.url.startsWith(environment.webApiUrl) ||
      request.url.startsWith(environment.cmsApiUrl) ||
      request.url.startsWith(environment.analyticApiUrl);

    if (isAuthenticated && isApiUrl) {
      const token = this.authService.getToken();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request);
  }
}
