import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Claim } from '../../models/auth/claim.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { SecurityContext } from '../../models/auth/security-context.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public claims: Claim[] = [];
  private securityContextSubject: BehaviorSubject<SecurityContext>;
  public securityContext: Observable<SecurityContext>;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {
    this.authService.user.subscribe(async () => {
      await this.fetchClaims();
      this.securityContextSubject.next(this.makeSecurityContext());
    });
    this.securityContextSubject = new BehaviorSubject<SecurityContext>(this.makeSecurityContext());
    this.securityContext = this.securityContextSubject.asObservable();
  }

  public get getSecurityContext(): SecurityContext {
    return this.securityContextSubject.value;
  }

  async fetchClaims() {
    this.claims = await this.httpClient.get<Claim[]>(`${environment.apiUrl}/api/claims`).toPromise();
  }

  makeSecurityContext(): SecurityContext {
    let context = new SecurityContext();

    if (this.claims !== undefined) {
      this.claims.map(claim => {
        context[claim.type] = claim.value;
      });
    }

    return context; 
  }

  hasClaim(type: string, value: string): boolean {
    if (this.claims === undefined) return true;
    return this.claims.some(claim => claim.type === type && claim.value === value);
  }
}
