import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private translateService: TranslateService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if ([401, 403].indexOf(error.status) !== -1) {
        this.authService.logout();
      }

      if (request.url.startsWith(environment.cmsApiUrl)) {
        let msg = '';
        const err = error.error;
        if (typeof err === 'string' || err instanceof String) {
          msg = err.toString();
        }
        if (!msg && (err?.errors instanceof Object)) {
          let keys = Object.keys(error.error.errors);
          if (keys.length) {
            msg = keys.map(k => `${k}: ${error.error.errors[k].join(', ')}`).join('\n');
          }
        }
        if (!msg && (error.error instanceof ErrorEvent)) {
          msg = error.error.message;
        }
        if (!msg) {
          msg = error.message;
        }
        if (!msg) {
          msg = 'Unexpected error occured. See console log for details.';
        }

        if (msg.toLowerCase().indexOf('duplicate key') != -1) {
          msg = 'Duplicate key.';
        } else {
          msg = 'System error occured. Contact system administrator for further support.'
        }

        window.alert(this.translateService.instant(msg));
      }

      return throwError(error);
    }));
  }
}
