<div class="smodal-content w-480">
    
    <div class="text-center mb-20">
        <div class="font-alt text-xl">{{data["title"]}}</div>
        <div class="divider-sm df my-20"></div>
        <div class="mb-40 text-sm text-center lh-18 color-secondary">{{data["text"]}}</div>
    </div>

    <div class="df justify-center">
        <form action="#" method="POST" class="w-300 maw-full">
            <div class="mb-40">
                <div class="form-lbinfo">
                    <div class="form-lbinfo__title font-alt">Введите код</div>
                    <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" data-tooltip="Подсказка"></div>
                </div>
                <input type="number" name="actionConfirmCode" class="form__input h-50" placeholder="*****" [(ngModel)]="enteredConfirmationSmsCode">
            </div>
            <button type="button" id="btSMSconfirm" (click)="confirm()" class="btn btn--primary h-50 w-full mb-20">Подтвердить</button>
            <button type="button" id="btSMScancel" (click)="cancel()" class="btn btn--dark-outline h-50 w-full mb-20">Отмена</button>
            <div class="text-center text-sm color-secondary">Повторить отправку кода через: {{smsTime}}</div>
        </form>
    </div>
</div>
