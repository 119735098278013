import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/core/utils/utils';
import { environment } from 'src/environments/environment';
import { OffersAccept } from '../../models/offers-accept/offers-accept.model';
import { OfferName } from '../../models/offers-accept/offer-name.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneralResponse } from 'src/app/shared/models/GeneralResponse.model';

@Injectable({
  providedIn: 'root'
})
export class OffersAcceptService {

  private _offerAcceptedSubject: BehaviorSubject<OfferName>;
  public offerAccepted$: Observable<OfferName>;


  constructor(
    private httpClient: HttpClient,
    private utils: Utils
  )
  {
    this._offerAcceptedSubject = new BehaviorSubject<OfferName>(new OfferName());
    this.offerAccepted$ = this._offerAcceptedSubject.asObservable();
  }

  public async markOfferAsAccepted(acceptedOfferName: OfferName) {
    this._offerAcceptedSubject.next(acceptedOfferName);
  }

  public get getPublicationInfo() {
    return this._offerAcceptedSubject.value;
  }


  async get(): Promise<OffersAccept> {
    const list = await this.httpClient.get<any[]>(`${environment.apiUrl}/api/offers-accepts`).toPromise();
    const accept = new OffersAccept();
    list.map(item => {
      if (typeof accept[item.offerCode] !== 'undefined') accept[item.offerCode] = true;
    });
    return accept;
  }

  async post(offersAccept: OffersAccept): Promise<Map<string, any>> {
    let errors = new Map<string, any>();
    try {
      await this.httpClient.post(`${environment.apiUrl}/api/offers-accepts`, offersAccept).toPromise();
    } catch (response: any) {
      errors = this.utils.collectErrors(response);
    }
    return errors;
  }
  async offersAccepted(typeName: string): Promise<GeneralResponse<boolean>>{
    let resp = new GeneralResponse<boolean>();
    try {
      let obj= await this.httpClient.get<boolean>(`${environment.apiUrl}/api/offers-accepts/getOffersAcceptState/${typeName}`).toPromise();;
      resp.Response = obj;
    }
    catch (err: any) {
      resp.Errors = this.utils.collectErrors(err);
    }

    return resp;
  }


  async getOffersAcceptState(typeName: string): Promise<boolean> {
    return await this.httpClient.get<boolean>(`${environment.apiUrl}/api/offers-accepts/getOffersAcceptState/${typeName}`).toPromise();
  }

  async setOffersAcceptState(offerInfo: OfferName): Promise<Map<string, any>> {
    let errors = new Map<string, any>();
    try {
      await this.httpClient.post(`${environment.apiUrl}/api/offers-accepts/setOffersAcceptState`, JSON.stringify(offerInfo),
        { headers: this.utils.jsonHeaders() }).toPromise();
    } catch (response: any) {
      errors = this.utils.collectErrors(response);
    }
    return errors;
  }
}
