import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { StaticContentService } from './services/static-content.service';
import { StaticContentComponent } from './components/static-component/static-content.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [StaticContentComponent],
  imports: [CommonModule, NgxExtendedPdfViewerModule],
  exports: [StaticContentComponent],
  providers: [StaticContentService]
})
export class StaticContentModule {}
