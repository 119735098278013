import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';

declare function ucfirst(str: string): string;
declare function closeModal(): void;

@Component({
  selector: 'app-modal-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Input() supportEmail: string = '';
  @Input() stay: string = '';
  errors = new Map();
  email: string = '';
  password: string = '';
  loading: boolean = false;
  passwordVisible: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
  }

  async login() {
    try {
      this.loading = true;
      await this.authService.login(this.email, this.password);
      this.email = '';
      this.password = '';
      this.errors.clear();
      if (this.authService.navigate === true) {
        this.router.navigate([this.authService.getUrlToRedirectAfterLogin()]);
      } else {
        this.authService.navigate = true;
      }
      closeModal();
    } catch (response: any) {
      this.errors.clear();
      const errors = Object.entries(response?.error?.errors);
      if (Array.isArray(errors)) {
        errors.forEach(([key, value]) => {
          this.errors.set(ucfirst(key), value);
        });
      }
    } finally {
      this.loading = false;
    }
  }
}
