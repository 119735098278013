import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from './core/enums/role.enum';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    data: { publicArea: true },
    loadChildren: () =>
      import('./features/public-area/public-area.module').then(
        (m) => m.PublicAreaModule
      )
  },
  {
    path: 'modal',
    data: { excludeFromSitemap: true },
    loadChildren: () =>
      import('./front/modals/modal.module').then((m) => m.ModalModule)
  },
  {
    path: 'personal-area',
    data: { excludeFromSitemap: true, roles: [Role.Dealer] },
    loadChildren: () =>
      import('./features/personal-area/personal-area.module').then(
        (m) => m.PersonalAreaModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'broker-area',
    data: { excludeFromSitemap: true, roles: [Role.Broker] },
    loadChildren: () =>
      import('./features/broker-area/broker-area.module').then(
        (m) => m.BrokerAreaModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'manager-area',
    data: { excludeFromSitemap: true, roles: [Role.Admin, Role.Superadmin] },
    loadChildren: () =>
      import('./features/manager-area/manager-area.module').then(
        (m) => m.ManagerAreaModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'superadmin-area',
    data: { excludeFromSitemap: true, roles: [Role.Superadmin] },
    loadChildren: () =>
      import('./features/superadmin-area/superadmin-area.module').then(
        (m) => m.SuperadminAreaModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics-area',
    data: { excludeFromSitemap: true, roles: [Role.Analytic, Role.Superadmin] },
    loadChildren: () =>
      import('./features/analytics-area/analytics-area.module').then(
        (m) => m.AnalitycsAreaModule
      ),
    canActivate: [AuthGuard]
  },
  { path: '**', data: { excludeFromSitemap: true }, redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
