import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { Account } from 'src/app/core/models/auth/account.model';
import { User } from 'src/app/core/models/auth/user.model';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Agreement } from 'src/app/features/personal-area/models/agreement/agreement.model';
import { AgreementService } from 'src/app/features/personal-area/services/agreement/agreement.service';
import { DocumentModalComponent } from '../document-modal/document-modal.component';
import { SmsConfirmModalComponent } from '../sms-confirm-modal/sms-confirm-modal.component';
export interface AgreementModel {
  id: number | undefined;
  price: number | undefined;
  data: any | undefined;
}
export class CessionLink {
  text: string = '';
  publicationId: number = 0;
  certificateId: number = 0;
  popupHtml: string = '';
}
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
@Component({
  selector: 'app-agreement-modal',
  templateUrl: './agreement-modal.component.html',
  styleUrls: ['./agreement-modal.component.css']
})
export class AgreementModalComponent
  extends SimpleModalComponent<AgreementModel, boolean>
  implements AgreementModel, OnInit
{
  id: number = 0;
  data: any = null;
  account: Account | null = null;
  entity: Agreement = new Agreement();
  loading: boolean = false;
  UserName: string = '';
  content: string = '';
  contentEn: string = '';
  user: User | null = null;
  cessionLinks: CessionLink[] = [];
  title: string = 'Соглашение о подписании лота';
  price: number = 0;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private agreementService: AgreementService,
    private modalService: SimpleModalService
  ) {
    super();
  }
  async Accept() {
    let smsConfirm = await this.modalService
      .addModal(SmsConfirmModalComponent, {
        //SMS confirmation form
        data: {
          title: 'Подтверждение сделки',
          text: 'Введите код из СМС, чтобы принять соглашение'
        }
      })
      .toPromise<boolean>();
    if (smsConfirm) {
      this.result = true;
      this.close();
    } else {
      this.result = false;
      this.close();
    }
  }
  Cancel() {
    this.result = false;
    this.close();
  }
  async ngOnInit() {
    this.authService.user.subscribe(async (user) => {
      this.user = user;
      this.account = await this.accountService.getUserAccount().toPromise();
      this.UserName = this.account.name;
    });
    if (this.data && this.data['title']) {
      this.title = this.data['title'];
    }
    debugger;
    if (this.id > 0) {
      let cessions = await this.agreementService.getList(
        this.id,
        this.price || 0
      );

      cessions.Response = cessions.Response ?? new Array<Agreement>(0);

      for (let i = 0; i < cessions.Response.length; i++) {
        let cl = new CessionLink();
        cl.text =
          'Соглашением о передаче прав и обязанностей по договору таймшера №' +
          cessions.Response[i].relatedCertificateId;
        cl.publicationId = this.id;
        cl.certificateId = cessions.Response[i].relatedCertificateId;
        cl.popupHtml = cessions.Response[i].content;
        //`<h2>Текст соглашения №${i+1}</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`;
        this.cessionLinks.push(cl);
      }
    }
  }

  showDoc(cessionLink: CessionLink) {
    this.modalService.addModal(DocumentModalComponent, cessionLink);
  }
}
