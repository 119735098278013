<div id="modal-trade-time" class="f-modal" style="display: none">
  <div class="modal-content w-480">
    <div class="modal-header">
      <div data-fancybox-close class="modal-close">
        <svg class="icon">
          <use xlink:href="#times-light"></use>
        </svg>
      </div>
    </div>

    <div class="text-center mb-20">
      <div class="font-alt text-xl">Внимание!</div>
      <div class="divider-sm"></div>
      <div class="text-sm">
        Время для проведения сделки {{ ts.dealTime }} минут
      </div>
    </div>

    <input
      type="button"
      class="btn w-300 h-50 maw-100p btOK btn-centered"
      value="ОК"
      (click)="closeInfoDialog($event)"
    />
  </div>
</div>
<div id="modal-trade-canceled" class="f-modal" style="display: none">
  <div class="modal-content w-480">
    <div class="modal-header">
      <div id="bCloseInfoDialog" data-fancybox-close class="modal-close">
        <svg class="icon">
          <use xlink:href="#times-light"></use>
        </svg>
      </div>
    </div>

    <div class="text-center mb-20">
      <div class="font-alt text-xl">Сделка отменена</div>
      <div class="divider-sm"></div>
      <div class="text-sm">
        Если у вас возникли вопросы - обратитесь в
        <strong>теxподдержку</strong>
      </div>
    </div>

    <input
      type="button"
      class="btn w-300 h-50 maw-100p btOK btn-centered"
      value="ОК"
      (click)="closeInfoDialog($event)"
    />
  </div>
</div>
<div
  id="dealer-auth-step-one"
  class="f-modal f-modal--error"
  style="display: none"
>
  <div class="modal-content">
    <div class="modal-header">
      <div data-fancybox-close class="modal-close">
        <svg class="icon">
          <use xlink:href="#times-light"></use>
        </svg>
      </div>
    </div>

    <div class="modal-dealer__list">
      <div class="modal-dealer__item mb-2">
        <div class="char">Авторизация</div>
      </div>
      <div class="modal-dealer__action mb-2">
        <a href="javascript:;" (click)="dealLogin()" class="btn btn--primary">
          Войти
        </a>
        <a
          href="javascript:;"
          (click)="dealRegister()"
          class="btn btn--dark-outline"
        >
          Зарегистрироваться
        </a>
      </div>
      <div class="modal-dealer__item mb-2">
        <div class="char">Профиль и банковские реквизиты</div>
      </div>
      <div class="modal-dealer__item mb-1">
        <div class="char">
          <!--{ts.dealDetails.price|number:'1.2-2'}-->
          Стоимость лота:
          <span class="font-alt-extra font-semibold">
            RUB {{ ts.packagePrice | number : '1.2-2' }}
          </span>
          <br />
          Комиссия торговой площадки
          <span class="font-alt-extra">(9%)</span>
          :
          <span class="font-alt-extra font-semibold">
            RUB {{ ts.dealDetails.comission | number : '1.2-2' }}
          </span>
        </div>
      </div>
      <div class="modal-dealer__item font-alt-extra">
        <div class="char">
          Необходимый баланс:
          <span class="font-alt-extra font-semibold">
            RUB {{ ts.dealDetails.total | number : '1.2-2' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="dealer-auth-step-two"
  class="f-modal f-modal--error"
  style="display: none"
>
  <div class="modal-content">
    <div class="modal-header">
      <div data-fancybox-close class="modal-close">
        <svg class="icon">
          <use xlink:href="#times-light"></use>
        </svg>
      </div>
    </div>

    <div class="modal-dealer__list">
      <div class="modal-dealer__item mb-2">
        <div class="char">Авторизация</div>
        <div class="val">&#10004;</div>
      </div>
      <div class="modal-dealer__item mb-2">
        <div class="char">Профиль и банковские реквизиты</div>
      </div>
      <div class="modal-dealer__action mb-2">
        <a
          href="javascript:;"
          (click)="profileNavigate()"
          class="btn btn--primary"
        >
          Заполнить данные
        </a>
        <a
          href="javascript:;"
          (click)="dealCancel()"
          class="btn btn--dark-outline"
        >
          Отмена
        </a>
      </div>

      <div class="modal-dealer__item mb-1">
        <div class="char">
          Стоимость лота:
          <span class="font-alt-extra font-semibold">
            {{ ts.packagePrice | number : '1.2-2' }}
          </span>
          <br />
          Комиссия торговой площадки
          <span class="font-alt-extra">(9%)</span>
          :
          <span class="font-alt-extra font-semibold">
            {{ ts.dealDetails.comission | number : '1.2-2' }}
          </span>
        </div>
      </div>
      <div class="modal-dealer__item">
        <div class="char">
          Необходимый баланс:
          <span class="font-alt-extra font-semibold">
            RUB {{ ts.dealDetails.total | number : '1.2-2' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="dealer-auth-step-three"
  class="f-modal f-modal--error"
  style="display: none"
>
  <div class="modal-content">
    <div class="modal-header">
      <div data-fancybox-close class="modal-close">
        <svg class="icon">
          <use xlink:href="#times-light"></use>
        </svg>
      </div>
    </div>

    <div class="modal-dealer__list font-alt">
      <div class="modal-dealer__item mb-2">
        <div class="char">Авторизация</div>
        <div class="val">&#10004;</div>
      </div>
      <div class="modal-dealer__item mb-2">
        <div class="char">Профиль и банковские реквизиты</div>
        <div class="val">&#10004;</div>
      </div>
      <div
        *ngIf="ts.availableBalance - ts.dealDetails.total >= 0"
        class="modal-dealer__item mb-2"
      >
        <div class="char">
          Баланс:
          <span class="font-alt-extra font-semibold">
            RUB {{ ts.availableBalance | number : '1.2-2' }}
          </span>
        </div>
        <div class="val">&#10004;</div>
      </div>
      <div class="modal-dealer__item mb-2">
        <div class="char">
          <div
            *ngIf="ts.availableBalance - ts.dealDetails.total < 0"
            class="mb-10"
          >
            Баланс:
            <span class="text-danger font-alt-extra font-semibold">
              RUB {{ ts.availableBalance | number : '1.2-2' }}
            </span>
          </div>
          <div class="mb-10">
            Стоимость лота:
            <span class="font-alt-extra font-semibold">
              {{ ts.packagePrice | number : '1.2-2' }}
            </span>
          </div>
          <div class="mb-10">
            Комиссия торговой площадки
            <span class="font-alt-extra">(9%)</span>
            :
            <span class="font-alt-extra font-semibold">
              RUB {{ ts.dealDetails.comission | number : '1.2-2' }}
            </span>
          </div>
          <div class="mb-10">
            Необходимый баланс:
            <span class="font-alt-extra font-semibold">
              RUB {{ ts.dealDetails.total | number : '1.2-2' }}
            </span>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          !updateBalanceFlag && ts.availableBalance - ts.dealDetails.total < 0
        "
        class="modal-dealer__item mb-10"
      >
        <div class="char">
          <div class="mb-10">
            Не хватает средств:
            <span class="text-danger font-alt-extra font-semibold">
              RUB {{ ts.distinctionAmount | number : '1.2-2' }}
            </span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="updateBalanceFlag">
        <div class="modal-dealer__item">
          <div class="char">
            <div class="mb-10">
              Пополнить на (RUB):
              <span class="font-alt-extra font-semibold">
                RUB {{ ts.distinctionAmount | number : '1.2-2' }}
              </span>
            </div>
          </div>
        </div>
        <div class="modal-dealer__item mb-10">
          <input
            type="text"
            class="form__input"
            [(ngModel)]="updateBalanceSum"
            placeholder="{{ 'type in...' | translate }}"
          />
          <div class="char">
            <button
              type="button"
              (click)="balanceConfirm()"
              class="btn btn--primary ml"
            >
              Подтвердить
            </button>
          </div>
        </div>
      </ng-container>
      <div class="modal-dealer__action">
        <button
          *ngIf="ts.availableBalance - ts.dealDetails.total < 0"
          type="button"
          (click)="balanceAdd()"
          class="btn btn--primary"
        >
          Пополнить
        </button>
        <button
          *ngIf="ts.availableBalance - ts.dealDetails.total >= 0"
          type="button"
          (click)="dealOffer()"
          class="btn btn--primary"
        >
          Оплатить
        </button>
        <button
          type="button"
          (click)="dealCancel()"
          class="btn btn--dark-outline"
        >
          Отмена
        </button>
      </div>
    </div>
  </div>
</div>

<div
  id="dealer-auth-step-four"
  class="f-modal f-modal--error"
  style="display: none"
>
  <div class="modal-content" style="width: 570px">
    <div class="modal-header">
      <div data-fancybox-close class="modal-close">
        <svg class="icon">
          <use xlink:href="#times-light"></use>
        </svg>
      </div>
    </div>

    <div class="modal-dealer__list font-alt">
      <div class="modal-dealer__item mb-2">
        <div class="char">Авторизация</div>
        <div class="val">&#10004;</div>
      </div>
      <div class="modal-dealer__item mb-2">
        <div class="char">Профиль и банковские реквизиты</div>
        <div class="val">&#10004;</div>
      </div>
      <div class="modal-dealer__item mb-4">
        <div class="char">
          Баланс:
          <span class="font-alt-extra font-semibold">
            RUB {{ ts.account?.balance || 0 | number : '1.2-2' }}
          </span>
        </div>
        <div class="val">&#10004;</div>
      </div>
      <div class="modal-dealer__item mb-30">
        <div class="char fz18">Поздравляем, вы успешно совершили сделку!</div>
      </div>
      <div class="modal-dealer__item modal-dealer__item--package mb-30">
        <div class="char">
          <a
            href="javascript:;"
            (click)="Go()"
            data-position="top center"
            data-tooltip="Перейти к лоту"
          >
            Номер приобретённого Вами лота -
            {{ ts.dealDetails.publicationNumber }}
          </a>
        </div>
      </div>
      <div class="modal-dealer__action">
        <a
          href="javascript:;"
          (click)="GoTo('/personal-area/profile')"
          class="btn btn--primary"
        >
          Личный кабинет
        </a>
        <a
          href="javascript:;"
          (click)="GoTo('/personal-area/deals')"
          class="btn btn--dark-outline"
        >
          К сделкам
        </a>
      </div>
    </div>
  </div>
</div>

<div id="modal-sms-confirm" class="f-modal" style="display: none">
  <div class="modal-content w-480">
    <div class="modal-header">
      <div data-fancybox-close class="modal-close">
        <svg class="icon">
          <use xlink:href="#times-light"></use>
        </svg>
      </div>
    </div>

    <div class="text-center mb-20">
      <div class="font-alt text-xl">Подтверждение</div>
      <div class="divider-sm df my-20"></div>
      <div class="mb-40 text-sm text-center lh-18 color-secondary">
        Подтвердите номер телефона,
        <br />
        введите код из СМС
      </div>
    </div>

    <div class="df justify-center">
      <form action="#" method="POST" class="w-300 maw-full">
        <div class="mb-40">
          <div class="form-lbinfo">
            <div class="form-lbinfo__title font-alt">Введите код</div>
            <div
              class="form-lbinfo__icon ic-lbinfo"
              data-position="top center"
              data-tooltip="Подсказка"
            ></div>
          </div>
          <input
            type="number"
            name="actionConfirmCode"
            class="form__input h-50"
            placeholder="*****"
            [(ngModel)]="enteredConfirmationSmsCode"
          />
        </div>
        <button
          type="button"
          id="btSMSconfirm"
          class="btn btn--primary h-50 w-full mb-20"
        >
          Подтвердить
        </button>
        <button
          type="button"
          id="btSMScancel"
          class="btn btn--dark-outline h-50 w-full mb-20"
        >
          Отмена
        </button>
        <div class="text-center text-sm color-secondary">
          Повторить отправку кода через: {{ ts.smsTime }}
        </div>
      </form>
    </div>
  </div>
</div>
<div id="deal_login" class="mw" style="display: none">
  <div class="mw__content">
    <div class="mw__head">
      <div class="mw__title">{{ 'Sign In' | translate }}</div>
      <span class="mw__line"></span>
    </div>
    <div class="mw__body">
      <form class="form">
        <div class="form__list mb3">
          <div class="error">
            <div
              class="error__common-message fz14 pb3"
              *ngIf="errors.get('Error')"
            >
              {{ errors.get('Error')[0] | translate }}
            </div>
          </div>

          <div
            class="form__item"
            [class.error]="errors.get('Email')"
            (input)="errors.delete('Email')"
          >
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">E-mail *</div>
              <div
                class="form-lbinfo__icon ic-lbinfo"
                data-position="top center"
                [attr.data-tooltip]="'Enter your valid e-mail' | translate"
              ></div>
            </div>
            <input
              type="email"
              class="form__input"
              placeholder="E-mail"
              required
              [(ngModel)]="email"
              name="email"
            />
            <div class="error__message" *ngIf="errors.get('Email')">
              {{ errors.get('Email')[0] | translate }}
            </div>
          </div>

          <div
            class="form__item mb4"
            [class.error]="errors.get('Password')"
            (input)="errors.delete('Password')"
          >
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">
                {{ 'Password' | translate }} *
              </div>
              <div
                class="form-lbinfo__icon ic-lbinfo"
                data-position="top center"
                [attr.data-tooltip]="'Enter your password' | translate"
              ></div>
            </div>
            <div class="form__group">
              <input
                [type]="passwordVisible ? 'text' : 'password'"
                class="form__input pl2"
                placeholder="{{ 'Password' | translate }}"
                required
                [(ngModel)]="password"
                name="password"
              />
              <button
                type="button"
                class="form__input-icon"
                (click)="passwordVisible = !passwordVisible"
              >
                <span
                  class="js-pass-view ma"
                  [ngClass]="passwordVisible ? 'ic-eye' : 'ic-eye-off'"
                ></span>
              </button>
            </div>
            <div class="error__message" *ngIf="errors.get('Password')">
              {{ errors.get('Password')[0] | translate }}
            </div>
          </div>
        </div>
        <div class="form__item mb2">
          <button
            [disabled]="loading"
            type="button"
            (click)="dealLoginCkick()"
            class="btn w100 btnLogin"
            [attr.data-tooltip]="'DoSignIn' | translate"
            data-position="bottom center"
          >
            {{ (loading ? 'Loading...' : 'DoSignIn') | translate }}
          </button>
        </div>
        <div class="form__item tc">
          <a
            href="javascript:;"
            onclick="openModal('#password-recovery')"
            class="mw__stitle form__link"
            [attr.data-tooltip]="'Forgot password?' | translate"
            data-position="bottom center"
          >
            {{ 'Forgot password?' | translate }}
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
