import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private httpClient: HttpClient
  ) { }

  async getDefaultLanguage(): Promise<string> {
    const data = await this.httpClient.get<any>(`${environment.webApiUrl}/default-language`).toPromise();
    return data.languageCodeInEnglish || 'ru';
  }

}
