import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { CookieService } from 'ngx-cookie-service';

import { GeneralResponse } from 'src/app/shared/models/GeneralResponse.model';
import { environment } from 'src/environments/environment';
import { BalanceReplenish } from '../../models/balance/balance-replenish.model';
import { BalanceWithdraw } from '../../models/balance/balance-withdraw.model';
import { TransactionSettlement } from '../../models/balance/transaction-settlement.model';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utils } from 'src/app/core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  private serverUrl = `${environment.webApiUrl}/personal`;
  locale = 'RU';

  constructor(
    private httpClient: HttpClient,
    //private cookieService: CookieService,
    private utils: Utils
  ) {
    //this.locale = this.cookieService.get('locale');
  }

  async withdrawRequest(entity: BalanceWithdraw): Promise<void> {
    const serverUrl = `${environment.webApiUrl}/api/Balance`;
    await this.httpClient
      .post(`${serverUrl}/BalanceWithdrawRequest`, JSON.stringify(entity), {
        headers: this.utils.jsonHeaders()
      })
      .pipe(catchError((error) => throwError(this.utils.collectErrors(error))))
      .toPromise();
  }

  async replenishRequest(entity: BalanceReplenish): Promise<void> {
    const serverUrl = `${environment.webApiUrl}/api/Balance`;
    await this.httpClient
      .post(`${serverUrl}/BalanceReplenishRequest`, JSON.stringify(entity), {
        headers: this.utils.jsonHeaders()
      })
      .pipe(catchError((error) => throwError(this.utils.collectErrors(error))))
      .toPromise();
  }

  async transactionSettlement(
    entity: TransactionSettlement
  ): Promise<Map<string, string>> {
    let errors = new Map<string, any>();
    //debugger;
    try {
      await this.httpClient
        .post(
          `${this.serverUrl}/transactionSettlement`,
          JSON.stringify(entity),
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();
    } catch (response: any) {
      errors = this.utils.collectErrors(response);
      //debugger;
    }
    return errors;
  }

  async blockAmount(amount: number): Promise<GeneralResponse<boolean>> {
    let result = new GeneralResponse<boolean>();

    //debugger;
    try {
      let resp = await this.httpClient
        .post<boolean>(`${this.serverUrl}/blockAmount`, amount, {
          headers: this.utils.jsonHeaders()
        })
        .toPromise();

      //debugger;
      result.Response = resp;
    } catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
      //debugger;
    }
    return result;
  }

  async blockAmountByPublicationId(
    publicationId: number
  ): Promise<GeneralResponse<boolean>> {
    let result = new GeneralResponse<boolean>();

    //debugger;
    try {
      let resp = await this.httpClient
        .post<boolean>(
          `${this.serverUrl}/blockAmountByPublicationId`,
          publicationId,
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();

      //debugger;
      result.Response = resp;
    } catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
      //debugger;
    }
    return result;
  }

  async connectionToPublication(
    publicationId: number,
    amount:number
  ): Promise<GeneralResponse<boolean>> {
    let result = new GeneralResponse<boolean>();

   
    try {
      let resp = await this.httpClient
        .post<boolean>(
          `${environment.webApiUrl}/personal/connectionToPublication`,
          {  publicationId: publicationId,
          ammount: amount
        },
          { headers: this.utils.jsonHeaders() }
        )
        .toPromise();

      //debugger;
      result.Response = resp;
    } catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
      //debugger;
    }
    return result;
  }

  getGift(): Observable<void> {
    return this.httpClient
      .post<void>(
        `${environment.webApiUrl}/api/Balance/Gift`,
        {},
        {
          headers: this.utils.jsonHeaders()
        }
      )
      .pipe(catchError((error) => throwError(this.utils.collectErrors(error))));
  }
}
