export class FinancialStatistic {
  financialTransactionId = 0;
  financialTransactionTypeCode = '';
  financialTransactionApproveDateTime = '';
  financialTransactionApprovedAmount = 0;
  financialTransactionApprovedAmountFormatted = '';
  financialTransactionCreationDateTime = '';
  financialTransactionPlannedAmount = 0;
  financialTransactionPlannedAmountFormatted = '';
  financialTransactionUseDescription = false;
  financialTransactionTypeName = '';
  

  constructor() {}
}
