import { formatNumber } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Publication } from 'src/app/core/models/publications/publication.model';
import { Utils } from 'src/app/core/utils/utils';
import { FinancialStatistic } from 'src/app/features/personal-area/models/financial-statistics/financial-statistic.model';
import {
  DealTax,
  FinancialTaxInfo
} from 'src/app/features/personal-area/models/financial-statistics/financial-tax-info.model';
import { UserFinancialTransaction } from 'src/app/features/personal-area/models/financial-statistics/user-financial-transaction.model';
import { GeneralResponse } from 'src/app/shared/models/GeneralResponse.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinancialStatisticsService {
  constructor(private httpClient: HttpClient, private utils: Utils) {}

  getList(): Observable<GeneralResponse<FinancialStatistic[]>> {
    return this.httpClient
      .get<FinancialStatistic[]>(
        `${environment.apiUrl}/api/FinancialStatistics/Get`
      )
      .pipe(
        map((response) => {
          const data = new GeneralResponse<FinancialStatistic[]>();

          data.Response = response
            ? response.map((item: FinancialStatistic) => this.formatItem(item))
            : new FinancialStatistic[0]();

          return data;
        }),
        catchError((error) => {
          // ToDo toast
          console.log('getList => error', error);

          const data = new GeneralResponse<FinancialStatistic[]>();

          data.Response = new FinancialStatistic[0]();
          data.Errors = this.utils.collectErrors(error);

          return of(data);
        })
      );

    // var result = new GeneralResponse<FinancialStatistic[]>();
    // try {
    //   result.Response = await this.httpClient.get<FinancialStatistic[]>(
    //     `${environment.apiUrl}/api/FinancialStatistics/Get`).toPromise();

    //   result.Response = result.Response ?? new FinancialStatistic[0];
    //   result.Response!.map((item: FinancialStatistic) => this.formatItem(item));
    // }
    // catch (ex: any) {
    //   result.Errors = this.utils.collectErrors(ex);
    // }

    // return result;
  }

  async getByUserId(
    userId: string
  ): Promise<GeneralResponse<UserFinancialTransaction[]>> {
    const result = new GeneralResponse<UserFinancialTransaction[]>();
    try {
      result.Response = await this.httpClient
        .get<UserFinancialTransaction[]>(
          `${environment.apiUrl}/api/FinancialStatistics/GetByUserId/${userId}`
        )
        .toPromise();

      result.Response = (
        result.Response || new UserFinancialTransaction[0]()
      ).map((item: UserFinancialTransaction) =>
        this.formatTransactionItem(item)
      );
    } catch (ex: any) {
      result.Errors = this.utils.collectErrors(ex);
    }

    return result;
  }

  async approveRequestById(
    transactionId: number,
    isConfirmed: boolean = true
  ): Promise<GeneralResponse<boolean>> {
    const result = new GeneralResponse<boolean>();
    // Old API
    // const url = `${environment.apiUrl}/api/FinancialStatistics/ApproveRequest`;
    const url = `${environment.webApiUrl}/api/Balance/ChangeTransactionState`;
    try {
      result.Response = await this.httpClient
        .post<boolean>(url, {
          transactionId,
          isConfirmed
        })
        .toPromise();
    } catch (error: any) {
      result.Errors = this.utils.collectErrors(error);
    }

    return result;
  }

  async changeTax(transactionId: number, newTax: number): Promise<GeneralResponse<void>> {
    const result = new GeneralResponse<void>();

    const url = `${environment.cmsApiUrl}/api/FinancialTransactionsCatalog/ChangeTax`;
    try {
      await this.httpClient
        .post(url, {
          transactionId,
          newTax
        })
        .toPromise();
    } catch (error: any) {
      result.Errors = this.utils.collectErrors(error);
    }

    return result;
  }

  async cancelTransaction(transactionId: number): Promise<GeneralResponse<void>> {
    const result = new GeneralResponse<void>();

    const url = `${environment.cmsApiUrl}/api/FinancialTransactionsCatalog/Cancel`;
    try {
      await this.httpClient.post(url, {
          transactionId,
        }).toPromise();
    } catch (error: any) {
      result.Errors = this.utils.collectErrors(error);
    }

    return result;
  }

  async getTaxByPackage(
    packageId: number,
    price: number
  ): Promise<GeneralResponse<DealTax>> {
    const result = new GeneralResponse<DealTax>();
    try {
      result.Response = await this.httpClient
        .post<DealTax>(`${environment.webApiUrl}/api/Tax/GetTaxByPackage`, {
          packageId: packageId && packageId !== -1 ? packageId : null,
          price
        })
        .toPromise();

      result.Response = this.formatDealTaxes(result.Response || new DealTax());
    } catch (ex: any) {
      result.Errors = this.utils.collectErrors(ex);
    }

    return result;
  }

  // ToDo: remove old method
  async getTaxes(
    publicationId: number,
    userId: string
  ): Promise<GeneralResponse<FinancialTaxInfo>> {
    const result = new GeneralResponse<FinancialTaxInfo>();
    try {
      result.Response = await this.httpClient
        .get<FinancialTaxInfo>(
          `${environment.apiUrl}/api/FinancialStatistics/GetTaxesBy/${publicationId}/${userId}`
        )
        .toPromise();

      result.Response = this.formatTaxes(
        result.Response || new FinancialTaxInfo()
      );
    } catch (ex: any) {
      result.Errors = this.utils.collectErrors(ex);
    }

    return result;
  }

  private formatItem(item: FinancialStatistic): FinancialStatistic {
    item.financialTransactionApprovedAmountFormatted = `RUB ${formatNumber(
      item.financialTransactionApprovedAmount,
      'ru-RU',
      '1.2-2'
    )}`;
    item.financialTransactionPlannedAmountFormatted = `RUB ${formatNumber(
      item.financialTransactionPlannedAmount,
      'ru-RU',
      '1.2-2'
    )}`;

    return item;
  }

  private formatTransactionItem(
    item: UserFinancialTransaction
  ): UserFinancialTransaction {
    item.financialTransactionApprovedAmountFormatted = `RUB ${formatNumber(
      item.financialTransactionApprovedAmount,
      'ru-RU',
      '1.2-2'
    )}`;
    item.financialTransactionPlannedAmountFormatted = `RUB ${formatNumber(
      item.financialTransactionPlannedAmount,
      'ru-RU',
      '1.2-2'
    )}`;
    item.financialTransactionCalculatedCommissionValueFormatted = `RUB ${formatNumber(
      item.financialTransactionCalculatedCommissionValue,
      'ru-RU',
      '1.2-2'
    )}`;
    item.financialTransactionCalculatedTaxValueFormatted = `RUB ${formatNumber(
      item.financialTransactionCalculatedTaxValue,
      'ru-RU',
      '1.2-2'
    )}`;

    return item;
  }

  private formatDealTaxes(item: DealTax): DealTax {
    return {
      ...item,

      sellerTax: parseFloat((item.sellerTax || 0).toFixed(2)),
      sellerTaxFormatted: `RUB ${formatNumber(
        item.sellerTax,
        'ru-RU',
        '1.2-2'
      )}`,
      sellerCommission: parseFloat((item.sellerCommission || 0).toFixed(2)),
      sellerCommissionFormatted: `RUB ${formatNumber(
        item.sellerCommission,
        'ru-RU',
        '1.2-2'
      )}`,
      sellerSum: parseFloat((item.sellerSum || 0).toFixed(2)),
      sellerSumFormatted: `RUB ${formatNumber(
        item.sellerSum,
        'ru-RU',
        '1.2-2'
      )}`,

      buyerTax: parseFloat((item.buyerTax || 0).toFixed(2)),
      buyerTaxFormatted: `RUB ${formatNumber(item.buyerTax, 'ru-RU', '1.2-2')}`,
      buyerCommission: parseFloat((item.buyerCommission || 0).toFixed(2)),
      buyerCommissionFormatted: `RUB ${formatNumber(
        item.buyerCommission,
        'ru-RU',
        '1.2-2'
      )}`,
      buyerSum: parseFloat((item.buyerSum || 0).toFixed(2)),
      buyerSumFormatted: `RUB ${formatNumber(item.buyerSum, 'ru-RU', '1.2-2')}`
    };
  }

  private formatTaxes(item: FinancialTaxInfo): FinancialTaxInfo {
    item.commissionSumFormatted = `RUB ${formatNumber(
      item.commissionSum,
      'ru-RU',
      '1.2-2'
    )}`;
    item.taxesFormatted = `RUB ${formatNumber(item.taxes, 'ru-RU', '1.2-2')}`;
    item.totalAmountFormatted = `RUB ${formatNumber(
      item.totalAmount,
      'ru-RU',
      '1.2-2'
    )}`;

    return item;
  }

  numberWithSpaces(x) {
    var parts = [] 
    parts.push( x.toString())
    if(x.toString().includes('.')){
      parts = x.toString().split(".");
    }
    if(x.toString().includes(',')){
      parts = x.toString().split(",");
    }
    
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let result = ''
    if(parts.length > 1) {
      result =parts.join(",");
    } else {
      result =parts[0]+',00';
    }
    return result
  }

  getPriceFormattedNumber(requisition: Publication){
    return +requisition.priceFormatted.replace("RUB", "").replace(",00", "").replace(" ", "").replace(" ","")
  }

  getPercentProgressBar(requisition: Publication){
    return (requisition.allConnectionAmount / (+requisition.priceFormatted.replace("RUB", "").replace(",00", "").replace(" ", "").replace(" ","")/ 100)).toFixed(0)
  }
}
