import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Sms } from '../../models/sms/sms.model';
import { SmsResponse } from '../../models/sms/sms-response.model';
import { SmsSimpleMessage } from '../../models/sms/sms-simple-message.model';
import { SmsSimpleMessageResponse } from '../../models/sms/sms-simple-message-response.model';
import { Utils } from 'src/app/core/utils/utils';
import { GeneralResponse } from '../../../../shared/models/GeneralResponse.model';

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  private serverUrl = `${environment.cmsApiUrl}/api/Sms`;

  constructor(
    private httpClient: HttpClient,
    private utils: Utils
  ) { }

  async getAll(params: any): Promise<SmsResponse> {
    return await this.httpClient.get<SmsResponse>(
      `${this.serverUrl}/GetAll`,
      { params: params, headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async filter(params: any): Promise<SmsResponse> {
    return await this.httpClient.get<SmsResponse>(
      `${this.serverUrl}/Filter`,
      { params: params, headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async get(id: number): Promise<Sms> {
    return await this.httpClient.get<Sms>(
      `${this.serverUrl}/get/${id}`,
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async add(entity: Sms): Promise<void> {
    await this.httpClient.post(
      `${this.serverUrl}/add`,
      JSON.stringify(entity),
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async update(entity: Sms): Promise<void> {
    await this.httpClient.post(
      `${this.serverUrl}/update`,
      JSON.stringify(entity),
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async delete(id: number): Promise<void> {
    await this.httpClient.post(
      `${this.serverUrl}/delete/${id}`,
      {},
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

  async send(entity: SmsSimpleMessage): Promise<GeneralResponse<SmsSimpleMessageResponse>> {
    let result = new GeneralResponse<SmsSimpleMessageResponse>();

    try {
      let response = await this.httpClient.post(
        `${this.serverUrl}/sendMessage`,
        JSON.stringify(entity),
        { headers: this.utils.jsonHeaders() }
      ).toPromise();

      result.Response = response as SmsSimpleMessageResponse;
    }
    catch (response: any) {
      result.Errors = this.utils.collectErrors(response);
    }

    return result;
  }
}
