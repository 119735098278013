import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { interval, Observable, Subscription } from 'rxjs';
import { Account } from 'src/app/core/models/auth/account.model';
import { SmsAuthCodeCheckResult } from 'src/app/core/models/sms-auth-code/sms-auth-code-check-result.model';
import { SmsAuthCodeRequest } from 'src/app/core/models/sms-auth-code/sms-auth-code-request.model';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SmsAuthCodesService } from 'src/app/core/services/sms-auth-code/sms-auth-code.service';

export interface SmsConfirmModel {
  data: any | undefined;
}
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
@Component({
  selector: 'app-sms-confirm-modal',
  templateUrl: './sms-confirm-modal.component.html',
  styleUrls: ['./sms-confirm-modal.component.css']
})
export class SmsConfirmModalComponent
  extends SimpleModalComponent<SmsConfirmModel, boolean>
  implements SmsConfirmModel, OnInit
{
  data: any | undefined;
  enteredConfirmationSmsCode: string = '';
  smsTime: string = '4:00';
  smsTimer$: Subscription | null = null;
  currSmsDuration: number = 0;
  ti1: Observable<number> | undefined;
  account: Account | null = null;
  smsAuthCodeRequest: SmsAuthCodeRequest = new SmsAuthCodeRequest();
  smsAuthCodeRequestId: string | null = null;
  smsAuthCodeCheckResult: SmsAuthCodeCheckResult = new SmsAuthCodeCheckResult();

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private smsAuthCodeService: SmsAuthCodesService,
  ) {
    super();
  }
  confirm() {
    this.CheckSmsCode(this.smsAuthCodeRequestId ?? '').then(
      async () => {
        if (this.smsAuthCodeCheckResult !== null) {
          if (this.smsAuthCodeCheckResult.status) {
            this.enteredConfirmationSmsCode = '';
            // we set modal result as true on click on confirm button,
            // then we can get modal result from caller code
            this.smsTmrStop();
            this.result = true;
            this.close();
          } else {
            this.enteredConfirmationSmsCode = '';
            anAlert('Введен неверный код. <br><br> Публикация Заявки требует Вашего подтверждения. Заявка размещена в списке неопубликованных', 'OK');
          }
        } else {
          this.enteredConfirmationSmsCode = '';
          anAlert('Ошибка проверки кода. Обратитесь к администратору.', 'OK');
          this.smsTmrStop();
          this.result = false;
          this.close();
        }
      },
      () => {
        this.enteredConfirmationSmsCode = '';
        anAlert('Ошибка проверки кода. Обратитесь к администратору.', 'OK');
      }
    );
  }
  cancel() {
    this.smsTmrStop();
    this.result = false;
    this.close();
  }
  async ngOnInit(): Promise<void> {
    this.account = await this.accountService.getUserAccount().toPromise();
    try {
      await this.GenerateSmsCode();
  
    } catch {}
    this.smsTmrStart(() => {
      try {
        this.GenerateSmsCode();
  
      } catch {}
    });
  }
  smsTmrStart(callback?) {
    if (this.smsTimer$ != null) {
      this.smsTimer$.unsubscribe();
    }
    this.currSmsDuration = 0;
    this.smsTime = '4:00';
    const cb = callback || (() => {});
    this.ti1 = interval(1000);
    this.smsTimer$ = this.ti1.subscribe((t) => {
      this.currSmsDuration += 1;
      this.smsTime = dayjs
        .utc(
          dayjs.duration(240 - this.currSmsDuration, 'seconds').asMilliseconds()
        )
        .format('mm:ss');
      if (this.currSmsDuration >= 239) {
        //this.smsTmrStop();
        this.currSmsDuration = 0;
        this.smsTime = '4:00';
        cb();
      }
    });
  }
  smsTmrStop() {
    if (this.smsTimer$ != null) {
      this.smsTimer$.unsubscribe();
    }
    this.currSmsDuration = 0;
    this.smsTime = '00:00';
  }

  async GenerateSmsCode(): Promise<string | void> {
    this.enteredConfirmationSmsCode = '';
    let stdPhone: string = this.account?.phoneNumber ?? '';
    if (stdPhone?.length == 0) {
      return Promise.reject(
        'Невозможно отправить SMS-код - в профиле не указан номер телефона.'
      );
    }
    let numberPattern = /\d+/gi;

    stdPhone = stdPhone.match(numberPattern)?.join('') ?? '';
    if (stdPhone.length < 11) {
      return Promise.reject('В номере телефона должно быть минимум 11 чисел');
    }

    this.smsAuthCodeRequest = new SmsAuthCodeRequest();
    return this.smsAuthCodeService.sendCode(stdPhone).then((resp) => {
      
      this.smsAuthCodeRequest = resp;
      this.smsAuthCodeRequestId = this.smsAuthCodeRequest.requestId;
    });
  }
  async CheckSmsCode(requestId: string) {
    try {
      this.smsAuthCodeCheckResult =
        await this.smsAuthCodeService.checkAuthCodePromise(
          requestId ?? '',
          this.enteredConfirmationSmsCode ?? '',
          false
        );
    } catch (ex) {
      this.smsAuthCodeCheckResult = new SmsAuthCodeCheckResult();
      this.smsAuthCodeCheckResult.status = false;
    }
  }
}
