import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SupportService } from 'src/app/core/services/support/support.service';
import { environment } from 'src/environments/environment';
import { Role } from './core/enums/role.enum';
import { Account } from './core/models/auth/account.model';
import { SecurityContext } from './core/models/auth/security-context.model';
import { User } from './core/models/auth/user.model';
import { AccountService } from './core/services/account/account.service';
import { AuthorizationService } from './core/services/authorization/authorization.service';
import { LanguageService } from './core/services/language/language.service';

declare var $: any;
declare var gsap: any;
declare function initDropdowns(): any;
declare function initModals(): any;
declare function openMenu(): any;
declare function closeMenu(): any;
declare function initContextMenu(): any;
declare function initHeaderAnimation(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  user: User = new User();
  securityContext: SecurityContext = new SecurityContext();
  account: Account = new Account();
  languages: any = {};
  currentLanguageCode: string = '';
  isDay: boolean = false;
  section: string = 'front';
  menu: any = [];
  searchTerm: string = '';
  isSearchOpened: boolean = false;
  supportEmail: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private cookieService: CookieService,
    private authService: AuthService,
    private accountService: AccountService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private viewport: ViewportScroller,
    private supportService: SupportService
  ) {
    this.authService.user.subscribe(async (user) => {
      this.user = user;
      this.account = await this.accountService.getUserAccount().toPromise();
    });
    this.authorizationService.securityContext.subscribe((securityContext) => {
      this.securityContext = securityContext;
      this.setMenu();
      initContextMenu();
      this.initMenu();
    });
  }

  // ToDo remove
  get isDemo(): boolean {
    return false && !environment.production;
  }

  get isGuest(): boolean {
    return this.user.role === Role.Guest;
  }

  get isDealer(): boolean {
    return this.user.role === Role.Dealer;
  }

  get isBroker(): boolean {
    return this.user.role === Role.Broker;
  }

  get isAdmin(): boolean {
    return this.user.role === Role.Admin;
  }

  get isSuperadmin(): boolean {
    return this.user.role === Role.Superadmin;
  }

  get isAnalytic(): boolean {
    return this.user.role === Role.Analytic;
  }

  async ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setSection(val.urlAfterRedirects);
        this.setMenu();
        initContextMenu();
        this.initMenu();
        initHeaderAnimation();
        initDropdowns();
        initModals();
        this.viewport.scrollToPosition([0, 0]);
      }
    });
    this.setSection(this.document.location.pathname);
    this.languages = [
      { lang: { name: 'RU', isCurrent: true } },
      { lang: { name: 'EN', isCurrent: false } }
    ];
    await this.initLocale();
    initContextMenu();
    this.initMenu();
    initHeaderAnimation();
    initDropdowns();
    initModals();
    this.processCookieConsent();
    this.supportEmail = await this.supportService.getEmail();
  }

  // ToDo: удалить это дерьмо
  setSection(pathname: string): void {
    if (pathname.startsWith('/manager-area')) {
      this.section = 'manager-area';
      return;
    }
    if (pathname.startsWith('/superadmin-area')) {
      this.section = 'superadmin-area';
      return;
    }
    if (pathname.startsWith('/analytics-area')) {
      this.section = 'analytics-area';
      return;
    }
    if (pathname.startsWith('/broker-area')) {
      this.section = 'broker-area';
      return;
    }
    this.section = 'front';
  }

  initMenu(): void {
    if (sessionStorage.getItem('menuOpened') === '1') {
      setTimeout(() => openMenu(), 100);
    }
  }

  // ToDo: и это тоже
  setMenu() {
    if (this.section == 'manager-area') {
      this.menu = [
        { routerLink: '/manager-area/notifications', text: 'Notifications' },
        {
          routerLink: '/manager-area/users-and-certificates',
          text: 'Users and certificates',
          children: [
            { routerLink: '/manager-area/users', text: 'Users' },
            { routerLink: '/manager-area/certificates', text: 'Certificates' },
            { routerLink: '/manager-area/publications', text: 'Packages' }
          ]
        },
        {
          routerLink: '/manager-area/marketplace',
          text: 'Marketplace',
          children: [
            { routerLink: '/manager-area/marketplace', text: 'Marketplace' },
            {
              routerLink: '/manager-area/purchase-requests',
              text: 'Purchase requisitions'
            },
            { routerLink: '/manager-area', text: 'Deals' },
            { routerLink: '/manager-area/members', text: 'Members' },
            { routerLink: '/manager-area', text: 'About TST' }
          ]
        },
        {
          routerLink: '/manager-area/settings',
          text: 'Settings',
          children: [
            { routerLink: '/manager-area/static-blocks', text: 'Edit pages' },
            { routerLink: '/manager-area/logs', text: 'Server logs' },
            { routerLink: '/manager-area/event-log', text: 'Event log' },
            { routerLink: '/manager-area/slides', text: 'Edit slider photos' },
            {
              routerLink: '/manager-area/template-layout',
              text: 'Templates layout editor'
            },
            { routerLink: '/manager-area', text: 'Offers' },
            { routerLink: '/manager-area', text: 'Articles' },
            { routerLink: '/manager-area', text: 'Subscriptions' },
            { routerLink: '/manager-area', text: 'Event mailing templates' },
            { routerLink: '/manager-area', text: 'Deal agreement template' },
            {
              routerLink: '/manager-area',
              text: 'Transaction document signature'
            },
            { routerLink: '/manager-area', text: 'Company details' },
            {
              routerLink: '/manager-area/company-requisites',
              text: 'Company details'
            }
          ]
        },
        {
          // routerLink: '/manager-area/classifiers',
          text: 'Classifiers',
          columns: 3,
          alignRight: true,
          children: [
            { routerLink: '/manager-area/swiftbics', text: 'SWIFTBIC' },
            { routerLink: '/manager-area/currencies', text: 'Currencies' },
            {
              routerLink: '/manager-area/property-rights-types',
              text: 'Property rights types'
            },
            {
              routerLink: '/manager-area/membership-renewals',
              text: 'Membership renewals'
            },
            { routerLink: '/manager-area/genders', text: 'Genders' },
            {
              routerLink: '/manager-area/certificate-interval-types',
              text: 'Certificate interval types'
            },
            { routerLink: '/manager-area/cities', text: 'Cities' },
            { routerLink: '/manager-area/clubs', text: 'Clubs' },
            {
              routerLink: '/manager-area',
              text: 'All-Russia economic activities types ',
              columns: 1,
              alignRight: true,
              children: [
                {
                  routerLink:
                    '/manager-area/all-russian-economic-activities-sections',
                  text: 'All-Russia economic activities types sections'
                },
                {
                  routerLink:
                    '/manager-area/all-russian-economic-activities-categories',
                  text: 'All-Russia economic activities types categories'
                },
                {
                  routerLink: '/manager-area/all-russian-economic-activities',
                  text: 'All-Russia economic activities types'
                }
              ]
            },
            { routerLink: '/manager-area/commissions', text: 'Commission' },
            { routerLink: '/manager-area/resorts', text: 'Resorts' },
            {
              routerLink: '/manager-area/purchaseplaces',
              text: 'Certificate purchase places'
            },
            {
              routerLink: '/manager-area/expert-opinions',
              text: 'Expert opinion operator'
            },
            {
              routerLink: '/manager-area/certificate-payment-rules',
              text: 'Certificate payment rules'
            },
            {
              routerLink: '/manager-area/tradingstatereasons',
              text: 'Trading stop reasons'
            },
            { routerLink: '/manager-area/regions', text: 'Regions' },
            {
              routerLink: '/manager-area/role-rights',
              text: 'Roles-based rights'
            },
            { routerLink: '/manager-area/roles', text: 'Account roles' },
            { routerLink: '/manager-area/banks', text: 'Banks' },
            { routerLink: '/manager-area/seasons', text: 'Seasons' },
            {
              routerLink: '/manager-area/exchangesystems',
              text: 'Exchange systems'
            },
            {
              routerLink: '/manager-area/tradingstates',
              text: 'Tradings states'
            },
            {
              routerLink: '/manager-area/package-statuses',
              text: 'Packages statuses'
            },
            {
              routerLink: '/manager-area/publication-statuses',
              text: 'Publications statuses'
            },
            /*{ routerLink: "/manager-area/deal-statuses", text: "Deal statuses" },*/

            {
              routerLink: '/manager-area/deal-process-statuses',
              text: 'Deal process statuses'
            },
            {
              routerLink: '/manager-area/packages-types',
              text: 'Package types'
            },
            {
              routerLink: '/manager-area/payment-statuses',
              text: 'Payment statuses'
            },
            {
              routerLink: '/manager-area/publication-types',
              text: 'Publication types'
            },

            { routerLink: '/manager-area/dealsides', text: 'Deal sides' },
            { routerLink: '/manager-area/countries', text: 'Countries' },
            {
              routerLink: '/manager-area/mailingtypes',
              text: 'Email campaigns types'
            },
            {
              routerLink: '/manager-area/timesharetypes',
              text: 'Types of timeshare'
            },
            {
              routerLink: '/manager-area/apartmentsTypes',
              text: 'Apartments types'
            },
            { routerLink: '/manager-area/dealtypes', text: 'Deal types' },
            {
              routerLink: '/manager-area/managementfeestypes',
              text: 'Management fees types'
            },
            {
              routerLink: '/manager-area/transactiontypes',
              text: 'Financial transactions types'
            },
            {
              routerLink: '/manager-area/membership-types',
              text: 'Timeshare membership types'
            },
            { routerLink: '/manager-area/streets', text: 'Streets' },
            {
              routerLink: '/manager-area/available-documents',
              text: 'Documents available'
            },
            {
              routerLink: '/manager-area/comission-types',
              text: 'Comission types'
            },
            {
              routerLink: '/manager-area/membership-statuses',
              text: 'Membership status'
            },
            {
              routerLink: '/manager-area/penalties-presence',
              text: 'The presence of penalties'
            },
            {
              routerLink: '/manager-area/nationalities',
              text: 'Nationalities'
            },

            {
              routerLink: '/manager-area',
              text: 'Federal migration service',
              columns: 1,
              alignRight: true,
              children: [
                {
                  routerLink:
                    '/manager-area/federal-migration-service-units-regions',
                  text: 'Federal migration service units regions'
                },
                {
                  routerLink: '/manager-area/federal-migration-service-units',
                  text: 'Federal migration service units'
                }
              ]
            },
            {
              routerLink: '/manager-area/techsupport-appeal-targets',
              text: 'Tech. support appeal targets'
            },
            {
              routerLink: '/manager-area/logotype-texts',
              text: 'Logotype texts'
            },
            {
              routerLink: '/manager-area/payment-types',
              text: 'Payment types'
            },
            { routerLink: '/manager-area/taxes', text: 'Taxes' },
            {
              routerLink: '/manager-area/templates',
              text: 'Mailing templates'
            },
            {
              routerLink: '/manager-area/template-variables',
              text: 'Template variables'
            },
            {
              routerLink: '/manager-area',
              text: 'Companies, legal forms and states',
              columns: 1,
              alignRight: true,
              children: [
                {
                  routerLink: '/manager-area/legal-forms',
                  text: 'Organizations legal forms'
                },
                {
                  routerLink: '/manager-area/legal-companies-states',
                  text: 'States of legal companies'
                },
                {
                  routerLink: '/manager-area/legalcompanies',
                  text: 'Legal companies'
                }
              ]
            },
            { routerLink: '/manager-area/languages', text: 'Languages' },
            {
              routerLink: '/manager-area',
              text: 'Notification Events',
              columns: 1,
              alignRight: true,
              children: [
                {
                  routerLink: '/manager-area/notification-event-types',
                  text: 'Notification events types'
                },
                {
                  routerLink: '/manager-area/notification-event-templates',
                  text: 'Notification event templates'
                }
              ]
            },
            {
              routerLink: '/manager-area',
              text: 'Offers',
              columns: 1,
              alignRight: true,
              children: [
                {
                  routerLink: '/manager-area/portal-offers-templates',
                  text: 'Offers templates'
                },
                {
                  routerLink: '/manager-area/portal-offers',
                  text: 'Portal offers'
                }
              ]
            },

            { separator: true },

            {
              routerLink: '/manager-area/transactiontemplates',
              doNotSort: true,
              text: 'Transaction printing documents signature templates'
            },
            {
              routerLink: '/manager-area/locations',
              doNotSort: true,
              text: 'Club locations'
            },
            {
              routerLink: '/manager-area/violationtypes',
              doNotSort: true,
              text: 'Violations types'
            },
            {
              routerLink: '/manager-area/finestypes',
              doNotSort: true,
              text: 'Fines types'
            },
            {
              routerLink: '/manager-area',
              doNotSort: true,
              text: 'СтраницыРазделовФормОпераций'
            }

            //{ routerLink: "/manager-area", text: "Property rights types" },
            //{ routerLink: "/manager-area/membership-renewals", text: "Membership renewals" },
            // { routerLink: "/manager-area/pagecategories", text: "Категории страниц" },
            // { routerLink: "/manager-area", text: "OKVED codes" },
            //{ routerLink: "/manager-area", text: "Resorts" },
            //{ routerLink: "/manager-area", text: "Organizational and legal forms" },
            //{ routerLink: "/manager-area", text: "UFMS divisions" },
            //{ routerLink: "/manager-area", text: "Seasons" },
            //{ routerLink: "/manager-area", text: "Publications statuses" },
            //{ routerLink: "/manager-area", text: "Legal companies statuses" },
            // { routerLink: "/manager-area/similarityfactors", text: "Схожие факторы" },
            // { routerLink: "/manager-area/pagecategorytypes", text: "Типы категорий страниц" },
            // { routerLink: "/manager-area/clubPeriodTypes", text: "Типы периодов клубов" },
            // { routerLink: "/manager-area/sizefactors", text: "Факторы размера" },
            //{ routerLink: "/manager-area/membership-types", text: "Timeshare membership types" },
            //{ routerLink: "/manager-area/legalcompanies", text: "Legal companies" },
            //{ routerLink: "/manager-area/all-russian-economic-activities-sections", text: "All-Russia economic activities types sections" },
            //{ routerLink: "/manager-area/all-russian-economic-activities-categories", text: "All-Russia economic activities types categories" },
            //{ routerLink: "/manager-area/all-russian-economic-activities", text: "All-Russia economic activities types" },
            //{ routerLink: "/manager-area/legal-forms", text: "Organizations legal forms" },
            //{ routerLink: "/manager-area/federal-migration-service-units-regions", text: "Federal migration service units regions" },
            //{ routerLink: "/manager-area/federal-migration-service-units", text: "Federal migration service units" },
            //{ routerLink: "/manager-area/federal-migration-service-units", text: "Federal migration service units" },
          ]
        }
      ];
      this.menu.forEach((item) => {
        if (!item.children) return;
        item.children.sort((a, b) => {
          if (a.separator || b.separator || a.doNotSort || b.doNotSort)
            return 0;
          const textA = this.translateService.instant(a.text);
          const textB = this.translateService.instant(b.text);
          if (textA > textB) return 1;
          if (textA < textB) return -1;
          return 0;
        });
      });
      return;
    }

    if (this.section == 'superadmin-area') {
      this.menu = [
        {
          routerLink: '/superadmin-area/administrators',
          text: 'Administrators'
        },
        { routerLink: '/superadmin-area/toggle-bidding', text: 'Trading' },
        { routerLink: '/superadmin-area/exchange-rates', text: 'Currencies' },
        {
          text: 'Отчеты',
          children: [
            {
              routerLink: '/superadmin-area/customers-transactions-catalog',
              text: 'Customers transactions'
            },
            { routerLink: '/superadmin-area/sms-catalog', text: 'Отчет SMS' },
            {
              routerLink: '/superadmin-area/sms-verifications-catalog',
              text: 'Отчет уникальных кодов для SMS'
            }
          ]
        },
        {
          routerLink: '/superadmin-area/manual-balance-management',
          text: 'Manual customer balance management'
        },
        {
          text: 'Settings',
          children: [
            {
              routerLink: '/superadmin-area/settings/default-language',
              text: 'Default site language'
            },
            {
              routerLink: '/superadmin-area/settings/default-currency',
              text: 'Default site currency'
            },
            {
              routerLink: '/superadmin-area/settings/role-rights',
              text: 'Edit role rights'
            },
            {
              routerLink:
                '/superadmin-area/settings/registation-links-lifetime',
              text: 'Registation links lifetime'
            },
            {
              routerLink: '/superadmin-area/settings/marketplace-filter',
              text: 'Marketplace filter'
            }
          ]
        },
        {
          routerLink: '/superadmin-area/support-contacts',
          text: 'Support contacts'
        }
      ];
      return;
    }

    if (this.section == 'analytics-area') {
      this.menu = [
        { routerLink: '/analytics-area/report', text: 'Финансовые операции' },
        {
          routerLink: '/analytics-area/report-filters',
          text: 'Reports'
        }
      ];
      return;
    }

    if (this.isBroker) {
      if (this.securityContext.topMenuBuy == 'isDisplayed') {
        this.menu.push({ routerLink: '/buy', text: 'buy' });
      }
      if (this.securityContext.topMenuSell == 'isDisplayed') {
        this.menu.push({ routerLink: '/sell', text: 'Sell' });
      }
      this.menu = [
        { routerLink: '/buy', text: 'buy' },
        { routerLink: '/sell', text: 'Sell' },
        { routerLink: '/broker-area/report', text: 'Отчетность' },
        { routerLink: '/broker-area/search', text: 'Поиск' },
        { routerLink: '/broker-area/deals', text: 'Сделки' },
        { routerLink: '/broker-area/offers', text: 'Предложения' },
        { routerLink: '/help', text: 'help' },
        { routerLink: '/contacts', text: 'Contacts' }
      ];
      return;
    }

    this.menu = [];
    if (this.securityContext.topMenuBuy == 'isDisplayed') {
      this.menu.push({ routerLink: '/buy', text: 'buy' });
    }
    if (this.securityContext.topMenuSell == 'isDisplayed') {
      this.menu.push({ routerLink: '/sell', text: 'Sell' });
    }
    //if (this.securityContext.topMenuAuctions == 'isDisplayed') {
    //  this.menu.push({ routerLink: '/auctions', text: 'auctions' });
    //}
    if (this.securityContext.topMenuMembers == 'isDisplayed') {
      this.menu.push({ routerLink: '/members', text: 'members' });
    }
    if (this.securityContext.topMenuAbout == 'isDisplayed') {
      this.menu.push({ routerLink: '/about', text: 'about' });
    }
    if (this.securityContext.topMenuBlog == 'isDisplayed') {
      this.menu.push({ routerLink: '/scheme', text: 'Схема взаимодействия' });
    }
    if (this.securityContext.topMenuHelp == 'isDisplayed') {
      //this.menu.push({ routerLink: '/help', text: 'help' });
      this.menu.push({ routerLink: '/help', text: 'help' });
    }

    this.menu.push({ routerLink: '/contacts', text: 'Contacts' });
  }

  toggleMenu() {
    if (sessionStorage.getItem('menuOpened') === '1') {
      closeMenu();
      sessionStorage.setItem('menuOpened', '0');
    } else {
      openMenu();
      sessionStorage.setItem('menuOpened', '1');
    }
  }

  transformMenu(a: any[]) {
    let rows: any[] = [];
    a.map((item) => {
      if (item.name.trim() == '' || item.ishidden) return;
      let row: any = {};
      row.routerLink = item.routerLink ?? '';
      row.text = item.name
        .replace('Admin.Home.Menu.', '')
        .replace('Admin.Layout.LeftMenu.', '');
      if (row.text != 'Tasks') row.text = row.text.replace('Tasks', '');
      if (row.text != 'Settings') row.text = row.text.replace('Settings', '');
      const menuItems = item.menuItems || item.menuitems;
      if (menuItems) {
        const children = this.transformMenu(menuItems);
        if (children.length) {
          row.children = children;
        }
      }
      rows.push(row);
    });
    return rows;
  }

  async initLocale() {
    var lang = this.cookieService.get('locale');
    try {
      if (lang === undefined || lang === null || lang === '') {
        // lang = this.translateService.getBrowserLang().toLocaleUpperCase();
        lang = await this.languageService.getDefaultLanguage();
        lang = lang.toLocaleUpperCase();
      }
      this.translateService.setDefaultLang(lang.toLocaleLowerCase());
    } catch (ex) {
      this.translateService.setDefaultLang('ru');
      lang = 'RU';
    }
    this.currentLanguageCode = lang;
    this.languages.forEach(
      (item: { lang: { isCurrent: boolean; name: string } }) => {
        item.lang.isCurrent = false;
        if (item.lang.name == this.currentLanguageCode) {
          item.lang.isCurrent = true;
        }
      }
    );
    //var now = new Date();
    //var hours = now.getHours();
    //if (hours < 20 && hours > 7)
    {
      this.isDay = true;
      this.document.body.className = 'light-theme';
    }
  }

  hideContextMenu(): void {
    $('.cmenu-def').dropdown('hide');
  }

  // openContextMenu(event: Event): void {
  // $(event.target).dropdown('show')
  // }

  logout(): void {
    this.hideContextMenu();
    this.authService.logout();
  }

  refresh(): void {
    window.location.reload();
  }

  setTheme() {
    this.isDay = true; //!this.isDay;
    this.document.body.className = this.isDay ? 'light-theme' : '';
  }

  setLocale(lang: string) {
    this.cookieService.delete('locale');
    this.cookieService.set('locale', lang, { expires: 365 * 100, path: '/' }); // 100 years
    this.translateService.setDefaultLang(lang.toLocaleLowerCase());
    this.currentLanguageCode = lang;
    this.languages.forEach(
      (item: { lang: { isCurrent: boolean; name: string } }) => {
        item.lang.isCurrent = false;
        if (item.lang.name == this.currentLanguageCode) {
          item.lang.isCurrent = true;
        }
      }
    );
  }

  onLanguageDropdownLinkClick() {
    // this.hideContextMenu();
    this.setLocale(this.currentLanguageCode == 'EN' ? 'RU' : 'EN');
    this.refresh();
  }

  processCookieConsent() {
    const consentCookie = this.cookieService.get('cookie_consented') || false;
    if (consentCookie === false) {
      this.cookieService.set('cookie_consented', '1', {
        expires: 365 * 100,
        path: '/'
      }); // 100 years
      setTimeout(() => {
        $('#cookie').modal();
      }, 500);
    }
  }

  submenuOnClick(event: any) {
    // $(event.target).dropdown('show');
    // setTimeout(() => {
    //   document.querySelectorAll('.menu').forEach(node => {
    //     node.classList.remove('visible');
    //     node.classList.add('hidden');
    //   });
    // }, 100);
  }

  submenuOnEnter(event: any) {
    // const submenu = event.target.querySelector('.menu');
    // if (!submenu) return;
    // $('.cmenu').dropdown('hide');
    // $('.cmenu-hover').dropdown('hide');
    // $(event.target).dropdown('show');
    // submenu.classList.add('visible');
  }

  submenuOnLeave(event: any) {
    // const submenu = event.target.querySelector('.menu');
    // if (!submenu) return;
    // submenu.classList.remove('visible');
    // $(event.target).dropdown('hide');
  }

  openSubMenu(event: any) {
    const submenu = event.target.querySelector('.menu');
    if (!submenu) return;
    // $(submenu).show();
    submenu.classList.add('visible');
  }

  closeSubMenu(event: any) {
    const submenu = event.target.querySelector('.menu');
    if (!submenu) return;
    // $(submenu).hide();
    submenu.classList.remove('visible');
  }

  onSearchSubmit() {
    const searchContainer = document.querySelector('.search__input-block');
    if (this.isSearchOpened) {
      gsap.to(searchContainer, 0.3, { width: '0' });
      this.router.navigate(['/search'], {
        replaceUrl: true,
        queryParams: { q: this.searchTerm }
      });
    } else {
      this.searchTerm = '';
      gsap.to(searchContainer, 0.3, { width: '32.5rem' });
      searchContainer?.querySelector<HTMLElement>('.search__input')?.focus();
    }
    this.isSearchOpened = !this.isSearchOpened;
  }
}
