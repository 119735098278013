<div id="modal-change-package-price" class="mw">
  <div class="mw__content">
    <div class="mw__head">
      <div class="mw__title">{{'Package price changing' | translate}}</div>
      <span class="mw__line"></span>
      <div class="mw__stitle mt2" *ngIf="newPriceSetted">{{'New package price is set' | translate}}</div>
    </div>
    <div class="mw__body">
      <form class="form" (ngSubmit)="setNewPrice()">
        <div class="form__list mb3">

          <div class="form__item" [class.error]="errors.get('newPrice')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Enter your new package price' | translate}}</div>
            </div>
            <input type="number" name="newPrice" [(ngModel)]="entity.newPrice" min="1" class="form__input" placeholder="{{'Enter your new package price' | translate}}"
                   (ngModelChange)="errors.delete('newPrice')">
            <div class="error__message" *ngIf="errors.get('newPrice')">
              {{errors.get('newPrice')[0] | translate}}
            </div>
          </div>
        </div>
        <div class="form__item mb2">
          <button [disabled]="loading" type="button" class="btn w100" id="btnSignPriceAgreement" style="display: none!important;"
                  [attr.data-tooltip]="(loading ? 'Loading...' : 'Sign price agreement') | translate"
                  data-position="bottom center">
            {{(loading ? 'Loading...' : 'Sign price agreement') | translate}}
          </button>
        </div>
        <div class="form__item mb2">
          <button [disabled]="loading" type="submit" id="btnSetNewPrice" class="btn w100"
                  [attr.data-tooltip]="(loading ? 'Loading...' : 'Set new package price') | translate"
                  data-position="bottom center">
            {{(loading ? 'Loading...' : 'Set new package price') | translate}}
          </button>
        </div>
        <div class="form__item mb2">
          <button [disabled]="!loading" type="button" class="btn w100" id="btnCancelSetNewPrice"
                  [attr.data-tooltip]="(loading ? 'Loading...' : 'Cancel') | translate"
                  data-position="bottom center">
            {{('Cancel') | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
