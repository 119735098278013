<button *ngIf="ns.userMessages.length==0" class="notification" data-position="bottom center" [attr.data-tooltip]="'Notifications'|translate" > 
    <span class="notification__num">{{ns.userMessages.length}}</span>
    <span class="notification__icon"></span>
 </button>
<button *ngIf="ns.userMessages.length>0" class="notification" data-position="bottom center" > 
    <span class="notification__num">{{ns.userMessages.length}}</span>
    <span class="notification__icon"></span>
    <div class="menu center transition" tabindex="-1">
        <div class="item item_none active selected"></div>
        <div class="dropdown__content">
            <div class="notification__body">
                <div class="notification__scroll">
                    <div class="notification__head">
                        <p class="notification__title">Уведомления:</p><a href="#" class="notification__del">Очистить все</a>
                    </div>
                    <div class="notification__content">
                        <a *ngFor="let nf of ns.userMessages" (click)="notificationClick(nf)" class="notification__item notification-item">
                            <div class="notification-item__logo"><img [src]="nf.fromIcon" alt="" loading="lazy"></div>
                            <div>
                                <div class="notification-item__name"> {{nf.from}} </div>
                                <div class="notification-item__sms"> {{nf.subject}} </div>
                                <div class="notification-item__time"> {{hoursAgo(nf.created)}} часа(ов) назад </div>
                            </div>
                        </a>
                    </div>
                </div><a href="#" class="notification__btn btn">Смотреть все</a>
            </div>
        </div>
    </div> 
</button>

