import { Component, Injectable, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OffersAcceptService } from '../../services/offers-accepts/offers-accept.service';
import { OffersAccept } from '../../models/offers-accept/offers-accept.model';
import { OfferName } from '../../models/offers-accept/offer-name.model';
//import { PublicationService } from 'src/app/publication.service';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';
import { SupportService } from 'src/app/core/services/support/support.service';
import { TimerServiceService } from 'src/app/timer-service.service';
import { Subscription } from 'rxjs';
import { EventLogService } from '../../../manager-area/services/event-log/event-log.service';
import { PublicationChangePrice } from 'src/app/core/models/publications/publication-change-price.model';
import { Router } from '@angular/router';

declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
declare function openModal(string, closeFn, bodyFn): Promise<boolean>;
declare function closeModal(): void;
declare var $: any;

const offerTypeName = 'price-agreement';

@Component({
  selector: 'app-price-agreement',
  templateUrl: './price-agreement.component.html',
  styleUrls: ['./price-agreement.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class PriceAgreementComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  errors = new Map();
  loading: boolean = false;
  priceAgreementAccepted: boolean = false;


  supportEmail: string = '';
  entity: PublicationChangePrice = new PublicationChangePrice();

  constructor(
    //   private offersAcceptService: OffersAcceptService,
    private supportService: SupportService,
    private translateService: TranslateService,
    public ts: TimerServiceService,
    private offersAcceptService: OffersAcceptService,
    //private publicationService: PublicationService,
    private publicationsService: PublicationsService,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {

    this.entity = this.publicationsService.getPublicationInfo

    this.subscriptions = this.publicationsService.publicationInfo$.subscribe((priceInfo: PublicationChangePrice) => {
      //debugger;
      if (this.loading)
        return;

      this.entity.newPrice = priceInfo.newPrice;
      this.entity.publicationId = priceInfo.publicationId;
      this.entity.oldPrice = priceInfo.oldPrice;

      if (this.priceAgreementAccepted) {
        closeModal();
      }
    });

    this.subscriptions.add(this.offersAcceptService.offerAccepted$.subscribe((acceptedOffer: OfferName) => {
      //debugger;
      if (acceptedOffer.typeName == offerTypeName) {
        this.priceAgreementAccepted = true;

        this.publishPackageAndRedirect().then(() => {
          closeModal();
        });
      }
      else if (acceptedOffer.typeName == `${offerTypeName}_x`) {
        this.priceAgreementAccepted = false;
        //debugger;
        this.entity.newPrice = this.entity.oldPrice;

        this.publicationsService.updatePrice(this.entity).then((err) => {
          //debugger;
          this.errors = err;
          this.loading = false;
          if (this.errors.size == 0) {

            anAlert("Соглашение о цене не подписано, публикация лота не возможна.", "OK");

            closeModal();
          }
          else {
            let msg = "Не отменить изменение стоимости лота: ";

            msg = msg + this.errors.entries().next().value[1];

            anAlert(msg, "OK");
          }
        });
      }
    }));

    this.priceAgreementAccepted = await this.offersAcceptService.getOffersAcceptState(offerTypeName);
    ///TODO: Read value of offersAccepted from server
    this.supportEmail = await this.supportService.getEmail();
    //debugger;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async showPriceAgreement(): Promise<void> {
    await openModal("#mdlPriceAgreement", null, null);
  }


  async submitPriceAgreementAccept(): Promise<void> {
    if (!this.priceAgreementAccepted) {
      this.ts.msg.emit({ msg: "priceAgreementSMS", obj: this });
    }
  }

  async publishPackageAndRedirect(): Promise<void> {
    this.errors = await this.publicationsService.publish(this.entity.publicationId);

    if (this.errors.size === 0) {
      this.router.navigateByUrl("/personal-area/private-packages/published");
    }
    else {
      let msg = "Не удалось опубликовать лот: ";

      msg = msg + this.errors.entries().next().value[1];

      anAlert(msg, "OK");
    }
  }
}
