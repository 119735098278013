import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class Utils {

  constructor() { }

  /**
   * ☃ dateDiff "Snowman Carl" (http://stackoverflow.com/questions/13903897)
   * Returns a detail object about the difference between two dates
   *
   * When providing custom units, provide them in descending order (eg week,day,hour; not hour,day,week)
   *
   * @param {Date} dateStart - date to compare to
   * @param {Date|string} [dateEnd=new Date()] - second date, can be used as unit param instead
   * @param {...string} [units=Object.keys(dateDiffDef)] - limits the returned object to provided keys
   */
  dateDiff(
    dateStart: Date,
    dateEnd: Date | string = new Date,
    ...units: string[]
  ): {
    [key: string]: number
  } {
    if (typeof dateEnd === 'string') dateEnd = new Date();

    let delta: number = Math.abs(dateStart.getTime() - dateEnd.getTime());

    return (units.length ? units : Object.keys(this.dateDiffDef))
      .reduce((res: { [key: string]: number }, key: string) => {
        if (!this.dateDiffDef.hasOwnProperty(key))
          throw new Error('Unknown unit in dateDiff: ' + key);

        const value = Math.floor(delta / this.dateDiffDef[key]);
        if (value != 0) {
          res[key] = value;
          delta -= res[key] * this.dateDiffDef[key];
        }

        return res;
      }, {});
  }
  toNumber(x: any): number {
    if (isNaN(Number(x))) {
      x = String(x) || "0";
      x = x.replace(/[^0-9,]/g, '').replace(",00", '').replace(",", ".");
      return Number(x) || 0
    } else {
      return Number(x);
    }

  }
  // default time units for dateDiff
  private dateDiffDef: { [key: string]: number } = {
    // millennium: 31536000000000,
    // century: 3153600000000,
    // decade: 315360000000,
    // year: 31536000000,
    // quarter: 7776000000,
    // month: 2592000000,
    // week: 604800000,
    dayAbbr: 86400000,
    hourAbbr: 3600000,
    minuteAbbr: 60000,
    // second: 1000,
    // millisecond: 1
  };

  jsonHeaders() {
    return new HttpHeaders({
      //'Authorization' : 'Bearer ',
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    });
  }

  scrollToTop(y = 0) {
    window.scrollTo(0, y);
    // $('html, body').stop().animate({ scrollTop: y }, 500);
  }

  ucfirst(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  ucwords(str: string): string {
    if (!str) return '';
    return str.trim().toLowerCase().replace(/[A-Za-zА-Яа-я0-9]+/g, ($1) => this.ucfirst($1));
  }

  collectErrors(response: any): Map<string, any> {
    let collected = new Map<string, any>();

    if (response?.error?.errors instanceof Object) {
      const errors = Object.entries(response?.error?.errors);
      if (Array.isArray(errors)) errors.forEach(([key, value]) => collected.set(this.ucfirst(key), value));
    }
    if (!collected.size) {
      collected.set('Error', ['Error occured']);
    }

    return collected;
  }

  mapResponseErrors(response: any): Map<string, any> {
    let collected = new Map<string, any>();

    if (response?.error?.errors instanceof Object) {
      const errors = Object.entries(response?.error?.errors);
      if (Array.isArray(errors)) errors.forEach(([key, value]) => collected.set(key, value));
    }

    if (!collected.size) {
      collected.set('Error', ['Error occured']);
    }

    return collected;
  }

  closeAccordion() {
    $('.accordion').accordion('close', 0);
  }

  // togglePasswordVisibility(event: Event) {
  //   const target = event.target as HTMLElement;
  //   const group = target.closest('.form__group');
  //   if (!group) return;
  //   const eye = group.querySelector('.js-pass-view');
  //   const input = group.querySelector('input');
  //   if (!eye || !input) return;
  //   eye.classList.remove('ic-eye');
  //   eye.classList.remove('ic-eye-off');
  //   if (input.getAttribute('type') == 'password') {
  //     input.setAttribute('type', 'text');
  //     eye.classList.add('ic-eye');
  //   } else {
  //     input.setAttribute('type', 'password');
  //     eye.classList.add('ic-eye-off');
  //   }
  // }

  // initShowPass() {
    // $(function() {
    //   $('[data-show-pass]').on('click', function () {
    //     $('.js-pass-view').toggleClass('ic-eye-off ic-eye');
    //     $('[data-show-pass]').parent().find('input').attr('type', $('[data-show-pass]').parent().find('input').attr('type') == 'password' ? 'text' : 'password');
    //   });    
    // });
  // }
}
