<div class="smodal-content">
    <div class="smodal-header">
      Необходимо заполнить данные
      <div (click)="cancel()" class="modal-close"><svg class="icon">
        <use xlink:href="/assets/img/svg-sprite.svg#times-light"></use>
    </svg></div>
    </div>
    <div class="smodal-body">
      <div class="modal-dealer__list">
        <div class="modal-dealer__item mb-2">
            <div class="char">Авторизация</div>
            <div class="val">&#10004;</div>
        </div>
        <div class="modal-dealer__item mb-2">
            <div class="char">Профиль и банковские реквизиты</div>
            <div class="val" style="color:red">&#10008;</div>
        </div>
    </div>
    </div>
    <div class="smodal-footer">
        <button type="button" class="btn btn--primary" (click)="confirm()" >Заполнить данные</button>
        <button type="button" class="btn btn--dark-outline" (click)="cancel()">Отмена</button>
    </div>
</div>
