import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { SmsAuthCodeRequest } from '../../models/sms-auth-code/sms-auth-code-request.model';
import { SmsAuthCodeCheckResult } from '../../models/sms-auth-code/sms-auth-code-check-result.model';
import { Utils } from 'src/app/core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class SmsAuthCodesService {
  private serverUrl = `${environment.cmsApiUrl}/api/SmsVerifications`;

  constructor(
    private httpClient: HttpClient,
    private utils: Utils
  ) { }

  async sendCode(phoneNumber: string): Promise<SmsAuthCodeRequest> {
    try {
      let ret:Promise<SmsAuthCodeRequest> = (await this.httpClient.get<SmsAuthCodeRequest>(
        `${this.serverUrl}/SendAuthCode/${phoneNumber}`,
        { headers: this.utils.jsonHeaders() }
      )).toPromise();
      return ret;
    } catch {
      return Promise.reject(null);
    }
  }

  async checkAuthCode(requestId: string, authCode: string, isItPhoneNumberCheck: boolean = false): Promise<SmsAuthCodeCheckResult> {
    return await this.httpClient.post<SmsAuthCodeCheckResult>(
      `${this.serverUrl}/CheckAuthCode/${requestId}/${authCode}/${isItPhoneNumberCheck}`,
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }
  checkAuthCodePromise(requestId: string, authCode: string, isItPhoneNumberCheck: boolean = false): Promise<SmsAuthCodeCheckResult> {
    return this.httpClient.post<SmsAuthCodeCheckResult>(
      `${this.serverUrl}/CheckAuthCode/${requestId}/${authCode}/${isItPhoneNumberCheck}`,
      { headers: this.utils.jsonHeaders() }
    ).toPromise();
  }

}
