import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface AnAlertModel {
  title?: string;
  body: string;
  ok?: string;
  cancel?: string;
  wclass?: string;
}

@Component({
  selector: 'app-an-alert',
  templateUrl: './an-alert.component.html',
  styleUrls: ['./an-alert.component.css']
})
export class AnAlertComponent
  extends SimpleModalComponent<AnAlertModel, boolean>
  implements AnAlertModel, OnInit
{
  title = '';
  body = '';
  ok = '';
  cancel = '';
  wclass = 'w-480';

  existOkButton = false;
  existCancelButton = false;
  popupHtml = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.existOkButton = this.ok !== '';
    this.existCancelButton = this.cancel !== '';

    if (this.title !== '') {
      this.popupHtml +=
        '<div class="font-alt-extra text-xl">' + this.title + '</div>';
      this.popupHtml += '<div class="divider-sm df"></div>';
    }
    if (this.body !== '') {
      this.popupHtml += '<div class="text-sm mb-20">' + this.body + '</div>';
    }
  }

  Ok(): void {
    this.result = true;
    this.close();
  }

  Cancel(): void {
    this.result = false;
    this.close();
  }
}
