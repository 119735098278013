import { Component, OnInit, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OffersAcceptService } from '../../services/offers-accepts/offers-accept.service';
import { OffersAccept } from '../../models/offers-accept/offers-accept.model';
import { SupportService } from 'src/app/core/services/support/support.service';
import { TimerServiceService } from 'src/app/timer-service.service';
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
declare function openModal(string, closeFn, bodyFn): Promise<boolean>;
declare function closeModal(): void;

const offerTypeName = 'offers';

@Component({
  selector: 'app-offers-accept',
  templateUrl: './offers-accept.component.html',
  styleUrls: ['./offers-accept.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class OffersAcceptComponent implements OnInit {

  errors = new Map();
  loading: boolean = false;
  offersAccepted: boolean = false;

  //offersAccept: OffersAccept = new OffersAccept();
  supportEmail: string = '';

  

  constructor(
 //   private offersAcceptService: OffersAcceptService,
    private supportService: SupportService,
    private translateService: TranslateService,
    public ts: TimerServiceService,
    private offersAcceptService: OffersAcceptService
  ) { }

  async ngOnInit(): Promise<void> {
    //debugger;
    this.offersAccepted = await this.offersAcceptService.getOffersAcceptState(offerTypeName);
    ///TODO: Read value of offersAccepted from server
    this.supportEmail = await this.supportService.getEmail();
  }
  async showAgreement(): Promise<void>{
    await openModal("#mdlAgreement", null, null);

  }


  async submitOffersAccept(): Promise<void> {
    if (!this.offersAccepted) {
      closeModal();
      this.ts.msg.emit({ msg: "profileOffersSMS", obj: this });
    }
  }

}
