import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from './modal.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {};

@NgModule({
  declarations: [
    ModalComponent,
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    NgxMaskModule.forRoot(maskConfig),
  ]
})
export class ModalModule { }
