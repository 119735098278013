import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/core/utils/utils';
import { environment } from 'src/environments/environment';
import { Agreement } from '../../models/agreement/agreement.model';
import { StoreAgreement } from '../../models/agreement/store-agreement.model';
import { GeneralResponse } from 'src/app/shared/models/GeneralResponse.model';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {

  errors = new Map<string, any>();

  constructor(
    private httpClient: HttpClient,
    private utils: Utils
  ) { }

  clearErrors(): Promise<void>{
    this.errors = new Map<string, any>();

    return Promise.resolve();
  }

  getErrors(): Promise<Map<string, any>> {
    return Promise.resolve(this.errors);
  }

  async get(params: any): Promise<Agreement> {
    this.errors = new Map<string, any>();

    try {
      let agreement = await this.httpClient.get<Agreement>(`${environment.apiUrl}/api/DealAgreement/get`,
        { params: params, headers: this.utils.jsonHeaders() }).toPromise();

      if (!agreement) {
        agreement = new Agreement();
      }

      return agreement
    }
    catch (response: any) {
      this.errors = this.utils.collectErrors(response);
      debugger;
    }

    return new Agreement();
  }

  async getList(publicationId: number, newPrice: number = 0): Promise<GeneralResponse<Agreement[]>> {
    let result = new GeneralResponse<Agreement[]>();

    try {
      result.Response = await this.httpClient.get<Agreement[]>(`${environment.apiUrl}/api/DealAgreement/getList/?publicationId=${publicationId}&newPrice=${newPrice}`,
        { headers: this.utils.jsonHeaders() }).toPromise();      
    }
    catch (response: any) {
      debugger;
      result.Errors = this.utils.collectErrors(response);      
    }

    return result;
  }

  async store(entity: StoreAgreement): Promise<Map<string, any>> {
    this.errors = new Map<string, any>();

    try {
      await this.httpClient.post(`${environment.apiUrl}/api/DealAgreement/storeAgreement`, entity,
        { headers: this.utils.jsonHeaders() }).toPromise();

    } catch (response: any) {
      this.errors = this.utils.collectErrors(response);
      debugger;
    }

    return this.errors;
  }
}
