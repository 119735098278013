<div id="cookie" class="mw">
  <div class="mw__content">
    <div class="mw__head">
      <div class="mw__title">{{'Attention!' | translate}}</div>
      <span class="mw__line mb2"></span>
      <div class="mw__stitle mb3">
        <div class="mb1" [innerHTML]="'CookiePopupHTML' | translate"></div>
        <div><a href="/cookies" target="_blank" class="tdu">{{'CookieFileTypes' | translate}}</a></div>
      </div>
      <div class="tc">
        <a href="#" rel="modal:close" class="btn ml2 mr2 cookie-modal-btn"
          [attr.data-tooltip]=" 'I agree to the use of Cookes' | translate " data-position="bottom center">
          {{'I agree to the use of Cookes' | translate}}
        </a>
        <a href="/policy" target="_blank" class="btn ml2 mr2 btn_wt cookie-modal-btn"
          [attr.data-tooltip]=" 'More about cookies and privacy policy' | translate " data-position="bottom center">
          {{'More details' | translate}}
        </a>
      </div>
    </div>
  </div>
</div>