import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import { TimerServiceService } from 'src/app/timer-service.service';
import { Agreement } from 'src/app/features/personal-area/models/agreement/agreement.model';
import { StoreAgreement } from 'src/app/features/personal-area/models/agreement/store-agreement.model';
import { AgreementService } from 'src/app/features/personal-area/services/agreement/agreement.service'
import { PublicationChangePrice } from 'src/app/core/models/publications/publication-change-price.model';
//import { PublicationService } from 'src/app/publication.service';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';

import { OffersAcceptService } from '../../services/offers-accepts/offers-accept.service';

import { Subscription } from 'rxjs';

declare var $: any;
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
declare function closeModal(): void;

const offerTypeName = 'mainAgreement';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class AgreementComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  pdfOptions = {
    filename: `agreement.pdf`,
    margin: 2,
    image: { type: 'jpeg', quality: 0.95 },
    html2canvas: { scale: 2 },
    enableLinks: false,
    jsPDF: { unit: 'em', format: 'a4', orientation: 'portrait' }
  };

  loading: boolean = false;
  printing: boolean = false;
  saving: boolean = false;

  offerAccepted: boolean = false;

  errors: Map<string, any> = new Map<string, any>();

  dealAgreementId: number = 0;
  relatedPublicationId: number = 0;
  dealId: number = 0;

  content: string = '';
  contentEn: string = '';

  entity: Agreement = new Agreement();
  info: PublicationChangePrice = new PublicationChangePrice();

  constructor(
    private router: Router,
    private route: ActivatedRoute,    
    public ts: TimerServiceService,
    private agreementService: AgreementService,
    //private publicationService: PublicationService,
    private publicationsService: PublicationsService,
    private offersAcceptService: OffersAcceptService
  ) { }

  async ngOnInit() {
    this.loading = true;
    
    this.route.queryParams.subscribe(params => {
      //this.dealAgreementId = Number(params.id) || 0;

      if (this.relatedPublicationId == 0) {
        this.relatedPublicationId = Number(params.id) || 0;

        if (this.relatedPublicationId > 0) {
          this.getContent().then(() => {
            //debugger;
            let el = document.getElementById("modal-agreement")

            if ((el as HTMLElement) != null) {
              if (el?.classList.contains("mw")) {
                el.classList.remove("mw");
              }
            }

            this.loading = false;
          });
        }
      }
    });

    if (!this.relatedPublicationId) {
      let info: PublicationChangePrice;
      info = this.publicationsService.getPublicationInfo;

      if (info != null) {
        if (info.publicationId > 0 && this.relatedPublicationId == 0) {
          this.relatedPublicationId = info.publicationId;

          if (this.relatedPublicationId > 0) {
            this.getContent().then(() => {
              this.loading = false;
            });
          }
        }
      }

      //  if (!this.relatedPublicationId) {
      //    this.router.navigate(['/404']);
      //    return;
      //  }
    }

    this.subscriptions = this.publicationsService.publicationInfo$.subscribe((priceInfo: PublicationChangePrice) => {
      //debugger;
      if (this.saving)
        return;

      this.relatedPublicationId = priceInfo.publicationId;

      if (this.relatedPublicationId > 0) {
      //  this.router.navigate(['/404']);
      //  return;
      //}
      //else {
        this.getContent().then(() => {
          this.loading = false;
          return;
        });
      }
    });  

    if (this.relatedPublicationId) {
      await this.getContent();
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  async getContent(): Promise<void> {

    try {
      let info: PublicationChangePrice;
      info = this.publicationsService.getPublicationInfo;

      this.entity = await this.agreementService.get({
        relatedPublicationId: this.relatedPublicationId, newPrice: info.newPrice
      });
    }
    catch (ex: any) {
      console.error(ex);
      anAlert("Не удалось получить данные договора цессии.", "OK");
    }

    if (this.agreementService.errors.size == 0) {
      this.dealAgreementId = this.entity.dealAgreementId;
      this.dealId = this.entity.relatedDealId;
      this.ts.currentDealId = this.entity.relatedDealId;
      this.content = this.entity.content;
      this.contentEn = this.entity.contentEn;
    }
    else {
      let msg = "Не удалось сформировать договор цессии: ";

      msg = msg + this.agreementService.errors.entries().next().value[1];

      anAlert(msg, "OK");
    }

    //  try {
    //    this.entity = await this.httpClient.get<Agreement>(this.url + '/get', {
    //      params: {
    //        dealAgreementId: this.dealAgreementId,
    //        relatedPublicationId: this.relatedCertificateId,
    //      }
    //    }).toPromise();
    //    this.dealAgreementId = this.entity.dealAgreementId;
    //    this.content = this.entity.content;
    //    this.contentEn = this.entity.contentEn;

    //  } catch (e: any) {
    //    if (e.status == 404) {
    //      this.router.navigate(['/404']);
    //    }
    //  }
  }

  async download(event: Event) {
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.page')?.querySelector('.pdf-container');
      const opt = this.pdfOptions;
      //const dealAgreementId = this.dealAgreementId;
      opt.filename = `agreement-${this.relatedPublicationId}.pdf`;
      this.loading = true;
      html2pdf()
        .set(opt)
        .from(element)
        .toContainer()
        .toPdf()
        .get('pdf')
        .then(async (pdfObj) => {
          const data = pdfObj.output();

          //const model = new StoreAgreement();

          //model.pdf = btoa(data);
          //model.dealAgreementId = this.relatedPublicationId;

          //let errors = await this.agreementService.store(model);

          //if (errors.size != 0) {
          //  let msg = "Не удалось скачать договор цессии: ";

          //  msg = msg + this.agreementService.errors.entries().next().value[1];

          //  anAlert(msg, "OK");
          //}

          //await this.httpClient.post(this.url + '/storeAgreement', model).toPromise();
          this.loading = false;
        }).save();
    }, 100);
  }

  print(event: Event) {
    $('#printing-modal').modal();
    setTimeout(() => {
      const element = (event.target as HTMLElement).closest('.page')?.querySelector('.pdf-container');
      const opt = this.pdfOptions;
      //const dealAgreementId = this.relatedPublicationId;
      opt.filename = `agreement-${this.relatedPublicationId}.pdf`;
      this.printing = true;
      html2pdf()
        .set(opt)
        .from(element)
        .toContainer()
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          pdf.autoPrint();
          $.modal.close();
          setTimeout(() => {
            window.open(pdf.output('bloburl'), '_blank');
          }, 100);
        })
        .finally(() => {
          setTimeout(() => {
            this.printing = false;
            $.modal.close();
          }, 100)
        });
    }, 100);
  }

  async store() {
    this.saving = true;
    setTimeout(() => {
    //  debugger;
      const element = (document.getElementsByClassName("btn mr5")[0] as HTMLElement).closest('.page')?.querySelector('.pdf-container');
      const opt = this.pdfOptions;
      //const dealAgreementId = this.dealAgreementId;
      opt.filename = `agreement-${this.relatedPublicationId}.pdf`;
      this.loading = true;
      html2pdf()
        .set(opt)
        .from(element)
        .toContainer()
        .toPdf()
        .get('pdf')
        .then(async (pdfObj) => {
          const data = pdfObj.output();
          //debugger;
          const model = new StoreAgreement();

          model.pdf = btoa(data);
          model.dealAgreementId = this.relatedPublicationId;

          //debugger;
          this.errors = await this.agreementService.store(model);

          if (this.errors.size != 0) {
            let msg = "Не удалось сохранить завизированный договор цессии: ";

            msg = msg + this.agreementService.errors.entries().next().value[1];

            anAlert(msg, "OK");
          }
          else {
            //debugger;
            if (this.ts.Step != '') {
              if (this.ts.Step != 'signPriceAgreementAtPublishing') {
                this.ts.Step == "AddCertificateStep";
                this.router.navigateByUrl("/personal-area/add-certificate");
              }
            }
            //this.router.navigateByUrl("/personal-area/agreement?id=1234");
          }
          this.saving = false;
          //await this.httpClient.post(this.url + '/storeAgreement', model).toPromise();
          this.loading = false;

          Promise.resolve();
        });//.save();
    }, 100);
  }


  dialogSMS(): void {
    
    this.store().then(res => {
      if (this.errors.size == 0)
          closeModal();
          this.ts.msg.emit({ msg: "DealOfferSMS1", obj: this });
    });
    
  }
}
