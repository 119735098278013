export class UserSessionData {
  constructor() { }
  userSessionDataId: number = 0;

  relatedUserId: string | null = null;
  sessionData: string | null = null;

  createdAt: string | null = null;
  expiredAt: string | null = null;
}
