export enum DealTypesEnum {
  ///Сделка по публикации с типом "Продажа" (пакета)
  Buy = 'd_sale',
  ///Сделка по публикации с типом "Покупка" (заявка на покупку)
  Sell = 'd_purchase',
  ///Сделка по публикации с типом "Присоединение" (заявка на присоединение)
  Add = 'd_add',
  ///Сделка по публикации с типом "Аукцион"
  Auction = 'd_auction'
}
