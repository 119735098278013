export class DealTax {
  sellerTax = 0;
  sellerTaxFormatted = '';
  sellerCommission = 0;
  sellerCommissionFormatted = '';
  sellerSum = 0;
  sellerSumFormatted = '';
  buyerTax = 0;
  buyerTaxFormatted = '';
  buyerCommission = 0;
  buyerCommissionFormatted = '';
  buyerSum = 0;
  buyerSumFormatted = '';

  constructor() {}
}

export class FinancialTaxInfo {
  commissionSum = 0;
  commissionSumFormatted = '';
  taxes = 0;
  taxesFormatted = '';
  totalAmount = 0;
  totalAmountFormatted = '';

  constructor() {}
}
