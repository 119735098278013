<div id="registration-error" class="mw">
  <div class="mw__content">
    <div class="mw__head">
      <div class="mw__title">{{'Data entered incorrectly!' | translate}}</div>
      <span class="mw__line mb2"></span>
      <div class="fz14 lh tc mb3">
        {{'Please fill out the form again.' | translate}}
      </div>
      <div class="form__item mb2">
        <button type="submit" class="btn w100" data-modal="registration">{{'Fill out again' | translate}}</button>
      </div>
      <div class="form__item mb3">
        <a href="#close-modal" rel="modal:close" class="btn btn_wt w100">{{'Cancel' | translate}}</a>
      </div>
    </div>
  </div>
</div>