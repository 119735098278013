import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BnNgIdleService } from 'bn-ng-idle';
import { BehaviorSubject, Observable } from 'rxjs';
import { Role } from 'src/app/core/enums/role.enum';
import { IRegistration } from 'src/app/registration.model';
import { Token } from 'src/app/token.model';
import { environment } from 'src/environments/environment';
import { User } from '../../models/auth/user.model';
import { Utils } from '../../utils/utils';

export const ACCESS_TOKEN_NAME = 'tst_access_token';
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
declare function openModal(string): Promise<boolean>;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public navigate: boolean = true;
  public nextPoupId: string = '';
  constructor(
    private httpClient: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private utils: Utils,
    private bnIdle: BnNgIdleService
  ) {
    this.userSubject = new BehaviorSubject<User>(this.getUserFromToken());
    this.user = this.userSubject.asObservable();
  }

  public get userData(): User {
    return this.userSubject.value;
  }

  async register(request: IRegistration): Promise<Map<string, any>> {
    let errors = new Map<string, any>();
    try {
      await this.httpClient
        .post(`${environment.authApiUrl}/api/auth/register`, request)
        .toPromise();
    } catch (response: any) {
      errors = this.utils.collectErrors(response);
    }

    return errors;
  }

  async verify(request: IRegistration): Promise<Map<string, any>> {
    let errors = new Map<string, any>();
    try {
      await this.httpClient
        .post(`${environment.authApiUrl}/api/auth/verify`, request)
        .toPromise();
    } catch (response: any) {
      errors = this.utils.collectErrors(response);
    }

    return errors;
  }

  async login(email: string, password: string): Promise<void> {
    const token = await this.httpClient
      .post<Token>(`${environment.authApiUrl}/api/auth/login`, {
        email,
        password
      })
      .toPromise();
    localStorage.setItem(ACCESS_TOKEN_NAME, token.access_token);
    this.userSubject.next(this.getUserFromToken());
  }

  logout(): void {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    this.userSubject.next(this.getUserFromToken());
    this.router.navigate(['/']);
  }

  getUrlToRedirectAfterLogin(): string {
    const user = this.userData;

    let urlToRedirect = '/';
    if (user.role === Role.Broker) {
      urlToRedirect = '/broker-area';
    } else if (user.role === Role.Dealer) {
      urlToRedirect = '/personal-area';
    } else if (user.role === Role.Admin) {
      urlToRedirect = '/manager-area';
    } else if (user.role === Role.Superadmin) {
      urlToRedirect = '/superadmin-area';
    } else if (user.role === Role.Analytic) {
      urlToRedirect = '/analytics-area';
    }

    return urlToRedirect;
  }

  getToken(): string | undefined {
    return localStorage.getItem(ACCESS_TOKEN_NAME) ?? undefined;
  }

  isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.getToken());
  }

  getTokenData(): any {
    return this.jwtHelper.decodeToken(this.getToken());
  }

  isTokenExpired(token: string): boolean {
    return this.jwtHelper.isTokenExpired(token);
  }

  getEmailFromToken(token: string): string {
    return this.jwtHelper.decodeToken(token)?.email ?? '';
  }

  getUserFromToken(): User {
    const user = new User();

    if (!this.isAuthenticated()) return user;

    const tokenData = this.getTokenData();
    if (!tokenData) return user;

    user.id = tokenData.sub;
    //this.bnIdle.startWatching(420).subscribe((isTimedOut: boolean) => {
    this.bnIdle.startWatching(43200).subscribe(async (isTimedOut: boolean) => {
      if (isTimedOut) {
        this.bnIdle.stopTimer();
        this.logout();
        await anAlert('Для продолжения сессии войдите в аккаунт.', 'OK').then(
          () => {
            openModal('#login');
          }
        );
        this.bnIdle.resetTimer();
      }
    });
    if (tokenData?.role) {
      user.role = Array.isArray(tokenData.role)
        ? tokenData.role[0]
        : tokenData.role;
    }

    return user;
  }

  async confirmEmail(code: string, guid: string): Promise<any> {
    return await this.httpClient
      .post(`${environment.authApiUrl}/api/auth/confirmemail`, { code, guid })
      .toPromise();
  }

  async sendPasswordRecoveryLink(
    email: string,
    captcha: string,
    language: string
  ): Promise<Map<string, any>> {
    let errors = new Map<string, any>();
    try {
      await this.httpClient
        .post(
          `${environment.authApiUrl}/api/auth/send-password-recovery-link`,
          { email, captcha, language }
        )
        .toPromise();
    } catch (response: any) {
      errors = this.utils.collectErrors(response);
    }
    return errors;
  }

  async resetPassword(
    token: string,
    newPassword: string,
    passwordConfirmation: string
  ): Promise<Map<string, any>> {
    let errors = new Map<string, any>();
    try {
      await this.httpClient
        .post(`${environment.authApiUrl}/api/auth/reset-password`, {
          token,
          newPassword,
          passwordConfirmation
        })
        .toPromise();
    } catch (response: any) {
      errors = this.utils.collectErrors(response);
    }
    return errors;
  }
}
