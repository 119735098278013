import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from "ngx-simple-modal";
import { OfferName } from 'src/app/features/personal-area/models/offers-accept/offer-name.model';
import { OffersAcceptService } from 'src/app/features/personal-area/services/offers-accepts/offers-accept.service';
import { SmsConfirmModalComponent } from '../sms-confirm-modal/sms-confirm-modal.component';
export interface OffersAcceptModel {
  offerType:string|undefined
  data:any|undefined;
}
@Component({
  selector: 'app-offers-accept-modal',
  templateUrl: './offers-accept-modal.component.html',
  styleUrls: ['./offers-accept-modal.component.css']
})
export class OffersAcceptModalComponent  extends SimpleModalComponent<OffersAcceptModel , boolean> implements OffersAcceptModel, OnInit {
  errors = new Map();
  loading: boolean = false;
  offersAccepted: boolean = false;
  supportEmail: string = '';
  offerType: string | undefined = "offer";
  data: any|undefined=null; 

  constructor(
    private modalService: SimpleModalService,
    private offersAcceptService:OffersAcceptService
  ) {
    super();
  }
  async Accept() {
    this.result = await this.modalService.addModal(SmsConfirmModalComponent, {   //SMS confirmation form
              data: {
                title: "Подтверждение",
                text: "Подтвердите оферты портвла, введите код из СМС"
              }
    }).toPromise<boolean>();
    if (this.result) {
      let offerNameModel = new OfferName();
      offerNameModel.typeName = this.offerType||"offer";
      this.errors = await this.offersAcceptService.setOffersAcceptState(offerNameModel);
      if (this.errors.size != 0) {
        this.result = false;
      }
    }
    this.close();
  }
  Cancel() {
    // we set modal result as true on click on confirm button,
    // then we can get modal result from caller code
    this.result = false;
    this.close();
  }
  ngOnInit(): void {
  }

}
