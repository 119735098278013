export class Profile {
  constructor() {}
  avatar = '/assets/img/user-empty.png';
  newAvatar = '';
  newAvatarFileName = '';
  newAvatarFileExtension = '';
  name = '';
  nameLatin = '';
  surname = '';
  surnameLatin = '';
  middleName = '';
  middleNameLatin = '';
  fullNameRod: string | null = null;
  email = '';
  phoneNumber = '';
  gender: number | null = null;
  birthdate: Date | null = null;
  photoPermit = false;
  namePermit = true;
  surnamePermit = false;
  middleNamePermit = false;
  fullNamePermit = false;
  citizenshipCountryId: number | null = null;
  countryId: number | null = null;
  regionId: number | null = null;
  cityId: number | null = null;
  postCode: string | null = null;
  street: string | null = null;
  house: string | null = null;
  building: string | null = null;
  apartment: string | null = null;
  userId: string | null = null;
  roleId: string | null = null;
  isBlocked = false;
  isGiftReceived = false;
}
