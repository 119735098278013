import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { RegistrationWarningComponent } from './components/modals/registration-warning/registration-warning.component';
import { RegistrationComponent } from './components/modals/registration/registration.component';
import { RegistrationErrorComponent } from './components/modals/registration-error/registration-error.component';
import { RegistrationSuccessComponent } from './components/modals/registration-success/registration-success.component';
import { LoginComponent } from './components/modals/login/login.component';
import { PasswordRecoveryComponent } from './components/modals/password-recovery/password-recovery.component';
import { PersonalDataComponent } from './components/modals/personal-data/personal-data.component';
import { CookieComponent } from './components/modals/cookie/cookie.component';
import { CardComponent } from './components/modals/card/card.component';
import { PublicationChangePriceComponent } from './components/modals/publication-change-price/publication-change-price.component';
import { PriceAgreementComponent } from 'src/app/features/personal-area/pages/price-agreement/price-agreement.component';
import { AgreementComponent } from 'src/app/features/personal-area/pages/agreement/agreement.component';
import { OffersAcceptComponent } from 'src/app/features/personal-area/pages/offers-accept/offers-accept.component';

const maskConfig: () => Partial<IConfig> = () => {
  return {
    showMaskTyped: true
  };
};

@NgModule({
  declarations: [
    RegistrationWarningComponent,
    RegistrationComponent,
    RegistrationErrorComponent,
    RegistrationSuccessComponent,
    LoginComponent,
    PasswordRecoveryComponent,
    PersonalDataComponent,
    CookieComponent,
    CardComponent,
    PublicationChangePriceComponent,
    PriceAgreementComponent,
    AgreementComponent,
    OffersAcceptComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  exports: [
    RegistrationWarningComponent,
    RegistrationComponent,
    RegistrationErrorComponent,
    RegistrationSuccessComponent,
    LoginComponent,
    PasswordRecoveryComponent,
    PersonalDataComponent,
    CookieComponent,
    CardComponent,
    PublicationChangePriceComponent,
    PriceAgreementComponent,
    AgreementComponent,
    OffersAcceptComponent
  ]
})
export class CoreModule {}
