import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/core/utils/utils';
import { GeneralResponse } from 'src/app/shared/models/GeneralResponse.model';
import { environment } from 'src/environments/environment';
import { UserProfileCheckingsResult } from '../../models/user-profile-checkings/user-profile-checkings-result.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileCheckingsService {

  constructor(
    private httpClient: HttpClient,
    private utils: Utils
  ) { }

  async checkUserProfile(): Promise<GeneralResponse<boolean>> {
    let resp = new GeneralResponse<boolean>();
    try {
      let obj= await this.httpClient.get<UserProfileCheckingsResult>(`${environment.webApiUrl}/personal/checkProfileStateForDeal`).toPromise();
      resp.Response = ((obj.financeProfileStateIsValid) && (obj.legalProfileStateIsValid) && (obj.profileStateIsValid));
    }
    catch (err: any) {
      resp.Errors = this.utils.collectErrors(err);
    }
    return resp;
  }

  async checkUserProfileState(): Promise<UserProfileCheckingsResult> {
    let profileCkeckingResult = await this.httpClient.get<UserProfileCheckingsResult>(`${environment.webApiUrl}/personal/checkProfileStateForDeal`).toPromise();
    
    if (!profileCkeckingResult) {
      profileCkeckingResult = new UserProfileCheckingsResult();
    }

    return profileCkeckingResult;
  }
}
