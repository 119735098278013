import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from "ngx-simple-modal";
export interface RequisitionsProfileModel {
  data: {}|undefined;
}

@Component({
  selector: 'app-requsitions-profile',
  templateUrl: './requsitions-profile.component.html',
  styleUrls: ['./requsitions-profile.component.css']
})

  export class RequsitionsProfileComponent extends SimpleModalComponent<RequisitionsProfileModel , boolean> {

    constructor() {
      super();
    }
    confirm() {
      // we set modal result as true on click on confirm button,
      // then we can get modal result from caller code
      this.result = true;
      this.close();
    }
    cancel() {
      this.result = false;
      this.close();
    }
  }
