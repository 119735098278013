export class Agreement {
  constructor() { }
  dealAgreementId: number = 0;
  relatedCertificateId: number = 0;
  relatedDealId: number = 0;
  createdByUserId: string = '';
  relatedCustomerId: string = '';
  relatedSellerId: string = '';
  content: string = '';
  contentEn: string = '';
}
