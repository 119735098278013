import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import { PublicationChangePrice } from 'src/app/core/models/publications/publication-change-price.model';
//import { PublicationService } from 'src/app/publication.service';
import { PublicationsService } from 'src/app/core/services/publications/publications.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { OffersAcceptService } from 'src/app/features/personal-area/services/offers-accepts/offers-accept.service';
import { OfferName } from 'src/app/features/personal-area/models/offers-accept/offer-name.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TimerServiceService } from 'src/app/timer-service.service';
import { AgreementModalComponent } from 'src/app/blocks/modals/agreement-modal/agreement-modal.component';

//declare function openModal(string): void;
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
declare var $: any;
declare function openModal(string, closeFn, bodyFn): Promise<boolean>;
declare function closeModal(): void;

const offerTypeName = 'price-agreement';

@Component({
  selector: 'app-modal-publication-change-price',
  templateUrl: './publication-change-price.component.html',
  styleUrls: ['./publication-change-price.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class PublicationChangePriceComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  errors: Map<string, any> = new Map<string, any>();
  loading: boolean = false;
  newPriceSetted: boolean = false;
  agreementIsSigned: boolean = false;
  public entity: PublicationChangePrice = new PublicationChangePrice();

  constructor(
    //private publicationService: PublicationService,
    private publicationsService: PublicationsService,
    private offersAcceptService: OffersAcceptService,
    private router: Router,
    public ts: TimerServiceService,
    private modalService: SimpleModalService
  ) { }

  async ngOnInit(): Promise<void> {
    this.subscriptions = this.publicationsService.publicationInfo$.subscribe((priceInfo: PublicationChangePrice) => {
      if (this.loading)
        return;

      this.entity.newPrice = priceInfo.newPrice;
      this.entity.publicationId = priceInfo.publicationId;
      this.entity.oldPrice = priceInfo.oldPrice;

      //if (this.agreementIsSigned) {
      //  this.signMainAgreement();
      //}
    });

    this.subscriptions.add(this.offersAcceptService.offerAccepted$.subscribe((acceptedOffer: OfferName) => {
      if (acceptedOffer.typeName == offerTypeName) {
        this.agreementIsSigned = true;
      }
      else if (acceptedOffer.typeName == `${offerTypeName}_x`) {
        this.agreementIsSigned = false;
      }
      else if (acceptedOffer.typeName == 'mainAgreement') {
        this.publishNow();
      }

    }));

    this.agreementIsSigned = await this.offersAcceptService.getOffersAcceptState(offerTypeName);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ShowDialog(): Promise<void> {
    await openModal("#modal-change-package-price", (d) => {
      d.reject(false);
    }, (d) => {
      $("#btnCancelSetNewPrice").on("click", () => {
        closeModal();
        d.resolve(false);
      });
      $("#btnSetNewPrice").on("click", () => {
        this.checkNewPrice().then((result) => {
          d.resolve(result);
        });
      });
    }).catch((err) => {
      Promise.resolve(err);
    })
  }

  async setNewPrice(): Promise<void> {
    this.loading = true;

    if (this.entity != null) {
      if (this.entity.publicationId == 0) {
        Promise.resolve(false);
        return;
      }

      await this.publicationsService.updateCurrentPublicationInfo(this.entity);

      this.errors = await this.publicationsService.updatePrice(this.entity);

      this.loading = false;

      if (!this.errors.size) {
        this.newPriceSetted = true;

        await this.signPriceAgreement();

        //this.entity = new PublicationChangePrice();
      }
      else {
        anAlert("Не удалось обновить стоимость лота для продажи: " + this.errors.entries().next().value[1], "OK");
      }
    }
    else {
      this.loading = false;

      this.errors.set("error", "Не удалось определить данные лота. Обратитесь к администратору.");
    }
  }

  async signPriceAgreement(): Promise<void> {

    //if (this.agreementIsSigned) {
    //  return await this.signMainAgreement();
    //}
    //else {
      this.ts.Step = 'signPriceAgreementAtPublishing';;

      let res = await openModal("#modal-price-agreement", (d) => {
        d.reject(false);
      }, (d) => {
        d.resolve(true);
      }).catch((r) => {
        Promise.resolve(r);
      });

      await this.publicationsService.updateCurrentPublicationInfo(this.entity);

      await this.signMainAgreement();

      if (this.agreementIsSigned)
          await this.publishNow();
     //}
  }

  async checkNewPrice(): Promise<boolean> {
    if (this.newPriceSetted)
      if (this.entity.newPrice < 1)
        this.errors.set("newPrice", "Стоимость не может быть ниже 1");

    return Promise.resolve(this.errors.size == 0);
  }

  async signMainAgreement() {

    //this.ts.Step = "signMainAgreement";

    let agreed = await this.modalService.addModal(AgreementModalComponent, {
      id: this.entity.publicationId,
      price: this.entity.newPrice,
      data: {}
    }).toPromise<boolean>();

    this.agreementIsSigned = agreed;
    if (!agreed) {
      
      await anAlert("Невозможно выполнить данное действия без подписи соглашения.", "OK")
    }


  //  await openModal("#modal-agreement", (d) => {
  //      d.reject(false);
  //  }, (d) => {
  //    d.resolve(true);
  //  }).catch((r) => {
  //    Promise.resolve(r);
  //  });
  }

  async publishNow(): Promise<boolean> {

    if (!this.newPriceSetted) {
      return Promise.resolve(false);
    }
    else {
      if (this.entity.publicationId == 0) {
        this.entity = await this.publicationsService.getPublicationInfo;
      }
      this.errors = await this.publicationsService.publish(this.entity.publicationId);

      closeModal();

      if (this.errors.size === 0) {

        this.router.navigateByUrl("/personal-area/private-packages/published");
        return Promise.resolve(true);
      }
      else {
        let msg = "Не удалось опубликовать лот: ";

        msg = msg + this.errors.entries().next().value[1];

        anAlert(msg, "OK");

        return Promise.resolve(false);
      }
    }
  }
}
