<ng-container *ngIf="section != 'cms'">
  <header class="header header_light">
    <div class="header__wrap md">
      <div class="header__logo">
        <a routerLink="/" class="header__icon"></a>
      </div>
      <div class="header__info">
        <div
          *ngIf="securityContext.topSearch == 'isDisplayed'"
          class="header__search search mr35"
        >
          <form class="search__container" (ngSubmit)="onSearchSubmit()">
            <div
              class="search__input-block"
              [class.is-open]="isSearchOpened === true"
            >
              <input
                type="search"
                [(ngModel)]="searchTerm"
                name="searchTerm"
                class="search__input"
                placeholder="{{ 'search' | translate }}"
              />
            </div>
            <div
              class="search__btn-block"
              data-position="bottom center"
              [attr.data-tooltip]="'search' | translate"
            >
              <button
                type="submit"
                class="search__btn feather-icon icon-search c-link"
                data-header-search
              ></button>
            </div>
          </form>
        </div>
        <ng-container *ngIf="!isDemo">
          <div
            *ngIf="isGuest; else userPanel"
            class="header__registration registration mr35"
          >
            <span class="registration__icon material-icons">person</span>
            <span
              class="registration__tx"
              onclick="openModal('#login')"
              data-position="bottom center"
              [attr.data-tooltip]="'login' | translate"
            >
              {{ 'login' | translate }}
            </span>
            <span class="registration__tx registration__tx-vline">|</span>
            <span
              class="registration__tx"
              onclick="openModal('#registration-warning')"
              data-position="bottom center"
              [attr.data-tooltip]="'registration' | translate"
            >
              {{ 'registration' | translate }}
            </span>
          </div>
          <ng-template #userPanel>
            <a
              *ngIf="isDealer"
              routerLink="/personal-area/balance"
              class="header__balance mr35"
              data-position="bottom center"
              [attr.data-tooltip]="'Balance' | translate"
            >
              {{ account.balanceFormatted }}
            </a>
            <a
              *ngIf="isBroker"
              routerLink="/broker-area/balance"
              class="header__balance mr35"
              data-position="bottom center"
              [attr.data-tooltip]="'Balance' | translate"
            >
              {{ account.balanceFormatted }}
            </a>

            <div
              *ngIf="isDealer || isBroker || isAdmin"
              class="header__notification ui inline-block dropdown cmenu cmenu-def mr35"
            >
              <app-notifications></app-notifications>
            </div>

            <div
              class="header__info-avatar ui inline-block dropdown cmenu cmenu-def mr35"
              data-position="bottom center"
              [attr.data-tooltip]="'Personal area' | translate"
            >
              <button class="avatar c-link">
                <img [src]="account.avatar" alt="" class="avatar__img" />
              </button>
              <div class="menu center">
                <div class="item item_none"></div>
                <div class="dropdown__content" data-tooltip="">
                  <div class="header-account">
                    <div class="header-account__body">
                      <div class="header-account__head mb3">
                        <div class="header-account__avatar avatar">
                          <img
                            [src]="account.avatar"
                            alt=""
                            class="avatar__img"
                          />
                        </div>
                        <div class="header-account__title">
                          <div class="header-account__name">
                            {{ account.nameFormatted }}
                          </div>
                          <div class="header-account__post">
                            {{
                              (isDealer
                                ? 'Dealer'
                                : isBroker
                                ? 'Broker'
                                : isAdmin
                                ? 'Administrator'
                                : isAnalytic
                                ? 'Analytic'
                                : 'Super Administrator'
                              ) | translate
                            }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="header-account__list"
                        [class.text-center]="isAdmin || isSuperadmin"
                      >
                        <div *ngIf="isDealer" class="header-account__item">
                          <a
                            routerLink="/personal-area/profile"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'Profile' | translate }}
                          </a>
                        </div>
                        <div *ngIf="isBroker" class="header-account__item">
                          <a
                            routerLink="/broker-area/profile"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'Profile' | translate }}
                          </a>
                        </div>
                        <div *ngIf="isAdmin" class="header-account__item">
                          <a
                            routerLink="/manager-area/profile"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'Profile' | translate }}
                          </a>
                        </div>
                        <div *ngIf="isAnalytic" class="header-account__item">
                          <a
                            routerLink="/analytics-area/profile"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'Profile' | translate }}
                          </a>
                        </div>
                        <div *ngIf="isAnalytic" class="header-account__item">
                          <a
                            routerLink="/analytics-area/report"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            Финансовые операции
                          </a>
                        </div>
                        <div *ngIf="isAnalytic" class="header-account__item">
                          <a
                            routerLink="/analytics-area/report-filters"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'Reports' | translate }}
                          </a>
                        </div>
                        <div *ngIf="isSuperadmin" class="header-account__item">
                          <a
                            routerLink="/superadmin-area/profile"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'Profile' | translate }}
                          </a>
                        </div>
                        <div *ngIf="isDealer" class="header-account__item">
                          <a
                            routerLink="/personal-area/private-packages/unpublished"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'My packages' | translate }}
                          </a>
                          <!-- <div class="header-account-item-sub">
                            <a
                              routerLink="/personal-area/new-certificate"
                              (click)="hideContextMenu()"
                              class="header-account__link"
                            >
                              {{ 'Add certificate' | translate }}
                            </a>
                          </div> -->
                        </div>
                        <!-- <div *ngIf="isDealer" class="header-account__item">
                          <a
                            routerLink="/personal-area/deals"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'My deals' | translate }}
                          </a>
                        </div> -->
                        <!-- <div *ngIf="isBroker" class="header-account__item">
                          <a
                            routerLink="/broker-area/search"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            Поиск
                          </a>
                        </div>
                        <div *ngIf="isBroker" class="header-account__item">
                          <a
                            routerLink="/broker-area/deals"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            Сделки
                          </a>
                        </div>
                        <div *ngIf="isBroker" class="header-account__item">
                          <a
                            routerLink="/broker-area/offers"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            Предложения
                          </a>
                        </div> -->
                        <!--<div *ngIf="isDealer || isBroker" class="header-account__item">
    <a routerLink="/personal-area/contracts" (click)="hideContextMenu()" class="header-account__link">
      {{ 'My contracts' | translate }}
    </a>
  </div>-->
                        <!--<div *ngIf="isDealer || isBroker" class="header-account__item">
    <a routerLink="/personal-area/add-certificate" (click)="hideContextMenu()" class="header-account__link">
      {{ 'Add certificate' | translate }}
    </a>
  </div>-->
                        <div *ngIf="isDealer" class="header-account__item">
                          <a
                            routerLink="/personal-area/purchase-requisitions/unpublished"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'My purchase requisitions' | translate }}
                          </a>
                        </div>
                        <div *ngIf="isDealer" class="header-account__item">
                          <a
                            routerLink="/personal-area/purchase-joining/unpublished"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'My Applications to Join' | translate }}
                          </a>
                        </div>
                        <div *ngIf="isDealer" class="header-account__item">
                          <a
                            routerLink="/personal-area/balance"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'Balance' | translate }}
                          </a>
                        </div>
                        <div *ngIf="isBroker" class="header-account__item">
                          <a
                            routerLink="/broker-area/balance"
                            (click)="hideContextMenu()"
                            class="header-account__link"
                          >
                            {{ 'Balance' | translate }}
                          </a>
                        </div>
                        <!--<div *ngIf="isDealer || isBroker" class="header-account__item">
    <a routerLink="#" (click)="hideContextMenu()" class="header-account__link">
      {{ 'Archive' | translate }}
    </a>
  </div>
  <div *ngIf="!isSuperadmin" class="header-account__item">
    <a href="javascript:;" (click)="hideContextMenu()" class="header-account__link">
      {{ 'Chat' | translate }}
    </a>
  </div>-->
                      </div>
                    </div>
                    <button (click)="logout()" class="header-account__btn btn">
                      {{ 'Logout' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- ToDo: add favorite -->
            <div
              *ngIf="false && !isAdmin && !isSuperadmin"
              class="header__info-favorite mr35"
            >
              <button
                class="header-favorite"
                routerLink="/personal-area/favorite-packages"
                data-position="bottom center"
                [attr.data-tooltip]="'Favorites' | translate"
              >
                <div class="header-favorite__num">10</div>
                <svg class="ic ic-add-favorite add-favorite__icon c-red">
                  <use xlink:href="/assets/img/sprite.svg#add-favorite"></use>
                </svg>
              </button>
            </div>
            <!-- END ToDo -->
          </ng-template>
        </ng-container>

        <div
          *ngIf="securityContext.topLanguageSwitcher == 'isDisplayed'"
          class="header__language language ui dropdown cmenu cmenu-def mr35"
        >
          <button type="button" class="language__title">
            <span
              class="language__label"
              data-position="bottom center"
              [attr.data-tooltip]="'Switch language' | translate"
            >
              {{ currentLanguageCode }}
            </span>
            <span class="language__icon"></span>
          </button>
          <div class="menu center">
            <div class="item item_none"></div>
            <div class="language__dropdown">
              <div class="language__dropdown-list">
                <div class="language__dropdown-item">
                  <span class="language__dropdown-link"></span>
                  <a
                    href="javascript:;"
                    (click)="onLanguageDropdownLinkClick()"
                    class="language__dropdown-link"
                  >
                    {{ currentLanguageCode == 'EN' ? 'RU' : 'EN' }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="securityContext.topMenuHamburger == 'isDisplayed'"
          class="header__btn"
        >
          <button
            type="button"
            class="header__menu-btn menu-btn"
            (click)="toggleMenu()"
            data-header-btn
            data-position="bottom center"
            [attr.data-tooltip]="'Menu' | translate"
          >
            <span class="menu-btn__line menu-btn__line_t"></span>
            <span class="menu-btn__line menu-btn__line_m"></span>
            <span class="menu-btn__line menu-btn__line_b"></span>
          </button>
        </div>
      </div>
    </div>

    <nav
      *ngIf="securityContext.topMenuHamburger == 'isDisplayed'"
      class="nav"
      data-nav
    >
      <div class="nav__bg"></div>
      <div class="nav__wrap">
        <div class="md">
          <ul
            class="nav__list"
            [ngStyle]="isBroker ? { 'justify-content': 'flex-start' } : {}"
          >
            <li
              *ngFor="let item of menu"
              class="nav__item"
              (mouseenter)="submenuOnEnter($event)"
              (mouseleave)="submenuOnLeave($event)"
              [ngClass]="{
                'ui inline-block dropdown cmenu cmenu-hover': item.children
              }"
            >
              <a
                *ngIf="item.routerLink; else hrefLink"
                [routerLink]="item.routerLink"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="nav__link"
                [attr.data-tooltip]="item.text | translate"
              >
                {{ item.text | translate }}
              </a>
              <ng-template #hrefLink>
                <a
                  *ngIf="item.href; else noLink"
                  href="{{ item.href }}"
                  class="nav__link"
                  target="_blank"
                  [attr.data-tooltip]="item.text | translate"
                >
                  {{ item.text | translate }}
                </a>
              </ng-template>
              <ng-template #noLink>
                <a
                  href=""
                  onclick="return !1"
                  class="nav__link"
                  [attr.data-tooltip]="item.text | translate"
                >
                  {{ item.text | translate }}
                </a>
              </ng-template>
              <div class="menu center transition hidden" *ngIf="item.children">
                <div class="item item_none"></div>
                <div class="dropdown__content">
                  <div class="header-account">
                    <div
                      class="header-account__body"
                      [class.header-account__body-right]="item.alignRight"
                      [class.header-account__body-3-col]="item.columns == 3"
                    >
                      <div class="header-account__list">
                        <div
                          class="header-account__item"
                          [ngClass]="{
                            'ui inline-block dropdown cmenu': item.children
                          }"
                          (mouseenter)="openSubMenu($event)"
                          (mouseleave)="closeSubMenu($event)"
                          *ngFor="let item of item.children; let i = index"
                        >
                          <a
                            *ngIf="item.routerLink; else hrefLink"
                            routerLink="{{ item.routerLink }}"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            class="header-account__link"
                            [attr.data-tooltip]="item.text | translate"
                          >
                            {{ item.text | translate }}
                            <img
                              *ngIf="item.children"
                              alt=""
                              class="sort-chevron"
                              src="/assets/img/arrow-down.svg"
                            />
                          </a>
                          <ng-template #hrefLink>
                            <a
                              *ngIf="!item.separator; else separator"
                              href="{{ item.href }}"
                              target="_blank"
                              class="header-account__link"
                              [attr.data-tooltip]="item.text | translate"
                            >
                              {{ item.text | translate }}
                            </a>
                            <ng-template #separator>
                              <div class="separator"></div>
                            </ng-template>
                          </ng-template>
                          <div
                            class="menu center transition submenu"
                            *ngIf="item.children"
                          >
                            <div class="item item_none"></div>
                            <div class="dropdown__content">
                              <div class="header-account">
                                <div class="header-account__body">
                                  <div class="header-account__list">
                                    <div
                                      class="header-account__item"
                                      (mouseenter)="openSubMenu($event)"
                                      (mouseleave)="closeSubMenu($event)"
                                      (click)="submenuOnClick($event)"
                                      [ngClass]="{
                                        'ui inline-block dropdown cmenu':
                                          item.children
                                      }"
                                      *ngFor="let item of item.children"
                                    >
                                      <a
                                        *ngIf="item.routerLink; else hrefLink"
                                        routerLink="{{ item.routerLink }}"
                                        routerLinkActive="active"
                                        class="header-account__link"
                                        [attr.data-tooltip]="
                                          item.text | translate
                                        "
                                      >
                                        {{ item.text | translate }}
                                      </a>
                                      <ng-template #hrefLink>
                                        <a
                                          href="{{ item.href }}"
                                          class="header-account__link"
                                          [attr.data-tooltip]="
                                            item.text | translate
                                          "
                                        >
                                          {{ item.text | translate }}
                                        </a>
                                      </ng-template>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</ng-container>

<main class="main-content" [class.authorized-user]="!isGuest">
  <router-outlet></router-outlet>
</main>

<ng-container *ngIf="section != 'cms' && section != 'manager-area'">
  <footer class="footer">
    <div class="footer__wrap md">
      <div class="footer__content">
        <div class="footer__logo">
          <a href="/" class="footer__logo-icon"></a>
        </div>

        <nav
          *ngIf="securityContext.bottomMenu == 'isDisplayed'"
          class="footer__nav"
        >
          <ul class="footer__nav-list">
            <li
              *ngIf="securityContext.bottomMenuSitemap == 'isDisplayed'"
              class="footer__nav-item"
            >
              <a
                routerLink="/site-map"
                class="footer__nav-link"
                [attr.data-tooltip]="'siteMap' | translate"
                data-position="bottom center"
              >
                {{ 'siteMap' | translate }}
              </a>
            </li>
            <li
              *ngIf="securityContext.bottomMenuAbout == 'isDisplayed'"
              class="footer__nav-item"
            >
              <a
                routerLink="/about"
                class="footer__nav-link"
                [attr.data-tooltip]="'about' | translate"
                data-position="bottom center"
              >
                {{ 'about' | translate }}
              </a>
            </li>
            <li class="footer__nav-item">
              <a class="footer__nav-link">&nbsp;</a>
            </li>
          </ul>

          <span class="footer__line"></span>

          <ul class="footer__nav-list">
            <li
              *ngIf="securityContext.bottomMenuBlog == 'isDisplayed'"
              class="footer__nav-item"
            >
              <a
                routerLink="/scheme"
                class="footer__nav-link"
                [attr.data-tooltip]="'Схема взаимодействия' | translate"
                data-position="bottom center"
              >
                {{ 'Схема взаимодействия' | translate }}
              </a>
            </li>
            <li
              *ngIf="securityContext.bottomMenuHelp == 'isDisplayed'"
              class="footer__nav-item"
            >
              <a
                routerLink="/help"
                class="footer__nav-link"
                [attr.data-tooltip]="'help' | translate"
                data-position="bottom center"
              >
                {{ 'help' | translate }}
              </a>
            </li>
            <li
              *ngIf="securityContext.bottomMenuHelp == 'isDisplayed'"
              class="footer__nav-item"
            >
              <a
                routerLink="/contacts"
                class="footer__nav-link"
                [attr.data-tooltip]="'Contacts' | translate"
                data-position="bottom center"
              >
                {{ 'Contacts' | translate }}
              </a>
            </li>
          </ul>

          <span class="footer__line"></span>

          <ul class="footer__nav-list">
            <li
              *ngIf="securityContext.bottomMenuPolicy == 'isDisplayed'"
              class="footer__nav-item"
            >
              <a
                routerLink="/policy"
                class="footer__nav-link"
                [attr.data-tooltip]="'privacyPolicy' | translate"
                data-position="bottom center"
              >
                {{ 'privacyPolicy' | translate }}
              </a>
            </li>
            <li
              *ngIf="securityContext.bottomMenuTerms == 'isDisplayed'"
              class="footer__nav-item"
            >
              <a
                routerLink="/terms"
                class="footer__nav-link"
                [attr.data-tooltip]="'termsofuse' | translate"
                data-position="bottom center"
              >
                {{ 'termsofuse' | translate }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div
      *ngIf="securityContext.footer == 'isDisplayed'"
      class="footer-copy bg-orange light:bg-blue"
    >
      <div class="footer-copy__wrap md">
        <div class="footer-copy__tx">
          <p class="mb15">© Trading System Inc</p>
          <p>Copyright: 2017 - 2022 г</p>
        </div>

        <div class="footer-soc">
          <ng-container *ngIf="currentLanguageCode == 'RU'; else socEn">
            <a
              href="https://www.instagram.com/timeshare_tst/"
              target="_blank"
              class="footer-soc__link"
              style="display: none !important"
            >
              <svg class="ic ic-instagram">
                <use
                  xlink:href="/assets/img/sprite.svg#instagram"
                  [attr.data-tooltip]="'Instagram' | translate"
                ></use>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/timeshare.tst/"
              target="_blank"
              class="footer-soc__link"
              style="display: none !important"
            >
              <svg class="ic ic-facebook">
                <use
                  xlink:href="/assets/img/sprite.svg#facebook"
                  [attr.data-tooltip]="'Facebook' | translate"
                ></use>
              </svg>
            </a>
            <a
              href="https://twitter.com/timeshare_tst"
              target="_blank"
              class="footer-soc__link"
              style="display: none !important"
            >
              <svg class="ic ic-twitter">
                <use
                  xlink:href="/assets/img/sprite.svg#twitter"
                  [attr.data-tooltip]="'Twitter' | translate"
                ></use>
              </svg>
            </a>
            <a
              href="https://vk.ru/tst_trade"
              target="_blank"
              class="footer-soc__link"
            >
              <svg class="ic ic-vk">
                <use
                  xlink:href="/assets/img/sprite.svg#vk"
                  [attr.data-tooltip]="'VKontakte' | translate"
                ></use>
              </svg>
            </a>

            <a
              href="https://t.me/tst_timeshare"
              target="_blank"
              class="footer-soc__link"
              [attr.data-tooltip]="'Telegram' | translate"
            >
              <svg class="ic ic-telegram">
                <use xlink:href="/assets/img/sprite.svg#telegram"></use>
              </svg>
            </a>
          </ng-container>
          <ng-template #socEn>
            <a
              href="https://www.instagram.com/tst_timeshare/"
              target="_blank"
              class="footer-soc__link"
            >
              <svg class="ic ic-instagram">
                <use
                  xlink:href="/assets/img/sprite.svg#instagram"
                  [attr.data-tooltip]="'Instagram' | translate"
                ></use>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/groups/223348313123216/about"
              target="_blank"
              class="footer-soc__link"
            >
              <svg class="ic ic-facebook">
                <use
                  xlink:href="/assets/img/sprite.svg#facebook"
                  [attr.data-tooltip]="'Facebook' | translate"
                ></use>
              </svg>
            </a>
            <a
              href="https://twitter.com/TimeshareTst"
              target="_blank"
              class="footer-soc__link"
            >
              <svg class="ic ic-twitter">
                <use
                  xlink:href="/assets/img/sprite.svg#twitter"
                  [attr.data-tooltip]="'Twitter' | translate"
                ></use>
              </svg>
            </a>
            <a
              href="https://vk.com/public206552036"
              target="_blank"
              class="footer-soc__link"
            >
              <svg class="ic ic-vk">
                <use
                  xlink:href="/assets/img/sprite.svg#vk"
                  [attr.data-tooltip]="'VKontakte' | translate"
                ></use>
              </svg>
            </a>
            <!--
              <a href="#" class="footer-soc__link" [attr.data-tooltip]=" 'Telegram' | translate ">
                <svg class="ic ic-telegram">
                  <use xlink:href="/assets/img/sprite.svg#telegram"></use>
                </svg>
              </a>
            -->
          </ng-template>
        </div>

        <div class="footer__cards">
          <div class="footer__card footer__cards_mir">
            <svg class="ic ic-mir">
              <use xlink:href="/assets/img/sprite.svg#mir"></use>
            </svg>
          </div>
          <div class="footer__card footer__cards_mastercard">
            <svg class="ic ic-mastercard">
              <use xlink:href="/assets/img/sprite.svg#mastercard"></use>
            </svg>
          </div>
          <div class="footer__card footer__cards_visa">
            <svg class="ic ic-visa">
              <use xlink:href="/assets/img/sprite.svg#visa"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </footer>
</ng-container>

<app-modal-cookie></app-modal-cookie>
<app-modal-login [supportEmail]="supportEmail"></app-modal-login>
<div *ngIf="!isDemo">
  <app-modal-registration-warning></app-modal-registration-warning>
  <app-modal-registration
    [supportEmail]="supportEmail"
  ></app-modal-registration>
  <app-modal-registration-error></app-modal-registration-error>
  <app-modal-registration-success></app-modal-registration-success>
  <app-modal-password-recovery
    [supportEmail]="supportEmail"
  ></app-modal-password-recovery>
</div>
<app-buy-wf></app-buy-wf>
<app-modal-publication-change-price></app-modal-publication-change-price>
<app-price-agreement></app-price-agreement>
<app-agreement></app-agreement>
<app-offers-accept></app-offers-accept>
<!-- <app-modal-card></app-modal-card> -->
