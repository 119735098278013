export enum DealStatusesEnum {
  ///Статус сделки "На составлении"
  Preparing = 'sd_preparing',
  ///Статус сделки "В процессе"
  InProgress = 'sd_inprogress',
  ///Статус сделки "Завершена успешно"
  Finished_successfully = 'sd_finished_success',
  ///Статус сделки "Отменена продавцом"
  CanceledBySeller = 'sd_cancel_seller',
  ///Статус сделки "Отменена покупателем"
  CanceledByBuyer = 'sd_cancel_buyer',
  ///Статус сделки "Отменена по истечению срока сделки"
  CanceledByTimeout = 'sd_cancel_timeout'
}
