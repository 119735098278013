<div id="agreement-modal" class="smodal-content">
  <div class="modal-header">
    <div data-fancybox-close (click)="Cancel()" class="modal-close">
      <svg class="icon">
        <use xlink:href="/assets/img/svg-sprite.svg#times-light"></use>
      </svg>
    </div>
  </div>
  <div>
    <div class="font-alt text-xl mb-1">{{ title }}</div>

    <p class="text-sm">
      Уважаемый
      <b>{{ UserName }}</b>
      !
    </p>

    <p class="text-sm">
      Ниже приведен перечень соглашений, содержащихся в лоте, предлагаемом Вам
      для подписания:
    </p>
    <ol>
      <li *ngFor="let cl of cessionLinks">
        <a class="link" (click)="showDoc(cl)">{{ cl.text }}</a>
      </li>
    </ol>
    <br />
    <p class="text-sm">
      Каждое соглашение представлено в электронном виде и у вас имеется
      возможность распечатать и сохранить его, все подписанные соглашения
      сохраняются в вашем личном кабинете в разделе
      <a class="link" routerLink="/personal-area/deals">Мои сделки</a>
    </p>

    <p class="text-sm">
      Подписывая лот Аналогом собственноручной подписи, Вы подписываете каждое
      Соглашение, содержащееся в Лоте отдельно и подтверждаете, что
      ознакомились с каждым Соглашением о передаче прав и обязанностей по
      каждому договору таймшера, содержащемуся в лоте и принимаете все
      существенные условия передачи прав и обязанностей.
    </p>
  </div>
  <div #btnSubscribe class="smodal-footer mt2">
    <button
      type="button"
      (click)="Accept()"
      #btnSubscribe
      class="btn btn--primary"
    >
      {{ 'Sign with SMS-code' | translate }}
    </button>
    <button type="button" class="btn btn--dark-outline" (click)="Cancel()">
      Отказаться
    </button>
  </div>
</div>
