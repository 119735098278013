import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Utils } from 'src/app/core/utils/utils';
import { PublicationChangePrice } from './core/models/publications/publication-change-price.model';

@Injectable()
export class PublicationService {
  private _publicationInfoSubject: BehaviorSubject<PublicationChangePrice>;
  // obsolete. Use publicationInfo$ in publicationsservice
  public publicationInfo$1: Observable<PublicationChangePrice>;

  constructor(private httpClient: HttpClient, private utils: Utils) {
    this._publicationInfoSubject = new BehaviorSubject<PublicationChangePrice>(
      new PublicationChangePrice()
    );
    this.publicationInfo$1 = this._publicationInfoSubject.asObservable();
  }

  // obsolete. Use updateCurrentPublicationInfo in publicationsservice
  public async updateCurrentPublicationInfo1(
    publicationPriceChangesInfo: PublicationChangePrice
  ) {
    this._publicationInfoSubject.next(publicationPriceChangesInfo);
  }

  // obsolete. Use getPublicationInfo in publicationsservice
  public get getPublicationInfo1(): PublicationChangePrice {
    return this._publicationInfoSubject.value;
  }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      // "Authorization" : "Bearer ",
      Accept: 'application/json',
      'Accept-Language': 'en',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return headers;
  }

  private getOptions(): { headers: HttpHeaders } {
    const options = { headers: this.getHeaders() };
    return options;
  }
}
