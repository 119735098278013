<div class="smodal-content" [ngClass]="wclass">
  <div class="modal-header">
    <div (click)="Cancel()" class="modal-close">
      <svg class="icon">
        <use xlink:href="/assets/img/svg-sprite.svg#times-light"></use>
      </svg>
    </div>
  </div>
  <div class="text-center">
    <div [innerHTML]="popupHtml"></div>
    <div class="df justify-center gap-20">
      <button
        *ngIf="existOkButton"
        type="button"
        class="btn btn--primary h-50"
        (click)="Ok()"
      >
        {{ ok }}
      </button>

      <button
        *ngIf="existCancelButton"
        class="btn btn--dark-outline h-50"
        (click)="Cancel()"
      >
        {{ cancel }}
      </button>
    </div>
  </div>
</div>
