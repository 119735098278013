<div id="registration-warning" class="mw">
  <div class="mw__content">
    <div class="mw__head">
      <div class="mw__title">{{'Attention!' | translate}}</div>
      <span class="mw__line mb2"></span>
      <div class="mw__stitle">
        {{'RegistrationWarningUserDataText' | translate}}
        <br>
        <br>
        {{'RegistrationWarningRestrictAccessText' | translate}}
      </div>
      <div class="mw__body">
        <div class="form__item mb2">
          <button type="submit" class="btn w100" data-modal="registration" data-position="top center"
            [attr.data-tooltip]="'I accept' | translate">{{'I accept' | translate}}</button>
        </div>
        <div class="form__item tc">
          <a href="#close-modal" rel="modal:close" class="mw__stitle form__link" data-position="bottom center"
            [attr.data-tooltip]="'I do not accept' | translate">{{'I do not accept' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>