import {
  AfterViewInit,
  Component,
  Injectable,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Role } from 'src/app/core/enums/role.enum';
import { SmsAuthCodeCheckResult } from 'src/app/core/models/sms-auth-code/sms-auth-code-check-result.model';
import { SmsAuthCodeRequest } from 'src/app/core/models/sms-auth-code/sms-auth-code-request.model';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SmsAuthCodesService } from 'src/app/core/services/sms-auth-code/sms-auth-code.service';
import { Utils } from 'src/app/core/utils/utils';
import { BalanceReplenish } from 'src/app/features/personal-area/models/balance/balance-replenish.model';
import { TransactionSettlement } from 'src/app/features/personal-area/models/balance/transaction-settlement.model';
import { DealSummary } from 'src/app/features/personal-area/models/deals/deal-summary.model';
import { BalanceService } from 'src/app/features/personal-area/services/balance/balance.service';
import { DealsService } from 'src/app/features/personal-area/services/deals/deals.service';
import { FinancialStatisticsService } from 'src/app/features/personal-area/services/financial-statistics/financial-statistics.service';
import { OffersAcceptService } from 'src/app/features/personal-area/services/offers-accepts/offers-accept.service';
import { UserProfileCheckingsService } from 'src/app/features/personal-area/services/user-profile-checkings/user-profile-checkings.service';
import { PublicationChangeStateResponse } from 'src/app/features/public-area/models/publication-state/publication-change-state-response.model';
import { PublicationStateService } from 'src/app/features/public-area/services/publication-state/publication-state.service';
import { TimerServiceService } from 'src/app/timer-service.service';

import { TranslateService } from '@ngx-translate/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { Subscription } from 'rxjs';
import { AnAlertComponent } from 'src/app/blocks/modals/an-alert/an-alert.component';
import { ProfileService } from 'src/app/features/personal-area/services/profile/profile.service';
import { SmsSimpleMessage } from 'src/app/features/superadmin-area/models/sms/sms-simple-message.model';
import { SmsService } from 'src/app/features/superadmin-area/services/sms/sms.service';
import { OfferName } from '../../features/personal-area/models/offers-accept/offer-name.model';
import { AgreementModalComponent } from '../modals/agreement-modal/agreement-modal.component';
import { OffersAcceptModalComponent } from '../modals/offers-accept-modal/offers-accept-modal.component';
import { RequsitionsProfileComponent } from '../modals/requsitions-profile/requsitions-profile.component';

const offerTypeName = 'offers';

declare var $: any;
declare function ucfirst(str: string): string;
declare function anAlert(bodyHtml: string, btnText: string): Promise<boolean>;
declare function openModal(string, closeFn, bodyFn): Promise<boolean>;
declare function closeModal(): void;

@Component({
  selector: 'app-buy-wf',
  templateUrl: './buy-wf.component.html',
  styleUrls: ['./buy-wf.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class BuyWfComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscribed: boolean = false;
  private deal: any = null;
  public availableBalance: number = 0;
  public distinctionAmount: number = 0;
  public enteredConfirmationSmsCode: string | null = null;
  smsAuthCodeRequest: SmsAuthCodeRequest = new SmsAuthCodeRequest();
  smsAuthCodeRequestId: string | null = null;
  smsAuthCodeCheckResult: SmsAuthCodeCheckResult = new SmsAuthCodeCheckResult();
  errors = new Map();
  email: string = '';
  password: string = '';
  loading: boolean = false;
  passwordVisible: boolean = false;
  updateBalanceFlag: boolean = false;
  updateBalanceSum: number = 0;
  lastStep: any = null;
  packageNumber: string = '002';
  transactionSettlement: TransactionSettlement = new TransactionSettlement();
  dealSummary: DealSummary = new DealSummary();
  publicationNumber: number = 0;
  totalAmountFormatted: string = '';
  totalAmount: number = 0;
  publicationOwnerId: string = '';

  profileIsValid: boolean = false;
  replenish: BalanceReplenish = new BalanceReplenish();

  publicationChangeStateResult: PublicationChangeStateResponse =
    new PublicationChangeStateResponse();

  private subscriptions: Subscription = new Subscription();
  offersAccepted: boolean = false;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private balanceService: BalanceService,
    private router: Router,
    private utils: Utils,
    public ts: TimerServiceService,
    private profileCheckingService: UserProfileCheckingsService,
    private smsAuthCodeService: SmsAuthCodesService,
    private publicationStateService: PublicationStateService,
    private translateService: TranslateService,
    private dealService: DealsService,
    private offersAcceptService: OffersAcceptService,
    private modalService: SimpleModalService,
    private smsService: SmsService,
    private profileService: ProfileService,
    private financialService: FinancialStatisticsService
  ) {}

  get isGuest(): boolean {
    let ret = false;
    if (this.ts.user != null) {
      ret = this.ts.user.role === Role.Guest;
    }
    return ret;
  }

  get isDealer(): boolean {
    let ret = false;
    if (this.ts.user != null) {
      ret = this.ts.user.role === Role.Dealer;
    }
    return ret;
  }

  get isBroker(): boolean {
    let ret = false;
    if (this.ts.user != null) {
      ret = this.ts.user.role === Role.Broker;
    }
    return ret;
  }

  get isAdmin(): boolean {
    let ret = false;
    if (this.ts.user != null) {
      ret = this.ts.user.role === Role.Admin;
    }
    return ret;
  }

  get isSuperadmin(): boolean {
    let ret = false;
    if (this.ts.user != null) {
      ret = this.ts.user.role === Role.Superadmin;
    }
    return ret;
  }

  ngOnInit(): void {
    this.offersAcceptService
      .getOffersAcceptState(offerTypeName)
      .then((state) => {
        this.offersAccepted = state;
      });

    this.subscriptions.add(
      this.authService.user.subscribe(async (user) => {
        this.ts.user = user;
        this.ts.account = await this.accountService
          .getUserAccount()
          .toPromise();
      })
    );

    this.subscriptions.add(
      this.ts.msg.subscribe((o) => {
        let m = o.msg;
        this.lastStep = m;
        if (m == 'DealStep2') {
          this.dealerStep2();
        } else if (m == 'DealOfferSMS') {
          this.dealConfirmSMS();
        } else if (m == 'DealOfferSMS1') {
          this.dealConfirmSMS();
        } else if (m.startsWith('certificateCMS')) {
          this.certificateSMS();
        } else if (m == 'profileOffersSMS') {
          this.profileOffersSMS(o.obj);
        } else if (m == 'priceAgreementSMS') {
          this.priceAgreementSMS(o.obj);
        }
      })
    );

    this.subscriptions.add(
      this.offersAcceptService.offerAccepted$.subscribe(
        (acceptedOffer: OfferName) => {
          //debugger;
          if (acceptedOffer.typeName == offerTypeName) {
            this.offersAccepted = true;
          } else if (acceptedOffer.typeName == `${offerTypeName}_x`) {
            this.offersAccepted = false;
          }
        }
      )
    );
  }

  ngAfterViewInit(): void {
    if (this.authService.isAuthenticated()) {
      ///Restore session ONLY if authenticated, otherwise wait until step 2
      //this.restoreSession();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  restoreSession() {
    if (
      !this.ts.UserData ||
      !this.ts.UserData.deal ||
      !this.ts.UserData.deal.id ||
      this.ts.UserData.deal.id < 0
    ) {
      //debugger;
      this.ts.initUserSession().then((_data) => {
        this.doDeal();
      });
    } else {
      //debugger;
      this.doDeal();
    }
  }

  doDeal() {
    //debugger;
    if (
      this.ts.UserData &&
      this.ts.UserData.deal &&
      this.ts.UserData.deal.id > 0
    ) {
      this.ts.applyUserSession(this.ts.UserData);

      if (this.offersAccepted) {
        this.ts.dealTmrStart(() => {
          this.dealCancel();
        }, this.ts.currDealDuration);
        if (this.ts.UserData.deal.currentStepId == 'dealerStep2') {
          this.dealerStep2();
        } else if (this.ts.UserData.deal.currentStepId == 'profileNavigate') {
          this.profileNavigate();
        } else if (this.ts.UserData.deal.currentStepId == 'DealOfferSMS') {
          this.ts.msg.emit({ msg: 'DealOfferSMS', obj: this });
          this.ts.saveUserSession('DealOfferSMS');
        } else if (this.ts.UserData.deal.currentStepId == 'DealOfferSMS1') {
          this.ts.msg.emit({ msg: 'DealOfferSMS1', obj: this });
          this.ts.saveUserSession('DealOfferSMS1');
        } else if (this.ts.UserData.deal.currentStepId == 'dealStep3') {
          this.dealStep3();
        }
      } else {
        this.showOffersAcceptDialog().then(async () => {
          this.offersAccepted =
            await this.offersAcceptService.getOffersAcceptState(offerTypeName);

          if (!this.offersAccepted) {
            anAlert(
              'Не возможно выполнить текущее действие без принятия лицензионного соглашения.',
              'OK'
            );
          }
        });
      }
    }
  }

  calcSums() {
    this.authService.user.subscribe(async (user) => {
      this.ts.user = user;
      this.ts.account = await this.accountService.getUserAccount().toPromise();
      if (this.ts.account) {
        // PM: temporary commented
        this.ts.availableBalance = this.ts.account.balance; // - this.ts.account.blockedForOperations;
      }
      if (this.ts.dealDetails.total - this.ts.availableBalance > 0) {
        this.ts.distinctionAmount = parseFloat(
          (this.ts.dealDetails.total - this.ts.availableBalance).toFixed(2)
        );
        this.updateBalanceSum = this.ts.distinctionAmount;
      }
    });
  }

  async dealStart(obj: any): Promise<void> {
    this.deal = obj;

    if (!this.isGuest) {
      if (this.deal.packageOwnerUserId === this.ts.user?.id) {
        anAlert(
          'Вы не можете купить лот №' +
            this.deal.publicationId +
            ' у самого себя. Выберите другой лот.',
          'OK'
        );
        return;
      }
    }

    this.publicationNumber = obj.publicationNumber || 0;
    this.ts.dealDetails.publicationOwnerId = obj.packageOwnerUserId;
    this.ts.dealDetails.publicationNumber = obj.publicationNumber || 0;
    this.ts.dealId = obj.id;

    const dv = await this.checkDeal();
    if (!dv) {
      this.dealCancel();
      return;
    }
    localStorage.setItem('dealId', this.ts.dealId.toString());

    const { Response: taxes, Errors: errors } =
      await this.financialService.getTaxByPackage(
        this.deal.packageId,
        this.deal.price
      );

    this.errors = errors;

    if (this.errors.size) {
      await this.modalService
        .addModal(AnAlertComponent, {
          title: '',
          body:
            'Ошибка расчёта комиссии: ' +
            this.errors.entries().next().value[1] +
            ' Обратитесь к администратору.',
          ok: 'OK'
        })
        .toPromise<boolean>();

      return;
    }

    this.ts.packagePrice = obj.price;
    this.ts.dealDetails.price = this.utils.toNumber(this.deal.price);
    this.ts.dealDetails.comission = taxes?.buyerCommission;
    this.ts.dealDetails.total = taxes?.buyerSum;

    this.calcSums();
    this.ts.dealTmrStart(() => {
      this.dealCancel();
    });
    openModal(
      '#modal-trade-time',
      (def) => {
        def.resolve();
      },
      null
    ).then(async () => {
      if (this.authService.isAuthenticated()) {
        this.dealerStep2();
      } else {
        await openModal('#dealer-auth-step-one', null, null);
      }
    });
  }

  async dealStartOld(obj: any): Promise<void> {
    this.deal = obj;

    if (!this.isGuest) {
      if (this.deal.packageOwnerUserId === this.ts.user?.id) {
        anAlert(
          'Вы не можете купить лот №' +
            this.deal.publicationId +
            ' у самого себя. Выберите другой лот.',
          'OK'
        );
        return;
      }
    }

    this.publicationNumber = obj.publicationNumber || 0;
    this.ts.dealDetails.publicationOwnerId = obj.packageOwnerUserId;
    this.ts.dealDetails.publicationNumber = obj.publicationNumber || 0;

    this.ts.dealId = obj.id;
    let dv = await this.checkDeal();
    if (!dv) {
      this.dealCancel();
      return;
    }
    localStorage.setItem('dealId', this.ts.dealId.toString());

    debugger;
    let calculatedAmounts = await this.financialService.getTaxes(
      this.ts.dealId,
      this.ts.user?.id ?? ''
    );

    this.errors = calculatedAmounts.Errors;

    if (this.errors.size > 0) {
      await this.modalService
        .addModal(AnAlertComponent, {
          title: '',
          body:
            'Ошибка расчёта комиссии: ' +
            this.errors.entries().next().value[1] +
            ' Обратитесь к администратору.',
          ok: 'OK'
        })
        .toPromise<boolean>();

      return;
    }

    this.ts.packagePrice = obj.price;
    this.ts.dealDetails.price = this.utils.toNumber(this.deal.price);
    this.ts.dealDetails.comission =
      calculatedAmounts.Response?.commissionSum ?? 0; //this.ts.dealDetails.price * 0.09;

    this.ts.dealDetails.total =
      this.ts.dealDetails.price + this.ts.dealDetails.comission;
    this.calcSums();
    this.ts.dealTmrStart(() => {
      this.dealCancel();
    });
    openModal(
      '#modal-trade-time',
      (def) => {
        def.resolve();
      },
      null
    ).then(async () => {
      if (this.authService.isAuthenticated()) {
        this.dealerStep2();
      } else {
        await openModal('#dealer-auth-step-one', null, null);
      }
    });
  }

  async checkDeal(): Promise<boolean> {
    if (this.ts.Step == 'signMainAgreement') {
      return Promise.resolve(true);
    }

    if (!this.ts.dealId) {
      await anAlert(
        `Время отведенное на сделку истекло.<br>
      Для возобновления сделки - выполните все подготовительные действия и откройте сделку повторно`,
        'OK'
      );
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  }

  dealCancel(): void {
    this.updateBalanceFlag = false;
    localStorage.removeItem('dealId');

    if (!this.ts.blocked) {
      this.ts.dealId = 0;
      this.ts.UserData = null;
      this.ts.dealTmrStop();
      this.ts.smsTmrStop();
      this.ts.clearSessionDeal();
      openModal('#modal-trade-canceled', null, null);
      return;
    }

    this.publicationStateService
      .unlockPublication(this.ts.dealId)
      .then(() => {
        this.ts.dealTmrStop();
        this.ts.smsTmrStop();
        this.ts.clearSessionDeal();
        openModal('#modal-trade-canceled', null, null);
      })
      .catch((error) => {
        anAlert(
          'Ошибка при попытке разблокировать лот №' +
            this.ts.dealId +
            ': ' +
            error?.error?.[0] +
            '.',
          'OK'
        );
      });
  }

  dealCancelOld(): void {
    this.updateBalanceFlag = false;
    localStorage.removeItem('dealId');
    if (this.ts.blocked) {
      this.publicationStateService.unlockPublicationOld(this.ts.dealId).then(
        (result) => {
          this.publicationChangeStateResult = result;
          if (result.status) {
            this.ts.dealTmrStop();
            this.ts.smsTmrStop();
            this.ts.clearSessionDeal();
            openModal('#modal-trade-canceled', null, null);
          } else {
            anAlert(
              'Ошибка при попытке разблокировать лот №' +
                this.ts.dealId +
                ': ' +
                result.errorText +
                '.',
              'OK'
            );
          }
        },
        (result) => {
          anAlert(
            'Ошибка при попытке разблокировать лот №' +
              this.ts.dealId +
              ': ' +
              result.errorText +
              '.',
            'OK'
          );
        }
      );
    } else {
      this.ts.dealId = 0;
      this.ts.UserData = null;
      this.ts.dealTmrStop();
      this.ts.smsTmrStop();
      this.ts.clearSessionDeal();
      openModal('#modal-trade-canceled', null, null);
    }
  }

  async login() {
    try {
      this.loading = true;
      await this.authService.login(this.email, this.password);
      this.email = '';
      this.password = '';
      this.errors.clear();
    } catch (response: any) {
      this.errors.clear();
      const errors = Object.entries(response?.error?.errors);
      if (Array.isArray(errors)) {
        errors.forEach(([key, value]) => {
          this.errors.set(ucfirst(key), value);
        });
      }
    } finally {
      this.loading = false;
    }
  }

  dealLogin(): void {
    openModal('#deal_login', null, null);
  }

  dealLoginCkick(): void {
    this.loading = true;
    this.ts.dealId = Number(localStorage.getItem('dealId'));
    this.login().then(
      () => {
        if (this.authService.isAuthenticated()) {
          $.modal.close();
          this.dealerStep2();
        }
      },
      () => {}
    );
  }

  dealRegister(): void {
    this.authService.nextPoupId = '#dealer-auth-step-one';
    openModal(
      '#registration-warning',
      (d) => {
        this.dealCancel();
        d.reject(false);
      },
      null
    );
  }

  async dealerStep2(): Promise<void> {
    // debugger;
    if (!this.authService.isAuthenticated()) {
      this.dealCancel();
      return;
    }
    let dv = await this.checkDeal();

    if (!dv) {
      this.dealCancel();
      return;
    }
    this.ts.initUserSession();
    if (
      this.ts.UserData &&
      this.ts.UserData.deal &&
      this.ts.UserData.deal.id &&
      this.ts.UserData.deal.id >= 0 &&
      this.ts.UserData.deal.id !== this.ts.dealId
    ) {
      ///If we have deal saved in session we restore its data after authentication
      this.restoreSession();
      return;
    }
    this.publicationStateService
      .blockPublication(this.ts.dealId)
      .then(() => {
        this.ts.blocked = true;
        this.ts.saveUserSession('dealerStep2');

        this.profileContentIsvalid().then(async (result) => {
          if (!result) {
            const fillProfile = await this.modalService
              .addModal(RequsitionsProfileComponent, {
                data: {}
              })
              .toPromise<boolean>();
            if (fillProfile) {
              this.profileNavigate();
            } else {
              this.dealCancel();
            }
            return;
          }

          this.lastStep = null;
          this.dealStep3();
        });
      })
      .catch((error) => {
        anAlert(
          'Ошибка при попытке разблокировать лот №' +
            this.ts.dealId +
            ': ' +
            error?.error?.[0] +
            '.',
          'OK'
        );
      });
  }

  async dealerStep2Old(): Promise<void> {
    // debugger;
    if (!this.authService.isAuthenticated()) {
      this.dealCancel();
      return;
    }
    let dv = await this.checkDeal();

    if (!dv) {
      this.dealCancel();
      return;
    }
    this.ts.initUserSession();
    if (
      this.ts.UserData &&
      this.ts.UserData.deal &&
      this.ts.UserData.deal.id &&
      this.ts.UserData.deal.id >= 0 &&
      this.ts.UserData.deal.id !== this.ts.dealId
    ) {
      ///If we have deal saved in session we restore its data after authentication
      this.restoreSession();
      return;
    }
    this.publicationStateService.blockPublicationOld(this.ts.dealId).then(
      (result) => {
        //debugger;
        this.publicationChangeStateResult = result;
        this.ts.blocked = result.status;
        if (result.status != false) {
          this.ts.saveUserSession('dealerStep2');
          this.profileContentIsvalid().then(async (result) => {
            if (result === false) {
              let fillProfile = await this.modalService
                .addModal(RequsitionsProfileComponent, {
                  data: {}
                })
                .toPromise<boolean>();
              if (fillProfile) {
                this.profileNavigate();
              } else {
                this.dealCancel();
              }
              // openModal("#dealer-auth-step-two", null, null);
              // setTimeout(() => { $("#dealer-auth-step-two").find(".close-modal").hide() }, 500);
            } else {
              this.lastStep = null;
              this.dealStep3();
            }
          });
        } else {
          anAlert(
            'Ошибка при попытке временно заблокировать лот №' +
              this.ts.dealId +
              ': ' +
              result.errorText +
              '.',
            'OK'
          );
        }
      },
      (result) => {
        anAlert(
          'Ошибка при попытке разблокировать лот №' +
            this.ts.dealId +
            ': ' +
            result.errorText +
            '.',
          'OK'
        );
      }
    );
  }

  profileNavigate() {
    this.ts.saveUserSession('profileNavigate');
    closeModal();
    this.router.navigateByUrl('/personal-area/profile#DealStep2');
  }

  balanceAdd() {
    this.updateBalanceSum = this.ts.distinctionAmount;
    this.updateBalanceFlag = true;
  }

  balanceConfirm() {
    this.replenish.amount = this.updateBalanceSum;
    this.balanceService.replenishRequest(this.replenish).then(
      (_result) => {
        anAlert(
          'Ваша заявка на пополнение средств принята. Дождитесь факта пополнения баланса и начните сделку сначала.',
          'OK'
        );
        closeModal();
        this.dealCancel();
        return;

        this.updateBalanceFlag = false;
        this.calcSums();
        this.updateBalanceSum = this.ts.distinctionAmount;
        if (this.ts.distinctionAmount > 0) {
          anAlert(
            'У вас все еще недостаточно средств для совершения сделки.',
            'OK'
          );
        }
        this.lastStep = null;
        this.dealStep3();
      },
      () => {
        anAlert(
          'Ваша заявка на пополнение средств принята. Дождитесь факта пополнения баланса и начните сделку сначала.',
          'OK'
        );
        closeModal();
        this.dealCancel();
        return;
        //this.dealCancel();
      }
    );
  }

  async dealOffer() {
    //this.ts.saveUserSession("dealOffer");
    //this.GoTo("/personal-area/deal-offers?id=" + this.ts.dealId);

    this.ts.saveUserSession('dealOffer');

    //this.GoTo("/personal-area/agreement?id=" + this.ts.dealId);

    let agreed = await this.modalService
      .addModal(AgreementModalComponent, {
        id: this.ts.dealId,
        price: 0,
        data: {}
      })
      .toPromise<boolean>();

    if (!agreed) {
      this.ts.smsTmrStop();
      this.ts.dealTmrStop();
      await anAlert('Сделка отменена', 'OK');
    } else {
      let offersAccepted = await this.offersAcceptService.getOffersAcceptState(
        offerTypeName
      );
      if (!offersAccepted) {
        offersAccepted = await this.modalService
          .addModal(OffersAcceptModalComponent)
          .toPromise<boolean>(); //Show Offers popup
      }
      if (offersAccepted) {
        this.makeDeal(this.ts.dealId, this.ts.currentDealId);
      } else {
        this.ts.smsTmrStop();
        this.ts.dealTmrStop();
        await anAlert(
          'Необходимио принять оферты портала. Сделка отменена',
          'OK'
        );
      }
    }
  }

  async dealConfirmSMS() {
    let agreed = await this.modalService
      .addModal(AgreementModalComponent, {
        id: this.ts.dealId,
        price: 0,
        data: {}
      })
      .toPromise<boolean>();
    if (!agreed) {
      await anAlert('Необходимо принятие Лицензионного соглашения.', 'OK');
      this.router.navigateByUrl(
        '/personal-area/purchase-requisitions/unpublished'
      );
      closeModal();
      this.dealCancel();
      return;
    }
    if (this.lastStep == 'DealOfferSMS') {
      //this.GoTo("/personal-area/agreement?id=" + this.ts.dealId)
      let agreed = await this.modalService
        .addModal(AgreementModalComponent, {
          id: this.ts.dealId,
          price: 0,
          data: {}
        })
        .toPromise<boolean>();
      if (!agreed) {
        this.ts.smsTmrStop();
        this.ts.dealTmrStop();
        await anAlert('Сделка отменена', 'OK');
      }
    } else if (this.lastStep == 'DealOfferSMS1') {
      if (this.ts.Step == 'AddCertificateStep') {
        this.router.navigateByUrl('/personal-area/add-certificate');
      } else {
        let dv = await this.checkDeal();
        if (!dv) {
          this.dealCancel();
          return;
        }

        if (
          this.ts.Step != 'signMainAgreement' &&
          this.ts.Step != 'signPriceAgreementAtPublishing'
        ) {
          this.makeDeal(this.ts.dealId, this.ts.currentDealId);
        } else if (this.ts.Step == 'signPriceAgreementAtPublishing') {
          let model = new OfferName();
          model.typeName = 'mainAgreement';

          this.offersAcceptService.markOfferAsAccepted(model).then(() => {
            this.ts.Step = '';
            closeModal();
          });
        } else {
          this.ts.smsTmrStop();
          this.ts.dealTmrStop();
          ///When deal is closed remove it from session
          this.ts.clearSessionDeal();

          let model = new OfferName();
          model.typeName = 'mainAgreement';

          this.offersAcceptService.markOfferAsAccepted(model).then(() => {
            this.ts.Step = '';
            closeModal();
          });
        }
      }
    }
  }

  async dealStep3() {
    let dv = await this.checkDeal();
    if (!dv) {
      this.dealCancel();
      return;
    }
    this.ts.saveUserSession('dealStep3');
    this.lastStep = null;
    this.updateBalanceSum = this.ts.distinctionAmount;
    let result = await openModal('#dealer-auth-step-three', null, null);
    if (!result) {
      this.dealCancel();
    }
  }

  async dealCheckSmsCode(requestId: string): Promise<void> {
    try {
      this.smsAuthCodeCheckResult = await this.smsAuthCodeService.checkAuthCode(
        requestId ?? '',
        this.enteredConfirmationSmsCode ?? '',
        false
      );
    } catch (ex) {
      this.smsAuthCodeCheckResult = new SmsAuthCodeCheckResult();
      this.smsAuthCodeCheckResult.status = true;
    }
    ///TODO: Check code
  }

  async dealGenerateSmsCode(): Promise<string | void> {
    this.enteredConfirmationSmsCode = '';
    let stdPhone: string = this.ts.account?.phoneNumber ?? '';
    if (stdPhone?.length == 0) {
      return Promise.reject(
        'Невозможно отправить SMS-код - в профиле не указан номер телефона.'
      );
    }
    let numberPattern = /\d+/gi;

    stdPhone = stdPhone.match(numberPattern)?.join('') ?? '';
    if (stdPhone.length < 11) {
      return Promise.reject('В номере телефона должно быть минимум 11 чисел');
    }

    this.smsAuthCodeRequest = new SmsAuthCodeRequest();
    return this.smsAuthCodeService.sendCode(stdPhone).then((resp) => {
      this.smsAuthCodeRequest = resp;
      this.smsAuthCodeRequestId = this.smsAuthCodeRequest.requestId;
    });
  }

  async makeDeal(publicationId: number, dealId: number): Promise<void> {
    closeModal();

    ///TODO: sign deal offer, make a deal
    this.transactionSettlement.publicationId = publicationId;

    this.errors = await this.balanceService.transactionSettlement(
      this.transactionSettlement
    );
    //debugger;
    if (this.errors.size == 0) {
      this.dealSummary.publicationId = publicationId;
      this.dealSummary.dealId = dealId;

      let result = await this.dealService.executeDeal(this.dealSummary);

      //debugger;
      if (result.Errors.size == 0) {
        if (result.Response?.status == 'OK') {
          this.dealSummary.dealId = result.Response!.dealId;
        } else {
          await anAlert('Не удалось завершить процедуру сделки.', 'OK');
        }

        result = await this.dealService.compliteDeal(this.dealSummary);
        if (this.errors.size == 0) {
          this.ts.dealDetails.newPublicationId =
            result.Response?.newPublicationId;

          this.ts.account = await this.accountService
            .getUserAccount()
            .toPromise();

          if (!this.ts.account) {
            await this.modalService
              .addModal(AnAlertComponent, {
                title: '',
                body:
                  'Ошибка расчёта комиссии: ' +
                  this.errors.entries().next().value[1] +
                  ' Обратитесь к администратору.',
                ok: 'OK'
              })
              .toPromise<boolean>();

            return;
          }

          await openModal(
            '#dealer-auth-step-four',
            async (d) => {
              this.router.navigateByUrl('/');
              d.resolve(true);
            },
            null
          );
          // ToDo: send sms form backend
          // await this.sendNotify();

          ///Stop all timers
          this.ts.smsTmrStop();
          this.ts.dealTmrStop();
          ///When deal is closed remove it from session
          this.ts.clearSessionDeal();
        } else {
          let msg = 'Не удалось сменить владельца лота по итогам сделки: ';

          msg = msg + this.errors.entries().next().value[1];

          anAlert(msg, 'OK');
        }
      } else {
        let msg = 'Не удалось провести сделку: ';

        msg = msg + this.errors.entries().next().value[1];

        anAlert(msg, 'OK');
      }
    } else {
      let msg = 'Не удалось произвести оплату по сделке: ';

      msg = msg + this.errors.entries().next().value[1];

      anAlert(msg, 'OK');
    }
  }

  //profileContentIsvalid(u: User): boolean {
  async profileContentIsvalid(): Promise<boolean> {
    let res = false;
    try {
      let result = await this.profileCheckingService.checkUserProfileState();
      res =
        result.financeProfileStateIsValid &&
        result.legalProfileStateIsValid &&
        result.profileStateIsValid;
    } catch (ex) {
      console.error(ex);
      //alert(ex);
      res = false;
    }
    return Promise.resolve(res);
  }

  GoTo(uri) {
    closeModal();

    this.router.navigateByUrl(uri);
  }

  Go() {
    closeModal();

    this.router.navigateByUrl(
      `/personal-area/private-package/${
        this.ts.dealDetails.newPublicationId || -1
      }`
    );
  }

  async certificateSMS() {
    let me = this;
    try {
      await this.dealGenerateSmsCode();
    } catch (e) {
      alert(e);
      return;
    }
    ///Callback should reset SMS code and send new one to user
    this.ts.smsTmrStart(() => {
      this.dealGenerateSmsCode();
    });

    openModal(
      '#modal-sms-confirm',
      (d) => {
        d.reject(false);
      },
      (d) => {
        $('#btSMSconfirm').on('click', () => {
          d.resolve(true);
        });
        $('#btSMScancel').on('click', () => {
          d.reject(false);
        });
      }
    ).then(
      () => {
        me.dealCheckSmsCode(me.smsAuthCodeRequestId ?? '').then(
          async () => {
            if (me.smsAuthCodeCheckResult !== null) {
              if (me.smsAuthCodeCheckResult.status) {
                me.ts.smsTmrStop();
                closeModal();
                this.enteredConfirmationSmsCode = '';
                if (this.lastStep === 'certificateSMS') {
                  await anAlert(
                    `<div style="text-align:center"><div class="mb-4 font-alt text-xl">Информация сохранена, данные по&nbsp;праву требования будут добавлены автоматически в течение <span class="font-alt-extra">7</span>и календарных дней</div></div>`,
                    'Окей'
                  );
                  this.router.navigateByUrl(
                    '/personal-area/private-packages/unpublished'
                  );
                } else if (this.lastStep === 'certificateSMS1') {
                  await anAlert(
                    `<div style="text-align:center"><div class="font-alt text-xl">Ваши данные отправлены в выбранную вами организацию</div>
                  <div class="divider-sm"></div>
                  <div class="mb-2 text-sm">С вами свяжется оператор.<br> Ждите ответа в течение суток</div></div>`,
                    'Окей'
                  );
                  this.router.navigateByUrl('/personal-area/select-operator');
                }
              } else {
                me.enteredConfirmationSmsCode = '';
                anAlert('Введен неправильный код.', 'OK');
              }
            } else {
              me.enteredConfirmationSmsCode = '';
              anAlert(
                'Ошибка проверки кода. Обратитесь к администратору.',
                'OK'
              );
              me.ts.smsTmrStop();
              closeModal();
            }
          },
          () => {
            me.enteredConfirmationSmsCode = '';
            anAlert('Ошибка проверки кода. Обратитесь к администратору.', 'OK');
            me.ts.smsTmrStop();
            closeModal();
          }
        );
      },
      () => {}
    );
  }

  priceAgreementSMS(obj: any) {
    let me = this;
    this.dealGenerateSmsCode();
    ///Callback should reset SMS code and send new one to user
    this.ts.smsTmrStart(() => {
      this.dealGenerateSmsCode();
    });

    openModal(
      '#modal-sms-confirm',
      (d) => {
        d.reject(false);
      },
      (d) => {
        $('#btSMSconfirm').on('click', () => {
          d.resolve(true);
        });
        $('#btSMScancel').on('click', () => {
          d.reject(false);
        });
      }
    ).then(() => {
      me.dealCheckSmsCode(me.smsAuthCodeRequestId ?? '').then(
        () => {
          if (me.smsAuthCodeCheckResult !== null) {
            if (me.smsAuthCodeCheckResult.status) {
              me.ts.smsTmrStop();
              closeModal();
              this.enteredConfirmationSmsCode = '';

              let offerNameModel = new OfferName();
              offerNameModel.typeName = 'price-agreement';

              this.offersAcceptService
                .setOffersAcceptState(offerNameModel)
                .then((err) => {
                  //debugger;
                  this.errors = err;

                  if (this.errors.size == 0) {
                    this.offersAcceptService
                      .markOfferAsAccepted(offerNameModel)
                      .then(() => {
                        //debugger;
                        obj.priceAgreementAccepted = true;
                        ///TODO: Set offersAccepted on server
                        obj.loading = false;
                        //anAlert(this.translateService.instant('Data saved'), "OK");

                        closeModal();
                      });
                  } else {
                    //debugger;
                    let msg =
                      'Не удалось сохранить данные о подписи под документом: ';

                    msg = msg + this.errors.entries().next().value[1];

                    anAlert(msg, 'OK');

                    offerNameModel.typeName += '_x';

                    this.offersAcceptService
                      .markOfferAsAccepted(offerNameModel)
                      .then(() => {
                        closeModal();
                      });
                  }
                });
            } else {
              me.enteredConfirmationSmsCode = '';
              anAlert('Введен неправильный код.', 'OK');
            }
          } else {
            me.enteredConfirmationSmsCode = '';
            anAlert('Ошибка на сервере. Обратитесь к администратору.', 'OK');
            me.ts.smsTmrStop();

            let model = new OfferName();
            model.typeName = 'price-agreement_x';
            this.offersAcceptService.markOfferAsAccepted(model);

            closeModal();
          }
        },
        () => {
          me.enteredConfirmationSmsCode = '';
          anAlert(
            'Возникли непредвиденные проблемы на сервере. Обратитесь к администратору.',
            'OK'
          );
          me.ts.smsTmrStop();

          let model = new OfferName();
          model.typeName = 'x';
          this.offersAcceptService.markOfferAsAccepted(model);

          closeModal();
        }
      );
    });
  }

  profileOffersSMS(obj: any) {
    let me = this;
    this.dealGenerateSmsCode();
    ///Callback should reset SMS code and send new one to user
    this.ts.smsTmrStart(() => {
      this.dealGenerateSmsCode();
    });

    openModal(
      '#modal-sms-confirm',
      (d) => {
        d.reject(false);
      },
      (d) => {
        $('#btSMSconfirm').on('click', () => {
          d.resolve(true);
        });
        $('#btSMScancel').on('click', () => {
          d.reject(false);
        });
      }
    ).then(() => {
      me.dealCheckSmsCode(me.smsAuthCodeRequestId ?? '').then(
        () => {
          let offerNameModel = new OfferName();
          offerNameModel.typeName = 'offers';

          if (me.smsAuthCodeCheckResult !== null) {
            if (me.smsAuthCodeCheckResult.status) {
              me.ts.smsTmrStop();
              closeModal();
              this.enteredConfirmationSmsCode = '';

              this.offersAcceptService
                .setOffersAcceptState(offerNameModel)
                .then(() => {
                  this.offersAcceptService
                    .markOfferAsAccepted(offerNameModel)
                    .then(() => {
                      obj.offersAccepted = true;
                      obj.loading = false;
                      anAlert(
                        this.translateService.instant('Data saved'),
                        'OK'
                      );
                    });
                });
            } else {
              me.enteredConfirmationSmsCode = '';
              anAlert('Введен неправильный код.', 'OK');
            }
          } else {
            me.enteredConfirmationSmsCode = '';
            anAlert('Ошибка на сервере. Обратитесь к администратору.', 'OK');
            me.ts.smsTmrStop();
            offerNameModel.typeName += '_x';

            this.offersAcceptService
              .markOfferAsAccepted(offerNameModel)
              .then(() => {
                closeModal();
              });
          }
        },
        () => {
          let offerNameModel = new OfferName();
          offerNameModel.typeName = 'offers_x';

          me.enteredConfirmationSmsCode = '';
          anAlert(
            'Возникли непредвиденные проблемы на сервере. Обратитесь к администратору.',
            'OK'
          );
          me.ts.smsTmrStop();

          this.offersAcceptService
            .markOfferAsAccepted(offerNameModel)
            .then(() => {
              closeModal();
            });
        }
      );
    });
  }

  closeInfoDialog(evnt: Event) {
    let el = (evnt.target as HTMLElement) || null;
    let parent = (el.parentElement as HTMLElement) || null;

    if (parent != null) {
      let parentOfParent = (parent.parentElement as HTMLElement) || null;

      if (parentOfParent != null) {
        let btn = parentOfParent.querySelector('[href="#close-modal"]') || null;

        if (btn != null) {
          $(btn).click();
        }
      }
    }
  }

  async showOffersAcceptDialog(): Promise<void> {
    let agreed = await this.modalService
      .addModal(AgreementModalComponent, {
        id: this.ts.dealId,
        price: this.deal.publicationPrice,
        data: {}
      })
      .toPromise<boolean>();
    if (!agreed) {
      await anAlert(
        'Невозможно выполнить текущее действие без принятия лицензионного соглашения',
        'OK'
      );
      this.router.navigateByUrl('/personal-area/profile');
      Promise.reject();
    }
    Promise.resolve();
    // await openModal('#modal-offers-accept', (def) => {
    //   def.resolve();
    // }, null).then(async () => {
    //   debugger;
    //   //this.offersAccepted = await this.offersAcceptService.getOffersAcceptState(offerTypeName);
    //   return Promise.resolve();
    // });
  }

  async sendNotify() {
    //let userAccount = await this.accountService.getUserAccount().toPromise();
    //debugger;
    let profile = await this.profileService.get();

    let sellerNameFormatted = '';
    if (profile.surname) sellerNameFormatted += `${profile.surname} `;
    sellerNameFormatted += profile.name;
    if (profile.middleName) sellerNameFormatted += ` ${profile.middleName}`;

    let sms = new SmsSimpleMessage();
    sms.recipientUserId = this.ts.dealDetails.publicationOwnerId;
    sms.smsText = `Поздравляем! Ваш Лот №${this.ts.dealDetails.publicationNumber}, ранее опубликованный на площадке TST Trade успешно продан! Средства от сделки, за вычетом комиссии и налоговых отчислений, поступили на баланс в вашем личном кабинете.`;

    let smsSendResult = await this.smsService.send(sms);

    if (smsSendResult.Errors.size > 0) {
      let msg =
        'Не удалось отправить уведомление по SMS о покупке лота его владельцу: ';

      msg = msg + smsSendResult.Errors.entries().next().value[1];
      await this.modalService
        .addModal(AnAlertComponent, { body: msg, ok: 'OK' })
        .toPromise<boolean>();
      //await anAlert(msg, "OK");

      return Promise.resolve();
    }

    //  let emailNotify = new EmailNotification();
    //  emailNotify.emailNotificationCode = EmailNotificationsTypesEnum.ReplyToPurchaseRequisitionBuyNotify;
    //  emailNotify.emailRelatedPublicationId = this.selectedPackages[0].id;
    //  emailNotify.emailRelatedSideOneUserId = this.publication.packageOwnerUserId;
    //  emailNotify.emailRelatedSideTwoUserId = userAccount.userId;

    //  let emailSendResult = await this.emailNotificationsService.addToQueue(emailNotify);

    //  if (emailSendResult.Errors.size > 0) {
    //    let msg = "Не удалось отправить уведомление по электронной почте о ответе на сделку покупателю: ";

    //    msg = msg + emailSendResult.Errors.entries().next().value[1];
    //    await this.modalService.addModal(AnAlertComponent, { title: 'Ошибка!', body: msg, ok: "OK" }).toPromise<boolean>();
    //    //await anAlert(msg, "OK");

    //    return Promise.resolve();
    //  }
    //  else {
    //    return Promise.resolve();
    //  }
  }
}
