 <!--class="page md"-->
<div id="modal-agreement" class="page md mw" data-id="Assignment agreement" style="min-width: 95%; margin: 0 auto; padding-top: 5%;">
  <!--<app-packages-slider></app-packages-slider>-->
  <div class="crumbs">
    <ul>
      <li><a routerLink="/" title="{{ 'Main page' | translate }}">{{ 'Main page' | translate }}</a></li>
      <li><span>{{ 'Assignment agreement' | translate }}</span></li>
    </ul>
  </div>
  <div class="pa">
    <h1 class="title ttu">{{ 'Assignment agreement' | translate}}</h1>

    <!-- <div class="assignment-agreement-column df jcsb mb8">
      <div class="privacy-policy-content" [innerHTML]="contentEn"></div>
      <div class="privacy-policy-content" [innerHTML]="content"></div>
    </div> -->

    <div class="mb5">
      <!--<button type="button" class="btn" (click)="sign($event)" [disabled]="loading">{{(loading ? 'Loading...' : 'Sign (Download PDF)') | translate}}</button>-->
      <button type="button" class="btn mr5" (click)="download($event)" [disabled]="loading">{{(loading ? 'Preparing for download...' : 'Download') | translate}}</button>
      <button type="button" class="btn" (click)="print($event)" [disabled]="loading">{{(loading ? 'Preparing for print...' : 'Print') | translate}}</button>
    </div>

    <div class="mb5">
      <button type="button" class="mr15 dn h-orange  h-light:c-blue" data-position="bottom center" [attr.data-tooltip]="'Print' | translate" (click)="print($event)">
        <svg class="ic ic-card-printer ic-round ic-stroke">
          <use xlink:href="/assets/img/sprite.svg#card-printer"></use>
        </svg>
      </button>
    </div>
    <div c class="modal-action" style="text-align:right ;">
      <button type="button" (click)="btnSubscribe.scrollIntoView(false)" class="btn btn--primary mb2">Перейти к подписанию</button>
    </div>


    <div class="df jcsb mb8" style="min-height:300px">
      <div class="privacy-policy-content pdf-container" [innerHTML]="content"></div>
    </div>
    <div class="modal-dealer__action" #btnSubscribe>
      <button type="button" id="btnSubscribe" #btnSubscribe (click)="dialogSMS()" class="btn btn--primary">{{'Sign with SMS-code' | translate}}</button>
    </div>
  </div>

  <div id="printing-modal" class="mw">
    <div class="mw__content">
      <div class="mw__body">
        <div class="stitle mb4 text-center">
          {{'Preparing for print...' | translate}}
        </div>
      </div>
    </div>
  </div>
