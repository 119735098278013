import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-registration-warning',
  templateUrl: './registration-warning.component.html',
  styleUrls: ['./registration-warning.component.css']
})
export class RegistrationWarningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
