import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from "ngx-simple-modal";
import { CessionLink } from '../agreement-modal/agreement-modal.component';

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.css']
})
export class DocumentModalComponent extends SimpleModalComponent<CessionLink, boolean> implements CessionLink, OnInit {
  text: string = "";
  publicationId: number = 0;
  certificateId: number = 0;
  popupHtml: string = "";

  constructor() {
    super();
   }

  ngOnInit(): void {
  }
  Cancel() {
    this.result = false;
    this.close();
  }
}
