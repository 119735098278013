<div id="registration" class="mw">
  <div class="mw__content">
    <div class="mw__head">
      <div class="mw__title">{{'Registration' | translate}}</div>
      <span class="mw__line mb2"></span>
      <div class="mw__stitle">{{'Please enter real data.' | translate}}</div>
    </div>
    <div class="mw__body">
      <form class="form" #form (ngSubmit)="register(form)">
        <div class="form__list mb3">
          <div class="form__item" [class.error]="errors.get('Surname')" (input)="errors.delete('Surname')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Surname' | translate}} *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" [attr.data-tooltip]="'Enter at least 1 letter' | translate"></div>
            </div>
            <input type="text" class="form__input" name="surname" [placeholder]="'Ivanov'|translate">
            <div class="error__message" *ngIf="errors.get('Surname')">{{errors.get('Surname')[0] | translate}}</div>
          </div>
          <div class="form__item" [class.error]="errors.get('Name')" (input)="errors.delete('Name')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Name' | translate}} *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" [attr.data-tooltip]="'Enter at least 1 letter' | translate"></div>
            </div>
            <input type="text" class="form__input" name="name" [placeholder]="'Ivan'|translate">
            <div class="error__message" *ngIf="errors.get('Name')">{{errors.get('Name')[0] | translate}}</div>
          </div>
          <div class="form__item" [class.error]="errors.get('MiddleName')" (input)="errors.delete('MiddleName')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Patronymic name' | translate}} *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" [attr.data-tooltip]="'Enter at least 1 letter' | translate"></div>
            </div>
            <input type="text" class="form__input" name="middleName" [placeholder]="'Ivanovich'|translate">
            <div class="error__message" *ngIf="errors.get('MiddleName')">{{errors.get('MiddleName')[0] | translate}}
            </div>
          </div>
          <div class="form__item" [class.error]="errors.get('Email')" (input)="errors.delete('Email')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">E-mail *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" [attr.data-tooltip]="'Enter valid e-mail address' | translate"></div>
            </div>
            <input type="email" class="form__input" name="email" placeholder="ivanov@gmail.com">
            <div class="error__message" *ngIf="errors.get('Email')">{{errors.get('Email')[0] | translate}}</div>
          </div>
          <div class="form__item" [class.error]="errors.get('PhoneNumber')" (input)="errors.delete('PhoneNumber')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Mobile phone number' | translate}} *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" [attr.data-tooltip]="'Enter at least 7 digits' | translate"></div>
            </div>
            <input type="tel" class="form__input" name="phoneNumber" prefix="+7 " mask="(000) 000-00-00" [showMaskTyped]="true" [dropSpecialCharacters]="false" [(ngModel)]="mobileNumber" >
            <div class="error__message" *ngIf="errors.get('PhoneNumber')">{{errors.get('PhoneNumber')[0] | translate}}
            </div>
          </div>
          <div class="form__item" [class.error]="errors.get('Password')" (input)="errors.delete('Password')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Pick a password' | translate}} *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" [attr.data-tooltip]="'The password must be at least six characters long, including mixed case letters, numbers, and special characters' | translate">
              </div>
            </div>
            <div class="form__group">
              <input [type]="newPasswordVisible ? 'text' : 'password'" class="form__input pl2" name="password" placeholder="{{'Password' | translate}}">
              <button type="button" class="form__input-icon" (click)="newPasswordVisible = !newPasswordVisible">
                <span class="js-pass-view ma" [ngClass]="newPasswordVisible ? 'ic-eye' : 'ic-eye-off'"></span>
              </button>
            </div>
            <div class="error__message" *ngIf="errors.get('Password')">{{errors.get('Password')[0] | translate}}</div>
          </div>
          <div class="form__item" [class.error]="errors.get('ConfirmPassword')" (input)="errors.delete('ConfirmPassword')">
            <div class="form-lbinfo">
              <div class="form-lbinfo__title">{{'Confirm new password' | translate}} *</div>
              <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" [attr.data-tooltip]="'The confirmation must match with password' | translate"></div>
            </div>
            <div class="form__group">
              <input [type]="passwordConfirmationVisible ? 'text' : 'password'" class="form__input pl2" name="confirmPassword" placeholder="{{'Password' | translate}}">
              <button type="button" class="form__input-icon" (click)="passwordConfirmationVisible = !passwordConfirmationVisible">
                <span class="js-pass-view ma" [ngClass]="passwordConfirmationVisible ? 'ic-eye' : 'ic-eye-off'"></span>
              </button>
            </div>
            <div class="error__message" *ngIf="errors.get('ConfirmPassword')">{{errors.get('ConfirmPassword')[0] |
              translate}}</div>
          </div>
        </div>

        <div class="mw__stitle mt4 mb1">{{'I do accept' | translate}}</div>

        <div class="dt ma mb3">
          <div class="form__item mb1" [class.error]="errors.get('AcceptPolicy')" (input)="errors.delete('AcceptPolicy')">
            <div class="ch">
              <label class="ch__content">
                <input type="checkbox" class="ch__input" name="acceptPolicy">
                <span class="ch__icon"></span>
                <a href="/policy" target="_blank" class="ch__tx tdu" [attr.data-tooltip]="'PrivacyPolicy__Genetive' | translate" data-position="bottom center">{{'PrivacyPolicy__Genetive' | translate}}</a>
              </label>
            </div>
            <div class="error__message" *ngIf="errors.get('AcceptPolicy')">{{errors.get('AcceptPolicy')[0] | translate}}
            </div>
          </div>
          <!-- <div class="form__item mb1" [class.error]="errors.get('AcceptTerms')" (input)="errors.delete('AcceptTerms')">
            <div class="ch">
              <label class="ch__content">
                <input type="checkbox" class="ch__input" name="acceptTerms">
                <span class="ch__icon"></span>
                <a href="/terms" target="_blank" class="ch__tx tdu" [attr.data-tooltip]="'Terms of use' | translate" data-position="bottom center">{{'Terms of use' | translate}}</a>
              </label>
            </div>
            <div class="error__message" *ngIf="errors.get('AcceptTerms')">{{errors.get('AcceptTerms')[0] | translate}}
            </div>
          </div> -->
        </div>

        <div class="form__item mb2">
          <button [disabled]="loading" type="submit" class="btn w100" [attr.data-tooltip]="'Register' | translate" data-position="bottom center">{{(loading ? 'Loading...' : 'Register') | translate}}</button>
        </div>

        <div class="form__item tc mb3">
          <a href="#" data-modal="login" class="btn btn_wt w100" [attr.data-tooltip]="'Already have an account' | translate" data-position="bottom center">
            {{'Already have an account' | translate}}
          </a>
        </div>
        <div class="form__item tc mb0">
          <a href="mailto:{{supportEmail}}" class="tdu" [attr.data-tooltip]="'Call support' | translate" data-position="bottom center">
            {{'Call support' | translate}}
          </a>
        </div>
      </form>
    </div>
  </div>
</div>
<div id="modal-sms-confirm-reg" class="f-modal" style="display: none;">
  <div class="modal-content w-480">
    <div class="modal-header">
      <div data-fancybox-close class="modal-close"><svg class="icon">
          <use xlink:href="#times-light"></use>
        </svg></div>
    </div>

    <div class="text-center mb-20">
      <div class="font-alt text-xl">Подтверждение</div>
      <div class="divider-sm df my-20"></div>
      <div class="mb-40 text-sm text-center lh-18 color-secondary">Подтвердите номер телефона,<br> введите код из СМС</div>
    </div>

    <div class="df justify-center">
      <form action="#" method="POST" class="w-300 maw-full">
        <div class="mb-40">
          <div class="form-lbinfo">
            <div class="form-lbinfo__title font-alt">Введите код</div>
            <div class="form-lbinfo__icon ic-lbinfo" data-position="top center" data-tooltip="Подсказка"></div>
          </div>
          <input type="number" name="smsCode" class="form__input h-50" placeholder="******" [(ngModel)]="enteredSmsCode">
        </div>
        <input type="button" class="btn btn--primary h-50 w-full mb-20 btOk" value="Подтвердить">
        <a href="javascript:;" class="btn btn--dark-outline h-50 w-full mb-20 btCancel">Отмена</a>
        <div class="text-center text-sm color-secondary">Повторить отправку кода через: {{ts.smsTime}}</div>
      </form>
    </div>
  </div>
</div>
