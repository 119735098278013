import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class StaticContentService {
  private serverUrl = `${environment.cmsApiUrl}/api/staticblocks`;

  constructor(private httpClient: HttpClient) {}

  getContent$(id: number): Observable<string> {
    return this.httpClient
      .get<StaticContentResponse>(`${this.serverUrl}/get/${id}`)
      .pipe(map(({ content }) => content));
  }
}

interface StaticContentResponse {
  blockId: number;
  content: string;
  contentEn: string;
  date: string;
  enabled: boolean;
  name: string;
}
