
<div class="smodal-content">
    <div class="modal-header">
        <div data-fancybox-close (click)="Cancel()" class="modal-close"><svg class="icon"><use xlink:href="/assets/img/svg-sprite.svg#times-light"></use></svg></div>
     </div> 
    <div>
        <img style="float:left;width:70px" alt="" class="avatar__img" [src]="notification.fromIcon">
        <h2>{{notification.subject}} от {{notification.from}}</h2>
        <div style="clear:left">
            {{notification.body["message"]}}
        </div>
   </div>
    <div #btnSubscribe class="smodal-footer mt2">
        <button type="button" (click)="Confirm()" #btnSubscribe class="btn btn--primary">OK</button>
        <button type="button" class="btn btn--dark-outline" (click)="Cancel()">Отказаться</button>
      </div>
</div>
