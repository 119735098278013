<div id="modal-price-agreement" class="mw" style="min-width: 55%; min-height: 0.5vh;">
  <form class="form mb7">

    <div class="fz22 ff:CormorantInfant medium mb3">
      {{"Please read the price agreement and accept them" | translate}}
    </div>
    <div class="mb4">
      <div class="ch-ofert">
        <label class="ch-ofert__content">
          <div *ngIf="priceAgreementAccepted" class="val">&#10004;</div>
          <div *ngIf="!priceAgreementAccepted" class="err">&#10008;</div>
          <a href="javascript:;" (click)="showPriceAgreement()" class="ch-ofert__link fz14 tdu lnk">
            Cоглашение о цене
          </a>
        </label>
      </div>
    </div>

    <!--<div *ngIf="!priceAgreementAccepted" class="form__item df aic w100">
      <button type="submit" (click)="showPriceAgreement()" class="btn mr2">{{'Sign with SMS-code' | translate}}</button>
    </div>-->

  </form>

  <!--style="display: none;"-->
  <div id="mdlPriceAgreement" class="f-modal f-modal--error">
    <div class="modal-content" style="height: 500px;overflow-y: auto; width: 100%;">
      <div class="modal-header">
        <div data-fancybox-close class="modal-close">
          <svg class="icon">
            <use xlink:href="#times-light"></use>
          </svg>
        </div>
      </div>
      <div *ngIf="!priceAgreementAccepted" c class="modal-action" style="text-align:right ;">
        <button type="button" (click)="btnSubscribe.scrollIntoView(false)" class="btn btn--primary mb2">Перейти к подписанию</button>
      </div>
      <div style="min-height:600px">


        <div style='word-wrap:break-word'>

          <div class=WordSection1>

            <p class=MsoNormal>
              <b>
                <span lang=ru style='font-size:14.0pt;line-height:115%'>
                  СОГЛАШЕНИЕ О ЦЕНЕ
                </span>
              </b>
            </p>

            <p class=MsoNormal><span style="font-weight: bold; font-size: 4em; color: red;" lang=ru>НОВАЯ ЦЕНА: {{entity.newPrice}}</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1. Что такое TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST — это интернет-сайт, доступный по адресу
                www.t-st.group (включая все уровни домена) через полную и мобильную версии и
                мобильное приложение, представляющий собой совокупность содержащихся в
                информационной системе объектов интеллектуальной собственности Компании и
                информации (административного и пользовательского контента) («TST»).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST представляет собой электронный каталог
                объявлений о купле-продаже таймшер-сертификатов с приостановленным членством,
                услугах и других предложениях (вместе — «услуги»), которые пользователи могут
                предлагать и искать на TST с целью заключения сделок.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания предоставляет авторизованным
                пользователям техническую возможность размещать информацию на TST в формате
                объявлений в представленных категориях услуг. Компания предоставляет всем
                пользователям (независимо от авторизации) техническую возможность искать и
                просматривать объявления на TST в целях, предусмотренных Условиями.
                Автоматические алгоритмы TST обрабатывают запросы, чтобы предоставлять
                релевантную информацию в результатах поиска.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не является участником, организатором
                сделки, цедентом, цессионарием, покупателем, продавцом, посредником, агентом,
                представителем какого-либо пользователя, выгодоприобретателем или иным
                заинтересованным лицом в отношении сделок между пользователями. Пользователи
                используют размещённую на TST информацию, чтобы заключать сделки на свой страх
                и риск без прямого или косвенного участия или контроля со стороны Компании.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2. Термины и определения</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Административный (управленческий) взнос» —
                ежегодный платеж для содержания членства в системе клубного отдыха (таймшер).
                Данные платежи выставляются клубом и в зависимости от устава клуба должны быть
                в обязательном порядке оплачены членом клубного отдыха (таймшер). При нарушении
                указанного условия об оплате существует факт риска потерять членство в клубе и
                лишиться членства в системе клубного отдыха (таймшер).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Администрация сайта» — сотрудники Компании, а
                также лица, уполномоченные надлежащим образом Компанией на управление Сайтом,
                организацию технической поддержки Сайта, консультационную поддержку
                Пользователей и предоставление услуг Компании Посетителям, в рамках
                использования Сайта последними.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Брокер» — пользователь, успешно прошедший
                обучение на площадке TST и купивший лицензию Брокера.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Верификация Пользователя» — проверка, целью
                которой является:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) подтверждение соответствия вошедшего в
                систему Пользователя владельцу Личного кабинета:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) подтверждение подлинности введенных
                сведений в профиле Личного кабинета фактическим персональным данным,
                подтвержденными документами.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Договор уступки требования (цессии)» —
                сделка, при исполнении которой Цедент уступает право требования исполнения
                обязательства к Клубу, Цессионарий приобретает указанное право требовать
                исполнение обязательства к Клубу и обязуется оплатить стоимость уступки
                требования (цессии) с использованием Сервиса.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Клуб» (должник) — собственник (администрация
                клуба, трастовая управляющая компания) недвижимого и недвижимого имущества,
                являющегося объектом членства в системе клубного отдыха (таймшер).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Личный кабинет» — интерфейс взаимодействия
                Цессионария / Цедента с Сайтом, позволяющий ему просматривать Объявления и
                управлять ими, изменять информацию, указанную Цессионарием / Цедентом о себе
                (фамилию, имя, фотографию, номер телефона), доступный Цессионарию / Цеденту
                после его регистрации на Сайте при вводе Логина и Пароля.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Логин» — адрес электронной почты
                Пользователя, используемые им на Сайте в целях доступа к защищенным страницам
                Сайта.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Объявление» — предложение Цедента об уступке
                требования (цессии) Таймшера с указанием сведений о нём: подробное описание
                характеристик Таймшера, а также иной необходимой и достоверной информации,
                обеспечивающей возможность его правильного выбора Цессионарием.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Оферта» — предложение Цессионария, сделанное
                Цеденту при помощи Сервисов, заключить Договор уступки требования (цессии) на
                условиях, указанных в Оферте.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Пароль» — сочетание букв, цифр и символов,
                уникальное для Логина, позволяющее при одновременном вводе с Логином войти на
                Защищенные страницы Сайта. Пароль выбирается Цессионарием и/или Цедентом
                самостоятельно.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Пользователь» — Посетитель, прошедший
                процедуру регистрации, имеющий учетную запись на Сайте и выступающий в качестве
                Цедента или Цессионария при использовании Сайта.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Посетитель» — любое физическое лицо, старше
                18 лет, использующее Сайт в случаях, прямо предусмотренном Правилами.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Правила» — правила пользования Сайтом,
                определяющие условия использования и развития Сайта, а также права и
                обязанности его Пользователей и Администрации сайта.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Сайт» — совокупность программных и аппаратных
                средств для ЭВМ, обеспечивающих посредством технических средств, применяемых
                для связи между ЭВМ в сети Интернет:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) публикацию для обозрения информации и
                данных, объединенных общим целевым назначением;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) предоставление услуг Цессионарию и Цеденту
                по переводу денежных средств на Сайте в рамках исполнения Договора уступки
                требования (цессии).
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                Сайт доступен по уникальному электронному
                адресу или его буквенному обозначению. Под Сайтом понимается Сайт,
                расположенный в сети Интернет по адресу: www.t-st.group
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Сервисы» — совокупность программ для ЭВМ, баз
                данных, обеспечивающих функционирование Сайта, а также совокупность услуг,
                предоставляемых Пользователям при использовании Сайта.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Сертификат» — основной документ,
                подтверждающий членство в системе клубного отдыха (таймшер) и его характеристики.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Служба доставки» — юридическое лицо или
                индивидуальный предприниматель, оказывающие в целях исполнения Договора уступки
                требования (цессии) услуги оператора почтовой (курьерской/экспедиторской) связи
                по доставке документов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Таймшер» — членство в системе клубного
                отдыха, связанное с передачей в собственность третьим лицам недвижимого и
                движимого имущества клуба, принадлежащего последнему на праве собственности на
                определенный период времени (сервитут). Фактом членства в системе клубного
                отдыха (таймшер), является ценный сертификат, выдаваемый администрацией клуба
                или трастовой управляющей компанией, наделенной соответствующими полномочиями.
                Под членством в системе клубного отдыха (таймшер) следует также понимать любой
                другой документ, подтверждающий факт полной оплаты членства в системе клубного
                отдыха (например, именная пластиковая карта, договор о приобретении, либо иная
                форма установления организацией, выдающей права на владение членством в системе
                клубного отдыха (таймшер).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Устав клуба» — свод правил, регламентирующих
                правовое положение членов системы клубного отдыха (таймшер) и администрации
                клуба, предоставляющей членство в системе клубного отдыха (таймшер), условия
                членства в системе клубного отдыха (таймшер), порядок расчетов, ответственность
                участников системы клубного отдыха (таймшер).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Цедент» — сторона Договора уступки требования
                (цессии), уступающая право требования исполнения обязательства к Клубу.
                Цедентом может быть полностью дееспособное физическое лицо, достигшее
                восемнадцатилетнего возраста.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Цессионарий» — сторона Договора уступки
                требования (цессии), приобретающая право требовать исполнение обязательства к
                Клубу. Цессионарием может быть полностью дееспособное физическое лицо,
                достигшее восемнадцатилетнего возраста.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3. Условия использования TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Добро пожаловать на TST — международную
                площадку купли-продажи таймшер-сертификатов с приостановленным членством.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Условия использования TST («Условия»)
                регулируют отношения между [наименование юр.лица] («Компания», «мы») и
                Посетителями интернета, которые осуществили доступ к TST («пользователи»,
                «вы»).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Вы можете использовать TST в соответствии с
                положениями Условий и политики о данных пользователей. Если вы с ними не
                согласны, вам необходимо прекратить использование TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4. Соглашение об использовании Сервиса
                физическими лицами
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Настоящий раздел регулирует порядок
                использования Сайта, а также взаимоотношения, возникающие при использовании
                Посетителями Сайта и Сервисов в целях размещения Объявлений исключительно для
                удовлетворения личных, семейных, домашних и иных нужд, не связанных с осуществлением
                предпринимательской деятельности при условии, что Цедент и Цессионарий не
                осуществляют предпринимательской деятельности.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Предметом настоящего Соглашения является
                предоставление Пользователям доступа к содержанию Сайта и оказываемым на Сайте услугам.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания предоставляет Пользователю следующие
                виды услуг и сервисов:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — доступ к электронному контенту Сайта на
                бесплатной основе, с правом просмотра контента;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — доступ к форме обратной связи Пользователя с
                Администрацией сайта;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — доступ к средствам поиска и навигации по
                Сайту;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — предоставление Пользователю возможности
                разместить Объявление;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — предоставление Пользователю возможности
                размещения сообщений, комментариев, рецензий Пользователей, выставления оценок
                контенту Сайта;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — доступ к информации о программных продуктах
                Компании и к информации о тестировании и приобретении программных продуктов
                Компании на платной основе;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — иные виды услуг и сервисов, реализуемые на
                страницах Сайта.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Под действие настоящего Соглашения подпадают
                все существующие (реально функционирующие) на данный момент услуги и сервисы
                TST, а также любые их последующие модификации и появляющиеся в дальнейшем
                дополнительные услуги и сервисы TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Доступ к Сайту предоставляется на бесплатной
                основе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не гарантирует доступность Сайта и
                Сервисов круглосуточно.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания вправе в любой момент отказать любому
                Посетителю, в том числе Пользователю, в использовании Сайта и Сервисов при
                нарушении Соглашения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания предоставляет Посетителям,
                Пользователям личное неисключительное и непередаваемое право использовать Сайт
                и Сервисы, представленные на сайте, в соответствии с настоящим Соглашением, при
                условии, что ни Посетитель / Пользователь, ни любые иные лица при содействии
                Посетителя / Пользователя не будут совершать действий: по копированию или
                изменению программного обеспечение Сайта, Сервисов; по созданию программ,
                производных от программного обеспечения Сайта и Сервисов; по проникновению в программное
                обеспечение с целью получения кодов программ; по осуществлению продажи,
                уступки, сдачи в аренду, передачи третьим лицам в любой иной форме прав в
                отношении материала Сайта и программного обеспечения Сайта; по модифицированию
                Сайта и Сервисов, в том числе с целью получения несанкционированного доступа к
                нему; и иных действий, аналогичных перечисленным выше и нарушающих права
                Компании и третьих лиц.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователи самостоятельно оценивают
                правомерность использования ими Сайта и Сервисов, в том числе и с точки зрения
                законодательства страны, резидентами которой они являются.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Посетитель, совершая любые действия по
                использованию Сайта и его Сервисов (просмотр страниц Сайта, Регистрация,
                Верификация, размещения Объявления с указанием контактного телефона, звонок по
                контактным телефонам Компании, указанным на Сайте и иные), в том числе по
                использованию Сайта и его Сервисов в особом режиме — «спящий» Профиль, выражает
                своё полное и безоговорочное согласие (Акцепт) с условиями настоящего
                соглашения об использовании Сервиса, размещённого в сети Интернет по адресу:
                www.t-st.group в качестве публичной оферты.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Настоящее соглашение об использовании Сервиса
                заключается в форме публичной оферты согласно Гражданскому Кодексу Российской
                Федерации и не требует двустороннего подписания и действительно в электронном
                виде.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Получая доступ к Сайту Пользователь считается
                присоединившимся к настоящему Соглашению.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Использование материалов и сервисов Сайта
                регулируется нормами действующего законодательства Российской Федерации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>5. Регистрация на TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Посетитель, желающий стать Пользователем,
                обязан пройти процедуру регистрации на соответствующей странице Сайта. При
                регистрации Пользователю присваивается выбранная им пара Логин плюс Пароль,
                которая используется в дальнейшем Пользователем при работе с Сайтом.
                Пользователь может пройти процедуру регистрации только один раз.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Одновременно на Сайте может быть
                зарегистрирован профиль только одного Пользователя, привязанный к одному номеру
                телефону и электронной почте.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                При регистрации на Сайте Пользователь обязан
                предоставить Администрации сайта необходимую достоверную и актуальную
                информацию для формирования персональной страницы Пользователя, включая
                уникальные для каждого Пользователя Логин (адрес электронной почты и/или номер
                телефона) и Пароль доступа к Сайту, а также фамилию и имя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Регистрационная форма Сайта может запрашивать
                у Пользователя дополнительную информацию.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь самостоятельно выбирает себе
                Пароль, однако Компания настоятельно рекомендует использовать Пароли, длиной не
                менее 6 (шести) символов и включающие одновременно строчные и заглавные буквы,
                а также цифры. Пароль может быть присвоен Администрацией сайта путём отправки
                на адрес электронной почты Пользователя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь несет ответственность за
                достоверность, актуальность, полноту и соответствие законодательству Российской
                Федерации предоставленной при регистрации информации и ее чистоту от претензий
                третьих лиц. Пользователь самостоятельно несет ответственность за сохранность
                Пароля в тайне от третьих лиц.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не несет никакой ответственности в
                случае нарушения прав Пользователя третьими лицами, получившими
                несанкционированный доступ к паре Логин плюс Пароль Пользователя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                При Регистрации Пользователь выражает свое
                согласие с настоящим Соглашением и принимает на себя указанные в нем права и
                обязанности, связанные с использованием и функционированием Сайта.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                После успешной регистрации Пользователя на
                Сайте Компания принимает на себя права и обязанности перед Пользователем,
                указанные в настоящем Соглашении.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>6. Вход на TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Войти в свой профиль на TST (авторизоваться)
                Вы можете либо с помощью номера мобильного телефона или адреса электронной почты,
                указанных в вашем профиле. Для этого Вам необходимо ввести пароль.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Вы обязаны сохранять конфиденциальность своих
                данных для входа на TST. Если у вас есть основания полагать, что кто-то получил
                к ним несанкционированный доступ, немедленно сообщите нам об этом. Все
                действия, совершённые на TST лицом, авторизованным с помощью ваших данных для
                входа, будут считаться совершёнными вами. Ответственность за такие действия
                будете нести вы.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>Войти в свой профиль на TST можно с помощью:</span></p>

            <p class=MsoNormal><span lang=ru>— любого из подтверждённых номеров телефона;</span></p>

            <p class=MsoNormal><span lang=ru>— подтверждённого адреса почты.</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Главное, чтобы выбранный вами логин был
                привязан к профилю. Например, если вы заходите по почте или телефону, они
                должны быть подтверждены в аккаунте.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                А если ввести при входе другие данные — вы
                автоматически создадите новый профиль. В нем не будет ваших объявлений и других
                данных.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>7. Сведения о Пользователе</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Сведения, которые вы предоставляете Компании о
                себе, должны быть достоверными, актуальными и не должны нарушать
                законодательство и права третьих лиц. Вы обязаны по мере необходимости
                обновлять их в своём профиле на TST. Мы можем запросить у вас документы или
                информацию для идентификации или подтверждения полномочий. Если вы их не
                предоставите, мы будем вправе ограничить доступ к вашему профилю.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Использование некоторых Сервисов и Услуг
                Компании возможно только после Верификации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                После прохождения процедуры Регистрации
                Пользователь может пройти процедуру Верификации, что подразумевает под собой
                размещение на сайте и подтверждение Пользователем следующих данных: имя,
                фамилия, адрес электронной почты, дата рождения, контактный телефон.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае необходимости для прохождения
                Верификации Компания оставляет за собой право: в целях соблюдения принципа KYC
                (Know Your Client) (знай своего клиента) на подтверждение личности с помощью
                паспортных данных Пользователя с приглашением на собеседование с уполномоченным
                сотрудником Компании или иным способом.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Статус Верифицированного пользователя
                предоставляется Администрацией сайта по своему усмотрению после получения от
                Пользователя запрашиваемой информации. Администрация сайта имеет право отказать
                Пользователю в Верификации без объяснения причин отказа независимо от затрат и
                усилий, понесённых Пользователем при прохождении процедуры Верификации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                После успешного прохождения процедуры
                верификации Пользователь заполняет Профиль.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Заполняя Профиль, Пользователь настоящим
                подтверждает, что делает информацию, содержащуюся в своём профиле,
                общедоступной, т. е. потенциально доступной всем Посетителям.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Статус Верифицированного Пользователя может
                быть снят с Пользователя в любой момент по усмотрению Администрации сайта без
                объяснения причин.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Нарушения, которые могут повлечь за собой
                строгое предупреждение (временный бан) или лишение статуса Верифицированного
                пользователя (постоянный бан):
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — Пользователь получил пять и более негативных
                отзывов;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — Пользователь указал в своём Профиле
                контактные данные, включая сайты для демонстрации рекламы или свое портфолио;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — в чате со службой поддержки или в Объявлении
                Пользователь использовал ненормативную лексику, оскорбления;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — Администрацией сайта получены жалобы на Пользователя
                (грубость, хамство, неадекватное поведение, нарушение договорённостей и т. п.)
                или материальная (имущественного характера) претензия;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — Пользователь отозвался на Объявление, но
                отказался выполнять достигнутые договоренности, не согласовав с другой стороной
                и не удалив своё предложение, что привело к возникновению конфликтной ситуации;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — Пользователь нарушил правила общения на
                Сайте. Запрещена критика и оскорбления Пользователей и Администрации сайта,
                ненормативная лексика, разжигание конфликтных ситуаций, призывы к нарушению
                Соглашения об использовании TST Trade и законодательства Российской Федерации,
                размещение сообщений, нарушающих законодательство Российской Федерации, нормы
                международного права, содержащих рекламную информацию, спам, схемы «финансовых
                пирамид», «письма счастья»; являющихся незаконными, вредоносными, угрожающими,
                оскорбляющими нравственность, клеветническими, нарушающими авторские права,
                пропагандирующими ненависть и/или дискриминацию людей по расовому, этническому,
                половому, социальному признакам; содержащие ссылки на Интернет-сайты,
                принадлежащие Пользователям или третьим лицам; нарушающих права третьих лиц;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                — Пользователь нарушил правила общения с
                Администрацией сайта. Администрация сайта призвана следить за соблюдением Соглашения
                об использовании TST всеми Пользователями Сайта, а также давать рекомендации
                Пользователям о правильном заполнении Профиля, написании Объявлений и др. Для
                обсуждения спорных вопросов с Администрацией сайта Пользователям необходимо
                использовать чат службы поддержки, встроенный интерфейс TST. Пожаловаться на
                действия или бездействие Администрации сайта можно путём направления
                соответствующего письма в чат службы поддержки.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Мы не можем гарантировать, что вся информация,
                которую пользователи указывают на TST, соответствует действительности. Будьте
                осмотрительны при совершении сделок.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Мы не раскрываем третьим лицам сведения о
                Пользователях, которые не размещены в открытом доступе, за исключением случаев,
                предусмотренных законом (например, по запросу некоторых государственных
                органов). При этом мы не можем нести ответственность за сторонние неправомерные
                действия в отношении информации, которую вы разместили в открытом доступе на
                TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Мы анализируем, как вы используете TST, чтобы
                делать TST лучше и помогать вам измерять эффективность наших сервисов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>8. Права и обязанности Пользователей</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь вправе при соблюдении
                предусмотренных настоящим Соглашением правил бесплатно использовать Сервис как
                программу для ЭВМ на условиях неисключительного пользования (простой
                неисключительной лицензии) для размещения и просмотра Объявлений, поиска услуг.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь обязуется ознакомиться с
                настоящим Соглашением до момента принятия условий (акцепта) настоящего
                Соглашения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>При использовании Сервиса Пользователь обязан:</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) соблюдать действующее законодательство
                Российской Федерации и положения настоящего Соглашения;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) составлять название и текст Объявления на
                русском языке в соответствии с требованиями действующего законодательства
                Российской Федерации, за исключением международных и общеизвестных товарных
                знаков. При этом, Пользователь вправе текст в Объявлении продублировать на ином
                языке, только если по содержанию такой текст полностью соответствует тексту на
                русском языке. Размещая Объявление на двух языках, Пользователь подтверждает
                аутентичность версий текстов Объявлений на русском и ином языках;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) перед размещением любой информации на
                Сервисе, включая Объявления, предварительно оценивать законность их размещения;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (4) размещать Объявления только о Таймшере, в
                отношении которого Пользователь обладает достаточным объемом прав для
                распоряжения таковым и совершения в отношении его сделок;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (5) доводить до сведения Пользователей полную
                и исключительно достоверную информацию о свойствах Таймшер и его
                характеристиках; Таймшер, указанный в Объявлении, должен соответствовать
                описанию, изложенному в данном Объявлении;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (6) хранить в тайне и не предоставлять другим
                Пользователям и третьим лицам ставшие ему известными в результате общения с
                другими Пользователями и иного использования Сервиса персональные данные и
                информацию о частной жизни других Пользователей и третьих лиц без получения
                соответствующего предварительного разрешения последних;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователю при использовании Сервиса
                запрещается:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) обмениваться контактными телефонами и
                иными средствами связями с другими Пользователя Сервиса, а том числе обсуждать
                вопросы, не связанные со сделкой между Цедентом и Цессионарием;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) осуществлять действия, перечисленные в
                требованиях к Личному кабинету Пользователя в Сервисе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                При использовании Сервиса Пользователи вправе
                заключать договоры уступки права требования (цессии) Таймшер с использованием
                Сервиса.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>9. Права и обязанности Компании</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания осуществляет текущее управление
                Сервисом, самостоятельно определяет его структуру, внешний вид и иные элементы.
                Компания сохраняет за собой право в любое время пересматривать или изменять
                оформление Сервиса, его функционал, изменять или дополнять используемые
                скрипты, программное обеспечение, используемые или хранящиеся в рамках Сервиса,
                и условия доступа Пользователей к ним без какого-либо специального уведомления Пользователей.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания также вправе по собственному
                усмотрению прекратить (временно или на постоянной основе) предоставление
                доступа к Сервису полностью либо в какой-либо части всем Пользователям в целом
                или отдельному Пользователю без какого-либо специального уведомления.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания вправе без возмещения каких-либо
                затрат или убытков в любое время без уведомления Пользователя заблокировать
                Личный кабинет Пользователя либо удалить несоответствующие требованиям
                настоящего Соглашения Объявления или поиск услуги, в том числе, в случае любого
                однократного нарушения Пользователем условий настоящего Соглашения, если иное
                прямо не предусмотрено применимым законодательством. Удаление Личного кабинета
                Пользователя означает автоматическое удаление всей информации, размещенной в
                нем. После удаления Личного кабинета Пользователь теряет доступ к использованию
                Сервиса. Компания вправе, но не обязана, восстановить доступ Пользователя к
                Сервису либо восстановить удаленные ранее Объявления в случае устранения
                Пользователем допущенных нарушений.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания вправе делать замечания
                Пользователям, предупреждать, уведомлять, информировать их о несоблюдении
                условий настоящего Соглашения. Адресованные Пользователю указания Компании по
                вопросам использования Сервиса обязательны для исполнения таким Пользователем.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания вправе в любое время по своему
                усмотрению проводить выборочную проверку Объявлений на предмет соблюдения
                Пользователями настоящего Соглашения и соответствия их действующему
                законодательству Российской Федерации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания также вправе в любое время запросить
                у Пользователя, а Пользователь обязан по запросу Компании предоставить ей
                информацию, документы и/или материалы, подтверждающие достоверность информации,
                указанной Пользователем о себе, в Объявлениях, а также ее соответствие
                настоящему Соглашению и действующему законодательству Российской Федерации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь, регистрируюсь в системе Сервиса,
                а также в процессе использования функционала Сервиса, выражает своё согласие с
                тем, что Компания вправе направлять Пользователю по предоставленным им контактным
                адресам (email, номер телефона), а также в виде пуш-уведомлений, информацию о
                развитии Сервиса и его функционала, а также рекламировать собственную
                деятельность и осуществлять рассылки рекламного и информационного характера,
                содержащие информацию об услугах Сервиса, об акциях и иных мероприятиях
                Компании, о предложениях Цедентов и Цессионариях в Объявлениях, а также иную
                информацию, непосредственно связанную с выполнением обязательств Сторон в
                рамках настоящего Соглашения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>Компания обязуется:</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) на условиях, изложенных в настоящем
                Соглашении, предоставить Пользователю права на использование Сервиса на
                условиях возмездного неисключительного пользования (простой неисключительной
                лицензии);
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) оказывать в разумные сроки техническую и
                информационную поддержку Пользователям, необходимую для получения доступа к
                Сервису и его последующего использования.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                10. Гарантии, ответственность, принятие рисков
                Пользователями
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Использование Сервисов осуществляется
                Пользователями на свой страх и риск: Цедент самостоятельно на свой страх и риск
                размещают Объявления в отношении Таймшер, которым Цедент правомочен
                распоряжаться, а Цессионарий рассматривает на свое усмотрение и под свою
                собственную ответственность Объявления Цедентов и принимает решение
                относительно заключения сделки с тем или иным Цедентом;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае наличия у Пользователя сомнений
                относительно законности осуществления тех или иных действий, в том числе
                связанных с размещением Объявлений, Компания рекомендует воздержаться от
                совершения последних.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь несет личную ответственность за
                любую информацию, Объявления и фотографии (скан-копии), которые размещает на
                Сайте, сообщает другим Пользователям, а также за любые взаимодействия с другими
                Пользователями, осуществляемые на свой риск.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь обязуется соблюдать
                осмотрительность при выборе контрагента (Цессионария или Цедента
                соответственно), под свою ответственность принимает решение о сделке,
                самостоятельно удостоверяясь, что предложение, уступка права требования
                (цессии) какого-либо Таймшера, указанного в Объявлении, является действительной
                и легальной.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователи несут ответственность за
                собственные действия в связи с использованием Сервисов, в том числе в связи с
                совершением сделок в отношении Таймшер, информация о которых размещена в Объявлениях,
                созданием и размещением информации и Объявлений в собственном Личном кабинете и
                в иных разделах Сайта, в соответствии с действующим законодательством
                Российской Федерации. Нарушение настоящего Соглашения и действующего
                законодательства Российской Федерации влечет за собой гражданско-правовую,
                административную и уголовную ответственность.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь подтверждает, что действует на
                законных основаниях (например, по доверенности, по праву наследства), обладает
                всеми необходимыми правами (в частности для размещения Объявлений) и не
                нарушает своими действиями законные права и интересы третьих лиц и действующее
                законодательство Российской Федерации, в том числе законодательство о
                конкуренции и правах на результаты интеллектуальной деятельности и средства
                индивидуализации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае нарушения Пользователем условий
                настоящего Соглашения Компания вправе: (1) приостановить возможность реализации
                Пользователем тех или иных прав использования Сайта и Сервисов до момента
                устранения Пользователем допущенных нарушений и возмещения (компенсации)
                причиненных Компании таким нарушением убытков в полном объеме и/или
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) расторгнуть настоящее Соглашение с
                направлением соответствующего уведомления Пользователю по адресу электронной
                почты Администратора, указанному при регистрации на Сайте.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае нарушения Пользователем условий
                настоящего Соглашения и/или нарушений обязанностей, предусмотренных в разделе
                «Права и обязанности Пользователей» настоящего Соглашения, Компания вправе
                применить к такому Пользователю следующие санкции (меры ответственности):
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) «отклонение данных», а именно: отказ в
                праве на осуществление переработки (модификации) Сервисов (Объявлений, поиск
                Таймшер) с использованием данных Пользователя, до исправления Пользователем
                выявленных Компанией нарушений;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) «удаление данных», а именно: удаление
                данных Пользователя с Сайта (без компенсации каких-либо возникших у
                Пользователя в связи с этим прямых или косвенных убытков);
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) «временная блокировка Личного кабинета», а
                именно: временный запрет на использование функциональных возможностей Личного
                кабинета Пользователя, в том числе ограничение права Пользователя на
                осуществление модификаций Сервиса (Объявлений) с использованием новых данных
                Пользователя;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (4) «блокировка оплаты», а именно: наложение
                запрета на оплату Пользователем вознаграждения по настоящему Соглашению;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (5) «отключение отдельных Сервисов», а именно:
                временное или бессрочное прекращение возможности реализации Пользователем тех
                или иных прав использования Сервисов (их выбор осуществляется по усмотрению
                Компании);
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (6) «бессрочная блокировка Личного кабинета»,
                а именно: расторжение настоящего Соглашения с Пользователем вследствие
                неоднократного нарушения или однократного грубого нарушения Пользователем
                условий настоящего Соглашения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>11. Ограничение ответственности Компании</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не предоставляет никаких гарантий
                того, что Сайт, Сервисы или его элементы могут подходить для конкретных целей
                использования. Компания не может гарантировать и не обещает никаких
                специфических результатов от использования Сайта, Сервисов или его элементов.
                Сайт, Сервисы, включая все скрипты, отдельные элементы и оформление Сайта,
                Сервисов предоставляются «как есть».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не предоставляет никаких гарантий
                отсутствия перерывов в работе Сайта, Сервисов, связанных с техническими
                неисправностями, проведением профилактических работ и т. п., однако
                предпринимает коммерчески обоснованные усилия для обеспечения функционирования
                Сайта, Сервисов в круглосуточном режиме. Компания не предоставляет никаких
                гарантий того, что Сайт, Сервисы или любые его элементы будут функционировать в
                любое конкретное время в будущем или что они не прекратят работу.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не несет ответственности и не
                возмещает никакой ущерб, прямой или косвенный, причиненный Пользователю или
                третьим лицам в результате использования или невозможности использования Сайта,
                Сервисов, если иное не предусмотрено применимым законодательством.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не несет ответственности за любой
                ущерб устройству или программному обеспечению Пользователя или иного лица,
                вызванный или связанный с использованием Сайта, Сервисов или при переходе по
                ссылкам, размещенным в рамках Сайта, Сервисов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Ни при каких обстоятельствах Компания и ее
                представители не несут ответственность перед Пользователями и/или третьими
                лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную
                выгоду или потерянные данные, вред чести, достоинству или деловой репутации,
                вызванный в связи с использованием Сайта, Сервисов, содержимого Сайта, Сервисов
                или иных материалов, к которым Пользователи или иные третьи лица получили
                доступ с помощью Сайта, Сервисов, даже если Компания предупреждала или
                указывала на возможность такого вреда.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не участвует в формировании
                содержания Объявлений, Личных кабинетов и иных разделов Сайта, Сервисов. При
                этом на Компанию не возлагается обязанность по проверке Объявлений и их
                содержания, ресурсов, ссылки на которые содержатся в Объявлении,
                благонадежности Цедентов и Цессионариев (а также их идентификации). В этой
                связи качество, безопасность, законность и соответствие Таймшер его описанию, а
                также возможность Цедента уступить право требования и/или Цессионария
                приобрести право требования находятся вне участия и контроля Компании. Компания
                не несет ответственность за содержание информации, предоставляемой Пользователями,
                включая содержание Объявлений, использование Пользователями товарных знаков,
                логотипов третьих лиц и иных компонентов Объявлений и иных разделов Сайта,
                Сервисов, формируемых Пользователями.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не является стороной по сделкам,
                совершаемым между Цедентом и Цессионарием, а также не является организатором,
                посредником, агентом или представителем какого-либо Пользователя и/или иным
                заинтересованным лицом в отношении предлагаемой/заключаемой между
                Пользователями сделки. Все сделки, совершаемые Пользователями в связи с
                размещением на Сайте, Сервисах Объявлений, заключаются и исполняются без
                прямого или косвенного участия Компании.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания ни при каких обстоятельствах не несет
                никакой ответственности по настоящему Соглашению за использование (или
                невозможность использования) и какие бы то ни было последствия использования
                (или невозможности использования) Пользователем выбранной им формы оплаты
                оказываемых услуг по настоящему Соглашению.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>12. Сервис личных сообщений</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователю после Регистрации предоставляется
                доступ к услуге обмена сообщениями, которая позволяет обмениваться
                корреспонденцией с Администрацией сайта.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Брокеру после Регистрации и аттестации
                предоставляется доступ к услуге обмена сообщениями, которая позволяет
                обмениваться корреспонденцией с Администрацией сайта и иными Пользователями.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Переписка, осуществляемая посредством данного
                сервиса, не является личной. Пользователь, используя данный Сервис, принимает
                тот факт, что Администрация сайта в любой момент имеет право осуществлять
                чтение отправляемых сообщений.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь выражает своё согласие на
                получение личных сообщений от Администрации сайта в любое время и любого
                характера, в том числе и рекламного, по всем контактным адресам, указанным
                Пользователем, в том числе не ограничиваясь Email, телефон (звонки, смс,
                сообщения в мессенджеры). Администрация сайта оставляет за собой право
                дополнять текст сообщений, отправляемых Пользователями, материалами рекламного
                характера. Администрация сайта не осуществляет модерацию (предварительный
                просмотр, оценку и фильтрацию) сообщений, направляемых Пользователями друг
                другу. Пользователям запрещается использовать Сервис личных сообщений для:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) совершения действий, нарушающих
                законодательство Российской Федерации, нормы международного права;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) загрузки, отправки, передачи или любого
                другого способа опубликования неразрешённой специальным образом рекламной
                информации, спама, схем «пирамид», «писем счастья»;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) загрузки, отправки, передачи или любого
                другого способа опубликования материалов, которые являются незаконными,
                вредоносными, угрожающими, оскорбляющими нравственность, клеветническими,
                нарушающими авторские права, пропагандирующими ненависть и/или дискриминацию
                людей по расовому, этническому, половому, социальному признакам;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (4) загрузки, отправки, передачи или любого
                другого способа опубликования материалов, нарушающих права третьих лиц.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Администрация сайта оставляет за собой право
                предоставить в случаях, предусмотренных законодательством Российской Федерации,
                доступ к личным сообщениям Пользователя третьим лицам.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                13. Территория и срок действия Соглашения.
                Изменение и прекращение Соглашения
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь вправе использовать Сайт, Сервисы
                на всей территории Российской Федерации, а также иных территориях, на которых
                он доступен с использованием стандартных компьютерных средств и программ.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Настоящее Соглашение вступает в силу для
                Пользователя с момента его присоединения к условиям Соглашения и действуют в
                течение неопределенного срока.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Настоящее соглашение прекращает свое действие,
                в случае если:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) Пользователь примет решение о прекращении использования
                Сайта, Сервисов, направив Компании соответствующее уведомление (посредством
                обращения через интерфейс Сайта, Сервисов в техническую поддержку, либо
                направив Компании сканированную копию соответствующего запроса по адресу
                электронной почты, указанному в настоящем Соглашении;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) Компания примет решение о прекращении
                настоящего Соглашения в одностороннем внесудебном порядке с немедленным
                прекращением доступа и возможности использовать Сайт, Сервисы и без возмещения
                каких-либо затрат или убытков, если иное не предусмотрено применимым
                законодательством. В частности, Компания может принять такое решение в случае:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>— закрытия Сайта, Сервисов;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                — любого, в том числе однократного, нарушения
                Пользователем условий настоящего Соглашения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Настоящее Соглашение может быть изменено
                Компанией без какого-либо предварительного уведомления. Любые изменения в
                Соглашении, осуществленные Компанией в одностороннем порядке вступают в силу в
                день, следующий за днем опубликования таких изменений на Сайте. Пользователь обязуется
                самостоятельно проверять Соглашение на предмет изменений. Неосуществление
                Пользователем действий по ознакомлению с Соглашением и/или измененной редакцией
                Соглашения не может служить основанием для неисполнения Пользователем своих
                обязательств и несоблюдения Пользователем ограничений, установленных
                Соглашением.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Настоящее соглашение считается изменённым,
                если Компанией были внесены изменения в его условия, и при этом Пользователь
                продолжил использование Сайта, Сервисов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>14. Арбитраж TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Решение споров между цедентом и цессионарием с
                помощью Арбитража возможно только при заключении сделки.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если одна сторона сообщила о выполнении
                условий сделки, а другая сторона заявила о нарушении ее условий, сделка
                считается «спорной» и передается на рассмотрение Арбитража в частном порядке.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Цессионарию запрещено совершать какие-либо
                действия с Таймшер до подтверждения успешности сделки. Последующая уступка
                Цессионарием права требования (цессии) на Таймшер расценивается как надлежащее
                подтверждение получения Таймшер и отсутствие претензий к нему.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                При разрешении ситуаций, ранее не описанных в
                Соглашении, а также ситуаций, которые могут трактоваться Соглашением
                неоднозначно, Арбитраж руководствуется совокупностью предоставленных сведений и
                фактов по сделке и ее обстоятельствами.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Сделка уступки права требования (цессии)
                признается успешной, если:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) в Систему от цедента поступило
                подтверждение о передаче, а от цессионария — о получении Таймшер и отсутствии
                каких-либо претензий (стандартное завершение сделки);
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) при рассмотрении спора в Арбитраже стороны
                пришли к согласию и подтвердили успешное завершение сделки (стандартное
                завершение сделки);
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) решением Арбитража вынесено обязательное
                для исполнения решение об успешном завершении сделки (завершение сделки по
                решению Арбитража);
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (4) цессионарий в отведенный для проверки
                Таймшер срок, установленный Соглашением, не сообщил о каких-либо претензиях к
                цеденту (автоматическое завершение сделки).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                После подтверждения цессионарием успеха
                заключенной сделки никакие претензии к сделке Арбитражем не принимаются и не
                рассматриваются.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Решение Арбитража обязательно для исполнения
                Сторонам
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>15. Валюта расчетов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Все расчеты по настоящему Соглашению
                осуществляются исключительно в Долларах США по курсу банка плательщика.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>16. Удаление информации о Пользователе</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь вправе в любой момент удалить
                свой информацию о себе на Сайте и Сервисах, обратившись в службу поддержки.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                Администрация сайта удалит личный кабинет и
                информацию на Сайте и Сервисах, если в течение трех лет Пользователь ими ни
                разу не воспользовался.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                В течение 30 дней после удаления информации о
                Пользователе, Пользователь не сможете использовать для новой регистрации ранее
                использованный номер телефона, который был указан в удалённом личном кабинете.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>17. Условия об интеллектуальных правах</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Исключительные права на Сайт и Сервисы,
                включая, но не ограничиваясь на программы ЭВМ, базы данных, интерфейс,
                технические разработки, логотип, товарный знак, иные средства индивидуализации,
                используемые на Сайте и Сервисах и позволяющие реализовывать функциональные
                возможности Сайта и Сервисов, принадлежат Компании.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Кроме случаев, установленных настоящим
                Соглашением, а также действующим законодательством Российской Федерации, Сайт и
                Сервисы, а также их компоненты, в том числе перечисленные в выше, не могут быть
                скопированы (воспроизведены), переработаны, распространены, отображены во
                фрейме, опубликованы, скачаны, переданы, проданы или иным способом использованы
                целиком или по частям без предварительного письменного разрешения Компании.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае если информация (контент),
                размещаемая Пользователем, охраняется авторским правом, права на такую
                информацию сохраняется за Пользователем, разместившим такую информацию.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                При этом Пользователь предоставляет другим
                Пользователям Сайта и Сервисов безвозмездное неисключительное право на
                использование такого контента путем просмотра, воспроизведения (в том числе
                копирования), переработку (в том числе на распечатку копий) и иные права
                исключительно с целью личного некоммерческого использования, кроме случаев,
                когда такое использование причиняет или может причинить вред охраняемым законом
                интересам правообладателя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Кроме того, Пользователь предоставляет
                Компании неисключительное право использовать на безвозмездной основе
                размещенный на Сайте и Сервисах и принадлежащий ему на законных основаниях
                контент в целях обеспечения Компанией функционирования Сайта и Сервисов в
                объеме, определяемом его функционалом и архитектурой, а также отображения
                контента (включая, но не ограничиваясь, фотографий Объявлений и из Объявлений,
                тексты описаний Таймшера) в промо-материалах Компании, в том числе в рамках
                изображений интерфейса Сайта и Сервисов, в том числе путём доведения таких
                промо-материалов до всеобщего сведения, в т. ч. в целях рекламирования Сайта и
                Сервисов на различных информационных ресурсах. Указанное неисключительное право
                предоставляется на весь срок действия исключительного права, включает в себя в
                т. ч. право воспроизведения контента, а также переработки контента, в т. ч.
                путем включения в состав сложного объекта или составного произведения,
                последующего показа, доведения до всеобщего сведения, сообщения по кабелю и т.
                п. и распространяет свое действие на территории стран всего мира. Компания
                вправе передавать права, указанные в настоящем пункте третьим лицам. Окончание
                срока размещения контента на Сайте и Сервисах и/или срока действия
                неисключительного права не влечет за собой необходимость изъятия из оборота
                промо-материалов Компании с отображением контента (в том числе их удаление из
                сети Интернет).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Никакие положения настоящего Соглашения не
                предоставляют Пользователю право на использование логотипа, фирменного
                наименования, товарных знаков, доменных имен и иных отличительных знаков
                Компании.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>18. Нарушения и последствия</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания стремимся сделать Сайт (Сервисы)
                удобным и безопасным инструментом, поэтому сохраняем за собой право проводить
                выборочную проверку выполнения Пользователями настоящего Соглашения в любой
                момент и по своему усмотрению. Для этого Администрация сайта вправе
                использовать автоматизированные методы.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Администрация сайта вправе принять меры при
                наличии оснований полагать, что с использованием профиля Пользователя или его
                технических средств произошло или может произойти нарушение законодательства,
                прав третьих лиц или Соглашения. Администрация сайта вправе ограничить доступ к
                профилю Пользователя или к какой-то функциональности Сайта (Сервисов) отклонить
                или заблокировать контент Пользователя. Администрация сайта также вправе
                ограничить доступ к Сайту (Сервисам) с определённых IP-адресов и телефонных номеров.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Администрация сайта вправе ограничить доступ к
                профилю в случае поступления в отношении такого профиля жалоб других
                Пользователей, рассылки спама, неоднократных или злостных нарушений условий
                использования, взлома профиля, непредоставления Пользователем документов или
                информации по запросу Администрации сайта, а также, если Администрация сайта
                заметит в поведении Пользователя на Сайте (Сервисах) признаки мошенничества,
                либо при наличии иных подобных обстоятельств, либо, если Пользователь
                зарегистрировал новый профиль на Сайте (Сервисах) вместо заблокированного
                ранее.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Администрация сайта вправе ограничить и
                восстановить доступ к профилю, а также определять условия такого восстановления
                на свое усмотрение и без пояснения причин.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не несёт ответственности за возможные
                убытки, причинённые Пользователям в связи с принятием мер для предотвращения и
                прекращения нарушений на Сайте (Сервисах). Рекомендуем Пользователям сохранять
                резервную копию своего контента на собственном устройстве.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                За нарушение законодательства или прав третьих
                лиц при использовании Сайта (Сервисов) Пользователь может нести
                административную, уголовную или гражданско-правовую ответственность.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>19. Иные положения</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В отношении функционирования и развития Сайта
                (Сервисов) Компания руководствуется законодательством Российской Федерации,
                настоящим Соглашением и иными специальными документами, которые разработаны или
                могут быть разработаны и приняты Компанией в целях регламентирования
                предоставления Пользователям доступа к Сайту (Сервисам).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Настоящее Соглашение регулируется и толкуется
                в соответствии с законодательством Российской Федерации. Вопросы, не
                урегулированные Соглашением, подлежат разрешению в соответствии с законодательством
                Российской Федерации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае возникновения любых споров или
                разногласий, связанных с исполнением настоящего Соглашения, Пользователь и
                Компания приложат все усилия для их разрешения путем проведения переговоров
                между ними. В случае если споры не будут разрешены путем переговоров, споры
                подлежат разрешению в порядке, установленном действующим законодательством
                Российской Федерации, по месту нахождения Компании, если иное прямо не
                предусмотрено применимым законодательством.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Настоящее Соглашение составлено на русском
                языке и может быть предоставлено Пользователю для ознакомления на другом языке.
                В случае расхождения русскоязычной версии Соглашения и версии на ином языке,
                применяются положения русскоязычной версии настоящего Соглашения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если по каким-либо причинам одно или несколько
                положений настоящего Соглашения будут признаны недействительными или не
                имеющими юридической силы, это не оказывает влияния на действительность или
                применимость остальных положений Соглашения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Обращения, предложения и претензии физических
                лиц к Компании в связи с исполнением настоящего Соглашения и функционированием
                Сайта (Сервисов), нарушениями прав и интересов третьих лиц при использовании
                Сайта (Сервисов), а также для запросов уполномоченных законодательством Российской
                Федерации лиц могут быть направлены в службу поддержки Сайта через форму
                обратной связи.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                _________________________________________________________________________<br>
              </span><b>
                <span lang=ru style='font-size:14.0pt;line-height:115%'>
                  <br>
                  ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
                </span>
              </b>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1. Общие положения</span></p>

            <p class=MsoNormal><b><span lang=ru style='font-size:14.0pt;line-height:115%'>&nbsp;</span></b></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.1. Настоящая Политика конфиденциальности
                (Правила защиты информации о Пользователях TST) являются официальным документом
                компании (далее — Администрация), и определяют порядок обработки и защиты
                информации о физических лицах (далее — Пользователи), использующих сервис TST
                Trade, доступный через сайт по адресу www.t-st.group (далее — Сервис).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.2. Целью настоящих Правил является
                обеспечение надлежащей защиты информации о Пользователях, в том числе их
                персональных данных, от несанкционированного доступа и разглашения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.3. Отношения, связанные со сбором,
                хранением, распространением и защитой информации о Пользователях, регулируются
                настоящими Правилами, Пользовательским соглашением и действующим
                законодательством Российской Федерации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.4. Компания вправе вносить изменения и/или
                дополнения в настоящие Правила в одностороннем порядке без какого-либо
                специального уведомления Пользователей. Пользователь обязуется регулярно
                проверять условия настоящих Правил на предмет их изменения и/или дополнения.
                Продолжение использования Сервиса Пользователем после внесения изменений и/или
                дополнений в настоящие Правила означает принятие их Пользователем и его
                согласие с такими изменениями и/или дополнениями.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.5. Настоящие Правила разработаны и
                используются в соответствии с Пользовательским соглашением. В случае наличия
                противоречий в сфере защиты информации о пользователях Сервиса между настоящими
                Правилами и Пользовательским соглашением применению подлежат настоящие Правила.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.6. При использовании Сервиса и его
                функциональных возможностей, Пользователь выражает свое согласие с условиями
                настоящих Правил. В случае несогласия Пользователя с условиями настоящих Правил
                использование Сервиса и его функциональности должно быть немедленно прекращено.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2. Условия пользования Сервисом</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.1. Предоставляя Пользователям возможность
                использования Сервиса, Администрация, действуя разумно и добросовестно,
                считает, что Пользователь:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) обладает всеми необходимыми правами,
                позволяющими ему регистрироваться и авторизовываться в Сервисе и использовать
                его;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) указывает достоверную информацию о себе в
                объемах, необходимых для использования Сервиса;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) осознает, что информация в Сервисе,
                размещаемая Пользователем о себе, в частности его фамилия, имя,
                месторасположение в определенный момент времени, является или может являться
                доступной для других Пользователей Сервиса и пользователей сети «Интернет»,
                может быть скопирована и распространена такими Пользователями;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (4) осознает, что некоторые виды информации,
                переданные им другим Пользователям, не могут быть удалены самим Пользователем;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (5) ознакомлен с настоящими Правилами,
                выражает свое согласие с ними и принимает на себя указанные в них права и
                обязанности.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.2. Администрация не проверяет достоверность
                получаемой (собираемой) информации о Пользователях, за исключением случаев,
                когда такая проверка необходима в целях исполнения положений действующего
                применимого законодательства и/или обязательств перед Пользователем.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3. Цели обработки информации</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Администрация сайта осуществляет обработку
                информации о Пользователях, в том числе их персональных данных, в целях:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) выполнения обязательств Администрации
                перед Пользователями в отношении использования Сервиса;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) улучшения и совершенствования Сервиса и
                его функциональных возможностей, а также разработки новых функциональных
                возможностей
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>и инструментов Сервиса;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) информирования Пользователя о развитии
                Сервиса и его функциональных возможностях, рекламировании ему собственной
                деятельности;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (4) проведения Администрацией сайта
                маркетинговых и/или рекламных акций. В случае несогласия Пользователя с
                обработкой его данных в таких целях, Пользователь может отказаться от обработки
                путем направления соответствующего запроса в службу поддержки на адрес
                электронной почты info@t-st.group.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4. Состав информации о Пользователях</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.1. Чтобы реализовать соглашение между
                Администрацией сайта и Пользователем и предоставить Пользователю доступ к
                использованию функциональности Сервиса, Администрация сайта улучшает услуги
                Сервиса, разрабатывает и внедряет новые услуги Сервиса, а также улучшает
                доступность функций Сервиса. Для достижения этих целей и в соответствии с
                действующим законодательством Администрация сайта собирает, хранит,
                накапливает, извлекает, сравнивает, использует, дополняет и т. п. данные
                Пользователей (далее «обработка»). Администрация также будет получать и
                передавать эти данные, а также результаты автоматической обработки этих данных
                аффилированным лицам и партнерам Администрации сайта, как указано в разделе 5
                настоящей Политики конфиденциальности.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.2. Ниже подробно изложены категории
                обрабатываемых данных Пользователей, причины обработки данных, правовые
                основания обработки данных Пользователей.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <table class=a border=1 cellspacing=0 cellpadding=0 width=602 style='margin-left:
           .1pt;border-collapse:collapse;border:none'>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-left:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Собираемая
                      информация
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-left:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>Цель</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-left:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Юридические
                      основания
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>1</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Данные
                      о местоположении
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует такие данные, чтобы рекомендовать Пользователю объявления,
                      наиболее подходящие ему по местоположению, для показа рекламы, а также для
                      направления рассылок и/или уведомлений Пользователям.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>Согласие</span></p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Законные
                      интересы
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>2</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Данные,
                      которые Пользователь указывает для регистрации на Сервисе, включая имя,
                      фамилия, номер мобильного телефона
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует эту информацию для управления и администрирования Сервисом,
                      включая предоставление Пользователю услуг Сервиса. Администрация сайта
                      использует эти данные, чтобы она могла выполнить свои обязательства перед
                      Пользователями при использовании ими Сервиса (например, в тех случаях, когда
                      Пользователю необходимо пройти процедуру авторизации по номеру мобильного
                      телефона).
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Законные
                      интересы Исполнение договора между Администрацией сайта и Пользователем
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>3</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Если
                      необходимо, копия
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      паспорта
                      Пользователя или другого документа, содержащего его имя, фамилию, фотографию
                      и номер основного документа, удостоверяющего личность Пользователя или
                      личность его представителя. Администрация сайта вправе, но не обязана,
                      предпринять дополнительные меры проверки, которые Администрация сайта сочтет
                      разумными для проверки учетной записи Пользователя.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует эти данные, чтобы идентифицировать Пользователя, проверить
                      его учетную запись и предотвратить злоупотребления
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      и
                      нарушения прав Пользователя или прав других лиц. Например, Администрация
                      сайта может использовать эту информацию для подтверждения личности
                      Пользователя, если он потерял свои учетные данные
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      и
                      хочет получить доступ к своей учетной записи.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Законные
                      интересы Исполнение договора между Администрацией сайта и Пользователем
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>4</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Дополнительные
                      данные, которые Пользователь предоставляет при редактировании своего профиля,
                      включая фото профиля, адрес, телефон, email, реквизиты для доставки (фамилия,
                      имя, отчество, телефон, город и адрес), реквизиты банковской карты
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует эту информацию для управления и администрирования Сервисом,
                      включая предоставление Пользователю услуг Сервиса, а также для направления
                      рассылок и/или уведомлений Пользователям.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Законные
                      интересы Исполнение договора между Администрацией сайта и Пользователем
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>5</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Дополнительные
                      данные, получаемые Администрацией
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      сайта
                      при доступе Пользователя
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      к
                      Сервису, включая информацию
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      о
                      технических устройствах, техническом взаимодействии
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      с
                      Сайтом (месторасположение
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      в
                      определенный момент времени, IP-адрес, вид операционной системы устройства
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Пользователя,
                      uid устройства Пользователя, GAID/IDFA устройства Пользователя, версия
                      Приложения).
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует эти данные для внутреннего контроля, чтобы постоянно
                      улучшать содержание Сервиса, предотвращать и пресекать любые ошибки, которые
                      могут возникнуть при использовании Сервиса, уведомлять Пользователя
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      об
                      изменениях на Сервисе и персонализировать использование Сервиса, а также для
                      показа рекламы.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Законные
                      интересы
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>6</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Информация,
                      содержащаяся
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      в
                      файлах cookies
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует эту информацию
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      для
                      предоставления Пользователю услуг Сервиса,
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      в том
                      числе чтобы рекомендовать Пользователю объявления, наиболее подходящие ему по
                      местоположению, а также для показа ему рекламы.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>Согласие</span></p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>7</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Информация,
                      созданная Пользователем в Сервисе вне раздела редактирования профиля
                      Пользователя (фотографии
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      и
                      описание товаров, отзывы)
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует эту информацию для управления и администрирования Сервисом,
                      включая предоставление Пользователю услуг Сервиса.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Законные
                      интересы
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Исполнение
                      договора между Администрацией сайта и Пользователем
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>8</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Информация,
                      созданная Пользователем при размещении запросов к Службе поддержки.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует эту информацию, чтобы подтвердить личность пользователя и
                      исполнить его запрос на поддержку.
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      также можем использовать
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      эти
                      данные для расследования любых жалоб
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      от
                      имени Пользователя и предоставления ему
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      более
                      эффективного обслуживания.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Законные
                      интересы
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Исполнение
                      договора между Администрацией сайта и Пользователем
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>9</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Информация,
                      полученная в результате поведенческих действий Пользователя на Сайте (дата
                      регистрации на Сервисе, статус «в сети» или в сети», разделы Сервиса,
                      посещаемые Пользователем, сведения в разделе «продаю», сведения в разделе
                      «продано», телефонные разговоры через АТС Сервиса и иные сообщения,
                      передаваемые через мессенджеры Сервиса, объявления Пользователя, включенные в
                      раздел «избранное», подписки на других пользователей, количество подписчиков
                      у Пользователя, номер заказа, выполняемого в рамках функционала «Безопасная
                      сделка», сведения о том, что Пользователь поделился объявлением через
                      социальные сети ВК, ОК, ФБ, сведения о количестве бонусов).
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует эту информацию
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      для
                      управления и администрирования Сервиса, включая предоставление услуг Сервиса.
                      Администрация также может использовать
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      эти
                      данные для расследования любых жалоб
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      от
                      имени Пользователя и предоставления ему
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      более
                      эффективного обслуживания.
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Законные
                      интересы
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width=150 valign=top style='width:112.85pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>10</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Информация,
                      полученная о Пользователе в результате действий других пользователей
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      на
                      Сервисе (в частности, отзывы других Пользователей, рейтинг (который
                      определяется на основании оценок других Пользователей), оформление подписки
                      на Пользователя другими Пользователями Сервиса).
                    </span>
                  </p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта использует эту информацию
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      для
                      управления и администрирования Сервиса, включая предоставление услуг Сервиса.
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Администрация
                      сайта также может использовать эти данные, чтобы адаптировать использование
                      Сервиса Пользователем и его взаимодействие с услугами Сервиса.
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>&nbsp;</span></p>
                </td>
                <td width=150 valign=top style='width:112.85pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Законные
                      интересы
                    </span>
                  </p>
                </td>
              </tr>
            </table>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.3. Законные интересы Администрации сайта
                включают в себя следующее:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>(1) поддержание и администрирование Сервиса;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>(2) предоставление Пользователю услуг Сервиса;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>(3) улучшение содержания Сервиса;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (4) обработка данных, которые были явно
                обнародованы Пользователем
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (5) обеспечение надлежащей защиты учетной
                записи Пользователя; а также
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (6) соблюдение любых договорных, правовых или
                нормативных требований в соответствии с применимым законодательством.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.4. Информация о Пользователях также может
                быть обработана, если это требуется правоохранительными или регулирующими
                органами. Администрация сайта не будет удалять личную информацию Пользователей,
                если она имеет отношение к расследованию уголовного дела или рассмотрения спора
                в суде. Информация будет храниться до тех пор, пока эти вопросы не будут
                полностью решены и/или в течение срока, который требуется и / или допустим в
                соответствии с применимым законодательством.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.5. Вы можете отозвать свое согласие на сбор
                данных местоположения, изменив настройки конфиденциальности на сайте или
                мобильном приложении/либо настройках вашего мобильного устройства.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.6. Если Пользователь не хочет, чтобы
                Администрация обрабатывала конфиденциальные и специальные категории данных о
                Пользователе (включая данные, касающиеся здоровья, расового или этнического
                происхождения, политических убеждений, религиозных или философских убеждений,
                сексуальной жизни и/ или сексуальной ориентации), Пользователь не должен
                публиковать эту информацию или сообщать эти данные Сервису. Как только
                Пользователь предоставит эти данные Сервису (например, при публикации
                объявления), они будут доступны другим пользователям Сервиса.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.7. Если Пользователь откажется от своего
                согласия на обработку или не предоставит данные, которые требуются нам для
                поддержания и администрирования Сервиса, он не сможет получить доступ или
                зарегистрироваться на Сервисе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>5. Обработка данных Пользователей</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.1. Сбор персональных данных Пользователя
                осуществляется в Сервисе при регистрации и/или авторизации, а также при
                использовании
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователем Сервиса и/или внесении
                Пользователем по своей инициативе дополнительных сведений о себе с помощью
                инструментария Сервиса.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.2. Персональные данные Пользователей
                хранятся исключительно на электронных носителях и обрабатываются с
                использованием автоматизированных систем, за исключением случаев, когда
                неавтоматизированная обработка персональных данных необходима в связи с
                исполнением требований законодательства. Администрация сайта может осуществлять
                передачу информации о Пользователях (в том числе трансграничную передачу на
                территорию иностранных государств, обеспечивающих адекватную защиту прав
                субъектов персональных данных), в том числе их персональных данных,
                контрагентам Администрации сайта, которые привлекаются Администрацией сайта для
                оказания услуг по использованию Сервиса. Предоставление указанной информации
                контрагентам Администрации сайта возможна при условии, что передача этой
                информации необходима для обеспечения функционирования Сервиса и предоставления
                Пользователю доступа к его использованию, а также при условии, что такими
                контрагентами приняты обязательства по обеспечению конфиденциальности
                полученной информации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.3. Администрация сайта хранит информацию о
                Пользователях до тех пор, пока это необходимо для выполнения целей, для которых
                были собраны эти данные, в зависимости от юридических оснований обработки, а
                также от дополнительных юридических/нормативных требований, устанавливающих
                необходимость хранения информации в течение определенного периода времени.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.4. Информация о Пользователе (а именно имя,
                фото профиля, город, дата регистрации на Сервисе, рейтинг, отзывы, объявления в
                разделах «продаю») может быть доступна другим пользователям сети
                «Интернет»/другим пользователям приложения, с учетом доступных настроек
                конфиденциальности (использование функции «Черный список»). Обратите внимание,
                что путем размещения вышеуказанной информации Пользователь явно сделал эту
                информацию общедоступной, и она может стать доступной для других пользователей
                Сервиса и пользователей сети Интернет и быть скопирована и/или распространена
                такими пользователями.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.5. Администрация сайта также может
                поделиться данными о Пользователях со сторонними подрядчиками (в том числе
                находящихся на территории других государств), при условии, что эти третьи
                стороны соблюдают обязательства в отношении конфиденциальности данных,
                собранных Сервисом.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>6. Принципы обработки данных о Пользователях</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.1. Обработка персональных данных
                осуществляется на основе принципов:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) законности целей и способов обработки
                персональных данных;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>(2) добросовестности;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) соответствия целей обработки персональных
                данных целям, заранее определенным и заявленным при сборе персональных данных,
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>а также полномочиям Администрации сайта;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (4) соответствия объема и характера
                обрабатываемых персональных данных, способов обработки персональных данных
                целям обработки
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>персональных данных;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (5) недопустимости объединения созданных для
                несовместимых между собой целей баз данных, содержащих персональные данные.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>7. Права и обязанности Пользователей</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>7.1. Пользователи вправе:</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                7.1.1. осуществлять свободный бесплатный
                доступ к информации, размещенной ими о себе, посредством ознакомления с ней в
                своем Личном кабинете в Сервисе;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                7.1.2. самостоятельно вносить изменения и
                исправления в информацию о себе в своем Личном кабинете в Сервисе, при условии,
                что такие изменения и исправления содержат актуальную и достоверную информацию;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                7.1.3. на основании запроса получать от
                Администрации сайта информацию, касающуюся обработки персональных данных.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                7.2. Пользователь понимает, что интерфейс
                Сервиса подразумевает, что часть информации о Пользователе будет доступна иным
                Пользователям Сервиса и пользователям сети «Интернет».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                7.3. Администрация не несет ответственности за
                разглашение персональных данных Пользователя другими Пользователями Сервиса и
                пользователями сети «Интернет», получившими доступ к таким данным.
                Администрация рекомендует Пользователям ответственно подходить к решению
                вопроса об объеме информации о себе, размещаемой в Сервисе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                7.4. Пользователь понимает, что при удалении
                персональных данных (иной пользовательской информации) из Личного кабинета
                Пользователя или удалении Личного кабинета Пользователя из Сервиса, информация
                о Пользователе, скопированная другими Пользователями, может сохраняться.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>8. Меры по защите информации о Пользователях</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                8.1. Администрация принимает технические и
                организационно-правовые меры в целях обеспечения защиты персональных данных
                Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения,
                блокирования, копирования, распространения, а также от иных неправомерных
                действий.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                8.2. Для авторизации в Сервисе используется
                логин (номер мобильного телефона) Пользователя и код, направленный
                Администрацией сайта по указанному Пользователем номеру телефона.
                Ответственность за сохранность данной информации несет Пользователь. Пользователь
                не вправе передавать собственный логин и код, направленный ему Администрацией
                сайта третьим лицам, а также обязан предпринимать меры по обеспечению их
                конфиденциальности.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>9. Обращения Пользователей</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                9.1. Пользователи вправе направлять
                Администрации сайта свои запросы, в том числе запросы относительно
                использования их персональных данных,
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                путем направления запроса в службу поддержки
                на адрес электронной почты info@t-st.group
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                9.2. Запрос, направляемый Пользователем,
                должен содержать следующую информацию:
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) номер основного документа, удостоверяющего
                личность Пользователя или его представителя;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) сведения о дате выдачи указанного
                документа и выдавшем его органе;
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) сведения, подтверждающие участие
                Пользователя в отношениях с Администрацией сайта (в частности номер телефона,
                используемый Пользователем в качестве своего логина для доступа в Сервис);
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                (4) подпись Пользователя или его
                представителя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                9.3. Администрация сайта обязуется рассмотреть
                и направить ответ на поступивший запрос Пользователя в течение 30 дней с
                момента поступления обращения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                9.4. Вся корреспонденция, полученная
                Администрацией сайта от Пользователей, относится к информации ограниченного
                доступа и не разглашается без письменного согласия Пользователя. Персональные
                данные и иная информация о Пользователе, направившем запрос, не могут быть без
                специального согласия Пользователя использованы иначе, как для ответа по теме
                полученного запроса или в случаях, прямо предусмотренных законодательством.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>Редакция от «07» июня 2021 года.</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>_________________________________________________________________________</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <b>
                <span lang=ru style='font-size:14.0pt;line-height:115%'>
                  Cookies
                  (Куки-файлы)
                </span>
              </b>
            </p>

            <p class=MsoNormal><b><span lang=ru style='font-size:14.0pt;line-height:115%'>&nbsp;</span></b></p>

            <p class=MsoNormal>
              <span lang=ru>
                С целью оптимальной работы веб-сайта и его
                постоянного обновления используются Cookies (куки-файлы), а также специальный
                сервис для сбора и статистического анализа данных, связанных с посещением вами
                наших сайтов. Нажимая кнопку «Согласен на использование cookie», вы
                соглашаетесь на использование куки-файлов и указанного сервиса для отслеживания
                предпочтений посетителей сайта и соответствующей оптимизации его дизайна. Куки-файлы
                — это небольшие файлы, которые сохраняются на жестком диске Вашего устройства.
                Они облегчают навигацию и делают посещение сайта
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>более удобным.</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Куки-файлы могут быть разделены на следующие
                категории
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <ol style='margin-top:0cm' start=1 type=1>
              <li class=MsoNormal>
                <span lang=ru>
                  Необходимые куки-файлы<br>
                  Данные куки-файлы являются необходимыми для обеспечения бесперебойной
                  работы веб-сайта и его функций. Без этих куки-файлов невозможно, например,
                  пользоваться такими службами, как конфигуратор автомобиля.
                </span>
              </li>
            </ol>

            <p class=MsoNormal style='margin-left:36.0pt'><span lang=ru>&nbsp;</span></p>

            <ol style='margin-top:0cm' start=2 type=1>
              <li class=MsoNormal>
                <span lang=ru>
                  Функциональные куки-файлы<br>
                  Данные куки-файлы позволяют повысить уровень комфорта пользования и
                  сервиса веб-сайтов и предоставлять в распоряжение различные функции. В
                  функциональных куки-файлах могут сохраняться, например, языковые
                  настройки.
                </span>
              </li>
            </ol>

            <p class=MsoNormal style='margin-left:36.0pt'><span lang=ru>&nbsp;</span></p>

            <ol style='margin-top:0cm' start=3 type=1>
              <li class=MsoNormal>
                <span lang=ru>
                  Сервисные куки-файлы<br>
                  Данные куки-файлы собирают информацию о том, как Вы пользуетесь
                  веб-сайтами. Сервисные куки-файлы помогают определять, например, какие
                  разделы нашего веб-сайта вызывают особый интерес. Таким образом мы можем
                  изменять содержание наших веб-сайтов в соответствии с Вашими потребностями
                  и улучшать наши услуги. Дальнейшую информацию по сбору и обработке
                  информации Вы найдете в разделе «Анализ данных об использовании».
                </span>
              </li>
            </ol>

            <p class=MsoNormal style='margin-left:36.0pt'><span lang=ru>&nbsp;</span></p>

            <ol style='margin-top:0cm' start=4 type=1>
              <li class=MsoNormal>
                <span lang=ru>
                  Куки-файлы третьих лиц<br>
                  Данные куки-файлы устанавливаются третьими лицами, например, социальными
                  сетями. Они в первую очередь используются для интеграции контентов
                  социальных сетей, например, плагинов, на нашем веб-сайте. Информацию об
                  использовании плагинов социальных сетей Вы найдете в подразделе «Плагины
                  социальных сетей» раздела «Защита данных».
                </span>
              </li>
            </ol>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>На нашем веб-сайте мы используем следующие куки-файлы:</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <table class=a0 border=1 cellspacing=0 cellpadding=0 width=602
                   style='margin-left:.1pt;border-collapse:collapse;border:none'>
              <tr>
                <td width=202 valign=top style='width:151.45pt;border:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>Наименование</span></p>
                </td>
                <td width=318 valign=top style='width:238.5pt;border:solid black 1.0pt;
            border-left:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>Описание</span></p>
                </td>
                <td width=82 valign=top style='width:61.5pt;border:solid black 1.0pt;
            border-left:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>Тип</span></p>
                </td>
              </tr>
              <tr>
                <td width=202 valign=top style='width:151.45pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>AKA_A2</span></p>
                </td>
                <td width=318 valign=top style='width:238.5pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Файлы
                      cookie Akamai A2 служат для уменьшения времени загрузки страниц за счет
                      функции Adaptive Acceleration (адаптивное ускорение). Технология Adaptive
                      Acceleration (A2) добавляет к функционалу интеллектуальной платформы Akamai
                      Edge постоянную оптимизацию производительности.
                    </span>
                  </p>
                </td>
                <td width=82 valign=top style='width:61.5pt;border-top:none;border-left:none;
            border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>1</span></p>
                </td>
              </tr>
              <tr>
                <td width=202 valign=top style='width:151.45pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      AWSALB,
                      AWSELB
                    </span>
                  </p>
                </td>
                <td width=318 valign=top style='width:238.5pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Для
                      обеспечения пользователю бесперебойного пользования веб-сайтами
                      «Мерседес-Бенц» использует несколько серверов для распределения нагрузки.
                      Этот куки-файл сохраняет соотнесение пользователя
                    </span>
                  </p>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      к
                      одному из этих серверов.
                    </span>
                  </p>
                </td>
                <td width=82 valign=top style='width:61.5pt;border-top:none;border-left:none;
            border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>1</span></p>
                </td>
              </tr>
              <tr>
                <td width=202 valign=top style='width:151.45pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>App-id</span></p>
                </td>
                <td width=318 valign=top style='width:238.5pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Идентификация
                      приложения в сегменте «Личность покупателя и управление доступом» служб связи
                      Mercedes me
                    </span>
                  </p>
                </td>
                <td width=82 valign=top style='width:61.5pt;border-top:none;border-left:none;
            border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>1</span></p>
                </td>
              </tr>
              <tr>
                <td width=202 valign=top style='width:151.45pt;border:solid black 1.0pt;
            border-top:none;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>MVP-JSESSIONID</span></p>
                </td>
                <td width=318 valign=top style='width:238.5pt;border-top:none;border-left:
            none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'>
                    <span lang=ru>
                      Сервисы
                      Mercedes me Connect – Этот куки-файл используется для правильной
                      маршрутизации в PCF.
                    </span>
                  </p>
                </td>
                <td width=82 valign=top style='width:61.5pt;border-top:none;border-left:none;
            border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:5.0pt 5.0pt 5.0pt 5.0pt'>
                  <p class=MsoNormal style='line-height:normal;border:none'><span lang=ru>1</span></p>
                </td>
              </tr>
            </table>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                _________________________________________________________________________<br>
                <br>
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
              </span><b>
                <span lang=ru style='font-size:14.0pt;line-height:115%'>
                  Соглашение об
                  использовании аналога
                </span>
              </b>
            </p>

            <p class=MsoNormal>
              <b>
                <span lang=ru style='font-size:14.0pt;line-height:115%'>
                  собственноручной
                  подписи
                </span>
              </b><span lang=ru>
                <br>
                <br>
                <br>
              </span>
            </p>

            <p class=MsoNormal align=right style='text-align:right'>
              <span lang=ru>
                УТВЕРЖДЕНО<br>
                приказом генерального директора<br>
                ________________<br>
                от ________________<br>
                №________________<br>
                <br>
              </span>
            </p>

            <p class=MsoNormal align=right style='text-align:right'><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal align=right style='text-align:right'><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Соглашение об использовании аналога
                собственноручной подписи<br>
                <br>
                В соответствии со ст. 435 Гражданского кодекса Российской Федерации настоящее
                Соглашение об использовании аналога собственноручной подписи (далее —
                Соглашение) является офертой<br>
                ________________________   (ИНН) ________________ ,  ОГРН ______________ .<br>
                <br>
                адрес места нахождения: _____________ ,  (далее — Компания) и адресовано
                физическим лицам, акцептовавших условия настоящей
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                оферты (далее — Пользователь).<br>
                <br>
                1. Термины и определения<br>
                <br>
                «Акцепт» — совершение физическим лицом всех юридически значимых действий,
                направленных на заключение Соглашения в соответствии с условиями оферты, а
                также принятие условий настоящей оферты.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Аналог собственноручной подписи Пользователя»
                (далее — АСП) — информация в электронной форме, которая представлена в виде
                одноразового пароля — известной только Пользователю последовательности цифровых
                или буквенно-цифровых символов, позволяет однозначно идентифицировать
                Пользователя Компанией при входе в Личный кабинет и подписании документов через
                Личный кабинет. Одноразовый пароль направляется Компанией в электронной форме в
                виде SMS-сообщения на Телефонный номер Пользователя. Электронные документы,
                подписанные АСП, направленные через Личный кабинет, после положительных
                результатов Аутентификации и Идентификации Пользователя считаются отправленными
                от имени Пользователя и признаются равнозначными документам на бумажном
                носителе, подписанным собственноручной подписью и порождают аналогичные права и
                обязанности в случаях, установленных настоящим Соглашением.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Аутентификация» — удостоверение правомочности
                входа Пользователя в Личный кабинет для направления другому Пользователю
                Электронных документов через Электронный документооборот Компании.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Идентификация» — удостоверение правомочности
                отправления Пользователем через Личный кабинет Электронных документов
                (сообщений), подписанных Корректным АСП.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Код подтверждения» — последовательность
                символов (цифровой или буквенно-цифровой код) формируемая с помощью
                программно-технических средств Компании, направленная в электронной форме в
                виде SMS-сообщения на номер мобильного телефона Пользователя, указанного при
                заполнении регистрационной формы для заключения Соглашения (Заявление о
                присоединении к Соглашению).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Корректный АСП» — одноразовый пароль, дающий
                положительный результат при его проверке на соответствие одноразовому паролю,
                отправленному Компанией на Телефонный номер Пользователя, и подтверждает факт
                формирования АСП определенным Пользователем.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Компрометация Телефонного номера /
                Одноразового пароля» — случай, когда абонентское устройство Пользователя
                (мобильный телефон) и/или SIM-карта, и/или Одноразовый пароль стали доступны
                третьим лицам или утрачены Пользователем.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Личный кабинет» — конфиденциальный раздел на
                Сайте, используемый для электронного документооборота между Пользователями
                (друг с другом), а также Компанией и Пользователями, а также с использованием
                Одноразовых паролей в качестве АСП.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Логин» — адрес электронной почты и/или номер
                телефона Пользователя, используемые им на Сайте в целях доступа к защищенным
                страницам Сайта.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Некорректный АСП» — одноразовый пароль,
                дающий отрицательный результат при его проверке на соответствие Одноразовому
                паролю, отправленному на Телефонный номер Пользователя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Одноразовый пароль (SMS-пароль)» —
                последовательность символов (цифровой или буквенно-цифровой код), формируемая с
                помощью программно-технических средств Компании, позволяющая произвести
                Аутентификацию и Идентификацию Пользователей при направлении друг другу
                Электронных документов через Личный кабинет Компании.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Период доступа» — промежуток времени с
                момента положительной Аутентификации Пользователем в Личном кабинете,
                осуществленной путем проверки Компанией введенного Пользователем логина и
                пароля для доступа в Личный кабинет и Одноразового пароля, полученного
                Пользователем на Телефонный номер для входа в Личный кабинет, в течение
                которого электронные документы, принятые от Пользователя другим Пользователем
                или Компанией, подписанные Корректным АСП признаются сторонами равнозначными
                документами на бумажном носителе, подписанными собственноручной подписью
                Пользователя, и являющимися основанием для совершения другим Пользователем или
                Компанией операций или иных действий.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Пользователь» — лицо, имеющее учетную запись
                на Сайте и прошедшее процедуру Аутентификации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Сайт» — совокупность программных и аппаратных
                средств для ЭВМ, обеспечивающих посредством технических средств, применяемых
                для связи между ЭВМ в сети Интернет:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) электронный документооборот между
                Пользователями (друг с другом), а также между Пользователем и Компанией;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) создание электронных документов в Личном
                кабинете;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) подписание электронных документов в Личном
                кабинете.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                Сайт доступен по уникальному электронному адресу или его буквенному
                обозначению. Под Сайтом понимается Сайт, расположенный в сети Интернет
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                по адресу: <br>
                <br>
                [ _________ ].<br>
                <br>
                «Сессия» — период времени с момента положительной Аутентификации Пользователя,
                в течение которого Пользователь вправе использовать в Личном кабинете
                одноразовый пароль, полученный в соответствии с Соглашением. Продолжительность
                сессии определяется Компанией.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Сообщение» — сообщение, направляемое
                Пользователем Компании посредством Личного кабинета, представляет собой
                электронный документ, содержащий идентификационный номер документа,
                присваиваемый Компанией, и Корректный АСП.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Телефонный номер Пользователя (номер
                мобильного телефона)» — абонентский номер Пользователя в сети российского
                оператора сотовой связи, указанный при заполнении регистрационной формы для
                заключения Соглашения (Заявление о присоединении к Соглашению), используемый
                для получения SMS-сообщений.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Электронный документ» — документ или
                совокупность документов, в котором(ых) информация представлена в электронной
                форме, содержащий Корректный АСП и содержащий указание на лицо, от имени
                которого создан и отправлен электронный документ, а также все обязательные
                реквизиты, предусмотренные действующим законодательством и/или договором,
                заключенным между Сторонами, для составления данного вида документа.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                «Электронный документооборот» — обмен
                электронными документами между Пользователями через Личный кабинет в
                соответствии с Соглашением. Компания является оператором информационной
                системы, обеспечивающей электронный документооборот.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Иные термины и определения, не приведенные в
                Соглашении, применяются в соответствии с договором, заключенным между Сторонами
                с использованием АСП, если иное не следует из положений Соглашения, в том числе
                существа отношений, регулируемых Соглашением, а также действующим гражданским
                законодательством Российской Федерации.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                2. Общие положения<br>
                <br>
                2.1. Соглашение заключается между Компанией и Пользователями, акцептовавшими
                условия настоящей оферты, и устанавливает условия и порядок использования
                Одноразовых паролей в качестве аналога собственноручной подписи (АСП) в
                соответствии со ст. 160 Гражданского кодекса Российской Федерации при заключении
                сделок и обмене документами в электронной форме (электронными документами)
                посредством Личного кабинета Пользователя на Сайте.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.2. Заключение Соглашения означает, что
                Пользователь и Компания пришли к соглашению о возможности использования АСП при
                заключении договоров и обмене Электронными документами между Пользователями
                посредством Личного кабинета Пользователя при соблюдении условий,
                предусмотренных Соглашением.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.3. Использование Пользователем АСП
                подтверждает его согласие на использование в качестве способа обмена
                электронными документами обмен электронными документами с использованием АСП, а
                также является подтверждением ознакомления с порядком и условиями использования
                указанного способа обмена сообщениями, условиями Соглашения.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4. В рамках Соглашения Пользователи друг с
                другом, а также Компания и Пользователь, вправе заключать договоры и
                обмениваться в виде Электронных документов любыми документами, подготовленными
                Пользователем или Компанией в случаях, предусмотренных п. 4.2 Соглашения, кроме
                случая, если федеральными законами или принимаемыми в соответствии с ними
                нормативными правовыми актами установлено требование о необходимости
                составления документа исключительно на бумажном носителе.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.5. В рамках Соглашения Пользователи друг с
                другом, а также Компания и Пользователь, могут обмениваться в виде Электронных
                документов только теми типами сообщений, в отношении которых в Личном кабинете
                Пользователем или Компанией обеспечивается возможность их создания (путем
                заполнения соответствующей электронной формы) с последующим заверением АСП и
                отправкой. Не допускается создание электронных документов за пределами Личного
                кабинета (без использования заполнения соответствующих электронных форм),
                самостоятельное определение Пользователем типов сообщений для обмена в виде
                Электронных документов через Личный кабинет.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.6. В рамках Соглашения документы могут
                создаваться на бумажном носителе и в электронной форме с соблюдением
                установленных правил оформления документов.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.7. Электронные шаблоны документов и
                документы на бумажном носителе должны быть идентичны по составу реквизитов,
                порядку их расположения, гарнитурам шрифта.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.8. Электронные шаблоны документов и
                документы на бумажном носителе изготавливаются на основании макетов,
                утверждаемых руководителем Компании.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.9. Электронные шаблоны документов должны
                быть защищены от несанкционированных изменений.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.10. Соглашение является договором
                присоединения в соответствии со ст. 428 Гражданского кодекса Российской
                Федерации. Присоединение к Соглашению осуществляется Пользователями путем
                акцепта условий настоящей оферты в порядке п. 3.1 Соглашения.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                2.11. Текст Соглашения публикуется на Сайте.
                Соглашение может быть изменено Компанией без какого-либо предварительного
                уведомления. Любые изменения в Соглашении, осуществленные Компанией в
                одностороннем порядке вступают в силу в день, следующий за днем опубликования
                таких изменений на Сайте. Пользователь обязуется самостоятельно проверять
                Соглашение на предмет изменений. Неосуществление Пользователем действий по
                ознакомлению с Соглашением и/или измененной редакцией Соглашения не может
                служить основанием для неисполнения Пользователем своих обязательств и
                несоблюдения Пользователем ограничений, установленных Соглашением.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                Внесение изменений и (или) дополнений в
                Соглашение осуществляется Компанией в одностороннем порядке путем утверждения
                приказом Генерального директора Компании.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                Соглашение считается изменённым, если
                Компанией были внесены изменения в его условия, и при этом Пользователь
                продолжил использование Сайта, Сервисов.<br>
                <br>
                3. Порядок заключения соглашения<br>
                <br>
                3.1. Заключение Соглашения осуществляется путем совершения Пользователем
                следующих действий:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.1.1. Заполнение на Сайте регистрационной
                формы с указанием сведений о физическом лице (ФИО, дата рождения) и номера
                мобильного телефона;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.1.2. Осуществление входа в Личный кабинет
                путем введения пароля, полученного от Компании в SMS-сообщении на номер
                мобильного телефона, указанного регистрационной форме;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.1.3. Введение Кода подтверждения,
                полученного от Компании в SMS-сообщении на номер мобильного телефона,
                указанного регистрационной форме. Введение Кода подтверждения в специальное
                поле в Личном кабинете является подачей Пользователем Компании Заявления о
                присоединении к Соглашению и подтверждением, что Заявление о присоединении к
                Соглашению исходит от Пользователя в соответствии с п. 2 ст. 434 Гражданского
                кодекса Российской Федерации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.2. Совершение действий, указанных в п. 3.1
                Соглашения, признается в соответствии со ст. 438 Гражданского кодекса
                Российской Федерации акцептом настоящей оферты. Акцепт настоящей оферты
                означает, что Пользователь согласен со всеми положениями Соглашения, и
                равносилен заключению Соглашения в письменной форме.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Совершение действий, указанных в п. 3.1
                Соглашение, может подтверждаться выписками из защищенных протоколов,
                электронных журналов и файлов, хранящиеся на сервере Компании, которые являются
                достоверными и достаточными доказательствами, свидетельствующими об акцепте
                Пользователем Соглашения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.3. Соглашение не является публичным
                договором. Компания вправе отказать в принятии указанного выше Заявления о
                присоединении к Соглашению после его получения и не заключить Соглашение по
                своему усмотрению без объяснения причин. Соглашение считается заключенным с
                момента принятия Компанией Заявления о присоединении к Соглашению.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                4. Порядок и условия использования АСП<br>
                <br>
                4.1. Для использования Одноразового пароля в качестве АСП, Пользователь должен
                войти в Личный кабинет, указав свои логин и Одноразовый пароль для входа в
                Личный кабинет, полученный от Компании в SMS-сообщении на Телефонный номер
                Пользователя. Момент Аутентификации Пользователя является началом Периода
                доступа, в течение которого Пользователь может отправить электронные документы,
                подписанные АСП.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.2. Электронный документ направленный одним
                Пользователем другому Пользователю через Личный кабинет в течение Периода
                доступа, подписанный Корректными АСП, признается во всех случаях без исключений
                Компанией и Пользователями его подписавшего, равнозначным документом на
                бумажном носителе, подписанным собственноручной подписью Пользователя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.3. Для подписания документа в Личном
                кабинете посредством АСП Пользователь должен ввести Одноразовый пароль,
                полученный от Компании на Телефонный номер Пользователя, в специальное поле в
                окне сформированных электронных документов в Личном кабинете. Допускается
                возможность подписания одним Одноразовым паролем нескольких Электронных
                документов, объединенных в один пакет Электронных документов в момент их
                формирования для подписания АСП. При этом каждый Электронный документ из пакета
                Электронных документов считается подписанным АСП как отдельный документ.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Срок действия Одноразового пароля составляет
                300 (триста) секунд с момента его отправления Компанией в SMS-сообщении.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Пользователь уведомлен о том, что время
                доставки SMS-сообщения с Одноразовым паролем от Компании зависит от оператора
                сети сотовой связи, загруженности сети сотовой связи, рельефа местности,
                особенности зданий/строений, места нахождения мобильного телефона Пользователя
                и других факторов, не зависящих от Компании, которые исключают ответственность
                Компании за несвоевременную доставку Пользователю SMS-сообщения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.4. Сообщение считается подписанным
                посредством АСП с момента получения другим Пользователем или Компанией
                электронного документа, направленного Пользователем через Личный кабинет, содержащее
                Одноразовый пароль, ранее направленный Пользователю в SMS-сообщении на
                Телефонный номер Пользователя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.5. Стороны признают, что:</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.5.1. используемые при обмене Электронными
                документами в рамках Соглашения методы обеспечения идентификации Пользователя,
                конфиденциальности и целостности сообщений являются достаточными, т.е.
                обеспечивающими защиту интересов Пользователя;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.5.2. Аутентификация Пользователя на
                основании логина и пароля являются достоверными и достаточными доказательствами
                входа Пользователя в Личный кабинет;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.5.3. Идентификация Пользователя (определение
                лица, подписавшего электронный документ посредством АСП) производится по
                Телефонному номеру Пользователя, на который был отправлен Одноразовый пароль, и
                по Одноразовому паролю, отправленному Компанией Пользователю на номер
                мобильного телефона, указанный в Заявлении о присоединении к Соглашению;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.5.4. выписки из защищенных протоколов,
                электронных журналов и файлов, хранящиеся на сервере Компании, являются
                достоверными и достаточными доказательствами совершения Пользователем действий
                в Личном кабинете, в т.ч. направление Компании электронных документов,
                подписанных Корректным АСП;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.5.5. заключение договоров, а также
                совершение операции и иных действий на основании электронных документов,
                принятых Компанией в течение Периода доступа от Пользователя через Личный
                кабинет, подписанные Корректным АСП, влекут юридические последствия,
                аналогичные последствиям заключенных договоров или операций и иных действий,
                совершенных Компанией на основании документов на бумажном носителе и
                подписанных собственноручной подписью Пользователя, и не могут быть оспорены ни
                одной из Сторон только на том основании, что эти действия не подтверждаются
                документами, составленными на бумажном носителе;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.5.6. Компания рассматривает любое лицо,
                осуществившее отправку Компании Электронного документа посредством Личного
                кабинета, как Пользователя и интерпретирует Электронный документ, полученный
                таким способом, как Электронный документ Пользователя, если документ подписан
                Корректным АСП.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.6. Компания не принимает на себя
                ответственность за возможные убытки, которые могут возникнуть у Пользователей в
                результате временной невозможности предоставить другому Пользователю или
                Компании Электронный документ через Личный кабинет. Пользователь обязан в
                случаях временной неработоспособности каналов связи или оборудования
                использовать иные альтернативные способы обмена сообщениями (адрес электронной
                почты и прочее).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.7. Компания предоставляет Пользователю
                возможность направлять Электронные документы в любое время суток.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.8. Пользователь обязан:</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.8.1. указать в Заявлении о присоединении к
                Соглашению номер мобильного телефона, на который возможно отправлять
                SMS-сообщение и позволяющего принимать SMS-сообщения;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.8.2. обеспечить поддержку мобильным
                телефоном, номер которого указан в Заявлении о присоединении к Соглашению
                функции приема SMS-сообщений, а также подписку на услугу SMS-сообщений у своего
                оператора сотовой связи, а также обязуется своевременно изменять указанный
                номер мобильного телефона через Личный кабинет. Пользователь подтверждает, что
                он уведомлен о том, что операторы сотовой связи могут взимать плату за передачу
                SMS-сообщений, и что расчеты с оператором мобильной связи в указанном случае
                являются обязанностью Пользователя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.8.3. обеспечить конфиденциальность, включая
                отсутствие доступа третьих лиц к Телефонному номеру Пользователя и Одноразовым
                паролям. Пользователь признает и подтверждает, что осознает и принимает на себя
                все риски, связанные с несанкционированным доступом третьих лиц к Личному
                кабинету, Телефонному номеру и Одноразовым паролям.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае компрометации Телефонного номера
                Пользователь должен незамедлительно сообщить об этом Компании любым доступным
                способом.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Датой и временем компрометации Телефонного
                номера считаются дата и время подтверждения о получении Компанией уведомления о
                компрометации, после получения которого Компания приостанавливает отправку
                Пользователю SMS-сообщений с Одноразовым паролем до предоставления
                Пользователем Заявления с указанием нового Телефонного номера.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Электронные документы, направленные
                Пользователем другим Пользователям и Компании и полученные ими до сообщения о
                факте компрометации Телефонного номера, имеют силу.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компания не несет ответственности за возможные
                убытки Пользователей, возникшие в связи с несвоевременным уведомлением
                Пользователя о факте компрометации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.9. В случае нарушения Пользователем условий
                Соглашения и/или в случае возникновения разногласий между Пользователями или
                Пользователем и Компанией относительно взаимных прав и обязанностей,
                существующих в рамках заключенных договоров, и/или связанных с формированием,
                доставкой, получением, подтверждением получения Электронных документов, а также
                использованием в данных документах АСП, Компания имеет право приостановить исполнение
                обязательств по Соглашению или отказаться от исполнения обязательств по
                Соглашению, в том числе приостановить возможность использования АСП в Личном
                кабинете, что не лишает Пользователя возможности обмениваться сообщениями с
                Компанией иными способами.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.10. Пользователь подтверждает, что он
                уведомлен о существовании рисков, связанных, в том числе, но не ограничиваясь
                перечисленными ниже:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.10.1. с использованием или невозможностью
                использования АСП, отправку и прием SMS-сообщений вследствие возникновения
                неисправностей и отказов оборудования, сбоев и ошибок, отказов систем связи,
                энергоснабжения, иных систем, изменений алгоритмов функционирования,
                профилактических работ, технологических изменений, обновлений, иных причин
                технического характера, в результате чего может, в том числе, оказаться
                невозможным отправка Электронных документов;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.10.2. с возможностью несанкционированного
                доступа третьих лиц к Телефонному номеру / Одноразовому паролю и совершения
                действий в Личном кабинете;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.10.3. раскрытием Одноразовых паролей
                оператору сотовой связи, иным третьим лицам, включая лицо, оказывающее Компании
                услуги по доставке третьим лицам сообщений (информации) через
                телекоммуникационные сети, в случае использования Пользователем Одноразовых
                паролей.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.11. Компания не несет ответственность перед
                Пользователем в случаях, но не ограничиваясь следующими:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.11.1. за убытки, возникшие вследствие
                исполнения заключенных договоров или совершения Компанией операций и иных
                действий на основании полученных электронных документов, подписанных Корректным
                АСП, если Компания обоснованно полагала, что направление электронных документов
                исходило от Пользователя;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.11.2. за неосуществление операции,
                неисполнение поручения, иного распоряжения Пользователя, если такое
                неисполнение стало следствием аварии (сбоев в работе) компьютерных сетей,
                силовых электрических сетей или систем электросвязи, непосредственно
                используемых для приема электронных документов, или совершения иных действий,
                произошедших не по вине Компании, а также действий третьих лиц;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.11.3. указания Пользователем в Заявлении о
                присоединении к Соглашению Телефонного номера, владельцем (абонентом) которого
                Пользователь не является, а также в случаях допущения Пользователем ошибки при
                указании номера;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.11.4. доступа третьих лиц к Телефонному
                номеру, в том числе повлекшего раскрытие неуполномоченным лицам Одноразовых
                паролей, направляемых в порядке, предусмотренном Соглашением;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.11.5. неполучения / несвоевременного
                получения Пользователем SMS-сообщения с Одноразовым паролем, в том числе в
                связи со следующими обстоятельствами:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (1) в результате нарушений в работе, сбоев и
                ошибок оборудования, каналов связи, с помощью которых обеспечивается
                направление и доставка SMS-сообщений, содержащих Одноразовый пароль;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (2) утратой или повреждением мобильного
                телефона Пользователя, номер которого указан Пользователем в Заявлении о
                присоединении к Соглашению;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (3) возникновение нарушений в работе, сбоев и
                ошибок оборудования, каналов связи, с помощью которых обеспечивается
                направление и доставка SMS-сообщений, содержащих Одноразовый пароль, в том
                числе, в случае если в результате наступления таких обстоятельств SMS-сообщение
                не было направлено, или было несвоевременно направлено, или было не получено
                Пользователем, или было несвоевременно получено Пользователем, или был
                направлен Одноразовый пароль, правильное указание Пользователем которого не
                обеспечивает совершение (подтверждение) операции (подписание документа АСП).
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                5. Оформление и правила проверки АСП<br>
                <br>
                5.1. Отметка об АСП используется при визуализации электронного документа,
                подписанного АСП, с соблюдением следующих требований:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.1.1. место размещения отметки об АСП должно
                соответствовать месту размещения собственноручной подписи в аналогичном
                документе на бумажном носителе;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.1.2. элементы отметки об АСП должны быть
                видимыми и читаемыми при отображении документа в натуральном размере;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.1.3. элементы отметки об АСП не должны
                перекрываться или накладываться друг на друга;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.1.4. элементы отметки об АСП не должны
                перекрывать элементы текста документа и другие отметки об АСП (при наличии).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.2. Отметка об АСП включает фразу «Документ
                подписан аналогом собственноручной подписи», номер сертификата (логина,
                введенного для аутентификации Пользователя в Личном кабинете) ключа электронной
                подписи, фамилию, имя, отчество владельца сертификата (Пользователя), дату
                подписания. Отметка об АСП может включать эмблему Компании, товарного знака
                (знака обслуживания) Компании в соответствии с действующим законодательством.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>Пример</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <table class=a1 border=1 cellspacing=0 cellpadding=0 width=602
                   style='margin-left:.1pt;border-collapse:collapse;border:none'>
              <tr style='height:245.4pt'>
                <td width=602 valign=top style='width:451.45pt;border:solid black 1.0pt;
            padding:5.0pt 5.0pt 5.0pt 5.0pt;height:245.4pt'>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>ДОКУМЕНТ ПОДПИСАН</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>АНАЛОГОМ СОБСТВЕННОРУЧНОЙ ПОДПИСИ</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>Сертификат (логин): 9100000000</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>Пользователь: Николаев Николай Николаевич</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>Дата подписания: 22.04.2021 13:42:31</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                  <p class=MsoNormal align=center style='text-align:center;line-height:normal;
            border:none'><span lang=ru>&nbsp;</span></p>
                </td>
              </tr>
            </table>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.3. Компания предоставляет возможность на
                своем сайте осуществить проверку подписи любого электронного документа,
                созданного с помощью Сайта
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                и подписанного АСП через Личный кабинет. Для
                проверки необходимо ввести АСП из документа в поле «Аналог собственноручной
                подписи», ввести код CAPTCHA и нажать на кнопку «Проверить подпись документа».
                В ответ программа выдаст реквизиты документа, либо напишет «Документ не найден,
                подпись не подтверждена».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.4. Процедуру проверки подлинности АСП может
                проводить любой Пользователь, Компания, а также третьи лица, которым
                Пользователь передал документы.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6. Заключительные положения<br>
                <br>
                6.1. Настоящая оферта становится действительной после ее утверждения приказом
                Генерального директора Компании и официального размещения на Сайте и прекращает
                свое действие с момента опубликования нового текста оферты либо уведомления
                Компании об отзыве оферты.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.2. В соответствии с Федеральным законом от
                27.07.2006 г. № 152-ФЗ «О персональных данных», Пользователь дает свое согласие
                на обработку всеми возможными способами всех персональных данных,
                предоставляемые Компании, в том числе в целях продвижения товаров, работ,
                услуг, предоставляемых путем осуществления прямых контактов с помощью средств
                связи. Указанное согласие действует до его отмены.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.3. Стороны согласились, что все споры и
                разногласия, возникшие из Соглашения или в связи с ним, будут решаться
                Сторонами путем переговоров. Стороны обязуются подчиняться исключительной
                юрисдикции судов Российской Федерации при возникновении спорных вопросов
                юридического характера, связанных с Соглашением. В случае не достижения
                согласия, спор решается в судебном порядке в<br>
                <br>
                __________________________________________________<br>
                <br>
                (в зависимости от подсудности, установленной законодательством Российской
                Федерации).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.4. Стороны договариваются о том, что
                претензионный порядок досудебного урегулирования споров из настоящего
                Соглашения является для Сторон обязательным.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Сторона, полагающая, что ее права нарушены или
                оспариваются другой Стороной, обязана направить другой Стороне письменную
                претензию. Все претензионные письма направляются Сторонами нарочным либо
                заказным почтовым отправлением с уведомлением о вручении последнего адресату по
                месту нахождения Компании / месту жительства физического лица.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Претензии рассматриваются в срок, не
                превышающий 30 (тридцать) календарных дней с момента получения претензии.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.5. Соглашение может быть расторгнуто по
                инициативе любой из Сторон без объяснения причин путем уведомления об этом
                другой Стороны. Соглашение считается расторгнутым по истечении 30 (тридцати)
                календарных дней с момента направления такого уведомления.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.6. Во всех остальных случаях, не
                предусмотренных настоящим Соглашением, Стороны руководствуются нормами
                действующего законодательства Российской Федерации.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                Редакция от «07» июня 2021 года.<br>
                <br>
                _________________________________________________________________________<br>
                <br>
              </span><b>
                <span lang=ru style='font-size:14.0pt;line-height:115%'>
                  ДОГОВОР ДЛЯ
                  ПРОДАВЦОВ НА ПЛАТФОРМЕ TST-TRADE.RU
                </span>
              </b>
            </p>

            <p class=MsoNormal><b><span lang=ru style='font-size:14.0pt;line-height:115%'>&nbsp;</span></b></p>

            <p class=MsoNormal><span lang=ru>1.1. Предмет Договора</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>TST обязуется:</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) за вознаграждение совершать за счёт
                Продавца сделки, связанные с реализацией товаров Продавца через Платформу; и
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) оказывать Продавцу услуги в соответствии
                с Приложениями к Договору, а Продавец обязуется оплачивать их.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Права и обязанности по сделкам, связанным с
                реализацией товаров Продавца и заключенным от его имени, возникают
                непосредственно у Продавца (ст. 1005 ГК РФ).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В рамках совершения сделок, связанных с
                реализацией товаров Продавца, TST в том числе совершает действия:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) предоставление Продавцу возможности
                размещать предложения товаров на Платформе, передача информации о заказах
                товаров Продавца, и информационное обслуживание Клиентов;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) разработкa, техническое обслуживание и
                администрирование ЛК Продавца.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.2. Квалификация Договора</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                К правоотношениям между TST и Продавцом
                применяются положения ГК РФ о возмездном оказании услуг (глава 39), об
                агентировании (глава 52), о комиссии (глава 51) и иные правовые акты, принятые
                в соответствии с ними. Договор является рамочным договором (ст. 429.1). Договор
                не является публичным (ст. 426).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.3. Третьи лица</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST вправе привлекать к исполнению своих
                обязательств по Договору третьих лиц и несёт перед Продавцом ответственность за
                ненадлежащее исполнение ими своих обязательств.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.4. Собственность на товар</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Товар, переданный TST, остаётся в
                собственности Продавца до реализации товара Клиенту (в момент исполнения
                обязательства по передаче товара Клиенту за исключением случаев отказа Клиента
                от товара в момент передачи) либо реализации товара TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.5. Уступка</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец не вправе уступать и/или передавать
                иным способом права и/или обязанности по Договору и/или в отношении какого-либо
                товара третьим лицам без предварительного согласия TST. TST вправе уступать
                и/или передавать иным способом свои права по Договору и/или в отношении
                какого-либо товара любым третьим лицам без согласия Продавца.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.6. Полнота соглашения</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Договор содержит полные и исчерпывающие
                условия относительно его предмета и заменяет собой все предыдущие устные или
                письменные договорённости между Сторонами. TST не даёт и не принимает на себя
                каких-либо иных заверений, гарантий или обязанностей, за исключением прямо
                оговоренных в Договоре.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.7. Независимость положений Договора</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Недействительность отдельных положений
                Договора не затрагивает действительность остальных положений Договора и
                Договора в целом.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.8. Части Договора</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Приложения к Договору и Разделы Договора или
                Приложений и Регламенты являются его неотъемлемой частью.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.9. Заключение и срок действия Договора</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Договор заключается путём направления TST
                текста Договора (оферты) с уведомлением об Активации и нажатия Продавцом кнопки
                «Принять оферту» в ЛК (акцепт), а также любыми последовавшими действиями
                Продавца в ЛК по выполнению условий Договора в течение 365 дней с даты
                Активации (срок для акцепта). Совершение потенциальным Продавцом любых действий
                в ЛК до Активации не считается акцептом. Сделки, совершённые Сторонами
                посредством действий в ЛК, признаются совершёнными в простой письменной форме.
                Договор признается заключённым в дату акцепта. Договор заключается на
                неопределённый срок и распространяет своё действие на отношения его Сторон,
                возникшие с даты регистрации Продавца на Платформе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.10. Отказ в Активации</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST вправе отказать в Активации без объяснения
                причин. Если TST не уведомил об Активации, то Договор считается незаключённым,
                а потенциальный Продавец не становится Продавцом и не вправе требовать
                признания Договора заключённым со стороны TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.11. Изменение Договора</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST вправе в одностороннем порядке изменять
                условия Договора (включая размеры вознаграждения) и уведомив Продавца не менее
                чем за 14 (четырнадцать) календарных дней до вступления таких изменений в силу,
                а в случае уменьшения размеров вознаграждения либо изменения условий Разделов
                Договора – не менее, чем за 7 (семь) календарных дней. Продавец обязан
                регулярно отслеживать ЛК и электронную почту на предмет изменений в Договоре.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                При несогласии с предлагаемыми изменениями в
                Договор Продавец обязан в течение 7 (семи) календарных дней с даты Уведомления
                об изменениях направить TST запрос о расторжении Договора. В противном случае
                такие изменения с даты их вступления в силу считаются согласованными обеими
                сторонами, подлежат применению к отношениям между ними и становятся
                неотъемлемой частью Договора.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST оставляет за собой право вносить изменения
                в Договор, уведомив Продавца не менее чем за 1 (один) календарный день до
                вступления таких изменений в силу в случаях, связанных с:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) изменением Списка запрещенных товаров в
                целях соблюдения требований законодательства, а также в целях защиты жизни и
                здоровья Клиентов;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) добавлением в функционал ЛК новых услуг
                и/или обновлением технических возможностей Платформы, которые не накладывают на
                Продавца дополнительных обязательств и не влекут изменение стоимости услуг в
                большую сторону (кроме дополнительных услуг, которые Продавец выбирает в ЛК).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.12. Приостановление исполнения Договора TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST вправе в одностороннем порядке полностью
                либо частично приостановить исполнение своих обязательств по Договору, уведомив
                об этом Продавца, если:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) у Продавца имеется задолженность по
                оплате любых сумм по Договору;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) в отношении конкретного товара, если он
                имеет недостатки, не согласованные Сторонами, или состояние товара не
                соответствует Карточке товара или требованиям Договора;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) Продавец не соблюдает условия Договора
                или нарушает права третьих лиц;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (г) Продавец не подключен к ЭДО в случаях,
                когда согласно условиям Договора, использование ЭДО является обязательным;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (д) по техническим или иным причинам. В
                течение срока приостановления плата за услуги, прямо затронутые таким
                приостановлением, не взимается.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.13. Расторжение Договора</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Любая Сторона может расторгнуть Договор во
                внесудебном порядке путем направления Уведомления. Дата расторжения — последний
                календарный день отчетного периода, следующего за отчетным периодом, в котором
                Стороной было направлено Уведомление.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец вправе в одностороннем внесудебном
                порядке отказаться от исполнения Договора в случае несогласия с изменениями в
                Договоре. Дата расторжения — 7 (седьмой) календарный день с даты направления
                Уведомления.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST вправе в одностороннем внесудебном порядке
                отказаться от исполнения Договора путём направления Уведомления, при этом датой
                расторжения будет дата направления Уведомления:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) если любое из Заверений оказывается
                недостоверным, недействительным, неточным или вводящим в заблуждение;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) если Продавец допускает существенные
                нарушения обязательств по Договору, такие как: предоставление недостоверной
                информации о товаре, непредоставление информации по запросу TST или препятствие
                TST в проведении проверок товара и Карточек товара на предмет соответствия
                требованиям Договора, нарушение исключительных или иных прав третьих лиц,
                нарушение платежных обязательств, неоднократный невывоз товаров в соответствии
                с условиями Договора, нарушения условий Раздела «Заверения и гарантии»,; или
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) в иных случаях, предусмотренных
                законодательством или Договором.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Расторжение Договора осуществляется в
                следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) в дату уведомления TST прекращает
                оказание Услуг по Договору (за исключением услуг, связанных с исполнением
                принятых, но невыполненных до даты уведомления заказов, а также с возвратами
                Клиентов, а при одностороннем отказе TST – любых услуг) и делает Товары
                Продавца недоступными к реализации;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) не позднее даты расторжения (либо в
                кратчайшие сроки при одностороннем отказе TST) Стороны обеспечивают вывоз
                товара из офиса TST;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) не позднее даты расторжения (либо в
                кратчайшие сроки при одностороннем отказе TST) Стороны проводят окончательную
                сверку взаиморасчетов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если после расторжения Договора TST получает
                возвраты от Клиентов, TST возвращает Клиентом уплаченные за товар денежные
                средства, однако не возвращает товар Продавцу. Стороны признают, что в таком
                случае у TST возникает право собственности на такой товар, и он может
                осуществить реализацию такого товара от своего имени либо иным образом
                распорядиться им по своему усмотрению.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2. Реализация товара<br>
                <br>
                2.1. Личный кабинет
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                После регистрации на Платформе Продавец
                получает доступ к ЛК. Условия использования ЛК установлены в Разделе «Личный
                кабинет».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.2. Карточка товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец самостоятельно создаёт Карточки
                товара в соответствии с условиями Раздела «Карточка товара».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.3. Требования к товару</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Товар Продавца должен соответствовать всем
                условиям Раздела «Требования к товару».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.4. Ограничения</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST вправе устанавливать ограничения для
                Продавцов на реализацию отдельных категорий товаров по определенному пакету
                услуг согласно Приложениям.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.5. Цена товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец самостоятельно определяет цену на
                товар и указывает её в ЛК.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.6. Скидка на товар и уценка</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST вправе предоставить Клиенту скидку на
                любой товар:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) в целях продвижения Платформы и
                увеличения реализаций совместно с Продавцом (совместная скидка) или по
                инициативе TST (скидка TST);
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) в случае несущественного недостатка,
                указав о таком недостатке в Карточке товара (уценка). Продавец имеет право
                уведомить TST о запрете TST производить уценку своих товаров (в таком случае
                TST снимает товар с недостатком с реализации).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Размер денежных средств, причитающихся
                Продавцу (R), определяется по формуле:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>R = AP – C + D, где </span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>AP – Фактическая цена реализации</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>С – Комиссия</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>D – Доплата за счет TST (Скидка TST)</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Совместная скидка учитывается при определении
                размера Комиссии в порядке, установленном в Разделе «Комиссии и тарифы». Скидка
                TST не уменьшает размер денежных средств, причитающихся Продавцу при реализации
                такого товара.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.7. Другие условия реализации товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                За исключением цены товара Продавец поручает
                TST определять все иные условия реализации товара через Платформу, в том числе
                условия и сроки возврата, которые не ухудшают положение Клиентов по сравнению с
                законодательством. Условия и сроки возврата определяются TST в соответствии с
                Условиями реализации товаров на Платформе, с которыми Продавец соглашается в
                полном объеме.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.8. Отмена заказа</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST имеет право отменять заказы Продавца, если
                он ненадлежащим образом исполнил обязательства по Договору. Такая отмена не
                признается нарушением TST обязательств по Договору. Продавец несет
                ответственность за все негативные последствия такой отмены, включая претензии
                или требования, предъявленные Клиентом, а также снижение показателей качества
                сервиса.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец имеет право отменять свои заказы,
                однако несёт ответственность за все негативные последствия такой отмены,
                включая претензии или требования, предъявленные Клиентом, а также снижение
                показателей качества сервиса. Отменой заказа со стороны Продавца также признается
                получение информации от выбранной Продавцом Службы доставки об отмене заказа.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.9. Чат с Клиентом и ответы на запросы службы
                поддержки
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец напрямую общается с Клиентом через
                чат по вопросам, связанным с товарами. Продавец гарантирует, что все его
                решения и действия в чате соответствуют требованиям Договора, разделу «Правила
                общения с покупателями TST» и требованиям действующего законодательства о
                защите прав потребителей.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Служба поддержки может направлять Продавцу
                запросы от Клиентов. Продавец обязан дать ответ в сроки, указанные в запросе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.10. Возврат товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Порядок принятия возвратов от Клиентов, а
                также их передачи Продавцу, установлен в Разделе «Возврат товаров».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.11. Утилизация товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST имеет право по поручению Продавца, либо с
                его предполагаемого согласия (в случае несоблюдения им сроков вывоза товаров)
                утилизировать (уничтожить) товары Продавца в порядке, установленном Разделом
                «Утилизация товара».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.12. Заверения и гарантии</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец предоставляет TST заверения и
                гарантии согласно условиям Раздела «Заверения и Гарантии».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.13. Конфиденциальность и персональные данные</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец обязан соблюдать требования Раздела
                «Конфиденциальность и персональные данные».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.14. Правила коммуникации</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если Договором прямо не предусмотрено иное,
                любая официальная коммуникация между Сторонами и все уведомления по Договору:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) оформляются в виде электронных сообщений
                на русском языке;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) направляются через ЛК и\или на адрес
                Электронной почты получателя (Продавцу – на адрес электронной почты, указанный
                в качестве логина при регистрации на Платформе, TST – на адрес электронной
                почты: e-mail@tst-trade.ru); и
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) считаются полученными в день отправки
                (при отправке с 00:00 до 20:00 по московскому времени) либо на следующий день
                после отправки (при отправке с 20:00 до 00:00 по московскому времени).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец обязуется регулярно проверять ЛК и
                электронную почту. TST не отвечает за любые негативные последствия в связи с
                несвоевременным ознакомлением Продавца с уведомлениями.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.15. Изменение адресов и реквизитов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Стороны обязаны сообщать друг другу об
                изменениях своих юридических адресов, реквизитов, номеров телефонов и адресов
                электронной почты, а Продавец обязан сообщить TST об изменении системы
                налогообложения, в течение 1 (одного) рабочего дня с даты изменений. TST вносит
                изменения в информацию о Продавце на Сайте в течение 1 (одного) рабочего дня, а
                в платежные реквизиты – в течение 7 (семи) календарных дней с даты сообщения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.16. Служба поддержки</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST консультирует Продавца по электронному
                адресу e-mail @tst-trade.ru по любым вопросам, связанным с реализацией товаров на
                Платформе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.17. Уведомления</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец подтверждает своё согласие на
                получение от TST любых Уведомлений по электронной почте и по номеру телефона,
                указанным в ЛК, в том числе, но не ограничиваясь, информационно-рекламных
                уведомлений о сервисах и продуктах TST и его группы компаний.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                3. Вознаграждение, отчётные документы и оплата<br>
                <br>
                3.1. Вознаграждение TST
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>Вознаграждение TST состоит из:</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) процентной ставки от цены реализации
                товара, установленной Продавцом в ЛК (Комиссия);
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) вознаграждения за услуги, оказываемые
                согласно Приложениям.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Размер Комиссии и стоимость услуг по Договору
                установлены в Разделе «Комиссии и тарифы TST».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.2. Направление Отчётных документов Продавцу</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST в течение 5 (пяти) календарных дней с
                окончания каждого отчётного периода (календарного месяца) направляет Продавцу в
                ЛК следующие документы (Отчётные документы):
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  (а) отчёт о реализации товара;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  (б) УПД-1 к отчёту о реализации товара;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) УПД-1, включающий в себя все виды услуг
                и работ, стоимость которых не входит в Комиссию;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  (г) при необходимости, иные документы.</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.3. Счёт-фактура</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST предоставляет Продавцу счет-фактуру в
                соответствии с положениями законодательства РФ. В случае реализации товара
                юридическому лицу Продавец поручает TST и/или его уполномоченным сотрудникам
                оформить и направить Клиенту от его имени счет-фактуру, либо УПД на сумму
                заказа в сроки, предусмотренные законодательством. TST вносит в УПД информацию
                о ставке НДС исходя из данных в Карточке товара.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.4. Использование ЭДО</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Использование ЭДО является обязательным для
                Сторон при согласовании Отчетных документов. Подписание документов
                осуществляется через системы ЭДО, указанные в ЛК.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.5. Согласование документов без ЭДО</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если Продавец по любым причинам не подключён к
                системе ЭДО, Продавец принимает на себя ответственность за все возможные
                негативные последствия в связи с отсутствием у него такого подключения. Стороны
                признают, что направление Отчетных документов в ЛК является необходимым и
                достаточным подтверждением:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) надлежащего исполнения TST обязанности
                по направлению Отчетных документов;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) принятия оказанных TST услуг в полном
                объёме в случае отсутствия разногласий со стороны Продавца, направленных в
                предусмотренном Договором порядке.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Стороны признают данные учётных систем TST,
                отображающиеся в ЛК, преимущественным достоверным источником информации об
                исполнении обязательств Сторон по Договору.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.6. Согласование Отчётных документов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец в течение 5 (пяти) рабочих дней после
                направления Отчетных документов от TST обязан:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) при отсутствии разногласий — подписать
                документы со своей стороны; либо
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) при наличии разногласий — направить TST
                мотивированный протокол разногласий по форме, установленной TST. Получив
                протокол разногласий, TST вносит принятые правки и направляет Продавцу
                изменённые документы, а Продавец обязан подписать их.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если Продавец в установленный срок не подписал
                Отчётные документы, то документы, подписанные TST в одностороннем порядке,
                считаются:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) должным образом согласованными
                Сторонами, (при этом датой согласования документа будет последний день срока,
                предусмотренного для обратной связи от Продавца); а также
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) должным подтверждением согласования
                Продавцом всех сумм и иных указанных в них условий и должным основанием для
                оплаты.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.7. Перечисление денежных средств Продавцу</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST перечисляет денежные средства за
                реализованные в течение отчётного месяца товары Продавца (за вычетом сумм,
                причитающихся TST) на банковский счёт Продавца дважды в месяц в следующем
                порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) не позднее 25 (двадцать пятого) числа
                отчётного месяца TST перечисляет Продавцу денежные средства на основании
                Промежуточного отчета о реализациях из ЛК по состоянию на 15 (пятнадцатый)
                календарный день отчётного месяца; и
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) не позднее 10 (десяти) календарных дней
                с даты согласования Отчётных документов TST перечисляет Продавцу разницу между
                суммой по Отчётным документам и суммой, выплаченной согласно п.п. (а)
                настоящего пункта.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Иные условия взаиморасчетов между Сторонами
                установлены в Разделе «Взаиморасчеты».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.8. Акты сверки</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                По окончании каждого календарного квартала не
                позднее 15 числа следующего месяца TST размещает в ЛК акт сверки с Продавцом за
                прошедший календарный квартал.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец в течение 15 (пятнадцати) рабочих
                дней после направления Акта сверки TST обязан:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) при отсутствии разногласий — нажать в ЛК
                кнопку «Согласен»; либо
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) при наличии разногласий — нажать в ЛК
                кнопку «Не согласен» и направить TST в ЛК свой вариант Акта сверки.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST в течение 5 (пяти) рабочих дней
                рассматривает возражения и направляет Продавцу Акт сверки с согласованными TST
                изменениями. Продавец обязан в течение 3 (трех) рабочих дней после направления
                согласовать новый Акт сверки в ЛК или сообщить о разногласиях. Стороны обязаны
                согласовать Акт сверки до окончания следующего календарного квартала.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если Продавец в установленный срок не сообщил
                о разногласиях, то Акт сверки, размещенный TST в ЛК, считается:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) должным образом согласованным Сторонами
                (при этом дата согласования - последний день срока для обратной связи от
                Продавца); а также
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) должным подтверждением согласования
                Продавцом всех сумм и иных указанных в Акте сверки условий.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                4. Ответственность<br>
                <br>
                4.1. Общие положения
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае неисполнения или ненадлежащего
                исполнения Сторонами своих обязательств по Договору, Стороны несут
                ответственность согласно условиям Договора, а в части, не урегулированной
                Договором – в соответствии с законодательством РФ.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.2. Возмещение ущерба</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец обязан по требованию TST возместить
                документально подтвержденный ущерб TST включая, но не ограничиваясь, за любое
                из следующих нарушений:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) Нарушение требований законодательства о
                защите прав потребителей;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Нарушение Заверений и обязательств
                Продавца в Разделе «Заверения и гарантии»;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) Нарушение обязательств, установленных в
                Разделе «Конфиденциальность и персональные данные».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Компенсация убытков согласно условиям Договора
                или Приложений к нему начисляются и выплачиваются, в том числе путем удержания,
                на основании требования, направленного TST в ЛК или на электронную почту
                Продавца.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.3. Ограничение ответственности TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В максимальной степени, допустимой
                законодательством, Стороны согласовали, что:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) любые требования к TST по Договору или в
                связи с ним ограничиваются возмещением реального ущерба и ни при каких
                обстоятельствах не включают упущенную выгоду или иные косвенные убытки;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) TST освобождается от ответственности за
                неисполнение или ненадлежащее исполнение своих обязательств, если Продавец не
                сообщил TST о своей претензии в течение 30 (тридцати) дней с момента, когда
                Продавец узнал или должен был узнать о возникновении оснований для такой
                претензии;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) TST не несет ответственность по сделкам,
                связанным с реализацией товара Клиентам, кроме случаев, когда нарушение прав
                Клиента возникло по вине TST, в таком случае TST возместит Продавцу
                документально подтвержденные убытки, причиненные Заказчику вследствие
                предъявления ему соответствующих требований или претензий если Продавец
                незамедлительно уведомит TST об их поступлении и привлечет к участию в
                рассмотрении;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (г) Продавец оградит TST от любых требований
                и претензий третьих лиц в связи с товаром (или иным образом в связи с
                Договором) и возместит TST любые имущественные потери и расходы, возникшие в
                связи с такими требованиями и претензиями;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (д) TST не несёт ответственности за
                неисполнение или ненадлежащее исполнение своих обязательств, если Продавец
                предоставил TST недостоверные, неполные, неточные или вводящие в заблуждение
                заверения или информацию.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.4. Действия TST в случае обнаружения товара,
                признанного утраченным
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае, если TST обнаружит товар, за который
                ранее выплатил Продавцу возмещение в связи с его утратой или недостачей
                согласно условиям Приложений к Договору, TST имеет право по своему выбору
                осуществить реализацию такого товара от своего имени по своему усмотрению
                (Стороны признают, что в таком случае у TST возникает право собственности на
                такой товар) либо вернуть товар Продавцу и удержать сумму ранее выплаченного
                возмещения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.5. Обстоятельства непреодолимой силы</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Стороны не несут ответственности за
                неисполнение или ненадлежащее исполнение обязательств по Договору, если оно
                вызвано действием чрезвычайных и непредотвратимых обстоятельств
                (обстоятельствами непреодолимой силы), которые подтверждены документами
                компетентных органов. Если обстоятельства непреодолимой силы непрерывно продолжаются
                более шести месяцев, то любая Сторона вправе отказаться от исполнения Договора
                без возмещения ущерба другой Стороне.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.6. Иные меры ответственности Сторон</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Дополнительные меры ответственности Сторон
                могут быть предусмотрены Приложениями к Договору.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.7. Применимое право и разрешение споров</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Договор регулируется и подлежит толкованию в
                соответствии с законодательством Российской Федерации. Стороны предпримут все
                усилия для решения любых споров и разногласий, возникших между ними по Договору
                или в связи с ним, путём переговоров. Срок рассмотрения претензии составляет 30
                (тридцать) календарных дней с даты получения. Любой спор, который не был
                разрешен путём переговоров, подлежит разрешению в Зюзинском районном суде
                города Москвы.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                Раздел «Определения»<br>
                <br>
                TST — Общество с ограниченной ответственностью «TST» (ОГРН 00000000,
                юридический адрес: Россия, 000000, г. Москва, Ленинская набережная, дом 45,
                корпус 2, помещение 5), являющееся владельцем и оператором Платформы.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Cut-off — время, установленное TST или
                Продавцом в ЛК для конкретных заказов, до наступления которого Продавец обязан
                подтвердить готовность входящих в заказ отправлений к передаче в доставку.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Активация — направляемое TST подтверждение
                Продавцу об активации его ЛК.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Договор — настоящий договор, заключаемый между
                TST и Продавцом на условиях, указанных ниже, текст которого размещен в ЛК.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Заказ — товар или набор товаров, который
                Клиент заказал у Продавца на Платформе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Заявка на поставку — запрос Продавца,
                оформленный через ЛК, содержащий информацию о товарах, включая их наименование,
                ассортимент и количество, которые Продавец планирует передать в офис TST для их
                дальнейшей реализации на Сайте. Является офертой Продавца, которая акцептуется
                TST путем согласования заявки в порядке, установленном Договором.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Заявка на возврат — запрос Продавца,
                оформленный через ЛК, содержащий информацию о товарах, включая их наименование,
                ассортимент и количество, которые Продавец планирует вывезти из офиса TST.
                Является офертой Продавца, которая акцептуется TST путем согласования заявки в
                порядке, установленном Договором.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Излишек — товар, не указанный Продавцом в
                Заявке на поставку, либо товар сверх количества, указанного в Заявке на
                поставку. Опознанный излишек – товар, карточки которого Продавец ранее заводил
                на Площадке. Неопознанный излишек – товар, карточки которого Продавец ранее не
                заводил на Площадке.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Карточка товара — совокупность информации о
                товаре на Платформе, включая текст с описанием его характеристик, цены и
                условий реализации, а также информацию о Продавце.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Клиент — полностью дееспособное физическое
                лицо (индивидуальный предприниматель) или юридическое лицо, приобретающее товар
                у Продавца на Платформе (либо указанное в качестве получателя товара)
                исключительно для личных нужд, не связанных с осуществлением
                предпринимательской деятельности.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Личный кабинет (ЛК) — персональная страница
                Продавца на Платформе, содержащая информацию о Продавце, размещаемых им товарах
                и о заказах Продавца.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Невыкуп — ситуация, когда Клиент полностью или
                частично отказывается от товаров в заказе при получении.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Неидентифицированные отправления —
                отправления, выявленные TST в ходе приёмки как неуказанные в Акте
                приёмки-передачи.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Объявленная стоимость — стоимость товара,
                установленная Продавцом на момент создания конкретного заказа Клиента и
                зафиксированная в ЛК.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Объявленная ценность — стоимость товара,
                зафиксированная в передаточных документах (УПД-2, Акт приема товара).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Отмена — ситуация, когда Клиент отказывается
                получить заказ до его доставки либо не является получить заказ.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Отправление — товар или совокупность товаров,
                которые Продавец упаковал (при необходимости: снабдил маркировкой).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Платформа — агрегатор информации о товарах –
                информационная система (программа для ЭВМ), размещенная на Сайте, которая
                предоставляет Продавцам возможность размещать товары с целью реализации, а
                также получать информацию о заказах. Функции Платформы, доступные Продавцу,
                определяются TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Прогнозируемый срок реализации товара – срок,
                в течение которого товар Продавца будет реализован Клиенту по прогнозам TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец — полностью дееспособное физическое
                лицо, которое прошло регистрацию на Платформе и заключило Договор.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Сайт — www.tst-trade.ru, который принадлежит и
                администрируются TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Сертификат — основной документ, подтверждающий
                членство в системе клубного отдыха (таймшер) и его характеристики.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Способы передачи — передача заказа Клиенту в
                офисе TST, через Почту России или курьером.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Сторонние службы доставки — службы доставки, с
                которыми Продавец самостоятельно заключает договор и производит расчеты по
                оказанным услугам без участия TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Таймшер — членство в системе клубного отдыха,
                связанное с передачей в собственность третьим лицам недвижимого и движимого
                имущества клуба, принадлежащего последнему на праве собственности на
                определенный период времени (сервитут). Фактом членства в системе клубного
                отдыха (таймшер), является ценный сертификат, выдаваемый администрацией клуба
                или трастовой управляющей компанией, наделенной соответствующими полномочиями.
                Под членством в системе клубного отдыха (таймшер) следует также понимать любой
                другой документ, подтверждающий факт полной оплаты членства в системе клубного
                отдыха (например, именная пластиковая карта, договор о приобретении, либо иная
                форма установления организацией, выдающей права на владение членством в системе
                клубного отдыха (таймшер).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Товар — права требования на Таймшер или права
                требования на несколько Таймшеров, которые Продавец разместил (или намерен
                разместить) к реализации на Платформе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Трек-номер — уникальный буквенно-цифровой или
                цифровой код (штрихкод - идентификатор), который присваивается заказам и
                позволяет отследить статусы их доставки в Службе доставки.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Уведомление — электронное сообщение,
                направляемое TST Продавцу в ЛК, а также на Электронную почту.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Электронная почта — адрес электронный почты,
                указанный Продавцом при регистрации на Платформе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Условия реализации товаров на Платформе —
                публичные оферты для Клиентов, приобретающих товары на Платформе, расположенные
                по адресам:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Заголовки статей и пунктов используются
                исключительно для удобства чтения и не влияют на смысл Договора.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                Раздел «Личный кабинет»<br>
                <br>
                1.1. Информация о Продавце
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец обязан указывать в ЛК коммерческое
                обозначение в соответствии с требованиями законодательства, в том числе не
                вводящее в заблуждение относительно принадлежности ЛК определенному лицу.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.2. Конфиденциальность пароля</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец обязуется принимать все необходимые
                меры для обеспечения конфиденциальности пароля и не сообщать логин и пароль
                третьим лицам кроме случаев, когда эти лица действуют по поручению Продавца
                (при этом он отвечает за их действия, как за свои собственные).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.3. Смена пароля</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                При возникновении у Продавца подозрений
                относительно безопасности его пароля или возможности его несанкционированного
                использования третьими лицами, Продавец обязан незамедлительно уведомить службу
                поддержки через ЛК или связавшись с ней, после чего сменить пароль ЛК.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                При возникновении у TST подозрений
                относительно использования ЛК Продавца третьим лицом или вредоносным
                программным обеспечением, TST вправе в одностороннем порядке сменить пароль
                Продавца к его ЛК. Новый пароль сообщается Продавцу исключительно при личном
                обращении в службу поддержки и идентификации Продавца.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.4. Право на деактивацию ЛК</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST с предварительным Уведомлением вправе
                деактивировать ЛК Продавца (приостановить оказание Услуг) при совокупности
                следующих факторов:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) Продавец активировал два и более ЛК на
                одно физическое лицо;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) ассортимент товаров, доступных к покупке
                Клиентам, в ЛК Продавца совпадает более чем на 30%.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST деактивирует все ЛК кроме одного по выбору
                Продавца. В случае, если Продавец не предоставил информацию о ЛК, который
                необходимо оставить, в течение 1 (одного) рабочего дня с даты Уведомления, TST
                оставляет активированным ЛК, к которому привязан Договор с самой ранней датой
                заключения.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.5. Удаление ЛК после расторжения Договора</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                По окончании всех действий в отношении ранее
                размещённых заказов TST удаляет ЛК и всю информацию о товарах Продавца с
                Платформы без возможности восстановления.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.6. Действия TST в ЛК</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST в лице сотрудников и иных уполномоченных
                лиц вправе совершать в ЛК Продавца любые действия, необходимые для решения
                обращений Продавца в Службу поддержки.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                Раздел «Карточка товара»<br>
                <br>
                1.1. Создание Карточки товара
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец через ЛК самостоятельно создаёт новую
                Карточку товара или добавляет своё ценовое предложение к существующей Карточке
                товара. Продавец, создавший Карточку, имеет право установить запрет на ее копирование
                другими Продавцами. Вносить изменения в Карточку может только Продавец, который
                ее создал. Продавец обязан указать в Карточке товара ставку НДС, в противном
                случае TST устанавливает ее автоматически и не несет ответственность за ее
                корректность. Продавец имеет право вносить изменения в ставку НДС в любой
                момент.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.2. Ответственность за информацию в Карточке
                товара
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец несёт ответственность за полноту и
                соответствие информации в Карточке товара требованиям законодательства о защите
                прав потребителей. Продавец обязан поддерживать актуальность и достоверность
                размещенной в Карточках товаров информации о товаре и его цене. В случае
                указания в Карточке товара недостоверной информации, Продавец принимает на себя
                любые наступившие в связи с этим негативные последствия, в том числе при
                расчете размера Вознаграждения TST исходя из недостоверных данных.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.3. Обработка Карточки товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае нарушения законодательства и условий
                Договора, TST вправе проводить проверку любой Карточки товара (как на этапе ее
                создания, так и после размещения на Платформе) и в случае недостаточности или
                несоответствия содержания Карточки законодательству РФ или условиям Договора по
                своему выбору:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) требовать внесения изменений Продавцом
                (а Продавец обязан их незамедлительно вносить);
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) самостоятельно менять категорию товара,
                менять или дополнять содержание Карточки товара, в том числе значения
                атрибутов;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) блокировать Карточку товара на
                Платформе, делая товар недоступным для заказа Клиентом.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец соглашается с тем, что TST при
                проверке и внесении изменений в Карточку товара вправе использовать информацию
                с официальных сайтов предоставляющих членство в системе клубного отдыха.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.4. Использование Карточки товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST вправе размещать Карточку товара и/или
                любые входящие в нее информационные блоки:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) на Платформе совместно с ценовыми
                предложениями аналогичных товаров другими Продавцами; а также
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) на сторонних ресурсах в
                рекламно-маркетинговых целях, при этом стоимость такого размещения определяется
                TST дополнительно согласно условиям Договора.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                Раздел «Требования к товару»<br>
                <br>
                1. Общие требования к товару
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.1. Соответствие требованиям Платформы</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Товар должен соответствовать условиям
                Договора, описанию в Карточке товара и иным требованиям TST в ЛК по всем
                параметрам. При несоответствии товара любым таким требованиям, TST вправе
                отказать в приёмке (и все дополнительные расходы в таком случае будет нести
                Продавец) или приостановить исполнение обязательств в отношении такого товара.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.2. Предоставление документов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец обязан в течение 3 (трёх) рабочих
                дней с даты направления запроса предоставлять TST заверенные копии любых
                запрашиваемых документов, подтверждающих соответствие товара требованиям
                законодательства и Договора.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.3. Дополнительные ограничения</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец не праве размещать к реализации на
                Платформе любые товары, которые не соответствуют ограниченным в правах
                (недействительным сертификатам) таймшер.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                <br>
                Раздел «Возврат товара»<br>
                <br>
                1. Условия возврата для Клиентов
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.1. Возврат товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Клиент имеет право вернуть товар в
                соответствии с положениями Условий реализации товаров на Платформе TST с учетом
                особенностей, установленных Договором.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.2. Обработка возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST обрабатывает возвраты в порядке,
                установленном Приложениями к Договору.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.3. Расчеты по возвратам</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST перечисляет Клиенту уплаченные им за
                возвращенный товар денежные средства в сроки, установленные законодательством.
                Продавец обязан возместить TST стоимость возвращенного товара, указанную в ЛК
                на момент оформления Клиентом заказа, а TST возвращает Продавцу сумму ранее
                удержанной Комиссии. Взаиморасчеты осуществляются путем удержания в порядке,
                предусмотренным Разделом «Взаиморасчеты».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2. Возврат товара Продавцу из офиса TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.1. Возврат товаров, не принятых при приемке</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST имеет право вернуть Продавцу товары по
                причине выявления при приемке, размещении в офисе, излишка, брака и иных
                требований Договора в следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) TST направляет Продавцу на согласование
                Акт о расхождениях (при поставке с особыми условиями) или Акт приема (при
                поставке без особых условий);
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Продавец обязан вывезти товар в течение
                14 (четырнадцати) календарных дней с момента согласования Акта в ЛК своими силами
                и за свой счет;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) Вывоз товаров должен быть осуществлен
                Продавцом из офиса TST, строго во временной интервал с соблюдением порядка,
                определенного TST в Разделе «Регламент партнера, который продает из офиса TST».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.2. Возврат товаров надлежащего качества</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST возвращает товары надлежащего качества по
                инициативе Продавца в следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) Продавец создает в ЛК Заявку на
                осуществление возврата товара и при наличии технической возможности выбирает
                дату и время вывоза из числа доступных;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) TST готовит товар к возврату к выбранной
                дате либо в течение 21 (двадцати одного) календарного дня с момента создания
                Заявки и направляет Продавцу Уведомление с указанием даты вывоза;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) TST также имеет право вернуть Продавцу
                товары надлежащего качества по своей инициативе, которые не были реализованы
                Продавцом в течение 60 (шестидесяти) календарных дней, с момента размещения
                товара в офисе TST, о чем направляет Уведомление;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (г) Продавец обязан вывезти товары в
                выбранную дату и время либо в течение 14 (четырнадцати) календарных дней с
                момента Уведомления об этом своими силами и за свой счет;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (д) При возврате товара TST оказывает
                Продавцу услуги по комплектации товаров для дальнейшего вывоза Продавцом,
                которые оплачиваются отдельно по стоимости, указанной в Разделе «Комиссии и
                тарифы TST»;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (е) Вывоз товаров должен быть осуществлен
                Продавцом строго в согласованные Сторонами дату и временной интервал с
                соблюдением порядка, определенного TST в Разделе «Регламент партнера, который
                продает из офиса TST», в противном случае TST имеет право утилизировать товар
                либо разместить его обратно на Склад для реализации, а также установить скидки
                на нереализованные товары за счет Продавца исходя из рыночных цен.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.3. Возврат товаров ненадлежащего качества</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST возвращает Продавцу товары, которые были
                возвращены Клиентами по причине ненадлежащего качества, признаны бракованными,
                товары с нарушением требований и иных требований Договора в следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) TST направляет Продавцу Уведомление о
                выявлении такого товара;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Продавец создает в ЛК Заявку на
                осуществление возврата товара и согласовывает дату и время вывоза посредством
                создания обращения в Службу поддержки;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) TST готовит товар к возврату к выбранной
                дате либо в течение 21 (двадцати одного) календарного дня с момента создания
                Заявки и направляет Продавцу Уведомление с указанием даты вывоза;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (г) Продавец обязан вывезти товар в течение
                14 (четырнадцати) календарных дней с момента Уведомления об этом своими силами
                и за свой счет;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (д) Вывоз товаров должен быть осуществлен
                Продавцом из офиса TST, строго во временной интервал с соблюдением порядка,
                определенного TST в Разделе «Регламент партнера, который продает со склада
                TST».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.4. Оформление возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Возврат товаров Продавцу оформляется УПД-2
                Возврат и транспортной накладной. Товары выдаются представителю Продавца только
                при предъявлении им доверенности, удостоверяющей его право на получение товара,
                и документа, удостоверяющего личность. При отсутствии указанных документов TST
                имеет право отказать в передаче товара, при этом ответственность за все
                негативные последствия такого отказа несет Продавец.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.5. Приемка возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Приемка возврата товаров по количеству и
                качеству осуществляется Продавцом в течение 5 (пяти) рабочих дней с даты
                приемки возврата по количеству тарных мест, и, если в течение указанного срока
                Продавец не заявит TST мотивированных возражений по форме, установленной TST,
                товар считается переданным в заявленном в УПД-2 количестве и качестве.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.6. Последствия нарушения сроков вывоза</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если Продавец не вывозит товары в
                установленные Договором сроки, TST имеет право по истечении 14 (четырнадцати)
                календарных дней с согласованной даты вывоза приостановить перечисление
                Продавцу денежных средств за реализованные товары до даты фактического вывоза
                и/или осуществить утилизацию товаров, подлежащих вывозу, в порядке,
                установленном Договором. При этом стоимость утилизированных товаров Продавцу не
                компенсируется.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3. Возврат товара Продавцу с точки приёма TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.1. Возврат отправлений, не соответствующих
                требованиям Договора
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Неидентифицированные отправления, либо
                отправления, не соответствующие требованиям Договора, TST возвращает в
                следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) TST направляет Продавцу Уведомление о
                необходимости вывезти/принять отправления с указанием адреса и доступных
                интервалов вывоза/приемки;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Продавец обязан вывезти отправления в
                течение 7 (семи) календарных дней с момента направления Уведомления своими
                силами и за свой счет, либо принять их, либо поручить TST их утилизировать,
                создав запрос в ЛК;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) Продавец обязан вывезти/принять отправления
                строго во временной интервал с соблюдением определенного TST порядка.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.2. Возврат товаров после их возврата
                Клиентом
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST возвращает Продавцу товары, возвращенные
                Клиентом, в срок не позднее, чем через 60 (шестьдесят) календарных дней с даты приемки
                от Клиента либо истечения срока хранения для выдачи Клиенту, в следующем
                порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) TST направляет Продавцу Уведомление о
                необходимости вывезти/принять товары с указанием адреса и доступных интервалов
                вывоза;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Стороны признают, что упаковка товаров
                может отличаться от той, в которой Продавец передавал товары TST, однако должна
                обеспечивать сохранность товара внутри отправления;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) Продавец обязан вывезти товары в течение
                3 (трех) календарных дней (без учета праздничных нерабочих дней) с момента
                направления Уведомления своими силами и за свой счет, либо принять их, либо
                поручить TST их утилизировать, создав запрос в ЛК;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (г) Продавец обязан вывезти/принять
                отправления строго во временной интервал с соблюдением определенного TST порядка.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.3. Последствия нарушения сроков вывоза или
                приемки
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если Продавец не вывозит/не принимает или не
                поручает TST утилизировать товары в установленные Договором сроки, по истечении
                30 (тридцати) календарных дней с согласованной даты вывоза/приемки TST вправе
                осуществить утилизацию товаров, подлежащих вывозу, в порядке, установленном
                Договором, при этом стоимость утилизированных товаров Продавцу не
                компенсируется.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.4. Оформление возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Возврат отправлений или товаров Продавцу
                оформляется Актом приема-передачи и транспортной накладной. Представитель
                Продавца обязан предъявить доверенность, удостоверяющую право на получение
                товара, и документ, удостоверяющий личность. При отсутствии указанных
                документов TST имеет право отказаться передавать отправления или товары, при
                этом ответственность за все негативные последствия такого отказа несет
                Продавец.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.5. Приемка возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец принимает возвращённые товары по
                количеству и качеству в момент их забора от TST строго во временной интервал
                для вывоза. Если при приемке Продавец не заявит TST мотивированных возражений
                по форме, установленной TST, товар считается переданным в заявленном в
                количестве и качестве согласно Акту приема-передачи.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3.6. Мотивированные возражения</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Стороны согласовали, что в качестве
                мотивированных возражений признают результаты видеофиксации процесса отгрузки
                TST и приемки возврата от TST, вскрытия отправлений, позволяющих
                идентифицировать спорные товары или отправления и фиксировать выявленные
                Продавцом недостатки.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4. Возврат товара Продавцу в случае
                расторжения Договора
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.1. Общий срок</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Стороны обязаны вывезти товар Продавца из
                офиса TST, согласовать Акт сверки и осуществить окончательные расчеты между
                собой не позднее, чем через 40 (сорок) календарных дней с даты направления
                Уведомления о расторжении Договора по любым основаниям. При этом условия
                Договора относительно размещения товаров на складе/сортировочном центре и
                взаиморасчетов его стоимости сохраняют свое действие до даты окончательного
                расчета между ними Сторонами.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.2. Уведомление о вывозе</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST не позднее, чем через 7 (семь) рабочих
                дней после готовности товара к вывозу направляет Продавцу Уведомление о вывозе
                товара.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.3. Срок вывоза</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец не позднее, чем через 7 (семь)
                рабочих дней с даты получения уведомления обязан вывезти товар из офиса TST
                своими силами и за свой счет, либо по согласованию с TST поручить ему
                утилизировать все товары или в части. Продавец осуществляет вывоз товаров
                строго в согласованный с TST временной интервал с соблюдением порядка,
                определенного TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.4. Оформление возврата</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Возврат товаров оформляется УПД-2 Возврат и
                транспортной накладной. Товары выдаются представителю Продавца только при
                предъявлении им доверенности, удостоверяющей его право на получение Товара, и
                документа, удостоверяющего личность. При отсутствии указанных документов TST
                имеет право отказаться передавать товар, при этом ответственность за все
                негативные последствия такого отказа несет Продавец.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.5. Приемка возврата</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец осуществляет приемку возврата товаров
                по количеству в момент забора товаров из офиса TST строго во временной
                интервал, согласованный Сторонами;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                и, если в момент приемки Продавец не заявит
                мотивированных возражений по форме, установленной TST, товар считается
                переданным в заявленном в УПД-2 количестве и качестве.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.6. Акт сверки</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                По результатам приемки возврата товаров
                Стороны подписывают Акт сверки и при необходимости осуществляют окончательные
                расчеты между собой. Если Продавец в течение 3 (трех) рабочих дней с даты
                получения от TST Акта сверки не подписал его и не направил их оригиналы TST, то
                Акт сверки, подписанный TST в одностороннем порядке, считается должным образом
                подписанными Сторонами, а указанные в них положения – должным образом
                согласованными и принятыми Продавцом.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                Раздел «Требования к товару»<br>
                <br>
                1. Общие требования к товару
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.1. Соответствие требованиям Платформы</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Товар должен соответствовать условиям
                Договора, описанию в Карточке товара и иным требованиям TST в ЛК по всем
                параметрам. При несоответствии товара любым таким требованиям, TST вправе
                отказать в приёмке (и все дополнительные расходы в таком случае будет нести
                Продавец) или приостановить исполнение обязательств в отношении такого товара.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.2. Предоставление документов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец обязан в течение 3 (трёх) рабочих
                дней с даты направления запроса предоставлять TST заверенные копии любых
                запрашиваемых документов, подтверждающих соответствие товара требованиям
                законодательства и Договора.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.3. Дополнительные ограничения</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец не праве размещать к реализации на
                Платформе любые товары, которые не соответствуют ограниченным в правах
                (недействительным сертификатам) таймшер.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                <br>
                Раздел «Возврат товара»<br>
                <br>
                1. Условия возврата для Клиентов
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  1.1. Возврат товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Клиент имеет право вернуть товар в
                соответствии с положениями Условий реализации товаров на Платформе TST с учетом
                особенностей, установленных Договором.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.2. Обработка возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST обрабатывает возвраты в порядке,
                установленном Приложениями к Договору.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.3. Расчеты по возвратам</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST перечисляет Клиенту уплаченные им за
                возвращенный товар денежные средства в сроки, установленные законодательством.
                Продавец обязан возместить TST стоимость возвращенного товара, указанную в ЛК
                на момент оформления Клиентом заказа, а TST возвращает Продавцу сумму ранее
                удержанной Комиссии. Взаиморасчеты осуществляются путем удержания в порядке,
                предусмотренным Разделом «Взаиморасчеты».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2. Возврат товара Продавцу из офиса TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.1. Возврат товаров, не принятых при
                приемке
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST имеет право вернуть Продавцу товары по
                причине выявления при приемке, размещении в офисе, излишка, брака и иных
                требований Договора в следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) TST направляет Продавцу на
                согласование Акт о расхождениях (при поставке с особыми условиями) или Акт
                приема (при поставке без особых условий);
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Продавец обязан вывезти товар в
                течение 14 (четырнадцати) календарных дней с момента согласования Акта в ЛК
                своими силами и за свой счет;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) Вывоз товаров должен быть осуществлен
                Продавцом из офиса TST, строго во временной интервал с соблюдением порядка,
                определенного TST в Разделе «Регламент партнера, который продает из офиса TST».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  2.2. Возврат товаров надлежащего качества</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST возвращает товары надлежащего качества по
                инициативе Продавца в следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) Продавец создает в ЛК Заявку на
                осуществление возврата товара и при наличии технической возможности выбирает
                дату и время вывоза из числа доступных;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) TST готовит товар к возврату к
                выбранной дате либо в течение 21 (двадцати одного) календарного дня с момента
                создания Заявки и направляет Продавцу Уведомление с указанием даты вывоза;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) TST также имеет право вернуть
                Продавцу товары надлежащего качества по своей инициативе, которые не были
                реализованы Продавцом в течение 60 (шестидесяти) календарных дней, с момента
                размещения товара в офисе TST, о чем направляет Уведомление;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (г) Продавец обязан вывезти товары в
                выбранную дату и время либо в течение 14 (четырнадцати) календарных дней с
                момента Уведомления об этом своими силами и за свой счет;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (д) При возврате товара TST оказывает
                Продавцу услуги по комплектации товаров для дальнейшего вывоза Продавцом,
                которые оплачиваются отдельно по стоимости, указанной в Разделе «Комиссии и
                тарифы TST»;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (е) Вывоз товаров должен быть осуществлен
                Продавцом строго в согласованные Сторонами дату и временной интервал с
                соблюдением порядка, определенного TST в Разделе «Регламент партнера, который
                продает из офиса TST», в противном случае TST имеет право утилизировать товар
                либо разместить его обратно на Склад для реализации, а также установить скидки
                на нереализованные товары за счет Продавца исходя из рыночных цен.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  2.3. Возврат товаров ненадлежащего качества</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST возвращает Продавцу товары, которые были
                возвращены Клиентами по причине ненадлежащего качества, признаны бракованными,
                товары с нарушением требований и иных требований Договора в следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) TST направляет Продавцу Уведомление о
                выявлении такого товара;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Продавец создает в ЛК Заявку на
                осуществление возврата товара и согласовывает дату и время вывоза посредством
                создания обращения в Службу поддержки;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) TST готовит товар к возврату к
                выбранной дате либо в течение 21 (двадцати одного) календарного дня с момента
                создания Заявки и направляет Продавцу Уведомление с указанием даты вывоза;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (г) Продавец обязан вывезти товар в
                течение 14 (четырнадцати) календарных дней с момента Уведомления об этом своими
                силами и за свой счет;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (д) Вывоз товаров должен быть осуществлен
                Продавцом из офиса TST, строго во временной интервал с соблюдением порядка,
                определенного TST в Разделе «Регламент партнера, который продает со склада
                TST».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  2.4. Оформление возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Возврат товаров Продавцу оформляется УПД-2
                Возврат и транспортной накладной. Товары выдаются представителю Продавца только
                при предъявлении им доверенности, удостоверяющей его право на получение товара,
                и документа, удостоверяющего личность. При отсутствии указанных документов TST
                имеет право отказать в передаче товара, при этом ответственность за все
                негативные последствия такого отказа несет Продавец.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  2.5. Приемка возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Приемка возврата товаров по количеству и
                качеству осуществляется Продавцом в течение 5 (пяти) рабочих дней с даты
                приемки возврата по количеству тарных мест, и, если в течение указанного срока
                Продавец не заявит TST мотивированных возражений по форме, установленной TST,
                товар считается переданным в заявленном в УПД-2 количестве и качестве.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  2.6. Последствия нарушения сроков вывоза</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если Продавец не вывозит товары в
                установленные Договором сроки, TST имеет право по истечении 14 (четырнадцати)
                календарных дней с согласованной даты вывоза приостановить перечисление
                Продавцу денежных средств за реализованные товары до даты фактического вывоза
                и/или осуществить утилизацию товаров, подлежащих вывозу, в порядке,
                установленном Договором. При этом стоимость утилизированных товаров Продавцу не
                компенсируется.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>3. Возврат товара Продавцу с точки приёма TST</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.1. Возврат отправлений, не соответствующих
                требованиям Договора
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Неидентифицированные отправления, либо
                отправления, не соответствующие требованиям Договора, TST возвращает в
                следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) TST направляет Продавцу Уведомление о
                необходимости вывезти/принять отправления с указанием адреса и доступных
                интервалов вывоза/приемки;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Продавец обязан вывезти отправления в
                течение 7 (семи) календарных дней с момента направления Уведомления своими
                силами и за свой счет, либо принять их, либо поручить TST их утилизировать,
                создав запрос в ЛК;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) Продавец обязан вывезти/принять
                отправления строго во временной интервал с соблюдением определенного TST
                порядка.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.2. Возврат товаров после их возврата
                Клиентом
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST возвращает Продавцу товары, возвращенные
                Клиентом, в срок не позднее, чем через 60 (шестьдесят) календарных дней с даты
                приемки от Клиента либо истечения срока хранения для выдачи Клиенту, в
                следующем порядке:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) TST направляет Продавцу Уведомление о
                необходимости вывезти/принять товары с указанием адреса и доступных интервалов
                вывоза;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Стороны признают, что упаковка
                товаров может отличаться от той, в которой Продавец передавал товары TST,
                однако должна обеспечивать сохранность товара внутри отправления;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) Продавец обязан вывезти товары в
                течение 3 (трех) календарных дней (без учета праздничных нерабочих дней) с
                момента направления Уведомления своими силами и за свой счет, либо принять их,
                либо поручить TST их утилизировать, создав запрос в ЛК;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (г) Продавец обязан вывезти/принять
                отправления строго во временной интервал с соблюдением определенного TST
                порядка.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.3. Последствия нарушения сроков вывоза или
                приемки
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если Продавец не вывозит/не принимает или не
                поручает TST утилизировать товары в установленные Договором сроки, по истечении
                30 (тридцати) календарных дней с согласованной даты вывоза/приемки TST вправе
                осуществить утилизацию товаров, подлежащих вывозу, в порядке, установленном
                Договором, при этом стоимость утилизированных товаров Продавцу не
                компенсируется.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  3.4. Оформление возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Возврат отправлений или товаров Продавцу
                оформляется Актом приема-передачи и транспортной накладной. Представитель
                Продавца обязан предъявить доверенность, удостоверяющую право на получение
                товара, и документ, удостоверяющий личность. При отсутствии указанных
                документов TST имеет право отказаться передавать отправления или товары, при
                этом ответственность за все негативные последствия такого отказа несет
                Продавец.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  3.5. Приемка возвратов</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец принимает возвращённые товары по
                количеству и качеству в момент их забора от TST строго во временной интервал
                для вывоза. Если при приемке Продавец не заявит TST мотивированных возражений
                по форме, установленной TST, товар считается переданным в заявленном в
                количестве и качестве согласно Акту приема-передачи.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  3.6. Мотивированные возражения</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Стороны согласовали, что в качестве
                мотивированных возражений признают результаты видеофиксации процесса отгрузки
                TST и приемки возврата от TST, вскрытия отправлений, позволяющих
                идентифицировать спорные товары или отправления и фиксировать выявленные
                Продавцом недостатки.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4. Возврат товара Продавцу в случае
                расторжения Договора
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  4.1. Общий срок</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Стороны обязаны вывезти товар Продавца из
                офиса TST, согласовать Акт сверки и осуществить окончательные расчеты между
                собой не позднее, чем через 40 (сорок) календарных дней с даты направления
                Уведомления о расторжении Договора по любым основаниям. При этом условия
                Договора относительно размещения товаров на складе/сортировочном центре и
                взаиморасчетов его стоимости сохраняют свое действие до даты окончательного
                расчета между ними Сторонами.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  4.2. Уведомление о вывозе</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST не позднее, чем через 7 (семь) рабочих
                дней после готовности товара к вывозу направляет Продавцу Уведомление о вывозе
                товара.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  4.3. Срок вывоза</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец не позднее, чем через 7 (семь)
                рабочих дней с даты получения уведомления обязан вывезти товар из офиса TST
                своими силами и за свой счет, либо по согласованию с TST поручить ему
                утилизировать все товары или в части. Продавец осуществляет вывоз товаров
                строго в согласованный с TST временной интервал с соблюдением порядка,
                определенного TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  4.4. Оформление возврата</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Возврат товаров оформляется УПД-2 Возврат и
                транспортной накладной. Товары выдаются представителю Продавца только при
                предъявлении им доверенности, удостоверяющей его право на получение Товара, и
                документа, удостоверяющего личность. При отсутствии указанных документов TST
                имеет право отказаться передавать товар, при этом ответственность за все
                негативные последствия такого отказа несет Продавец.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  4.5. Приемка возврата</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец осуществляет приемку возврата товаров
                по количеству в момент забора товаров из офиса TST строго во временной
                интервал, согласованный Сторонами;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                и, если в момент приемки Продавец не заявит
                мотивированных возражений по форме, установленной TST, товар считается
                переданным в заявленном в УПД-2 количестве и качестве.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>  4.6. Акт сверки</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                По результатам приемки возврата товаров
                Стороны подписывают Акт сверки и при необходимости осуществляют окончательные
                расчеты между собой. Если Продавец в течение 3 (трех) рабочих дней с даты
                получения от TST Акта сверки не подписал его и не направил их оригиналы TST, то
                Акт сверки, подписанный TST в одностороннем порядке, считается должным образом
                подписанными Сторонами, а указанные в них положения – должным образом
                согласованными и принятыми Продавцом.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                _________________________________________________________________________<br>
                <br>
              </span><b>
                <span lang=ru style='font-size:14.0pt;line-height:115%'>
                  УСЛОВИЯ
                  РЕЛИЗАЦИИ ТОВАРОВ ДЛЯ ФИЗИЧЕСКИХ ЛИЦ
                </span>
              </b>
            </p>

            <p class=MsoNormal>
              <b>
                <span lang=ru style='font-size:14.0pt;line-height:115%'>
                  НА
                  ПЛАТФОРМЕ TST-TRADE.RU
                </span>
              </b>
            </p>

            <p class=MsoNormal><b><span lang=ru style='font-size:14.0pt;line-height:115%'>&nbsp;</span></b></p>

            <p class=MsoNormal>
              <span lang=ru>
                Проходя процедуру Верификации и оформляя
                Заказы на TST-TRADE.RU, Клиент соглашается с Условиями реализации Товаров для
                физических лиц (Условия), изложенными ниже.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Условия регулируют отношения между Клиентом
                (вами), ООО «TST» (TST) и Продавцами, возникающие в связи с оформлением и
                выполнением Заказов на tst-trade.ru. Пожалуйста, внимательно прочитайте Условия до
                оформления Заказа. Если вы не согласны с какими-либо положениями Условий, вы
                можете отказаться от оформления Заказа.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В Условиях есть понятия, которые указаны с
                большой буквы. Их определения вы найдёте в конце Условий.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Условия содержат пункты с активными
                гиперссылками на страницы Сайта, которые являются неотъемлемой частью Условий.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Условия вступают в силу с момента их
                опубликования на Сайте и действуют бессрочно.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST может изменить Условия в любой момент в
                одностороннем порядке по своему усмотрению без специального уведомления.
                Изменения вступают в силу с момента размещения обновленной редакции на Сайте в
                разделе «Правила реализации и реквизиты».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>   1. Как оформить заказ</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>   2. Оплата</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>   3. Доставка</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>   4. Особенности реализации товаров</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>   5. Возврат товара</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>   6. Прочие условия</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>    7. Информация о TST и Продавцах</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Понятия<br>
                <br>
                1. Как оформить заказ
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.1. Верификация по номеру телефона</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.1.1. Для регистрации на Сайте и/или
                оформления Заказа Клиенту необходимо пройти процедуру верификации:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) ввести номер телефона и нажать кнопку
                «отправить код»;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) ввести в поле индивидуальный код,
                направленный владельцу посредством смс-сообщений / пуш-уведомлений / иным
                образом. Индивидуальный код является простой электронной подписью, которой
                Клиент подтверждает принадлежность ему указанного номера телефона и его
                согласие с настоящими Условиями. Индивидуальный код действует в течение 15
                минут с его отправки. Повторная попытка запросить код возможна через 120 секунд
                с момента отправки предыдущего кода. На один номер телефона в рамках одной
                пользовательской сессии можно отправить не более 5 кодов верификации (затем
                происходит блокировка на 30 минут). На один номер телефона в рамках одной
                пользовательской сессии можно совершить не более 19 неуспешных попыток ввода
                кода верификации (затем происходит блокировка на 30 минут). Одновременно для
                верификации одного номера телефона может быть только один валидный код.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Если код совпал, то верификация успешно
                пройдена: установлена принадлежность номера телефона определённому физическому
                лицу, чьи данные содержатся в Аккаунте и/или в Заказе («Ваши данные» — «Имя и
                фамилия»). Если у Клиента уже есть Аккаунт на Сайте, происходит автоматический
                вход в этот Аккаунт, а если нет — Клиент может ввести адрес электронной почты и
                пароль от существующего Аккаунта, либо создать новый Аккаунт.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.1.2. Если у Клиента изменился номер
                телефона, он может его изменить в Аккаунте, нажав на кнопку «Изменился номер
                телефона?» и последовав инструкциям.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.1.3. Если Клиент использовал один номер
                телефона для разных Аккаунтов, то при первичной верификации он вправе сделать
                один Аккаунт основным. В основном Аккаунте будут в совокупности отображаться
                сведения о состоянии Балансов всех Аккаунтов Клиента.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.1.4. Клиент может зайти в Аккаунт через
                логин (адрес электронной почты) и пароль от Аккаунта (поле «Войти по e-mail»)
                (однако для оформления Заказов надо пройти процедуру верификации).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.1.5. Клиент обязуется не сообщать третьим
                лицам логин и пароль, номер телефона и индивидуальные коды. В случае
                возникновения у Клиента подозрений относительно безопасности его данных, их
                несанкционированного использования третьими лицами, Клиент обязан
                незамедлительно уведомить об этом TST по адресу help@tst-trade.ru.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.1.6. Проходя Верификацию, Клиент
                соглашается с получением сообщений сервисного характера одновременно или по
                отдельности на адрес электронной почты, указанный при регистрации / посредством
                смс-сообщений / пуш-уведомлений / мессенджеров / иным образом на номер
                телефона, указанный Клиентом при регистрации и/или оформлении Заказа, о
                состоянии Заказа, условиях доставки, Товарах в корзине Клиента и/или
                добавленных Клиентом в «Избранное», а также с просьбой оставить отзыв. Отказ
                Клиента от получения указанных сообщений невозможен по техническим причинам.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.1.7. TST вправе направлять Клиенту
                сообщения рекламного характера (в форме СМС и/или пуш-уведомлений и/или и/или
                мессенджеров для смартфонов и/или телефонных звонков и/или иным образом на
                указанный Клиентом номер телефона, адрес электронной почты) при условии
                получения согласия способом, соответствующим требованиям законодательства, либо
                при условии, что Пользователь не отказался от получения сообщений рекламного
                характера. Клиент вправе отказаться получать сообщения рекламного характера
                посредством изменения настроек подписки в соответствующем разделе Личного
                кабинета в любое время.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.1.8. Клиент соглашается с тем, что к его
                Аккаунту могут быть применены любые ограничения (в том числе блокировка),
                связанные с безопасностью или нарушением Клиентом настоящих Условий, а также по
                причинам технического характера. Кроме того, принимая настоящие Условия, Клиент
                соглашается с тем, что ограничения на его Аккаунте могут быть применены в любое
                время без объяснения причин и без уведомления Клиента.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.2. Заключение договора</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.2.1. Настоящие Условия, а также информация
                о Товаре, представленная на Сайте, являются публичной офертой в соответствии со
                ст. 435 и ч. 2 ст. 437 ГК РФ.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.2.2. Клиент соглашается с условиями
                реализации выбранных Товаров нажатием кнопки «Оплатить онлайн» на последнем
                этапе оформления Заказа на Сайте. Совершение указанных действий является
                фактом, подтверждающим заключение договора между Клиентом и Продавцом. В случае
                оформления Заказа на условиях предоплаты (аванса в счет будущей сделки) договор
                считается заключённым, если в течение 24 часов после осуществления предоплаты
                Клиент не получил уведомление о невозможности передать Товар Клиенту.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.2.3. Правилами акций, конкурсов, иных
                стимулирующих мероприятий может быть установлен иной порядок оформления Заказа
                и возврата Товара. Факт участия в подобном мероприятии означает согласие
                Клиента с таким порядком.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.3. Оформление Заказа</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.3.1. Клиент самостоятельно оформляет Заказ
                на Сайте.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.3.2. Клиент выбирает на странице Товара
                предложение одного из Продавцов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.3.3. Информационные материалы о Товарах
                носят справочный характер и не могут в полной мере передавать достоверную
                информацию о свойствах и характеристиках Товара. В случае возникновения
                вопросов о свойствах и характеристиках Товара перед оформлением Заказа Клиент
                должен обратиться к TST или Продавцу.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.3.4. Клиент указывает следующую
                информацию:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>     (а) Ф.И.О. и номер телефона Клиента;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>     (б) адрес доставки Заказа.</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.3.5. Ожидаемая дата доставки Заказа —
                срок, после наступления которого Клиент может получить Заказ. Дата доставки
                зависит от наличия Товаров на Складе Продавца, времени, необходимого на
                обработку Заказа и сроков доставки выбранной Службы Доставки, и напрямую не
                зависит от TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.3.7. Информация о текущем статусе Заказа
                находится в Личном кабинете.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>1.4. Аннуляция Заказа</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.4.1. Заказ может аннулировать Продавец,
                TST или Клиент.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.4.2. Продавец или TST имеет право
                аннулировать Заказ с уведомлением Клиента, если исполнение Заказа со стороны
                Продавца, TST стало невозможным по причинам, не зависящим от воли Клиента,
                Продавца, TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Клиент вправе согласовать новые условия
                доставки Товаров путём оформления нового Заказа, если на момент оформления Заказа
                Товар находится под реализацией. Клиент при отсутствии товара у Продавца может
                согласовать с ним новый срок доставки, но в случае отсутствия товара у Продавца
                в новый срок Заказ будет отменён. Если на момент оформления Заказа цена Товара
                увеличилась, а предыдущий Заказ был аннулирован не по причине брака,
                компенсация разницы в цене на день оформления нового Заказа не производится.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.4.3. TST вправе аннулировать Заказы
                Клиента, содержащие Товары, от которых ранее Клиент отказался 5 и более раз,
                указав причины, не связанные с наличием недостатков в этих Товарах.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.4.4. Клиент не вправе отказаться от
                Товара, т.к. он обладает индивидуально-определёнными свойствами и после его
                оплаты может быть использован только Клиентом.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                1.4.5. Условия возврата денежных средств за
                Товар и доставку при полной или частичной аннуляции Заказа до момента его
                получения или в момент передачи Клиенту:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) денежные средства после возврата
                автоматически отражаются на Балансе Клиента. Подробная информация о порядке
                возврата денежных средств на Балансе, доступна по ссылке. Денежные средства на
                Балансе могут быть перечислены исключительно на реквизиты российского
                банковского счёта;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) если Заказ был оплачен банковской
                картой, возврат денежных средств осуществляется на карту, с которой
                производилась оплата.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                <br>
                2. Оплата<br>
                <br>
                2.1. Цена Товара и сроки оплаты
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.1.1. Цена Товара указывается на Сайте и
                может быть изменена Продавцом в одностороннем порядке. Товар в Заказе
                оплачивается по цене, указанной на момент нажатия кнопки «Оплатить онлайн» при
                оформлении Заказа. Цена на заказанный Клиентом Товар изменению не подлежит.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.1.2. Согласованным способом оплаты
                считается способ, выбранный Клиентом из числа доступных при оформлении Заказа.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.1.3. Срок на оплату — 10 минут с момента
                оформления Заказа при способе оплаты банковская карта, Аррlе Рау, Google Рау и
                15 минут при иных способах оплаты, после чего Продавец не гарантирует наличие
                Товара. Договор в этом случае считается не заключённым.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.1.4. TST направляет авансовый чек в
                электронной форме (либо ссылку на него с возможностью скачивания в формате PDF)
                на номер телефона или адрес электронной почты Клиента в случае успешного
                осуществления предоплаты с использованием способа оплаты «Картой онлайн». Чеки
                хранятся в разделе «Электронные чеки» и в печатной форме не предоставляются.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.1.5. Виды скидок, баллов, порядок и
                условия их начисления указаны на Сайте и могут быть изменены TST в
                одностороннем порядке.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.1.6. На Сайте по техническим причинам
                возможно возникновение ошибок при указании цены Товара (включая Товары, на
                которые распространяется скидка или акция). В случае возникновения такой ошибки
                в отношении Товара, заказанного Клиентом, TST информирует об этом Клиента. При
                возникновении сомнений в отношении цены Товара, Клиент может уточнить
                информацию по адресу help@tst-trade.ru, либо через чат с TST или Продавцом в Личном
                кабинете.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.2. Оплата с помощью банковских карт, а также
                электронных средств платежа с привязкой к банковским картам
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.2.1. В соответствии с положением ЦБ РФ «Об
                эмиссии банковских карт и об операциях, совершаемых с использованием платежных
                карт» от 24.12.2004 г. № 266-П операции по банковским картам совершаются
                держателем карты либо лицом, уполномоченным на основании доверенности,
                оформленной в соответствии с законодательством.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.2.2. Авторизация операций по банковским
                картам осуществляется банком. Если у банка есть основания полагать, что
                операция носит мошеннический характер, то банк вправе отказать в осуществлении
                данной операции. Мошеннические операции с банковскими картами попадают под
                действие статьи 159 УК РФ и наказываются в соответствии с уголовным
                законодательством. Во избежание случаев неправомерного использования банковских
                карт при оплате, все предоплаченные Заказы проверяются TST. TST оставляет за
                собой право без объяснения причины аннулировать Заказ. Стоимость Заказа
                возвращается на банковскую карту владельца.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.2.3. Приём и обработка платежей с
                использованием банковских карт проводится провайдером электронных платежей
                ASSIST, либо иным провайдером электронных платежей. TST и Продавец не
                осуществляют обработку, в том числе сбор и хранение данных банковских карт
                Клиентов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.2.4. Клиент может привязать банковскую
                карту к личному кабинету в соответствии с Условиями привязки карты на сайте
                TST-TRADE.RU.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>2.3. Оплата через Систему быстрых платежей</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.3.1. Система быстрых платежей (СБП) —
                технологический сервис, позволяющий физическим лицам мгновенно оплачивать
                Товары на Сайте (в режиме 24/7). Обслуживающий банк Клиента должен быть
                подключен к СБП. Авторизация операций через СБП осуществляется банком Клиента.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.3.2. СБП представляет собой оплату по
                QR-коду в мобильном приложении банка Клиента: при оплате Товаров Клиент
                использует функцию мобильного приложения банка Клиента «оплата по QR-коду» и
                сканирует QR-код, отображаемый на Сайте после выбора способа оплаты «Система
                быстрых платежей». Совершая эти действия, Клиент подтверждает проведение оплаты
                Заказа.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.3.3. QR-код для оплаты в пользу TST на
                данный момент доступен только на Сайте www.tst-trade.ru. Клиенту не следует
                сканировать QR-коды в целях оплаты Товаров TST на сторонних ресурсах,
                принадлежащим третьим лицам. TST не несёт ответственность за негативные последствия,
                связанные с оплатой посредством QR-кодов на сторонних ресурсах.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.3.4. СБП разработана и регулируется Банком
                России (https://www.cbr.ru/) и НСПК (https://www.nspk.ru/). Функционирование
                СБП как способа оплаты зависит от участников СБП, в связи с чем TST не несёт
                ответственности за возможные сбои при оплате с помощью СБП. В случае сбоев
                Клиент должен обратиться в службу поддержки банка Клиента.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4. Оплата с использованием Подарочного
                сертификата TST
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4.1. Подарочный сертификат предоставляет
                его обладателю (Держателю) право требовать передачи любого Товара (за
                исключением Товаров из разделов «Электронные подарочные сертификаты») на сумму,
                равную номиналу Подарочного сертификата и по ценам, действующим на момент
                оформления Заказа.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4.2. Срок действия Подарочного сертификата
                определяется TST и доводится до сведения Клиента на странице описания
                Подарочного сертификата (в любом случае срок действия Подарочного сертификата
                не может быть менее одного месяца и более одного года с даты его оплаты). В
                течение срока действия Подарочного сертификата Держателю необходимо
                активировать его на странице «Подарочные сертификаты».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4.3. После активации Подарочного
                сертификата его номинал отображается на Балансе и доступен для использования в
                течение 3 лет.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4.4. Не совершение действий, направленных
                на активацию Подарочного сертификата, в течение срока его действия, признается
                отказом Держателя от своих прав и не влечёт обязанности TST произвести обмен
                Подарочного сертификата или возвратить Клиенту уплаченные за Подарочный
                сертификат денежные средства.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4.5. Использование Подарочного сертификата
                предполагает оформление неограниченного числа Заказов в пределах номинала.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4.6. Держатель вправе активировать не
                более десяти Подарочных сертификатов в течение 24 часов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4.7. TST не несёт ответственность за
                несанкционированное использование Подарочного сертификата, поскольку он
                является предъявительским и не требует удостоверения личности Держателя.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                2.4.8. В случае аннулирования Заказа, для оформления
                которого использовался Подарочный сертификат, денежные средства отражаются на
                Пользовательском счете Держателя и могут быть использованы только для
                приобретения товаров и услуг на Сайте.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                <br>
                3. Доставка<br>
                <br>
                3.1. Доставку осуществляет TST или Продавец своими силами, либо привлеченная
                ими Служба доставки. Возможность доставки по адресу получателя определяется в
                момент оформления Заказа. Согласованным способом доставки считается способ,
                выбранный Клиентом из числа доступных при оформлении Заказа.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.2. Территория доставки Товаров ограничена.
                Перечень товаров, доставка которых ограничена, содержится в статьях «Доставка
                за пределы России», «Курьерская доставка TST».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.3. Стоимость доставки каждого Заказа
                рассчитывается индивидуально, исходя из его веса, региона и способа доставки и
                способа оплаты, и указывается на последнем этапе оформления Заказа на Сайте.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.4. Сроки, отведённые для получения Заказа
                Клиентом, ограничены и указываются при оформлении Заказа, на этапе выбора
                соответствующего способа доставки, а также в разделе Доставка (срока хранения).
                Неполучение Заказа в течение срока хранения считается отказом Клиента от
                договора уступки требования (цессии) и является основанием для аннуляции
                Заказа.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.5. При передаче Заказа Клиент в присутствии
                лица, осуществляющего доставку Заказа, должен проверить количество Товаров,
                комплектность, ассортимент. Претензии к Товару по комплектности, ассортименту,
                количеству не принимаются после передачи Заказа всеми способами доставки.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.6. При передаче Заказа лицо, осуществляющее
                доставку Заказа, вправе потребовать предъявления документа, удостоверяющего
                личность получателя, а получатель обязан предоставить документ, удостоверяющий
                личность, во избежание случаев мошенничества, а также для выполнения взятых на
                себя обязательств при вручении предоплаченного Заказа;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                В случае отсутствия документа, удостоверяющего
                личность, или несоответствия возраста получателя Товар получателю не
                передается, но услуга по доставке будет считаться оказанной.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.7. Переход риска случайной гибели или
                случайного повреждения Товара.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.7.1. Риск случайной гибели или случайного
                повреждения Товара переходит к получателю с момента передачи ему Заказа. Если
                до указанного момента Товар будет утрачен, Клиенту возмещается стоимость
                предоплаченного Товара и его доставки после получения подтверждения утраты
                Товара от Продавца или Службы доставки.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.7.2. В случае выбора Клиентом в качестве
                Службы доставки международных почтовых отправлений Почту России риск случайной
                гибели или случайного повреждения Товара лежит на Продавце в полном объёме до
                передачи Товара в Службу доставки. После передачи Товара в указанную Службу
                доставки ответственность Продавца определяется в пределах, установленных
                Всемирной почтовой конвенцией от 12 августа 2008 года.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.7.3. В случае выбора Клиентом доставки
                посредством нерегистрируемых международных почтовых отправлений риск случайной
                гибели или случайного повреждения Товара лежит на Продавце в полном объёме до
                передачи в Службу доставки. После передачи Товара в Службу доставки риск
                случайной гибели или случайного повреждения Товара лежит на Клиенте, поскольку
                по нерегистрируемым отправлениям невозможно получить подтверждение утраты
                Товара от Службы доставки. Клиент может выбрать другие способы доставки
                международных почтовых отправлений без указанных ограничений.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                3.8. При проведении маркетинговых мероприятий,
                предполагающих вложение каких-либо объектов в Заказ, доставка указанных
                вложений осуществляется вместе с Товаром. Чтобы отказаться от вложения, Клиенту
                необходимо обратиться в Службу поддержки.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                <br>
                4. Особенности реализации товаров<br>
                <br>
                4.1. Приобретение Товаров происходит в соответствии со следующими условиями:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) TST выступает в качестве агента
                Продавца, действуя от имени и по поручению Продавца, а права и обязанности по
                договору уступки требования (цессии) возникают исключительно у Продавца;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) Договор уступки требования (цессии) на
                товар заключается непосредственно между Продавцом и Клиентом по форме,
                доступной для ознакомления в процессе оформления Заказа, и действует с момента
                оформления вами Заказа;
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (в) Продавец обязуется передать подписанный
                со своей стороны договор в бумажном виде в двух экземплярах Клиенту в момент
                передачи ему Заказанного товара, а Клиент обязуется подписать договор и
                передать один экземпляр Продавцу.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.2. Продавец вправе отказать в возврате
                денежных средств за Товары в случае, если в Уведомление клуба о переходе права
                по Договору (Приложение № 1 к договору уступки требования (цессии) внесена
                отметка о «Новом владельце таймшер» на имя Клиента и Продавец поставил свою
                подпись на указанном Договору, в связи с тем, что Товар приобрел
                индивидуально-определенные свойства и может быть использован исключительно
                лицом, в чью пользу совершен переход права.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>4.3. Подарочные сертификаты</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                4.3.1. При оформлении Заказа на Подарочные
                сертификаты применяются Условия использования Подарочного сертификата.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                5. Возврат товара<br>
                <br>
                5.1. Возврат или обмен товара надлежащего качества
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Не подлежат обмену и возврату Товары
                надлежащего качества, если в Уведомление клуба о переходе права по Договору
                (Приложение № 1 к договору уступки требования (цессии) внесена отметка о «Новом
                владельце таймшер» на имя Клиента и Продавец поставил свою подпись на указанном
                Договору, в связи с тем, что Товар приобрел индивидуально-определенные свойства
                и может быть использован исключительно лицом, в чью пользу совершен переход
                права
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>5.2. Возврат товара ненадлежащего качества</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.2.1. При возврате Клиентом Товара
                ненадлежащего качества Продавец возвращает ему стоимость возвращенного Товара,
                а также расходы на доставку от Клиента возвращенного Товара в срок не позднее
                чем через 10 дней с даты получения Продавцом Товара ненадлежащего качества, при
                условии, что недостаток Товара возник не по вине Клиента. В случае если в
                Заказе Клиента присутствуют товары надлежащего качества, стоимость доставки не
                компенсируется.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.2.2. При обнаружении брака Клиент
                возвращает Товар Продавцу или TST. Товар должен быть возвращён вместе с
                оригиналами документов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.3. Нарушение условий об ассортименте
                (пересорт)
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.3.1. В случае передачи Товара с нарушением
                условий об ассортименте не применяются правила ст. 468 ГК РФ. В случае
                обнаружения пересорта Клиент вправе:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (а) отказаться от лишнего Товара и
                потребовать замены на Товар в ассортименте, предусмотренном Заказом, либо
                возврата уплаченных денежных средств за фактически непереданный Товар. При этом
                Товар подлежит возврату TST или Продавцу (при доставке силами Продавца).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                (б) принять Товар, а TST вправе
                потребовать от Клиента его оплаты по цене, установленной Продавцом на Сайте на
                момент передачи Товара (в том числе в судебном порядке). Если фактически
                переданный Товар отсутствует в ассортименте, представленном на Сайте на момент
                передачи Товара, данный Товар оплачивается по цене, согласованной с TST.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>5.4. Нарушение условий о количестве</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.4.1. Если при передаче Заказа Клиентом
                обнаружены расхождения по количеству Товара в Заказе, Клиент обязан
                незамедлительно проинформировать об этом TST или Продавца. В случае нарушения
                Клиентом условия об информировании TST или Продавца, они вправе отказать
                Клиенту в удовлетворении его претензий по количеству переданного Товара.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.4.2. Если Клиент получил меньшее
                количество Товара, чем определено Заказом (недовложение), Клиент при передаче
                Заказа вправе принять Товар в части, соответствующей Заказу, и потребовать
                передать недостающее количество Товара, либо, если недостающий Товар был
                оплачен, отказаться от Заказа в части недостающего Товара и потребовать
                возврата денежных средств за недостающий Товар.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.4.3. Передача недостающего Товара
                осуществляется посредством оформления нового Заказа. Если цена Товара
                увеличилась, перед оформлением нового Заказа необходимо связаться с TST или
                Продавцом. Если недостающий Товар не был ранее оплачен Клиентом, либо его
                стоимость была возвращена на банковскую карту, Товар может быть оплачен любым
                доступным способом при оформлении нового Заказа. В случае невозможности
                передать недостающий Товар, TST или Продавец уведомляет об этом Клиента.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>5.5. Исполнение требований Клиента</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.5.1. Замена Товара ненадлежащего качества
                осуществляется путем возврата Товара в TST с последующей аннуляцией Заказа или
                Товара, затем оформления нового Заказа. При этом аннуляция является техническим
                действием и не подразумевает отказ Продавца от исполнения договора. Денежные
                средства возвращаются Клиенту в полном объеме. Если на момент обращения Клиента
                аналогичный Товар отсутствует под реализацией у Продавца, Клиент вправе
                отказаться от исполнения договора уступки требования (цессии) и потребовать
                возврата уплаченной за указанный Товар денежной суммы. Продавец возвращает
                денежную сумму за возвращенный Товар в установленный законом срок.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>5.6. Возврат денежных средств</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.6.1. Возврат денежных средств за
                возвращённые или фактически не переданные Товары осуществляется TST одним из
                способов зависимости от способа оплаты Заказа.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.6.2. Денежные средства, отраженные на
                Балансе Клиента при активации Подарочных сертификатов, не подлежат возврату
                Клиенту и могут быть использованы только для оплаты Товаров на Сайте.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                5.6.3. Если в течение последних трёх лет на
                Балансе не отображалось движение денежных средств (списание или зачисление),
                средства могут быть аннулированы. TST вправе корректировать размер денежных
                средств в случае их ошибочного отображения на Балансе, без получения
                предварительного согласия Клиента.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                6. Прочие условия<br>
                <br>
                6.1. Интеллектуальная собственность
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.1.1. Вся текстовая информация и
                графические изображения, находящиеся на Сайте, являются собственностью TST,
                Продавцов и/или их контрагентов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>6.2. Гарантии и ответственность</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.2.1. TST и Продавец не несут
                ответственности за ущерб, причинённый Клиенту вследствие ненадлежащего
                использования Товаров, Заказанных на Сайте.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.2.2. TST не несёт ответственность за
                содержание и функционирование Внешних сайтов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.2.3. TST вправе переуступать либо
                каким-либо иным способом передавать свои права и обязанности, вытекающие из его
                отношений с Клиентом, третьим лицам.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.2.4. TST не несёт ответственность за
                убытки, которые Клиент может понести в результате того, что его номер телефона
                перешел в собственность другого лица, в том числе в результате длительного (от
                3х месяцев) неиспользования такого номера телефона Клиентом.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.2.5. TST не несёт ответственность за
                точность и правильность информации, предоставляемой Клиентом при Верификации.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.3. Конфиденциальность и защита персональной
                информации
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.3.1. Подробная информация о
                конфиденциальности и защите персональной информации представлена в разделе
                «Политика конфиденциальности».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>6.4. Коммуникация и иные условия</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.4.1. TST или Продавец направляют Клиенту
                уведомления и сообщения по электронному адресу, указанному при регистрации,
                и/или в личный кабинет в разделы «Уведомления» или «Сообщения».
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.4.2. В случае возникновения вопросов
                Клиент должен обратиться в Контактный Центр TST или Службу по работе с
                Клиентами Продавца по телефону, по адресу email@tst-trade.ru, либо через чат с TST
                или Продавцом в Личном кабинете. При возникновении претензии Клиент должен
                направить её почтой России заказным письмом с уведомлением о вручении по
                адресу: 000000, г. Москва, Ленинская набережная, дом 45, корпус 2, помещение 5.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.4.3. Ответы TST или Продавца на обращения
                и претензии Клиента признаются направленными в надлежащей форме в случае их
                отправки в Личный кабинет, либо на электронный адрес Клиента, указанный им при
                регистрации, либо в письменной форме на почтовый адрес Клиента (при наличии
                такого распоряжения от Клиента).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.4.4. Все возникающее споры стороны будут
                решать путём переговоров, при недостижении соглашения спор будет передан на
                рассмотрение в судебный орган в соответствии с действующим законодательством
                РФ.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                6.4.5. Признание судом недействительности
                какого-либо положения Условий и правил не влечёт за собой недействительность
                остальных положений.
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                <br>
                7. Информация об TST и Продавцах<br>
                <br>
                7.1. Реквизиты TST размещены здесь [подготовить ссылку на страницу на сайте
                tst-trade.ru с реквизитами компании].
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                7.2. Информация о Продавцах, которую TST
                обязан предоставить в соответствии с требованиями законодательства о защите
                прав потребителей, размещена на Сайте рядом с наименованием Продавца под знаком
                «i».
              </span>
            </p>

            <p class=MsoNormal>
              <span lang=ru>
                <br>
                <br>
                Понятия<br>
                <br>
                В Условиях используются следующие значения слов:
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Баланс средств (Баланс) — раздел Личного
                кабинета Клиента, в котором отражается информация о размере денежных средств,
                внесённых Клиентом при активации Подарочного сертификата, и/или о размере
                денежных средств, которые подлежат возврату Клиенту в случае аннулирования
                ранее оплаченного им Заказа или Товара, а также в иных аналогичных случаях.
                Размер денежных средств, отображаемый на Балансе подлежит использованию в
                качестве оплаты за последующие Заказы Клиента либо возврату Клиенту в порядке,
                указанном по ссылке. Баланс не является банковским счетом и предназначен
                исключительно для целей информирования Клиента.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Верификация — процедура, позволяющая
                установить реальность и принадлежность определенного номера телефона
                конкретному физическому лицу, в результате которой Клиенту присваивается
                определенный ID и личный кабинет, привязанный к верифицированному номеру
                телефона.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Заказ — запрос Клиента на приобретение Товара
                и его доставку по указанному адресу выбранным клиентом способом.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Клиент — полностью дееспособное физическое
                лицо, размещающее Заказ на Сайте, либо указанное в качестве получателя Товара
                или Сертификата на услугу, использующее Товары, исключительно для личных,
                семейных, домашних и иных нужд, не связанных с осуществлением
                предпринимательской деятельности.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Личный кабинет (Аккаунт) — персональная
                страница Клиента на Сайте, содержащая информацию о Заказах.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                TST — Общество с ограниченной ответственностью
                «TST» (ОГРН 00000000, юридический адрес: Россия, 000000, г. Москва, Ленинская
                набережная, дом 45, корпус 2, помещение 5), являющееся владельцем Сайта,
                Продавцом либо действующее в отношениях с Клиентом по поручению других
                Продавцов.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Продавец — ООО «TST», либо иное юридическое
                лицо или индивидуальный предприниматель, указанное в качестве Продавца на
                странице Товара.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Сайт — информационная система, размещённая на
                сайте tst-trade.ru, которая предоставляет Клиентам возможность выбирать и приобретать
                Товары.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Сертификат — основной документ, подтверждающий
                членство в системе клубного отдыха (таймшер) и его характеристики.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Служба доставки — юридическое лицо,
                индивидуальный предприниматель или самозанятый, оказывающие услуги по доставке
                Заказов Клиентам.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Таймшер — членство в системе клубного отдыха,
                связанное с передачей в собственность третьим лицам недвижимого и движимого
                имущества клуба, принадлежащего последнему на праве собственности на
                определенный период времени (сервитут). Фактом членства в системе клубного
                отдыха (таймшер), является ценный сертификат, выдаваемый администрацией клуба
                или трастовой управляющей компанией, наделенной соответствующими полномочиями.
                Под членством в системе клубного отдыха (таймшер) следует также понимать любой
                другой документ, подтверждающий факт полной оплаты членства в системе клубного
                отдыха (например, именная пластиковая карта, договор о приобретении, либо иная
                форма установления организацией, выдающей права на владение членством в системе
                клубного отдыха (таймшер).
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Товар — права требования на Таймшер или права
                требования на несколько Таймшеров, которые Продавец разместил (или намерен
                разместить) к реализации на Платформе.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal>
              <span lang=ru>
                Заголовки статей и пунктов используются
                исключительно для удобства чтения и не влияют на смысл Договора.
              </span>
            </p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

            <p class=MsoNormal><span lang=ru>&nbsp;</span></p>

          </div>

        </div>


      </div>
      <div [hidden]="priceAgreementAccepted" #btnSubscribe class="modal-dealer__action mt2" style="text-align:right;">
        <button type="button" id="btnSubscribe" (click)="submitPriceAgreementAccept()" #btnSubscribe class="btn btn--primary">{{'Sign with SMS-code' | translate}}</button>
      </div>
    </div>
  </div>
</div>
