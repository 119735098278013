import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(private httpClient: HttpClient) {}

  async getEmail(): Promise<string> {
    const data = await this.httpClient
      .get<any>(`${environment.apiUrl}/api/SupportContacts`)
      .toPromise();
    return data.email || 'support@tst-trade.ru';
  }
}
