import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UserNotification } from 'src/app/shared/models/user-notification.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
export interface NotificationModel {
  notification: UserNotification;
  data: any | undefined;
}
@Component({
  selector: 'app-notification-requsition-modal',
  templateUrl: './notification-requsition-modal.component.html',
  styleUrls: ['./notification-requsition-modal.component.css']
})
export class NotificationRequsitionModalComponent
  extends SimpleModalComponent<NotificationModel, boolean>
  implements NotificationModel, OnInit
{
  notification!: UserNotification;
  data: any | undefined;
  constructor(private notificationService: NotificationService) {
    super();
  }

  ngOnInit(): void {}
  Confirm() {
    this.result = true;
    this.close();
  }
  Cancel() {
    this.result = false;
    this.close();
  }
}
